// import React, { useState } from 'react'
// import DatePicker from 'react-datepicker'
// import "react-datepicker/dist/react-datepicker.css";
// import { useStepperContext } from './StepperContext';
// // import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
// import { TextField } from '@mui/material';

// interface DatePickProps{
//   label:string;
//   inputId:number;
//   formName:string;
//   required:boolean,
//   userselectedData:string
// }

// const DatePick: React.FC<DatePickProps> = ({ label, inputId, formName,required,userselectedData,initial }) => {
//   const { addUserData } = useStepperContext();
//   const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

//   const handleDateChange = (date: Date | null) => {
//     setSelectedDate(date);

//     // Example of adding the selected date to userData
//     // if (date) {
//       const newDataArray = {
//         id: inputId,  // Assuming inputId can be used as a unique identifier
//         [label]: date.toISOString(), // Store date as ISO string or format as needed
//         formName: formName,
//         required:required
//       };

//       addUserData(newDataArray);

//   };
//   const hasError=userselectedData?.includes(label)

//   return (

//     <div>
//  <DatePicker

//         selected={selectedDate}
//         label={label}
//         defaultValue={initial}
//         inputId={inputId}
//         formName={formName}
//         onChange={handleDateChange}
//         dateFormat="dd/MM/yyyy" // Example of date format (you can change this)

//       />

//       {hasError && <div>This field is required</div>}

//     </div>
//     // <LocalizationProvider dateAdapter={AdapterDateFns}>
//     //   <DesktopDatePicker
//     //     inputFormat="MM/dd/yyyy"
//     //     label={label}
//     //     value={selectedDate}
//     //     onChange={handleDateChange}
//     //     renderInput={(params) => <TextField {...params} />}
//     //   />
//     // </LocalizationProvider>
//   );
// };

// export default DatePick;

import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { useStepperContext } from './StepperContext';
import dayjs from 'dayjs'; // For date formatting

interface DatePickProps {
  label: string;
  inputId?: number;
  formName: string;
  required?: boolean;
  userselectedData?: any;
  initial?: Date; // Optional initial date
}

const DatePick: React.FC<DatePickProps> = ({
  label,
  inputId,
  formName,
  required,
  userselectedData,
  initial,
}) => {
  const { addUserData } = useStepperContext();
  // Ensure dateValue is initialized as an empty string if no initial date is provided
  const [dateValue, setDateValue] = useState<string>(
    initial ? dayjs(initial).format('YYYY-MM-DD') : ''
  );

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    // Simple date validation and formatting
    const parsedDate = dayjs(value, 'YYYY-MM-DD', true);
    if (parsedDate.isValid()) {
      setDateValue(value);

      const newDataArray = {
        id: inputId,
        [label]: value, // Store date as ISO string
        formName: formName,
        required: required,
      };

      addUserData(newDataArray as any);
    }
  };

  const hasError = userselectedData?.includes(label);

  return (
    <div>
      <TextField
        label={label}
        value={dateValue}
        type="date"
        onChange={handleDateChange}
        required
        fullWidth
        error={hasError}
        helperText={hasError ? 'This field is required' : ''}
        InputLabelProps={{ shrink: true }} // Ensure label is always visible
      />
    </div>
  );
};

export default DatePick;
