import React, { useEffect, useState } from 'react';
import { useStepperContext } from './StepperContext';
import { Box, Button, Grid, Modal, Typography } from '@mui/material';
import TextBox from './TextBox';
import CustomisedButton from './CustomisedButton';
import Popup from './Popup';

import {
  CommerRentRetailnShow,
  CommercialIndSpace,
  CommercialSellIndSp,
  CommercialSellOff,
  CommercialSellRetailnShow,
  CommercialSellWare,
  CommercialrentOffice,
  CommercialrentWareh,
  ResidentialSellPlot,
  Residentialrent,
  Residentialsell,
} from '../data';
import CustomButton from './CustomButton';
import CustomBoxButton from './CustomBoxButton';
import DropBox from './DropBox';
import DropBox1 from './DropBox1';
import FDropDown from './FDropDown';
import DropDown1 from './DropDown1';
import PriceTextField from './PriceTextField';
import PriceTextField1 from './PriceTextField1';
import PriceTextField2 from './PriceTextField2';
import DatePick from './DatePick';
import CustomisedButton1 from './CustomisedButton1';
import AccessButton from './AccessButton';
import TextAreaBox from './TextAreaBox';
import YFDropdown from './YFDropdown';
// import ModalDataForm from "./ModalDataForm";
import MultiSelectorAmenitiesButton from '../reusablecomponent/MultiSelectorAmenitiesButton';
import MultiSelectorButton2 from '../reusablecomponent/MultiSelectorButton2';
import BackComponent from './icon/BackComponent';

interface FormField {
  id: number;
  title: string;
  icon: string;
}

export interface FormOffers {
  formName: string;
  fields: {
    type: string;
    data: FormField[];
    label: string;
    inputId: number;
    required: boolean;
  }[];
}

interface PropertyDetailsProps {
  onNext: () => void;
  onBack: () => void;
  onReset: () => void;
}

const PropertyDetails: React.FC<PropertyDetailsProps> = ({
  onNext,
  onBack,
  onReset,
}) => {
  const {
    addUserData,
    userData,
    navprop,
    ammenties,
    addCurrentPage,
    customFormProps,
    addCustomFormDetails,
    customFormDetails,
    addnavprop,
    location
  } = useStepperContext();

  const [userselectedData, setuserSelectedData] = useState<string[]>([]);
  const [modalOpen, setModalOpen] = useState(false); // State for modal visibility
  const Construction = userData.find((item) => item?.['Possession Status'])?.[
    'Possession Status'
  ];

  const GetProperty = () => {
    if (userData) {
      const Looking_To = userData.find((item) => item?.['Looking To'])?.[
        'Looking To'
      ];
      const select_property_type = userData.find(
        (item) => item?.['Property Subtype']
      )?.['Property Subtype'];

      if (Looking_To === 'Rent/Lease') {
        switch (select_property_type) {
          case 'Apartment':
          case 'Independent House':
          case 'Villa':
            return Residentialrent;
          case 'Retail Shop':
          case 'Showroom':
            return CommerRentRetailnShow;
          case 'Office Space':
            return CommercialrentOffice;
          case 'Warehouse':
            return CommercialrentWareh;
          case 'Industrial Space':
            return CommercialIndSpace;
          default:
            return null;
        }
      } else if (Looking_To === 'Sell') {
        switch (select_property_type) {
          case 'Plot / Land':
            return ResidentialSellPlot;
          case 'Retail Shop':
          case 'Showroom':
            return CommercialSellRetailnShow;
          case 'Office Space':
            return CommercialSellOff;
          case 'Warehouse':
            return CommercialSellWare;
          case 'Industrial Space':
            return CommercialSellIndSp;
          default:
            return Residentialsell;
        }
      }
    }
  };

  const data2 = GetProperty();
  console.log('my data', data2);

  useEffect(() => {
    const filteredPropertyDetails = select_type === 'Apartment' 
  ? (data2?.property_details as any).filter((item:any) => item.label !== 'Plot Area') 
  : data2?.property_details || [];

  const updated={
    ...data2,
    property_details:filteredPropertyDetails
  }

  console.log("newdata2",updated)
    if (updated) {
      // addCustomFormDetails(data2)
      addCustomFormDetails(updated);
    }
    if (Construction) {
      setSelectedValue(Construction);
    }
  }, [data2, Construction, Construction]);

  const generateValidationSchema = (customFormDetails: any, userData: any) => {
    let requiredLabels = customFormDetails?.property_details
      ?.filter((item: any) => item.required)
      ?.map((item: any) => item.label);

    // If possession status is 'Under Construction', ensure 'calendar2' is included in required labels
    if (selectedValue === 'Under Construction') {
      const calendar2Label = customFormDetails?.property_details?.find(
        (item: any) => item.Type === 'calendar2'
      )?.label;
      if (calendar2Label) {
        requiredLabels.push(calendar2Label);
      }
    }

    const existingLabels = requiredLabels?.filter(
      (label: any) => !userData.some((item: any) => item.hasOwnProperty(label))
    );

    if (existingLabels?.length > 0) {
      setuserSelectedData(existingLabels);
    } else {
      onNext();
    }
  };

  const handleSubmit = () => {
    // Log the filled fields
console.log("meion",location)
    if (selectedValue === 'Ready to Move') {
      onNext(); // Go to the next page if possession status is "Ready to Move"
    } else if (selectedValue === 'Under Construction') {
      generateValidationSchema(customFormDetails, userData); // Validate if possession status is "Under Construction"
    } else {
      generateValidationSchema(customFormDetails, userData);
    }
  };
  console.log('userData', userData);
  const getInitialValue = (label: any) => {
    const entry = userData.find((obj) => obj.hasOwnProperty(label));
    return entry ? entry[label] : '';
  };

  const property = userData.find((item) => item?.['Property Type'])?.[
    'Property Type'
  ];
  const select_type = userData.find((item) => item?.['Property Subtype'])?.[
    'Property Subtype'
  ];

  const furnish = userData.find((item) => item?.['Furnishing'])?.['Furnishing'];
  const amenity = userData.find((item) => item?.['Amenities'])?.['Amenities'];

  const [selectedValue, setSelectedValue] = useState<string | null>(null);

  const handleBoxButtonChange = (value: string) => {
    setSelectedValue(value);
  };
  //const filteredPropertyDetails = select_type === 'Apartment' ? data2?.property_details?.filter((item:any) => item.label !== 'Plot Area House') : data2?.property_details||[];
 
 
  const filteredPropertyDetails = select_type === 'Apartment' 
  ? (data2?.property_details as any).filter((item:any) => item.label !== 'Plot Area') 
  : data2?.property_details || [];

  console.log('data2',filteredPropertyDetails,select_type)




  return (
    <>
      <div className="min-h-[1700px] p-2 overflow-hidden">
        <Button
          onClick={onBack}
          style={{
            position: 'relative',
            right: '20px',
          }}
        >
          <BackComponent />
        </Button>
        <Typography
          style={{
            fontWeight: '700',
            marginLeft: '40%',
            position: 'relative',
            bottom: '30px',
          }}
        >
          Property Details
        </Typography>

        {filteredPropertyDetails?.map((dataItem:any, index:number) => (
          <Box key={index}>
            {dataItem.Type === 'Text Field' && (
              <div className="my-3 ">
                {/* <label>{item.label}</label> */}
                <TextBox
                  label={dataItem.label}
                  inputId={dataItem.inputID}
                  formName="Property Details"
                  initial={getInitialValue(dataItem.label)}
                  label2={dataItem.label2}
                  keyboard={dataItem.keyboard}
                  userselectedData={userselectedData}
                />
              </div>
            )}
            {dataItem.Type === 'Text Field Built' && (
              <div className="my-2 flex">
                {/* <label>{item.label}</label> */}
                <TextBox
                  label={dataItem.label}
                  inputId={dataItem.inputID}
                  formName="Property Details"
                  label2={dataItem.label2}
                  keyboard={dataItem.keyboard}
                  userselectedData={userselectedData}
                  initial={getInitialValue(dataItem.label)}
                />
              </div>
            )}
            {dataItem.Type === 'Box Type' && (
              <div className="my-2">
                <Typography className="text-start  ">
                  {dataItem.label}
                </Typography>
                <div className="flex flex-row flex-wrap my-2">
                  {/* {dataItem.data && dataItem.data.map((buttonItem,buttonIndex)=>( */}
                  <CustomisedButton
                    data={dataItem.data}
                    label={dataItem.label}
                    inputId={dataItem.inputID}
                    formName="Property Details"
                    required={dataItem.required}
                    userselectedData={userselectedData}
                    initial={getInitialValue(dataItem.label)}
                  />

                  {/* ))} */}
                </div>
              </div>
            )}
            {dataItem.Type === 'Custom Box Type2' && (
              <div className="my-2 ">
                <Typography className="text-start">
                  {dataItem.label}
                  {dataItem.required && (
                    <span style={{ color: 'red' }}> *</span>
                  )}{' '}
                  {/* Indicate required fields */}
                </Typography>
                <div className="flex flex-row flex-nowrap  my-2">
                  {/* {dataItem.data && dataItem.data.map((buttonItem,buttonIndex)=>( */}
                  <Box>
                    <CustomBoxButton
                      data={dataItem.data}
                      // title={buttonItem.title}
                      label={dataItem.label}
                      inputId={dataItem.inputID}
                      formName="Property Details"
                      initial={getInitialValue(dataItem.label)}
                      required={dataItem.required}
                      userselectedData={userselectedData}
                      onSelectionChange={handleBoxButtonChange} // Pass the handler
                    />
                  </Box>
                  {/* ))} */}
                </div>
              </div>
            )}

            {dataItem.Type === 'Custom Box Type' && (
              <div className="my-2 mt-4">
                <Typography className="text-start">
                  {dataItem.label}
                  {dataItem.required && (
                    <span style={{ color: 'red' }}> *</span>
                  )}{' '}
                  {/* Indicate required fields */}
                </Typography>
                <div className="flex flex-row flex-nowrap  my-2">
                  {/* {dataItem.data && dataItem.data.map((buttonItem,buttonIndex)=>( */}
                  <Box>
                    <CustomBoxButton
                      data={dataItem.data}
                      // title={buttonItem.title}
                      label={dataItem.label}
                      inputId={dataItem.inputID}
                      formName="Property Details"
                      initial={getInitialValue(dataItem.label)}
                      required={dataItem.required}
                      userselectedData={userselectedData}
                      onSelectionChange={handleBoxButtonChange} // Pass the handler
                    />
                  </Box>
                  {/* ))} */}
                </div>
              </div>
            )}

            {dataItem.Type === 'Box Type10' && (
              <div className="my-2">
                <Typography className="text-start">
                  {dataItem.label}
                  {dataItem.required && (
                    <span style={{ color: 'red' }}> *</span>
                  )}
                  {/* Indicate required fields */}
                </Typography>
                <div className="flex flex-row flex-wrap my-2">
                  {/* {dataItem.data && dataItem.data.map((buttonItem,buttonIndex)=>( */}
                  <div>
                    <MultiSelectorAmenitiesButton
                      data={dataItem.data}
                      label={dataItem.label}
                      inputId={dataItem.inputID}
                      formName="Property Details"
                      initial={getInitialValue(dataItem.label)}
                      required={dataItem.required}
                      userselectedData={userselectedData}
                    />
                  </div>
                  {/* ))} */}
                </div>
              </div>
            )}

            {dataItem.Type === 'Box Type2' && (
              <div className="my-3">
                <Typography className="text-start">
                  {dataItem.label}
                  {dataItem.required && (
                    <span style={{ color: 'red' }}> *</span>
                  )}{' '}
                  {/* Indicate required fields */}
                </Typography>
                <div className="flex flex-row flex-wrap gap-4 my-3">
                  {/* {dataItem.data && dataItem.data.map((buttonItem,buttonIndex)=>( */}
                  <div>
                    <CustomButton
                      data={dataItem.data}
                      label={dataItem.label}
                      inputId={dataItem.inputID}
                      formName="Property Details"
                      initial={getInitialValue(dataItem.label)}
                      required={dataItem.required}
                      userselectedData={userselectedData}
                    />
                  </div>
                  {/* ))} */}
                </div>
              </div>
            )}

            {dataItem.Type === 'calendar2' &&
              selectedValue === 'Under Construction' && (
                <div>
                  <DatePick
                    label={dataItem.label}
                    inputId={dataItem.inputID}
                    formName="Property Details"
                    required={dataItem.required}
                    userselectedData={userselectedData}
                    initial={getInitialValue(dataItem.label)}
                  />
                </div>
              )}

            {dataItem.Type === 'Box Type11' && (
              <div>
                <Typography className="text-start mt-4">
                  {dataItem.label}
                </Typography>
                <div className="flex gap-7 my-3 ">
                  {/* {dataItem.data && dataItem.data.map((buttonItem,buttonIndex)=>( */}
                  <Box>
                    <MultiSelectorButton2
                      data={dataItem.data}
                      label={dataItem.label}
                      initial={getInitialValue(dataItem.label)}
                      inputId={dataItem.inputID}
                      formName="Property Details"
                      required={dataItem.required}
                    />
                  </Box>
                  {/* ))} */}
                </div>
              </div>
            )}
            {dataItem.Type === 'Box Type3' && (
              <div>
                <Typography sx={{ marginY: '3px' }} className="text-start">
                  {dataItem.label}
                </Typography>
                <div className="flex flex-row gap-10">
                  <div className="my-3">
                    <AccessButton
                      data={dataItem.data}
                      label={dataItem.label}
                      inputId={dataItem.inputID}
                      formName="Property Details"
                      required={dataItem.required}
                      userselectedData={userselectedData}
                      initial={getInitialValue(dataItem.label)}
                    />
                  </div>
                  {/* ))} */}
                </div>
              </div>
            )}
            {dataItem.Type === 'Drop down' && (
              <div>
                <DropBox1
                  label={dataItem.label}
                  options={dataItem.data}
                  inputId={dataItem.inputID}
                  formName="Property Details"
                  required={dataItem.required}
                  userselectedData={userselectedData}
                  initial={getInitialValue(dataItem.label)}
                />
              </div>
            )}

            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ width: '100%' }}>
                {dataItem.Type === 'FDrop down' && (
                  <div className="text-start ">
                    <FDropDown
                      label={dataItem.label}
                      options={dataItem.data}
                      inputId={dataItem.inputID}
                      formName="Property Details"
                      required={dataItem.required}
                      userselectedData={userselectedData}
                      initial={getInitialValue(dataItem.label)}
                    />
                  </div>
                )}
              </div>
              <div style={{ width: '100%' }}>
                {dataItem.Type === 'Drop down1' && (
                  <div className="text-start mt-2">
                    <YFDropdown
                      label={dataItem.label}
                      options={dataItem.data}
                      inputId={dataItem.inputID}
                      formName="Property Details"
                      required={dataItem.required}
                      userselectedData={userselectedData}
                      initial={getInitialValue(dataItem.label)}
                    />
                  </div>
                )}
              </div>
            </div>

            {dataItem.Type === 'Price Text Field' && (
              <div>
                {/* <label>{item.label}</label> */}
                <PriceTextField
                  label={dataItem.label}
                  inputId={dataItem.inputID}
                  formName="Property Details"
                  label2={dataItem.label2}
                  keyboard={dataItem.keyboard}
                  required={dataItem.required}
                  userselectedData={userselectedData}
                />
              </div>
            )}
            {dataItem.Type === 'Price Text Field1' && (
              <div>
                {/* <label>{item.label}</label> */}
                <PriceTextField1
                  label={dataItem.label}
                  inputId={dataItem.inputID}
                  formName="Property Details"
                  label2={dataItem.label2}
                  keyboard={dataItem.keyboard}
                  required={dataItem.required}
                  userselectedData={userselectedData}
                />
              </div>
            )}
            {dataItem.Type === 'Price Text Field2' && (
              <div>
                {/* <label>{item.label}</label> */}
                <PriceTextField2
                  label={dataItem.label}
                  inputId={dataItem.inputID}
                  formName="Property Details"
                  label2={dataItem.label2}
                  keyboard={dataItem.keyboard}
                  required={dataItem.required}
                  userselectedData={userselectedData}
                />
              </div>
            )}
            {dataItem.Type === 'calender' && (
              <div>
                <DatePick
                  label={dataItem.label}
                  inputId={dataItem.inputID}
                  formName="Property Details"
                  required={dataItem.required}
                  userselectedData={userselectedData}
                />
              </div>
            )}

            {dataItem.Type === 'Text Area' && (
              <div>
                <Typography className="text-start">{dataItem.label}</Typography>
                <TextAreaBox
                  label={dataItem.label}
                  inputId={dataItem.inputID}
                  formName="Property Details"
                  required={dataItem.required}
                  userselectedData={userselectedData}
                />
              </div>
            )}
          </Box>
        ))}

       

        <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 10,
          }}
        >
          <Box className="bg-white custom-xxl:w-2/5 md:w-2/5 sm:w-9/12 p-2 flex flex-col  justify-center items-center">
            {data2?.property_details?.map((dataItem, index) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {dataItem.Type === 'AMENITIES' && (
                  <div key={index}>
                    <Typography className="text-start text-black font-bold">
                      {dataItem.label}
                    </Typography>
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    >
                      <MultiSelectorButton2
                        data={dataItem.data}
                        label={dataItem.label}
                        inputId={dataItem.inputID}
                        formName="Property Details"
                        required={dataItem.required}
                        userselectedData={userselectedData}
                        initial={getInitialValue(dataItem.label)}
                      />
                    </div>
                  </div>
                )}
                {dataItem.Type === 'Furnishing' && (
                  <div key={index}>
                    <Typography className="text-start">
                      {dataItem.label}
                    </Typography>
                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                      <MultiSelectorButton2
                        data={dataItem.data}
                        label={dataItem.label}
                        inputId={dataItem.inputID}
                        formName="Property Details"
                        required={dataItem.required}
                        userselectedData={userselectedData}
                        initial={getInitialValue(dataItem.label)}
                      />
                    </div>
                  </div>
                )}
              </Box>
            ))}

            <Button
              variant="contained"
              sx={{
                width: '20%',
                justifyContent: 'center',
                display: 'flex',
                mx: 'auto',
                my: 2,
              }}
              onClick={() => {
                setModalOpen(!modalOpen);
              }}
            >
              Save
            </Button>
          </Box>
        </Modal>

        {/* <Typography variant="h6">Built Up Area</Typography>
      <Typography>{getInitialValue("Built up Area")}</Typography>
      <Typography>{getInitialValue("Plot Area")}</Typography> */}
        {property !== 'Commercial' && select_type !== 'Plot / Land' && (
          <>
            <button
              style={{
                color: '#2E368F',
                fontWeight: '400',
                marginTop: '10px',
                position: 'relative',
                right: '150px',
              }}
              onClick={() => setModalOpen(!modalOpen)}
            >
              + Add Furnishings / Amenities
            </button>
            <Typography sx={{ my: 1 }}>
              <span style={{ fontWeight: 600, marginTop: '5px' }}>
                Furnishing:
              </span>
              {furnish}
            </Typography>
            <Typography>
              <span style={{ fontWeight: 600, marginTop: '5px' }}>
                Ammenities:
              </span>
              {amenity}
            </Typography>
          </>
        )}

<>
          {' '}
          <button
            onClick={handleSubmit}
            className="w-[150px]  bg-[#2E368F] text-white rounded-[20px]  mt-10 custom-xxl:ml-[80%] xl:ml-[80%] lg:ml-[75%] md:ml-[70%] sm:ml-[70%] p-2"
          >
            Continue
          </button>
        </>
      </div>
    </>
  );
};

export default PropertyDetails;
