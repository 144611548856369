import React, { useEffect, useState } from 'react';

//box2
import { Interface } from 'readline';
import { useStepperContext, initialState } from './StepperContext';
import { title } from 'process';
import { Button } from '@mui/material';
import { blueGrey } from '@mui/material/colors';

interface CustomBoxProps {
  title?: string;
  label: string;
  inputId?: number;
  formName: string;
  values?: string;
  required?: boolean;
  data: any;
  initial: any;
  userselectedData?: any;
}

const AccessButton: React.FC<CustomBoxProps> = ({
  title,
  label,
  inputId,
  formName,
  data,
  required,
  initial,
}) => {
  const { addUserData, userData, addCustomFormDetails } = useStepperContext();
  // const[buttonColor,setButtonColor]=useState('transparent')
  const [activeButton, setActiveButton] = useState<number | null>(null);

  useEffect(() => {
    const initValue = data.find((item: any) => item.title === initial);
    setActiveButton(initValue?.id || null);
  });

  const handleClick = (item: any) => {
    setActiveButton(item.id);
    const data = {
      id: inputId,
      [label]: item.title,
      formName: formName,
      required: required,
    };
    addUserData(data as any);
    // setButtonColor(prev=>prev==='transparent'?'#515AA8':'transparent')
  };

  return (
    <div className="flex gap-5 ">
      {data.map((item: any) => (
        <Button
          key={item.id}
          onClick={() => handleClick(item)}
          className="h-10 w-[100px]"
          sx={{
            borderRadius: '999px',
            padding: '20px',
            bgcolor: activeButton === item.id ? '#515AA8' : '#F5F4F8', // Active or default background color
            color: activeButton === item.id ? 'white' : 'black', // Text color
            textTransform: 'capitalize', // Capitalize button text
            '&:hover': {
              bgcolor: activeButton === item.id ? '#515AA8' : '#F5F4F8',
            },
          }}
        >
          {item.title}
        </Button>
      ))}
    </div>
  );
};

export default AccessButton;
