import { Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useStepperContext } from './StepperContext';
import { blueGrey } from '@mui/material/colors';

interface CustomisedButtonProps {
  title?: string;
  label: string;
  inputId?: number;
  formName: string;
  required?: boolean;
  userselectedData?: any;
  data: any;
  initial: any;
}

const CustomisedButton: React.FC<CustomisedButtonProps> = ({
  title,
  label,
  inputId,
  formName,
  data,
  required,
  userselectedData,
  initial,
}) => {
  const { addUserData, userData } = useStepperContext();
  const [activeButton, setActiveButton] = useState<number | null>(null);

  useEffect(() => {
    const initValue = data.find((item: any) => item.title === initial);
    setActiveButton(initValue?.id || null);
  });

  const handleClick = (item: any) => {
    setActiveButton(item.id);
    const data = {
      id: inputId,
      [label]: item.title,
      formName: formName,
      required: required,
    };
    addUserData(data as any);
  };
  console.log(userData);

  const hasError = userselectedData?.includes(label);

  return (
    <div>
      {data.map((item: any) => (
        <Button
          // className='w-auto'
          // className=' w-10 '
          // style={{backgroundColor:"blue",color:"white"}}
          onClick={() => handleClick(item)}
          sx={{
            width: '200px',
            px: 2,
            borderRadius: '999px',
            my: 1,
            mx: 1,
            bgcolor: activeButton === item.id ? '#515AA8' : '#F5F4F8', // Active or default background color
            color: activeButton === item.id ? 'white' : 'black', // Text color
            textTransform: 'capitalize', // Capitalize button text
            '&:hover': {
              bgcolor: activeButton === item.id ? '#515AA8' : '#F5F4F8', // Hover background color
            },
          }}
        >
          {item.title}
        </Button>
      ))}
      {hasError && (
        <Typography sx={{ color: '#C91C1C' }}>
          This field is required
        </Typography>
      )}
    </div>
  );
};

export default CustomisedButton;
