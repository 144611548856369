// import React from "react";
// import SVGComponent from "../../Icons/SVGComponent";
// import LocationComponent from "../../Icons/Location";
// import gridam from "../../assets/crown.svg";
// import image from "../../images/imageflart.png";
// import mobilecard from "../../images/imageflart.png";
// import VerifyComponent from "../../Icons/Verify";
// import { Typography } from "@mui/material";
// import location from "../assets/location.png";
// import { axiosInstance } from "../api/APIs";
// import { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { truncateString } from "../ReuseableCompounts/TruncateString";
// import star from '../images/star.svg'
// import goldtick from '../images/goldtick.png'
// import loaction from '../../compounts/images/location.png'
// import heart from "../../compounts/images/Heart.svg";
// import LikedHeart from "../images/LikedHeart.png";
// import { formatPrice } from "../ReuseableCompounts/PriceFormat";
// import { formatSQPrice} from "../ReuseableCompounts/PriceSQFormat";
// import { handleChange } from '../ReuseableCompounts/PostDetail';
// import { useSelector}from 'react-redux'


// interface Listing {
//   id: number;
//   price: string;
//   size: string;
//   size1: string;
//   text: string;
//   text1: string;
//   image: string;
//   mobile: string;
// }
// interface image {
//   id: number;
//   isCover: any;
//   isvisible: any;
//   name: any;
//   uri: any;

// }

// interface rows {
//   id: number;
//   post_id: number;
//   property_type?: any;
//   looking_to?: string;
//   rent_or_lease?: string;
//   property_available_for?: string;
//   images?: image[];
//   view_post_count: number;
//   bhk: string;
//   city: string;
//   building_name: string;
//   landmark_street: string;
//   build_up_area?: string;
//   built_up_area?: string;
//   plot_area?: string;
//   PriceperSqft?: string;
//   price: number;
//   cost?: number;
//   monthly_rent?: number;
//   enter_lease_amount?: number;
//   lease_amount?: any
//   liked?: any
//   user_id?: any
//   district?: string;
//   kbl_property: any








// }



// const PropertyCard: React.FC = () => {
//   const loginData = useSelector((state: any) => state.userData.userLoginData);
//   const [cart, setCart] = useState<rows[]>([])
//   const navigate = useNavigate()

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axiosInstance.get("/api/vattara/app/homescreen/vattaraProperty")
//         console.log(response.data.rows)
//         setCart(response.data.rows)

//       } catch (error) {
//         console.log(error);

//       }
//     }
//     fetchData()
//   }, [])

//   function handleNavigate() {
//     navigate("/exclusive", { state: { data: cart } })
//   }


  



//   const gradientBorderStyle = {
//     border: '2.46px solid transparent', // Thin border with specified width
//     borderRadius: '20px', // Optional: Border radius if needed
//     borderImage: 'linear-gradient(135deg, rgba(0, 0, 128, 1) 0%, rgba(248, 248, 248, 0.5) 50%, rgba(255, 255, 255, 0) 100%) 1', // Gradient border
//   };



//   return (
//     <div className="   xl:mx-[20%]   ">
//       {" "}
//       <div className="flex justify-between items-center mt-6 mb-3">
//         <p className="text-[#1C1C1E] font-bold text-lg">Vattara verified properties</p>
//         <p className="text-[#006CE7] font-semibold text-lg" onClick={handleNavigate}>explore</p>
//       </div>
//       <div className="grid grid-cols-4 sl:hidden gap-3  w-[100%]">
//         {cart && cart.length > 0 ? (
//           cart.map((item, index) => {

//             const price = item.price || item.cost || item.enter_lease_amount || item.lease_amount || item.monthly_rent;
//             const formattedPrice = formatPrice(price);
//             const coverimg = item.images && item.images.length > 0 ? item.images.find(img => img.isCover) || "" : "";
//             const locality = truncateString(item.city, 20)
//             const sqtv: any = item.PriceperSqft?item.PriceperSqft:"";
//             const priceSQTV = formatSQPrice(sqtv);

//             const propertyView = {
//               postId: item.post_id,
//               property_type: item.property_type,
//               looking: item.looking_to || item.rent_or_lease || item.property_available_for,
//               userId: loginData.user_id
//             }

//             return (
//               <div key={index} className='relative mt- bg-[#FFFFFF]   border-[#000080] border-opacity-30 border-2 p-2 rounded-2xl w-[95%] ' >
//                  <img className="absolute right-0 z-10 -top-5" src={gridam}/>
//                 <div className='relative '>
               
//                   <p className='absolute right-0 mr-1  top-3 bg-[#2E368F] text-white py-1 px-3 rounded-2xl '>{item.looking_to || item.rent_or_lease || item.property_available_for}</p>
//                   <img onClick={() => { handleChange(propertyView) }}
//                     className='w-[15vw] h-[20vh]  flex justify-center bg-black items-center object-contain  rounded-3xl ' src={typeof coverimg === 'object' && coverimg?.uri ? coverimg.uri : ''} />

//                   <div className={`${item.kbl_property ? "flex" : "hidden"}  items-center bg-[#6135DD] absolute bottom-0 rounded-bl-3xl p-1 rounded-tr-3xl left-0`}>
//                     <img className="w-5 h-5" src={star} />
//                     <p className="text-white text-sm mr-1">VATTARA VERIFIED</p>
//                   </div>
//                 </div>
//                 <div className='ml-3'>
//                   <div className='flex items-center '>
//                     <p className='text-base flex font-bold'>{item.bhk} {item.property_type}   </p>
//                     <img src={goldtick} />
//                   </div>
//                   <div className='flex gap-2 items-center'>
//                     <img className="h-5" src={loaction} />
//                     <p className='text-sm capitalize'>{locality}</p>

//                   </div>

//                   <div className='flex items-center  w-[100%]'>
//                     <p className='text-xl font-bold text-[#404040] w-[50%]'>₹ {formattedPrice} </p>
//                     <div className='border-[#404040] h-6 border-1 border-l  '></div>
//                     <div className='ml-2'>
//                       <div className='flex items-center text-sm font-medium text-[#404040]  w-[50%]'>
//                         <p>{item.built_up_area || item.plot_area} </p>
//                         {item.built_up_area || item.plot_area ? <p className='text-sm'>sq.ft</p> : ""}
//                       </div>
//                       <div className='flex items-center text-sm  font-medium text-[#404040]'>
                       
//                         {item.PriceperSqft ?  <p>₹ {priceSQTV}</p> : ""}
//                         {item.PriceperSqft ? <p>/sq.ft</p> : ""}
//                       </div>

//                     </div>
//                   </div>

//                 </div>
//               </div>
//             )
//           })
//         ) : (
//           <p className="text-center text-gray-500">No listings available.</p>
//         )}
//       </div>

//     </div>
//   );
// };

// export default PropertyCard;




import React from "react";
import SVGComponent from "../../Icons/SVGComponent";
import LocationComponent from "../../Icons/Location";
import gridam from "../../assets/crown.svg";
import image from "../../images/imageflart.png";
import mobilecard from "../../images/imageflart.png";
import VerifyComponent from "../../Icons/Verify";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { axiosInstance } from "../api/APIs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { truncateString } from "../ReuseableCompounts/TruncateString";
import star from '../images/star.svg'
import goldtick from '../images/goldtick.png'
import location from '../../compounts/images/location.png';
import heart from "../../compounts/images/Heart.svg";
import LikedHeart from "../images/LikedHeart.png";
import { formatPrice } from "../ReuseableCompounts/PriceFormat";
import { formatSQPrice} from "../ReuseableCompounts/PriceSQFormat";
import { handleChange } from '../ReuseableCompounts/PostDetail';
import { useSelector}from 'react-redux'
import DefaultScroll from '../../compounts/images/defaultScroll.svg';
import { margin } from "@mui/system";


interface Listing {
  id: number;
  price: string;
  size: string;
  size1: string;
  text: string;
  text1: string;
  image: string;
  mobile: string;
}
interface image {
  id: number;
  isCover: any;
  isvisible: any;
  name: any;
  uri: any;

}

interface rows {
  id: number;
  post_id: number;
  property_type?: any;
  looking_to?: string;
  rent_or_lease?: string;
  property_available_for?: string;
  images?: image[];
  view_post_count: number;
  bhk: string;
  city: string;
  building_name: string;
  landmark_street: string;
  build_up_area?: string;
  built_up_area?: string;
  plot_area?: string;
  PriceperSqft?: string;
  price: number;
  cost?: number;
  monthly_rent?: number;
  enter_lease_amount?: number;
  lease_amount?: any
  liked?: any
  user_id?: any
  district?: string;
  kbl_property: any








}



const PropertyCard: React.FC = () => {
  const loginData = useSelector((state: any) => state.userData.userLoginData);
  const [cart, setCart] = useState<rows[]>([])
  const [loadingCardId, setLoadingCardId] = useState<number | null>(null); // Track loading card ID

  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get("/api/vattara/app/homescreen/vattaraProperty")
        console.log(response.data.rows)
        setCart(response.data.rows)

      } catch (error) {
        console.log(error);

      }
    }
    fetchData()
  }, [])

  function handleNavigate() {
    navigate("/exclusive", { state: { data: cart } })
  }


  const handleChange = async (propertyView: any) => {
    const body = {
      post_id: propertyView.postId,
      selected_property_type: propertyView.property_type,
      looking_to: propertyView.looking,
      user_id: propertyView.userId,
    };

    setLoadingCardId(propertyView.postId); // Set loading for this card

    try {
      const response = await axiosInstance.post("/filter/get/postDetail", body);
      const queryString = new URLSearchParams({
        data: JSON.stringify(response.data),
        look: propertyView.looking || '',
      }).toString();

      const newWindow = window.open(`/PropertyDetails?${queryString}`, '_blank');
      if (newWindow) {
        newWindow.focus();
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoadingCardId(null); // Reset loading after request completes
    }
  };



  const gradientBorderStyle = {
    border: '2.46px solid transparent', // Thin border with specified width
    borderRadius: '20px', // Optional: Border radius if needed
    borderImage: 'linear-gradient(135deg, rgba(0, 0, 128, 1) 0%, rgba(248, 248, 248, 0.5) 50%, rgba(255, 255, 255, 0) 100%) 1', // Gradient border
  };



  return (
    < Box sx={{ width: "100%", justifyContent: "center", alignItems: "center", display: "flex" }}>
            <Box sx={{ width: { sm: "95%", md: "95%", lg: "95%", xl: "60%",xs:"95%" }, position: "relative" }}>
      <div className="flex justify-between items-center mt-6 mb-3">
        <p className="text-[#1C1C1E] font-bold text-lg">Vattara verified properties</p>
        <p className="text-[#006CE7] font-semibold text-lg" onClick={handleNavigate}>explore</p>
      </div>
      
      <Grid container spacing={3} >
      {cart && cart.length > 0 ? (
          cart.map((item, index) => {

            const price = item.price || item.cost || item.enter_lease_amount || item.lease_amount || item.monthly_rent;
            const formattedPrice = formatPrice(price);
            const coverimg = item.images && item.images.length > 0 ? item.images.find(img => img.isCover) || "" : "";
            const locality = truncateString(item.city, 20)
            const sqtv: any = item.PriceperSqft?item.PriceperSqft:"";
            const priceSQTV = formatSQPrice(sqtv);

            const propertyView = {
              postId: item.post_id,
              property_type: item.property_type,
              looking: item.looking_to || item.rent_or_lease || item.property_available_for,
              userId: loginData.user_id
            }

            return (
              <Grid item xs={6} sm={4} md={4} lg={3} key={index} sx={{marginBottom:"20px"}}>
               <Box className='relative bg-[#FFFFFF] border-[#000080] border-opacity-30 border-2 p-1 rounded-2xl'>
               {loadingCardId === item.post_id && (
                    <div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-50 z-10 rounded-2xl">
                      <CircularProgress color="inherit" />
                    </div>
                  )}
                 <img className="absolute right-0 z-10 -top-5" src={gridam}/>
                <div className='relative '>
               
                <div className='absolute right-0 mr-2 top-3 bg-[#2E368F] text-white py-1 px-3 rounded-2xl'>
                                                {item.looking_to || item.rent_or_lease || item.property_available_for}
                                            </div>           
                                                   <img onClick={() => { handleChange(propertyView) }}
 className='w-full h-[140px] sm:h-[180px] md:h-[180px] lg:h-[180px] xl:h-[180px] flex justify-center bg-black items-center object-cover rounded-[15px]'
 src={typeof coverimg === 'object' && coverimg?.uri ? coverimg.uri : DefaultScroll}/>
                  <div className={`${item.kbl_property ? "flex" : "hidden"}  items-center bg-[#6135DD] absolute bottom-0  p-1 left-0`} style={{borderTopRightRadius:"15px",borderBottomLeftRadius:"15px"}}>
                    <img className="w-5 h-5" src={star} />
                    <div className="text-white mr-1" style={{fontSize:"10px"}}>VATTARA VERIFIED</div>
                  </div>
                </div>
                <Box sx={{ width: { sm: "100%", md: "100%", lg: "100%", xl: "100%",xs:"95%" }}}>
                <div className='flex gap-1 items-left' style={{alignItems:"center"}} >
                                                <img src={goldtick} className="w-5 h-5" style={{marginLeft:"-3px"}} />
                                                <div style={{ fontSize: "15px", fontWeight: 800 }}>{item.bhk} {item.property_type}</div>
                                            </div>
                                            <div className='flex gap-1 items-left'>
                                                <img className="h-4" src={location} />
                                                <Box sx={{ fontSize: {xs:"12px",lg:"15px",md:"15px",sm:"15px"}}} className='text-sm capitalize'>{locality}</Box>
                                            </div>
                                            <div className='flex items-center w-full'>
                                                <Box sx={{ fontSize: {xs:"13px",lg:"15px",md:"15px",sm:"14px"}, fontWeight: 800 ,width:{xs:"90px",md:"100px",lg:"100px"}}}>₹ {formattedPrice}</Box>
                                                <div className='border-[#404040] h-6 border-l'></div>
                                                <div className='ml-1'>
                                                <Box sx={{ fontSize: {xs:"11px",lg:"15px",sm:"11px",md:"15px"} }}  className='flex items-center text-sm font-sm text-[#404040]' >
                                                        <div className='truncate'>{item.built_up_area || item.plot_area}</div>
                                                        {item.built_up_area || item.plot_area ? <div className='text-sm'>sq.ft</div> : ""}
                                                    </Box>
                                                    <Box sx={{ fontSize: {xs:"11px",lg:"13px",sm:"11px",md:"15px"} }} className='flex items-center text-sm font-sm text-[#404040]'>
                                                        {item.PriceperSqft ? <div>₹ {priceSQTV}</div> : ""}
                                                        {item.PriceperSqft ? <div>/sq.ft</div> : ""}
                                                    </Box>
                                                </div>
                                            </div>
                                        </Box>
                                        </Box>
                                        </Grid>
            )
          })
        ) : (
          <Box sx={{ width: "100%", justifyContent: "center", alignItems: "center", display: "flex" }}>
          <div className="text-center text-gray-500" style={{marginLeft:"100px"}}>No listings available.</div>

        </Box>
)}
      </Grid>

</Box>
</Box>  );
};

export default PropertyCard;




