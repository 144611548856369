
import React, { useEffect, useState, useRef } from "react";
import search from '../images/Search.svg';
import { Box, Button, Grow, Snackbar, Alert, Typography, Chip, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { IoIosSearch } from "react-icons/io";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { useDispatch } from 'react-redux';
import { setUsers } from '../../slices/filterSlice';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { axiosInstance } from '../api/APIs';
import { districts, capitalizeWords, filterData } from '../data';
import Filter from "../filter/Filter";

interface Props {
  setLookingToValue: any;
  setClook: any;
}

const Navbar: React.FC<Props> = ({ setLookingToValue, setClook }) => {
  const [location, setLocation] = useState<string>("Vellore");
  const [cityVisible, setCityVisible] = useState<boolean>(false);
  const [selectedLook, setSelectedLook] = useState<string | null>("Buy");
  const [searchCity, setSearchCity] = useState<string>("");
  const [subCategory, setSubCategory] = useState<string | null>("Buy");
  const [selectedProperties, setSelectedProperties] = useState<any[]>([]);
  const [autocompleteValue, setAutocompleteValue] = useState<any>(null);
  const [selectedLocations, setSelectedLocations] = useState<any[]>([]); // New state for selected locations
  const [error, setError] = useState<string | null>(""); // Allow null type
  const [loading,setLoading]=useState<boolean>(false)
   const [filterProperty, setFilterProperty] = useState<string[]>([]);
    const [filterLoaction, setfilterLoaction] = useState<any[]>([])
    const [filterPrice, setFilterPrice] = useState<any>()
    const [kb, setKb] = useState<any>(false)

  const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false); 


  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginData = useSelector((state: any) => state.userData.userLoginData);
  const lookData = filterData[0].look;

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => setError(null), 5000);
      return () => clearTimeout(timer);
    }
  }, [error]);
  const growRef = useRef<HTMLDivElement | null>(null); // Create a ref for the Grow component


  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (growRef.current && !growRef.current.contains(event.target as Node)) {
        setCityVisible(false); // Close the Grow component when clicking outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLocationChange = (value: any) => {
    setCityVisible(false);
    setLocation(value);
    setSelectedLocations([]); // Reset selected locations when location changes

  };
  const toggleFilterVisibility = () => {
    setIsFilterVisible(!isFilterVisible);
    // setSortOpen(false)

}
  const handleChangeLook = (value: string) => {
    setSelectedLook(value);
    setLookingToValue(value);
  };

  const handleChangeSub = (value: string) => {
    setSubCategory(value);
    setClook(value);
  };

  const handleSearchCity = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchCity(event.target.value);
  };

  const handleApply = async () => {
    // if (!searchCity) {
    //   setError("Please enter a city to search.");
    //   return;
    // }
  
    const cityCap = capitalizeWords(searchCity);
    
    // Create a single string from selected locations
    const selectedLocationString = [ ...selectedLocations.map(location => location.label)];
    const body: any = {
      district: location,
      city: selectedLocationString, // Use the combined city string here
      property_type: selectedProperties.length === 0
        ? (selectedLook === "Commercial"
          ? ["Showroom", "Office Space", "Retail Shop", "Industrial Space", "Warehouse"]
          : (selectedLook === "Buy" || selectedLook === "Rent" || selectedLook === "Lease"
            ? ["Apartment", "Vacant Land", "Villa", "Independent House"]
            : selectedProperties))
        : selectedProperties,
      looking_to: selectedLook === "Commercial" ? subCategory : selectedLook,
      maxAmount: 1000000000,
      user_id: loginData.user_id,
      kbl_property: false,
      minAmount: 0,
      min_plot_area: 0,
      max_ploat_area: 500000,
      ready_to_move: 0,
      facing: ["North", "South", "West", "East"],
      // selected_locations: selectedLocationString // Include the selected locations as a single string
    };
  
    console.log('Request body sent to API:', body); // Log the body before sending
  
    const url = selectedLook === "Commercial" ? "/filter/commercialFilter" : "/filter/resbuyfilterData";
  
    try {
      const response = await axiosInstance.post(url, body);
      dispatch(setUsers(response.data.message));
      navigate('/Property');
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  const handleBoxClick = () => {
    setCityVisible(!cityVisible);
  };

  const handleSelect = async (place: any) => {
    console.log("naga Selected place:", place); // Log the selected place

    try {
      const address = place?.value?.structured_formatting?.main_text;
      const results = await geocodeByAddress(address);
      const { lat, lng } = await getLatLng(results[0]);

      const addressComponents = results[0].address_components;
      const isWithinDistrict = addressComponents.some(component =>
        component.long_name.toLowerCase() === location.toLowerCase()
      );

      if (isWithinDistrict) {
        // Check if we already have 3 locations
        if (selectedLocations.length < 3) {
          setSelectedLocations(prev => [
            ...prev,
            {
              label: place.value.structured_formatting.main_text,
              place_id: place.value.place_id,
            }
          ]);
          setAutocompleteValue(place);
          setSearchCity(address);
          setError(""); // Set to empty string instead of null
          console.log('Coordinates:', { lat, lng });
        } else {
          setError("You can only select up to 3 locations."); // Show an error if max is reached
        }
      } else {
        setError('Selected locality is not within the selected district.');
      }
    } catch (error) {
      console.error('Error fetching location details:', error);
      setError('Error fetching location details');
    }
  };

  const handleDeleteLocation = (placeId: string) => {
    setSelectedLocations(prev => prev.filter(location => location.place_id !== placeId));
  };
  const isMobile = window.innerWidth <= 768; // Adjust breakpoint as needed


function handlefilter()
{
  navigate('/Property')
}  
return (
    <div>
      <div className="sm:block msm:hidden">
        <Box sx={{marginTop:"3.5%"}} >
          <Box sx={{ display: "flex", flexDirection: "row",width: "100%", height:{sm:"55vh", md:"80vh",lg:"80vh"}}}>
            <Box sx={{
              bottom: 0, width: "100%", display: "flex", justifyContent: "flex-end", flexDirection: "column",
              background: 'linear-gradient(90deg, #E2EFF6, #E7ECEF)', position: "relative",
            }}>
              <img         src="https://vattara-img.s3.amazonaws.com/Banner_BG.jpg" 
 alt="Building" style={{ width: "100%", height: "100%" }} />
            </Box>

            <Box sx={{ position: "absolute", width: "100%", top: "20%" }}>
              <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <Box sx={{ height: "100px", marginLeft: { sm: "10px", lg: "0px" }, width: "60%", display: "flex", flexDirection: "column", justifyContent: "left", mb: 7 }}>
                  <Typography sx={{ fontSize: {xs:"15px",sm:"25px",md:"28px",lg:"28px"}, fontWeight: 500, textTransform: "uppercase", lineHeight: 1, fontFamily: "k2d" }}>
                    Your new Guide to get a
                  </Typography>
                  <Typography sx={{ fontSize: {xs:"25px",sm:"35px",md:"50px",lg:"50px"}, fontWeight: 900, textTransform: "uppercase", color: "#1063b5", lineHeight: 1 }}>
                    Comfortable Home
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <Box sx={{ height: "40px", width: {  sm: '98%', md: "95%", ld: "65%", xl: "65%" }, display: "flex", justifyContent: "left" }}>
                  <Box sx={{ backgroundColor: "#fff", borderRadius: '10px 10px 0 0' }}>
                    {lookData.map((item) => (
                      <Button
                        variant="text"
                        onClick={() => handleChangeLook(item.button)}
                        sx={{
                          padding: '6px 24px',
                          borderRadius: '10px 10px 0 0',
                          fontWeight: "600",
                          fontFamily: "k2d",
                          transition: "all 0.5s ease-in-out",
                          fontSize: { lg: "18px", sm: "14px" },
                          borderBottom: selectedLook === item.button
                            ? '4px solid #0cb7d1'
                            : '4px solid transparent',
                          backgroundColor: selectedLook === item.button ? '#Fff' : '#FFFFFF',
                          color: selectedLook === item.button ? '#000' : '#000000',
                          boxShadow: 'none',
                          textTransform: 'none'
                        }}
                      >
                        {item.button}
                      </Button>
                    ))}
                  </Box>
                  <Box sx={{ ml: 10, gap: 2, display: selectedLook === "Commercial" ? "flex" : "none", backgroundColor: "#fff", height: "30px", mt: 2, borderRadius: '10px 10px 0 0', }}>
                  {filterData[0].SubCategory.map((item) => (
                    <Box key={item.id}>
                      <Button
                        variant="text"
                        onClick={() => handleChangeSub(item.button)}
                        sx={{
                          padding: '6px 24px',
                          borderRadius: '10px 10px 0 0',

                          fontWeight: "600",
                          height: "30px",

                          fontFamily: "k2d",
                          transition: "all 0.5s ease-in-out",
                          fontSize: "18px",
                          borderBottom: subCategory === item.button
                            ? '4px solid #0cb7d1 '
                            : '4px solid transparent',
                          backgroundColor: subCategory === item.button ? '#Fff' : '#FFFFFF',
                          color: subCategory === item.button ? '#000' : '#000000',
                          boxShadow: 'none',
                          textTransform: 'none'

                        }}
                      >
                        {item.button}
                      </Button>
                    </Box>
                  ))}
                </Box>
                </Box>
              </Box>

              <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <Box sx={{ background: "#fff", height: "130px",width: {  sm: '98%', md: "95%", lg: "95%", xl: "65%" }, display: "flex", flexDirection:"column",justifyContent: "center", borderRadius: "0 15px 15px 15px", alignItems: "center",alignContent:"center",justifyItems:"center" }}>
                  <Box sx={{height:"38%"}}>
                    <Box sx={{ display: "flex", flexDirection: "row" ,}}>
                      <Box
                        sx={{
                          justifyContent: "center",
                          display: "flex",
                          flexDirection: "row",
                          backgroundColor: "#FAFAFA",
                          height: "50px",
                          alignItems: "center",
                          padding:"15px",

                          borderRadius: "42px 0 0 42px",
                          cursor: "pointer"
                        }}
                        onClick={handleBoxClick}
                      >
                        <Typography
                          sx={{
                            color: "#000",
                            fontWeight: "600",
                            fontFamily: "k2d",
                            fontSize: { xs: "16px", sm: "18px", lg: "20px" }, // Adjust font size based on screen size
                          }}
                        >
                          {location}                        </Typography>
<Box>
{cityVisible ? <KeyboardArrowUpIcon sx={{ color: "#000", fontSize: "inherit" }} /> : <KeyboardArrowDownIcon sx={{ color: "#000", fontSize: "inherit" }} />}

</Box>
                      </Box>

                      <Box
  sx={{
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    marginLeft: "20px",
    // width:"1200px", // Full width on mobile, fixed width on larger screens
  }}
>                  
                      <Box
  sx={{
    width:{xs:"500px",sm:"400px",md:"550px",lg:"650px",xl:"650px"}, // Full width on mobile, fixed width on larger screens
  }}
>                    <GooglePlacesAutocomplete
                                apiKey={process.env.AIzaSyDz2gppwqEkyckilXFDJkvgEtcoS2OEvE}

                          autocompletionRequest={{
                            componentRestrictions: { country: ['in'] },
                          }}
                          selectProps={{
                            styles: {
                              control: (base: any) => ({
                                ...base,
                                border: 'none',
                                // width: isMobile ? '100%' : 500, // 500px for desktop, 100% for mobile

                                                        cursor:"pointer",
                                boxShadow: 'none',
                                '&:hover': { border: 'none' },
                              }),
                              option: (base) => ({
                                ...base,
                                cursor: 'pointer',
                                // marginTop:"80px"
                            
                                // backgroundColor:"red"
                              }),
                            },
                            value: autocompleteValue,
                            onChange: handleSelect,
                            placeholder: 'Search upto 3 localities...',
                            isClearable: true,
                            formatOptionLabel: (option: any) => option.label || option.description // Show the main_text in the select
                          }}
                          debounce={700}
                        /></Box>

                        <Button
                          sx={{
                            backgroundColor: "#02a7e4",
                            color: "#fff",
                            width: "150px",
                            height: "50px",
                            fontFamily: "K2D",
                            fontSize: { xs: "16px", sm: "18px", lg: "20px" }, // Adjust font size based on screen size
                            textTransform: "capitalize",
                            borderRadius: "0 42px 42px 0",
                            ':hover': {
                              backgroundColor: "#1063b5",
                            }
                          }}
                          onClick={handleApply}
                        >
                          Search
                        </Button>
                        {error && (
                          <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError("")}>
                            <Alert onClose={() => setError("")} severity="error">{error}</Alert>
                          </Snackbar>
                        )}
                      </Box>
                    </Box>

                    {/* Selected Locations Rendering */}
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', }}>
                      {selectedLocations.map((location) => (
                        <Chip
                          key={location.place_id}
                          label={location.label}
                          onDelete={() => handleDeleteLocation(location.place_id)}
                          sx={{ margin: '4px' }}
                        />
                      ))}
                    </Box>

                    <div style={{ height: '60px' }}>
                      <Grow in={cityVisible}>
                        <div             ref={growRef} // Attach the ref to the Grow component
 style={{
                          // position: "absolute",
                          height: '200px',
                          left: '40%',
                          top: '45%',
                          borderWidth:"1px",
                          borderRadius:"10px",
                          overflowY: 'scroll',
                          backgroundColor: 'white',
paddingLeft:30,                          display: 'flex',
                          width:"300px",
                          flexDirection: 'column',
                          gap: '8px',
                          transition: "all 0.5s ease-in-out"
                        }}>
                          {districts.map((item) => (
                            <div key={item} onClick={() => handleLocationChange(item)} style={{ color: 'black', padding: '8px 0' }}>
                              {item}
                            </div>
                          ))}
                        </div>
                      </Grow>
                    </div>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </div>

      <div className="sm:hidden msm:block mt-[15%]">
        <p className="text-black font-bold text-center mt-2">Innovate | Elevate | Reside</p>
        <div className="bg-[#E2E6F2] rounded-3xl p-2 mx-1 mt-2">
          <div className="flex gap-3 justify-center items-center mt-1 mb-2">
          {lookData
            .filter(item => item.button !== 'Lease') // Omit "Lease"
            .map((item) => (
              <div className={`py-2 px-7 rounded-3xl justify-center items-center ${selectedLook === item.button ? 'bg-[#2E368F] text-white' : "bg-[#F5F4F8] text-black"} flex`}>
                <button onClick={() => handleChangeLook(item.button)}>{item.button}</button>
              </div>
            ))}
          </div>
          <div className="flex relative mt-2">
            <input
              placeholder="Search up to 3 locations"
              className="w-full bg-[#F5F4F8] rounded-3xl h-14 placeholder:text-gray-500 placeholder:ml-4 px-6 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={searchCity}
              onChange={handleSearchCity} // This will now work
            />
            <div onClick={handleOpenModal} className="bg-[#2E368F] absolute top-1 h-12 right-2 px-4 rounded-3xl flex items-center justify-center cursor-pointer">
              <img className="h-5 w-5" src={search} alt="Search Icon" />
            </div>
          </div>
          <Dialog open={isModalOpen} onClose={handleCloseModal} fullWidth sx={{width:"100%"}}>
            <DialogTitle>Filter Properties</DialogTitle>
            <DialogContent>
              <Filter setLoading={setLoading} toggleFilterVisibility={handleCloseModal} kb={kb} setFilterProperty={setFilterProperty} setfilterLoaction={setfilterLoaction} setFilterPrice={setFilterPrice} />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModal} color="primary">Close</Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
