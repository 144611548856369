import React, { useEffect, useState } from 'react'
import { useStepperContext } from './StepperContext'
// import {data} from '../data'
import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import CustomisedButton from './CustomisedButton'
import TextBox from './TextBox'
import DatePick from './DatePick'
import DropBox from './DropBox'
import CustomButton from './CustomButton'
import PriceTextField2 from './PriceTextField2'
import PriceTextField from './PriceTextField'
import PriceTextField1 from './PriceTextField1'
import CustomBoxButton from './CustomBoxButton'
import DropBox1 from './DropBox1'
import FDropDown from './FDropDown'
import DropDown1 from './DropDown1'
import TextAreaBox from './TextAreaBox'
import AccessButton from './AccessButton'
import CustomisedButton1 from './CustomisedButton1'
import { Form, Formik } from 'formik'
import {
  CommerRentRetailnShow,
  CommercialIndSpace,
  CommercialSellIndSp,
  CommercialSellOff,
  CommercialSellRetailnShow,
  CommercialSellWare,
  CommercialrentOffice,
  CommercialrentWareh,
  ResidentialSellPlot,
  Residentialrent,
  Residentialsell,

} from "../data";
import { useDispatch,useSelector } from 'react-redux';
import { setUsers } from '../../../slices/filterSlice';
import { datafinder } from '../../PostForm/component'



interface PriceProps {
  onNext: () => void
  onBack: () => void
  onReset: () => void
  setFullData: any
  passedData?: any
  priceDetails: any
  closeModal:any

}

const Price: React.FC<PriceProps> = ({ setFullData,closeModal,  priceDetails, onNext, onBack, onReset }) => {
  const { addUserData, userData, customFormDetails, addAmmenties, addPropImage, addCurrentPage, addCustomProps, removeUserDataByKeys, addCostPerBuiltUpArea2, addCostPerBuiltUpArea3, addCostPerBuiltUpArea } = useStepperContext()
  const [userselectedData, setuserSelectedData] = useState([])
  const passedData = useSelector((state: any) => state.usersInfo.users);
  console.log("redux check price",passedData)
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [dataB, setDataB] = useState(priceDetails)
  console.log("price base", dataB, passedData)
  const [mypass, setMypass] = useState(passedData)
  const dispatch = useDispatch();
  console.log("check price", customFormDetails)
  const RentrLease = dataB.find((item: any) => item?.['Property available for'])?.['Property available for'];
  console.log("check price rent/lease", RentrLease)
  const propertyDescription = userData.find((item) => item?.['Property Description'])?.['Property Description'] || '';
  const Construction = userData.find((item) => item?.["Construction Status"])?.["Construction Status"]
  const MonthlyRent = dataB.find((item: any) => item?.['Monthly Rent'])?.['Monthly Rent']
  const SecurityDep = userData.find((item) => item?.['Select'])?.['Select']
  const LeaseAmt = userData.find((item) => item?.['Lease Amount'])?.['Lease Amount']
  console.log("initial price data", passedData)


  const transformValue = (value: any) => {
    if (value === 'Rent' || value === 'Lease') {
      return 'Rent/Lease';
    } else if (value === 'Sale') {
      return 'Sell';
    } else {
      return value; // or return a default value if needed
    }
  };

  const getInitialValues = (passedData: any): any => {
    const initialValues: any = {};

    // Use Object.entries to iterate over the key-value pairs of the object
    Object.entries(passedData).forEach(([key, value]) => {
      // Apply transformation if needed
      initialValues[key] = transformValue(value);
    });

    return initialValues;
  };


  const removeSymbolsFromValues = (dataArray: any[]) => {
    return dataArray.map(item => {
      // Iterate over the keys in each item
      for (const key in item) {
        // Check if the key corresponds to fields that require number conversion
        if (['Monthly Rent', 'Lease Amount', 'Price'].includes(key)) {
          // Remove any non-numeric characters (except dot for decimals)
          const numericValue = item[key].replace(/[^0-9.]/g, '');

          // Update the item with the cleaned numeric value
          item[key] = numericValue;
        }
      }
      return item;
    });
  };
  const cleanedData = removeSymbolsFromValues(dataB);



  const initialValues = getInitialValues(passedData[0]);
  console.log("initial price data", initialValues)

  const GetProperty = () => {
    if (passedData) {
      const Looking_To =
        initialValues?.["looking_to"] ||
        initialValues?.["rent_or_lease"] ||
        initialValues?.["property_available_for"];

      const select_property_type = initialValues?.["property_type"];
      console.log("look in details", Looking_To, select_property_type)

      if (Looking_To === "Rent/Lease") {
        switch (select_property_type) {
          case "Apartment":
          case "Independent House":
          case "Villa":
            return Residentialrent;
          case "Retail Shop":
          case "Showroom":
            return CommerRentRetailnShow;
          case "Office Space":
            return CommercialrentOffice;
          case "Warehouse":
            return CommercialrentWareh;
          case "Industrial Space":
            return CommercialIndSpace;
          default:
            return null;
        }
      } else if (Looking_To === "Sell") {
        switch (select_property_type) {
          case "Plot / Land":
            return ResidentialSellPlot;
          case "Retail Shop":
          case "Showroom":
            return CommercialSellRetailnShow;
          case "Office Space":
            return CommercialSellOff;
          case "Warehouse":
            return CommercialSellWare;
          case "Industrial Space":
            return CommercialSellIndSp;
          default:
            return Residentialsell;
        }
      }
    }
  };

  const data2 = GetProperty();




  useEffect(() => {
    const property = RentrLease;

    if (property === 'Lease') {
      if (MonthlyRent !== undefined && MonthlyRent !== null) {
        removeUserDataByKeys(['Monthly Rent']); // Ensure payload is an array
      }
      if (SecurityDep !== undefined && SecurityDep !== null) {
        removeUserDataByKeys(['Select']); // Ensure payload is an array
      }
    } else if (property === 'Rent') {
      if (LeaseAmt !== undefined && LeaseAmt !== null) {
        removeUserDataByKeys(['Lease Amount']); // Ensure payload is an array
      }
    }
    if (Construction) {
      setSelectedValue(Construction)
    }
  }, [Construction]);

  const filters = () => {
    if (!customFormDetails || !Array.isArray((customFormDetails as any).price_details)) {
      return [];
    }

    if (RentrLease === 'Rent') {
      return (customFormDetails as any).price_details.filter((item: any) => item.label !== 'Lease Amount');
    } else if (RentrLease === 'Lease') {
      return (customFormDetails as any).price_details.filter((item: any) => item.label !== 'Monthly Rent' && item.label !== 'Select');
    }

    // Default to returning the entire list if no filter criteria are met
    return (customFormDetails as any).price_details;
  };


  // Usage example:
  const filteredResult = filters();




  const generateValidationSchema = (filteredResult: any, userData: any) => {



    const requiredLabels = filteredResult
      .filter((item: any) => item?.required)
      .map((item: any) => item.label);

    const existingLabels = requiredLabels.filter((label: any) => (
      !userData.some((item: any) => item.hasOwnProperty(label))
    ));

    if (existingLabels.length > 0) {
      setuserSelectedData(existingLabels); // Assuming this function is defined
    } else {
      // onNext(); // Assuming this function is defined
    }


  }



  const getInitialValue = (label: any) => {
    const entry = priceDetails.find((obj: any) => obj.hasOwnProperty(label));
    return entry ? entry[label] : '';
  };

  console.log("ujbnju", getInitialValue("Monthly Rent"))
  const [costPerBuiltUpArea, setCostPerBuiltUpArea] = useState<any>();
  const [costPerBuiltUpArea2, setCostPerBuiltUpArea2] = useState<any>();
  const [costPerBuiltUpArea3, setCostPerBuiltUpArea3] = useState<any>();




  useEffect(() => {
    const builtUpArea = parseFloat(userData.find((item) => item?.['Built up Area'])?.['Built up Area'] || 0);
    const cost = parseFloat(userData.find((item) => item?.['Cost'])?.['Cost'] || 0);
    const calculatedCostPerBuiltUpArea = builtUpArea > 0 ? (cost / builtUpArea).toFixed(2) : '';


    const price = parseFloat(userData.find((item) => item?.['Price'] || item?.['Cost'])?.['Price'] || ['Cost'] || 0);
    const calculatedCostPerBuiltUpArea2 = builtUpArea > 0 ? (price / builtUpArea).toFixed(2) : '';

    const plotArea = parseFloat(userData.find((item) => item?.['Plot Area'])?.['Plot Area'] || 0);

    const plotPrice = parseFloat(userData.find((item) => item?.['Plot Price'])?.['Plot Price'] || 0);

    const calculatedCostPerBuiltUpArea3 = plotArea > 0 ? (plotPrice / plotArea).toFixed(2) : '';

    // const totalCost = cost + price + plotPrice;
    // const calculatedCostPerBuiltUpArea = builtUpArea > 0 ? (totalCost / builtUpArea).toFixed(2) : 'N/A';
    setCostPerBuiltUpArea(calculatedCostPerBuiltUpArea);
    setCostPerBuiltUpArea2(calculatedCostPerBuiltUpArea2);
    setCostPerBuiltUpArea3(calculatedCostPerBuiltUpArea3);

    addCostPerBuiltUpArea(calculatedCostPerBuiltUpArea as any);
    addCostPerBuiltUpArea2(calculatedCostPerBuiltUpArea2 as any);
    addCostPerBuiltUpArea3(calculatedCostPerBuiltUpArea3 as any);
  }, []);


  const PlotValue = userData.find((item) => item['Plot Area'])?.['Plot Area']  
  const Property = userData.find((item) => item['Property Type'])?.['Property Type'];
  const PlotMeasureType = userData.find((item) => item['measure'] )?.['measure']  ;
  const PlotinSqft = PlotMeasureType === 'Cent' ? PlotValue * 435 : PlotMeasureType === 'Acre' ? PlotValue * 100 * 435 : PlotValue;
  const BuildUp = passedData.find((item:any) => item['built_up_area'])?.['built_up_area'] 
  const initialValue =dataB.find((item:any)=>item['price'])?.['price']
  const PlotPrice = userData.find((item)=>item['Plot Price'])?.['Plot Price']
  const CPrice = userData.find((item)=>item['Price'])?.['Price']

  const strippedString = initialValue?.replace(/[^\d.]/g, '') || PlotPrice ?.replace(/[^\d.]/g, '') || CPrice?.replace(/[^\d.]/g, '')
  const pricepersqft = (() => {
    if (PlotinSqft !== undefined && PlotinSqft !== null && PlotinSqft !== 0) {
      return Math.round(strippedString / PlotinSqft);
    } else if (BuildUp !== undefined && BuildUp !== null && BuildUp !== 0) {
      return Math.round(strippedString / BuildUp);
    } else {
      return 0;
    }
  })();
  console.log("priceSQ",PlotValue,Property,PlotMeasureType,PlotinSqft,pricepersqft,strippedString)

  const handleSubmit = () => {

    const update = mypass.map((item: any) => {

      const formatPrice = (value: any) => {

        if (value === null || value === undefined || value === '') {
          return ''; 
        }

       
        if (isNaN(value)) {
          return value; 
        }
      
        return `₹ ${new Intl.NumberFormat('en-IN', {
          style: 'decimal',
          maximumFractionDigits: 0,
        }).format(Number(value))}`;
      };

      const formatDate = (date: string | Date) => {
        const options = { day: '2-digit', month: 'long', year: 'numeric' } as const;
        const dateObj = new Date(date);
        return dateObj.toLocaleDateString('en-GB', options);
      };

      return {
        ...item,
        price: formatPrice(datafinder(dataB as any, "price")),
        negotiable: datafinder(dataB as any, "Negotiable"),
        possession_status: datafinder(dataB as any, "Construction Status"),
        security_deposit:formatPrice(datafinder(dataB as any,"Select")),
        available_from:formatDate(datafinder(dataB as any,"Available From")),
        monthly_rent:formatPrice(datafinder(dataB as any,"Monthly Rent")),
        lease_amount:formatPrice(datafinder(dataB as any,"Lease Amount")),
        property_description:datafinder(dataB as any,"Property Description"),
        PriceperSqft:pricepersqft
      }
    })
    console.log("for paassed",update)
    setMypass(update)
    console.log("fedfcew",mypass)
    dispatch(setUsers(update));
    setFullData(update)
    closeModal()

    if (selectedValue === 'Ready to Move') {
      // onNext(); // Go to the next page if possession status is "Ready to Move"
    } else if (selectedValue === 'Under Construction') {
      generateValidationSchema(filteredResult, userData); // Validate if possession status is "Under Construction"
    } else {
      generateValidationSchema(filteredResult, userData); 
    }
  };



  const handleBoxButtonChange = (value: string) => {
    setSelectedValue(value);
  };


  return (
    <div>


      <Typography>Price Details</Typography>
      {/* <Formik  
       onSubmit={(values, { setSubmitting }) => {
          // handle form submission
          generateValidationSchema(basic_details, userData);
         
          console.log(values);
          setSubmitting(false);
        }}
      
      
      >
         <Form onSubmit={handleSubmit}  className='form'> */}

      {(data2 as any).price_details?.map((dataItem: any, dataIndex: any) => (
        <Box key={dataIndex} my={2}>
          {/* Render based on RentrLease */}
          {/* {(RentrLease === 'Rent' || RentrLease === 'Lease') && ( */}
          <>
            {dataItem.Type === 'Custom Box Type' && (
              <Box my={3}>
                <Typography className='text-start' sx={{ marginY: "12PX" }}>{dataItem.label}
                  {dataItem.required && <span style={{ color: 'red' }}> *</span>} {/* Indicate required fields */}

                </Typography>
                <Grid container spacing={2}>
                  {/* {dataItem.data && dataItem.data.map((buttonItem, buttonIndex) => ( */}
                  <Grid item >
                    <CustomBoxButton
                      data={dataItem.data}
                      label={dataItem.label}
                      inputId={dataItem.inputID}
                      formName='Price Details'

                      baseData={dataB}
                      setDataB={setDataB}
                      required={dataItem.required}
                      userselectedData={userselectedData}
                      initial={getInitialValue(dataItem.label)}
                    // Pass the handler

                    />
                  </Grid>
                  {/* ))} */}
                </Grid>
              </Box>
            )}
            {dataItem.Type === 'calendar2' && selectedValue === 'Under Construction' && (
              <div>
                <DatePick
                  label={dataItem.label}
                  inputId={dataItem.inputID}
                  baseData={dataB}
                  setDataB={setDataB}
                  formName='Price Details'
                  required={dataItem.required}
                  userselectedData={userselectedData}
                  initial={getInitialValue(dataItem.label)}
                />
              </div>
            )}

            {dataItem.Type === 'Text Field' && (
              <div>
                <TextBox label={dataItem.label}
                  inputId={dataItem.inputID}
                  formName='Price Details'
                  baseData={dataB}
                  setDataB={setDataB}
                  label2={dataItem.label2}
                  keyboard={dataItem.keyboard}
                  required={dataItem.required}
                  userselectedData={userselectedData}
                  initial={getInitialValue(dataItem.label)}
                />

              </div>
            )}
            {dataItem.Type === 'Price Text Field' && (
              <div>
                <label>{dataItem.label}</label>
                <PriceTextField
                  label={dataItem.label}
                  inputId={dataItem.inputID}
                  formName='Price Details'
                  baseData={dataB}
                  setDataB={setDataB}
                  label2={dataItem.label2}
                  keyboard={dataItem.keyboard}
                  userselectedData={userselectedData}
                  initial={getInitialValue(dataItem.label)}
                />
                <Typography >Cost per Built up Area: {pricepersqft}
                  {/* {costPerBuiltUpArea2}{costPerBuiltUpArea3} */}
                   per sq. ft.</Typography>

              </div>
            )}
            {dataItem.Type === 'Price Text Fieldcom' && (
              <div>
                <label>{dataItem.label}</label>
                <PriceTextField label={dataItem.label}
                  inputId={dataItem.inputID}
                  formName='Price Details'
                  baseData={dataB}
                  setDataB={setDataB}
                  label2={dataItem.label2}
                  keyboard={dataItem.keyboard}
                  userselectedData={userselectedData}
                  initial={getInitialValue(dataItem.label)}
                />
                <Typography >Cost per Built up Area: {pricepersqft}
                  {/* {costPerBuiltUpArea2}{costPerBuiltUpArea3} */}
                     per sq. ft.</Typography>

              </div>
            )}
            {dataItem.Type === 'Text Area' && (
              <div>
                <Typography className='text-start'>{dataItem.label}</Typography>
                <TextAreaBox label={dataItem.label}
                  inputId={dataItem.inputID}
                  baseData={dataB}
                  setDataB={setDataB}
                  passedData={passedData}
                  formName='Price Details'
                  label2={dataItem.label2}
                  //  keyboard={dataItem.keyboard}
                  required={dataItem.required}
                  // userselectedData={userselectedData}

                  initial={getInitialValue("Property Description")}

                />

              </div>
            )}

            {dataItem.Type === 'Drop down' && (
              <Box my={2}>
                <Typography className='text-start'>{dataItem.label}</Typography>
                <DropBox1
                  label={dataItem.label}
                  options={dataItem.data}
                  inputId={dataItem.inputID}
                  formName='Price Details'
                  required={dataItem.required}
                  userselectedData={userselectedData}
                  initial={getInitialValue(dataItem.label)}
                />
              </Box>
            )}
            {
              dataItem.Type === 'Price Text Field1 ' && (
                <PriceTextField2
                  label={dataItem.label}
                  inputId={dataItem.inputID}
                  formName='Price Details'
                  label2={dataItem.label2}
                  keyboard={dataItem.keyboard}
                  baseData={dataB}
                  setDataB={setDataB}
                  required={dataItem.required}
                  userselectedData={userselectedData}
                  initial={getInitialValue(dataItem.label)}
                />
              )
            }
            {
              dataItem.Type === 'Price Text Field2' && (
                <PriceTextField2
                  label={dataItem.label}
                  inputId={dataItem.inputID}
                  formName='Price Details'
                  baseData={dataB}
                  setDataB={setDataB}
                  label2={dataItem.label2}
                  keyboard={dataItem.keyboard}
                  required={dataItem.required}
                  userselectedData={userselectedData}
                  initial={getInitialValue(dataItem.label)}
                />
              )
            }
            {dataItem.Type === 'Box Type3' && (
              <Box my={3}>
                <Typography className='text-start' sx={{ marginY: "12px" }}>{dataItem.label}</Typography>
                <Grid container spacing={2}>
                  {/* {dataItem.data && dataItem.data.map((buttonItem, buttonIndex) => ( */}
                  <Grid item >
                    <AccessButton
                      data={dataItem.data}
                      label={dataItem.label}
                      inputId={dataItem.inputID}
                      baseData={dataB}
                      setDataB={setDataB}
                      formName='Price Details'
                      required={dataItem.required}
                      userselectedData={userselectedData}
                      initial={getInitialValue(dataItem.label)}
                    />
                  </Grid>
                  {/* ))} */}
                </Grid>
              </Box>
            )}
            {dataItem.Type === 'Box Type' && (
              <Box >
                <CustomisedButton label={dataItem.label} data={dataItem.data} inputId={dataItem.inputID}
                  formName='Price Details'
                  baseData={dataB}
                  setDataB={setDataB}
                  required={dataItem.required}
                  userselectedData={userselectedData}
                  initial={getInitialValue(dataItem.label)}

                />
              </Box>
            )}
            {
              dataItem.Type === 'Box Type2' && (
                <Box>
                  <CustomButton label={dataItem.label} data={dataItem.data} inputId={dataItem.inputID}
                    formName='Price Details'
                    required={dataItem.required}
                    userselectedData={userselectedData}
                    initial={getInitialValue(dataItem.label)} />
                </Box>
              )
            }

            {RentrLease === 'Rent' && (
              <>
                {dataItem.Type === 'Price Text Field4' && (
                  <>
                    {console.log('Rendering PriceTextField1 with dataItem:', dataItem.label)}
                    <PriceTextField1
                      label={dataItem.label}
                      inputId={dataItem.inputID}
                      formName='Price Details'
                      baseData={dataB}
                      setDataB={setDataB}
                      label2={dataItem.label2}
                      keyboard={dataItem.keyboard}
                      required={dataItem.required}
                      userselectedData={userselectedData}
                      initial={getInitialValue(dataItem.label)}
                    />
                  </>
                )}
                {dataItem.Type === 'calendar1' && (
                  <DatePick
                    label={dataItem.label}
                    inputId={dataItem.inputID}
                    baseData={dataB}
                    setDataB={setDataB}
                    formName='Price Details'
                    required={dataItem.required}
                    userselectedData={userselectedData}
                    initial={getInitialValue(dataItem.label)}
                  />
                )}
                {dataItem.Type === 'Drop down5' && (
                  <Box my={2}>
                    <Typography className='text-start'>{dataItem.label}</Typography>
                    <DropDown1
                      label={dataItem.label}
                      options={dataItem.data}
                      baseData={dataB}
                      setDataB={setDataB}
                      inputId={dataItem.inputID}
                      formName='Price Details'
                      required={dataItem.required}
                      userselectedData={userselectedData}
                      initial={getInitialValue(dataItem.label)}
                    />
                  </Box>
                )}
                {dataItem.Type === 'Box Type4' && (
                  <Box my={3}>
                    <Typography className='text-start' sx={{ marginY: "12px" }}>{dataItem.label}</Typography>
                    <Grid container spacing={2}>
                      {/* {dataItem.data && dataItem.data.map((buttonItem, buttonIndex) => ( */}
                      <Grid item >
                        <AccessButton
                          data={dataItem.data}
                          label={dataItem.label}
                          inputId={dataItem.inputID}
                          baseData={dataB}
                          setDataB={setDataB}
                          formName='Price Details'
                          required={dataItem.required}
                          userselectedData={userselectedData}
                          initial={getInitialValue(dataItem.label)}
                        />
                      </Grid>
                      {/* ))} */}
                    </Grid>
                  </Box>
                )}
                {/* {
                    dataItem.Type==='Text Area' && (
                      <div> <Typography className='text-start'>{dataItem.label}</Typography>
                      <TextAreaBox
                                                    data={dataItem.data}
                                                    label={dataItem.label}
                      inputId={dataItem.inputID}
                      formName='Price Details'
                      // label2={dataItem.label2}
                      keyboard={dataItem.keyboard}
                      userselectedData={userselectedData}

                      required={dataItem.required}
                       initial={getInitialValue(dataItem.label)}
                      
                      />
                      </div>
                    )
                  } */}
              </>
            )}

            {RentrLease === 'Lease' && (
              <>
                {dataItem.Type === 'Price Text Field5' && (
                  <PriceTextField2
                    label={dataItem.label}
                    inputId={dataItem.inputID}
                    formName='Price Details'
                    label2={dataItem.label2}
                    baseData={dataB}
                    setDataB={setDataB}
                    keyboard={dataItem.keyboard}
                    required={dataItem.required}
                    userselectedData={userselectedData}
                    initial={getInitialValue(dataItem.label)}
                  />
                )}
                {dataItem.Type === 'calendar1' && (
                  <DatePick
                    label={dataItem.label}
                    inputId={dataItem.inputID}
                    baseData={dataB}
                    setDataB={setDataB}
                    formName='Price Details'
                    required={dataItem.required}
                    userselectedData={userselectedData}
                    initial={getInitialValue(dataItem.label)}
                  />
                )}
                {dataItem.Type === 'Box Type4' && (
                  <Box my={3}>
                    <Typography className='text-start' sx={{ marginY: "12px" }}>{dataItem.label}</Typography>
                    <Grid container spacing={2}>
                      {/* {dataItem.data && dataItem.data.map((buttonItem, buttonIndex) => ( */}
                      <Grid item >
                        <AccessButton
                          data={dataItem.data}
                          label={dataItem.label}
                          inputId={dataItem.inputID}
                          baseData={dataB}
                          setDataB={setDataB}
                          formName='Price Details'
                          required={dataItem.required}
                          userselectedData={userselectedData}
                          initial={getInitialValue(dataItem.label)}

                        />
                      </Grid>
                      {/* ))} */}
                    </Grid>
                  </Box>
                )}
                {/* {
                    dataItem.Type==='Text Area' && (
                      <div> <Typography className='text-start'>{dataItem.label}</Typography>
                      <TextAreaBox 
                                                    data={dataItem.data}
                                                    label={dataItem.label}
                      inputId={dataItem.inputID}
                      formName='Price Details'
                      // label2={dataItem.label2}
                      // keyboard={dataItem.keyboard}
                      required={dataItem.required}
                      userselectedData={userselectedData}

                        initial={getInitialValue(dataItem.label)}
                    />
                                            
                      </div>                    )
                  } */}
              </>
            )}
          </>
        </Box>
      ))}  <Box mt={2}>  <div>
      </div>
      </Box>
      <Button onClick={handleSubmit} type='submit'>Continue</Button>

    </div>
  )
}

export default Price

