import { Box } from '@mui/material';
import React from 'react'
import { MdArrowBackIosNew } from "react-icons/md";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


const TermsandConditions = () => {
  const navigate = useNavigate();


  return (
    <div className="bg-[#F5f4f8]" >
      <div className="lg:mx-[20%] msm:mx-[2%]  bg-[#F5F4F8] pb-10">
        <Box className='flex ' sx={{marginTop:{xs:0,sm:2},}}>
  <button 
    onClick={() => navigate(-1)} // Inline navigation logic
    className="absolute mt-4  z-40"
  >
    <MdArrowBackIosNew className='w-8 h-8 sm:flex msm:flex md:flex' />
  </button>
  <p className='text-xl p-4 font-bold flex justify-center items-center flex-1'>Vattara Terms and Conditions</p>

        </Box>
        <section className="bg-white p-6 rounded-lg shadow-md mb-6">
          <h2 className=" lg:text-2xl msm:text-lg sm:text-xl font-bold mb-4">1. Introduction</h2>
          <p>
            Welcome to Vattara. These Terms and Conditions ("Terms") govern your use of our real estate application and website (collectively, the "Services"). By accessing or using Vattara, you agree to be bound by these Terms. If you do not agree with any part of the Terms, you must discontinue use of our Services immediately.
          </p>
        </section>

        <section className="bg-white p-6 rounded-lg shadow-md mb-6">
          <h2 className="lg:text-2xl msm:text-lg sm:text-xl font-bold mb-4">2. Definitions</h2>
          <p>
            <strong>"Application":</strong> Refers to Vattara, including both the mobile application and website.
            <br />
            <strong>"We," "Us," "Our":</strong> Refers to Vattara, its owners, and affiliates.
            <br />
            <strong>"User," "You," "Your":</strong> Refers to any individual or entity using the Application.
            <br />
            <strong>"Content":</strong> Refers to any text, images, videos, audio, and other materials uploaded or displayed on the Application.
            <br />
            <strong>"Service Providers":</strong> Refers to third-party providers offering services through the Application, such as electricians, movers, etc.
          </p>
        </section>

        <section className="bg-white p-6 rounded-lg shadow-md mb-6">
          <h2 className="lg:text-2xl msm:text-lg font-bold mb-4">3. User Accounts</h2>
          <div className="ml-4">
            <h3 className="lg:text-2xl msm:text-lg sm:text-xl font-semibold mb-2">3.1 Registration</h3>
            <p>
              To use certain features of Vattara, Users must register an account. You agree to provide accurate, current, and complete information during registration and update such information to keep it accurate and complete.
            </p>
            <h3 className="lg:text-2xl msm:text-lg sm:text-xl font-semibold mt-4 mb-2">3.2 Account Security</h3>
            <p>
              You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You must immediately notify us of any unauthorized use of your account or any other breach of security.
            </p>
            <h3 className="lg:text-2xl msm:text-lg sm:text-xl font-semibold mt-4 mb-2">3.3 Login Limitations</h3>
            <p>
              Users are limited to logging in on 4 devices. To log in on a new device, you must log out from one of the previously logged-in devices.
            </p>
            <h3 className="lg:text-2xl msm:text-lg font-semibold mt-4 mb-2">3.4 Termination</h3>
            <p>
              We reserve the right to suspend or terminate your account at any time, without notice, for any reason, including but not limited to a breach of these Terms.
            </p>
          </div>
        </section>

        <section className="bg-white p-6 rounded-lg shadow-md mb-6">
          <h2 className="lg:text-2xl msm:text-lg sm:text-xl font-bold mb-4">4. Services Provided</h2>
          <div className="ml-4">
            <h3 className="lg:text-2xl msm:text-lg sm:text-xl font-semibold mb-2">4.1 General Services</h3>
            <p>
              Vattara provides a platform for Users to post and view property listings and contact each other for real estate-related services.
            </p>
            <h3 className="lg:text-2xl msm:text-lg sm:text-xl font-semibold mt-4 mb-2">4.2 Service Providers</h3>
            <p>
              Vattara allows Users to contact various service providers, including but not limited to electricians, packers and movers, home renovation experts, painters, construction services, and home interior designers. Vattara acts only as a platform for connecting Users and Service Providers and does not endorse, guarantee, or assume responsibility for any services provided by these third parties.
            </p>
            <h3 className="lg:text-2xl msm:text-lg sm:text-xl font-semibold mt-4 mb-2">4.3 Contact Information</h3>
            <p>
              Users can submit contact information for various services, which will be verified by the admin before being listed on the Application. Vattara reserves the right to reject any contact information submission at its sole discretion.
            </p>
          </div>
        </section>

        <section className="bg-white p-6 rounded-lg shadow-md mb-6">
          <h2 className="lg:text-2xl msm:text-lg sm:text-xl font-bold mb-4">5. User Responsibilities</h2>
          <div className="ml-4">
            <h3 className="lg:text-2xl msm:text-lg sm:text-xl font-semibold mb-2">5.1 Compliance with Laws</h3>
            <p>
              You agree to comply with all applicable laws, regulations, and local ordinances while using Vattara.
            </p>
            <h3 className="lg:text-2xl msm:text-lg sm:text-xl font-semibold mt-4 mb-2">5.2 Prohibited Activities</h3>
            <p>
              You agree not to:
              <ul className="list-disc list-inside ml-4">
                <li>Violate any laws or regulations.</li>
                <li>Infringe on the rights of others, including intellectual property rights.</li>
                <li>Engage in fraudulent, deceptive, or misleading practices.</li>
                <li>Disrupt or interfere with the security, integrity, or proper functioning of the Application.</li>
                <li>Post or transmit any content that is defamatory, obscene, offensive, or otherwise inappropriate.</li>
              </ul>
            </p>
            <h3 className="lg:text-2xl msm:text-lg sm:text-xl font-semibold mt-4 mb-2">5.3 User-Generated Content</h3>
            <p>
              By posting or uploading content to Vattara, you grant us a non-exclusive, worldwide, royalty-free, perpetual, and irrevocable license to use, reproduce, modify, distribute, and display such content. You represent and warrant that you own or have the necessary rights to the content you submit and that such content does not violate any third-party rights.
            </p>
          </div>
        </section>

        <section className="bg-white p-6 rounded-lg shadow-md mb-6">
          <h2 className="lg:text-2xl msm:text-lg sm:text-xl font-bold mb-4">6. Subscription Plans</h2>
          <div className="ml-4">
            <h3 className="lg:text-2xl msm:text-lg sm:text-xl font-semibold mb-2">6.1 Types of Plans</h3>
            <p>
              Vattara offers subscription plans under residential and commercial categories. Details about the plans, including pricing and benefits, are provided within the Application.
            </p>
            <h3 className="lg:text-2xl msm:text-lg sm:text-xl font-semibold mt-4 mb-2">6.2 Payment Terms</h3>
            <p>
              Subscription fees are billed on a periodic basis as specified in the subscription plan. All fees are non-refundable unless otherwise specified in these Terms or required by law.
            </p>
            <h3 className="lg:text-2xl msm:text-lg sm:text-xl font-semibold mt-4 mb-2">6.3 Automatic Renewal</h3>
            <p>
              Unless otherwise stated, subscriptions will automatically renew at the end of each subscription period. You may cancel the subscription at any time before the renewal date to avoid being charged for the next period.
            </p>
          </div>
        </section>

        <section className="bg-white p-6 rounded-lg shadow-md mb-6">
          <h2 className="lg:text-2xl msm:text-lg sm:text-xl font-bold mb-4">7. Enquiries and Callbacks</h2>
          <div className="ml-4">
            <h3 className="lg:text-2xl msm:text-lg sm:text-xl font-semibold mb-2">7.1 Enquiry Handling</h3>
            <p>
              Vattara facilitates the handling of enquiries and provides callbacks to arrange services based on User requests.
            </p>
            <h3 className="lg:text-2xl msm:text-lg sm:text-xl font-semibold mt-4 mb-2">7.2 Third-Party Responsibility</h3>
            <p>
              While Vattara facilitates connections between Users and Service Providers, we are not responsible for the quality, timing, or legality of services provided by third-party Service Providers.
            </p>
          </div>
        </section>

        <section className="bg-white p-6 rounded-lg shadow-md mb-6">
          <h2 className="lg:text-2xl msm:text-lg sm:text-xl font-bold mb-4">8. Intellectual Property</h2>
          <div className="ml-4">
            <h3 className="lg:text-2xl msm:text-lg sm:text-xl font-semibold mb-2">8.1 Ownership</h3>
            <p>
              All content, software, databases, designs, and other elements of the Application are the property of Vattara or its licensors and are protected by copyright, trademark, patent, and other intellectual property laws.
            </p>
            <h3 className="lg:text-2xl msm:text-lg sm:text-xl font-semibold mt-4 mb-2">8.2 Limited License</h3>
            <p>
              We grant you a limited, non-exclusive, non-transferable, and revocable license to access and use the Application for your personal, non-commercial use. You may not copy, modify, distribute, or create derivative works based on any part of the Application without our express written consent.
            </p>
          </div>
        </section>

        <section className="bg-white p-6 rounded-lg shadow-md mb-6">
          <h2 className="lg:text-2xl msm:text-lg sm:text-xl font-bold mb-4">9. Privacy</h2>
          <div className="ml-4">
            <h3 className="lg:text-2xl msm:text-lg sm:text-xl font-semibold mb-2">9.1 Data Collection</h3>
            <p>
              Our Privacy Policy explains how we collect, use, and protect your personal information. By using the Application, you agree to the terms of our Privacy Policy.
            </p>
            <h3 className="lg:text-2xl msm:text-lg sm:text-xl font-semibold mt-4 mb-2">9.2 Data Security</h3>
            <p>
              We implement reasonable measures to protect your data. However, we cannot guarantee the security of data transmitted to or from the Application.
            </p>
          </div>
        </section>

        <section className="bg-white p-6 rounded-lg shadow-md mb-6">
          <h2 className="lg:text-2xl msm:text-lg sm:text-xl font-bold mb-4">10. Limitation of Liability</h2>
          <div className="ml-4">
            <h3 className="lg:text-2xl msm:text-lg sm:text-xl font-semibold mb-2">10.1 No Warranty</h3>
            <p>
              Vattara is provided on an "as-is" and "as-available" basis. We make no representations or warranties of any kind, express or implied, as to the operation of the Application or the information, content, materials, or products included.
            </p>
            <h3 className="lg:text-2xl msm:text-lg sm:text-xl font-semibold mt-4 mb-2">10.2 Limitation of Damages</h3>
            <p>
              To the fullest extent permitted by law, Vattara shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, use, goodwill, or other intangible losses, resulting from:
              <ul className="list-disc list-inside ml-4">
                <li>Your use or inability to use the Application.</li>
                <li>Unauthorized access to or use of our servers and/or any personal information stored therein.</li>
                <li>Any interruption or cessation of transmission to or from the Application.</li>
                <li>Any bugs, viruses, trojan horses, or the like that may be transmitted to or through the Application by any third party.</li>
                <li>Any errors or omissions in any content or for any loss or damage incurred as a result of your use of any content posted, emailed, transmitted, or otherwise made available through the Application.</li>
              </ul>
            </p>
          </div>
        </section>

        <section className="bg-white p-6 rounded-lg shadow-md mb-6">
          <h2 className="text-2xl font-bold mb-4">11. Modifications</h2>
          <div className="ml-4">
            <h3 className="text-xl font-semibold mb-2">11.1 Changes to Terms</h3>
            <p>
              We reserve the right to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice before the new terms take effect. What constitutes a material change will be determined at our sole discretion.
            </p>
            <h3 className="text-xl font-semibold mt-4 mb-2">11.2 Continued Use</h3>
            <p>
              Your continued use of the Application after any changes to these Terms constitutes your acceptance of the new Terms.
            </p>
          </div>
        </section>

        <section className="bg-white p-6 rounded-lg shadow-md mb-6">
          <h2 className="text-2xl font-bold mb-4">12. Governing Law</h2>
          <p>
            These Terms shall be governed and construed in accordance with the laws of [Your Country/State], without regard to its conflict of law provisions. You agree to submit to the personal jurisdiction of the courts located within [Your Country/State] for the resolution of any disputes.
          </p>
        </section>

        <section className="bg-white p-6 rounded-lg shadow-md mb-6">
          <h2 className="text-2xl font-bold mb-4">13. Dispute Resolution</h2>
          <div className="ml-4">
            <h3 className="lg:text-2xl msm:text-lg sm:text-xl font-semibold mb-2">13.1 Informal Resolution</h3>
            <p>
              Before initiating any legal action, you agree to first contact us at [Your Contact Information] to attempt to resolve the dispute informally.
            </p>
            <h3 className="lg:text-2xl msm:text-lg sm:text-xl font-semibold mt-4 mb-2">13.2 Arbitration</h3>
            <p>
              In the event that informal resolution is not possible, any dispute arising out of or relating to these Terms shall be resolved by binding arbitration in accordance with the rules of [Arbitration Organization]. The arbitration shall take place in [Location], and the language of the arbitration shall be [Language].
            </p>
          </div>
        </section>

        <section className="bg-white p-6 rounded-lg shadow-md mb-6">
          <h2 className="lg:text-2xl msm:text-lg sm:text-xl font-bold mb-4">14. Severability</h2>
          <p>
            If any provision of these Terms is found to be invalid or unenforceable by a court of competent jurisdiction, the remaining provisions shall continue in full force and effect.
          </p>
        </section>

        <section className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="lg:text-2xl msm:text-lg sm:text-xl font-bold mb-4">15. Entire Agreement</h2>
          <p>
            These Terms, along with our Privacy Policy, constitute the entire agreement between you and Vattara regarding your use of the Application and supersede any prior agreements or understandings, whether written or oral.
          </p>
        </section>

        <section className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="lg:text-2xl msm:text-lg sm:text-xl font-bold mb-4">16. Contact Us</h2>
          <p>
            If you have any questions about these Terms, please contact us at [Your Contact Information].
          </p>
        </section>
      </div>
    </div>
  )
}
export default TermsandConditions