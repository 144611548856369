// import { useSelector } from "react-redux";
// import { axiosInstance } from "../api/APIs";




// export const handleChange = async (propertyView: any) => {
//   //  const loginData = useSelector((state: any) => state.userData.userLoginData)
//   const body = {
//     post_id: propertyView.postId,
//     selected_property_type: propertyView.property_type,
//     looking_to: propertyView.looking,
//     user_id: propertyView.userId
//   };
//   console.log(body,propertyView.Bview) 
//   console.log(propertyView,propertyView.postId)
//   try {
//     const response = await axiosInstance.post("/filter/get/postDetail", body);


//     const queryString = new URLSearchParams({
//       data: JSON.stringify(response.data),
//       look: propertyView.looking || '',
//       Bview:propertyView.Bview
//     }).toString();


//     const newWindow = window.open(`/PropertyDetails?${queryString}`, '_blank');

//     if (newWindow) {
//       newWindow.focus();
//     }
//   } catch (error) {
//     console.error('Error fetching data:', error);
//   }
// };



import { useSelector } from "react-redux";
import { axiosInstance } from "../api/APIs";

const isMobileOrTablet = () => {
  const userAgent = window.navigator.userAgent;
  return /Mobile|Android|iPhone|iPad|Tablet/.test(userAgent);
};

export const handleChange = async (propertyView: any) => {
  const body = {
    post_id: propertyView.postId,
    selected_property_type: propertyView.property_type,
    looking_to: propertyView.looking,
    user_id: propertyView.userId
  };

  console.log(body, propertyView.Bview);
  console.log(propertyView, propertyView.postId);

  try {
    const response = await axiosInstance.post("/filter/get/postDetail", body);

    const queryString = new URLSearchParams({
      data: JSON.stringify(response.data),
      look: propertyView.looking || '',
      Bview: propertyView.Bview
    }).toString();

    if (isMobileOrTablet()) {
      // If it's a mobile or tablet, navigate in the same window
      window.location.href = `/PropertyDetails?${queryString}`;
    } else {
      // For desktop, open a new window
      const newWindow = window.open(`/PropertyDetails?${queryString}`, '_blank');
      if (newWindow) {
        newWindow.focus();
      }
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};
