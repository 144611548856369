import React from "react";
import image from "../../assets/love.png";

const Tagline = () => {
  return (
    <section className=" sl:ml-0 lg:p-0  px-4  my-20 msm:mx-2 xl:mx-[20%]">
     
      <div>
        <span className=" text-[#AAAAAA] font-extrabold  text-8xl ">
          Find
        </span>
        <span className=" text-[#AAAAAA] text-5xl msm::text-8xl xl:text-8xl">
          Your
        </span>
      </div>
      <div className="f  text-[#0C3D5A] mt-3 font-bold text-3xl lg:text-5xl xl:text-5xl">
        Perfect Place
      </div>
      <div className="flex items-end font-normal text-base md:text-lg lg:text-xl xl:text-2xl pt-3">
        Crafted with
        <img className="flex w-8 h-8 lg:w-10 lg:h-10 " src={image} alt="" />
        in Chennai, India
      </div>
    </section>
  );
};

export default Tagline;
