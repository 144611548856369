import { InputAdornment, TextField } from '@mui/material';
import React from 'react';
import { useStepperContext } from './StepperContext';
import { maskCurrency } from '../../PostForm/data';
interface PriceTextField1Props {
  label: string;
  formName: string;
  inputId?: number;
  label2?: string;
  keyboard?: any;
  required?: boolean;
  userselectedData?: any;
  initial?: any;
}

const PriceTextField1: React.FC<PriceTextField1Props> = ({
  label,
  formName,
  inputId,
  label2,
  keyboard,
  initial,
  required,
  userselectedData,
}) => {
  const { addUserData } = useStepperContext();

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | { value: unknown }>
  ) => {
    const data = {
      id: inputId,
      [label]: maskCurrency(event.target.value as any),
      formName: formName,
    };
    addUserData(data as any);
  };
  const hasError = userselectedData?.includes(label);
  return (
    <div className="w-[100%]">
      <TextField
        id={label}
        defaultValue={initial}
        type={keyboard ? keyboard : 'text'}
        label={label}
        onChange={handleChange}
        variant="standard"
        className="w-[100%]"
        InputProps={{
          startAdornment: <InputAdornment position="start">₹</InputAdornment>,
          endAdornment: label2 && (
            <InputAdornment position="end">{label2}</InputAdornment>
          ),
        }}
      ></TextField>

      {hasError && <div>This field is required</div>}
    </div>
  );
};

export default PriceTextField1;
