import React from "react";
import logo from '../images/vattaraLogo.png'
import store from '../images/playstore.png'
import mail from '../images/mailFooter.png'
import add from '../images/footerAddress.png'
import phone from '../images/phoneFooter.png'
import x from '../images/footerX.png'
import fb from '../images/FooterFB.png'
import ins from '../images/FooterIns.png'
import chat from '../images/chatRating.png'
import zeal from '../images/zeal_logo.png'
import { useState } from "react";
import postproperty from "../../assets/postimg.png";
import { Avatar, Box, Button, Modal, CardActions, CardContent, CardMedia, Checkbox, Divider, FormControlLabel, Icon, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import RateingBox from "../RateingBox/RateingBox";



const Footer: React.FC = () => {

    const gradientStyle = {
        background: 'linear-gradient(to right,#3344CB,  #252B5C)',
        height: '70px', // Adjust as needed
        width: '100%'   // Adjust as needed
    };
    const [open, setOpen] = useState<any>(false);
    const handleClose = () => {
        // console.log("jheki");

        setOpen(false);
    }
    function handleRate() {
        setOpen(true)
    }

    return (
        <div className="flex flex-col justify-center">
            <div className=" msm:mt-[%] lg:mt- bg-[#F5F4F8]">
                <footer className=" lg:mx-[0%] sm:ml-2">
                    <div className="mx-auto w-full max-w-screen-xl  lg:p-4 sm:p-2 lg:py-8">
                        <div className="lg:flex sm:flex sm:gap-3">
                            <div className="mb-6 md:mb-0 msm:flex msm:justify-center msm:items-center msm:gap-5 sm:block lg:block">
                                <div className="flex  items-center">
                                    <img
                                        src={logo}
                                        className="w-26 h-16"
                                        alt="FlowBite Logo"
                                    />
                                    {/* <span className="self-center lg:text-2xl font-bold msm:text-xl whitespace-nowrap uppercase text-[#2E368F] ">
                                        Vattara
                                    </span> */}
                                </div>
                                <div className="border-1 sm:hidden border-black h-20 mt-4 opacity-30 msm:flex lg:hidden"></div>
                                <div>
                                    <p className="font-semibold mt-4">Download Our App</p>
                                    <div className="mt-2">
                                        <img src={store} className="w-32 lg:w-40"/>
                                    </div>
                                    <div onClick={handleRate} className="flex mt-2 gap-2 underline">
                                        <img src={chat} />
                                        <p className="font-semibold">Rate Us Now</p>
                                    </div>
                                </div>
                            </div>
                            <div className="lg:flex sm:flex   lg:justify-between msm:ml-10 sm:gap-3 sm:ml-3 lg:ml-32 lg:gap-32" >
                                <div>
                                    <h2 className="lg:mb-6 msm:mb-4 text-lg font-bold   ">
                                        Quick Links
                                    </h2>
                                    <div className=" font-medium">

                                        <p className="hover:underline ">
                                            Home
                                        </p>


                                        <p className="hover:underline mt-2">
                                            Services
                                        </p>


                                        <p className="hover:underline mt-2">
                                            Contact Us
                                        </p>


                                        <p className="hover:underline mt-2">
                                            Careers
                                        </p>


                                        <p className="hover:underline mt-2">
                                            FAQs
                                        </p>


                                    </div>
                                </div>
                                <div className="msm:mt-6 sm:mt-0 lg:mt-0">
                                    <h2 className="lg:mb-6 msm:mb-4 text-lg  font-bold">
                                        Services
                                    </h2>
                                    <div className="font-medium">

                                        <p className="hover:underline">
                                            Home
                                        </p>


                                        <p className="hover:underline mt-2">
                                            Property Listings
                                        </p>


                                        <p className="hover:underline mt-2">
                                            Post Your Property
                                        </p>


                                        <p className="hover:underline mt-2">
                                            Subscription Plans
                                        </p>


                                        <p className="hover:underline mt-2">
                                            Help Center
                                        </p>


                                        <p className="hover:underline mt-2">
                                            Featured Properties
                                        </p>


                                    </div>
                                </div>
                                <div className="lg:mt-0 sm:mt-0 msm:mt-6 sm:ml-2">
                                    <h2 className="lg:mb-6 msm:mb-4 text-lg font-bold">
                                        Contact Us
                                    </h2>
                                    <div className="">
                                        <div>
                                            <div className="flex gap-2 items-center " >
                                                <img src={mail} />
                                                <p className="font-medium">support@vattara.com</p>
                                            </div>
                                            <div className="flex gap-2 items-center mt-4" >
                                                <img src={phone} />
                                                <p className="font-medium">(+91) 9876543210</p>
                                            </div>
                                            <div className="flex gap-2 items-start mt-4" >
                                                <img src={add} className="mt-2" />
                                                <div>
                                                    <p className="font-medium">24/1, Lorem ipsum odor,
                                                    </p>
                                                    <p className="font-medium"> Fermentum eros,</p>
                                                    <p className="font-medium">Ridiculus - 142 865</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="lg:mt-0 msm:mt-6 sm:mt-0 msm:flex msm:gap-4 sm:block lg:block sm:ml-2">
                                    <div>
                                        <h2 className="lg:mb-6 msm:mb-4 text-lg  font-bold ">
                                            Legal
                                        </h2>


                                        <div>
                                            <p className="hover:underline font-medium">Terms and Conditions</p>
                                            <p className="hover:underline mt-2 font-medium">Privacy Policy</p>
                                        </div>
                                    </div>
                                    <div className="border-1 border-black h-24 mt-2 opacity-30 msm:flex sm:hidden lg:hidden"></div>
                                    <div>
                                        <h2 className="mb-2 text-lg sm:mt-4 font-bold lg:mt-4">Follow us</h2>


                                        <div className="flex gap-2">
                                            <img src={fb} />
                                            <img src={ins} />
                                            <img src={x} />

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>
                </footer>
            </div>

            <div className=" " style={gradientStyle}>
                <div className="lg:mx-[20%] mt-3 sm:flex sm:mx-4 lg:flex justify-between items-center">
                    <div className="">
                        <p className="text-white text-center msm:text-xs lg:text-sm">© 2024 Vattara. All rights reserved.</p>
                    </div>
                    <a href="https://zealzoft.com/" target="_blank" rel="noopener noreferrer">
                    <div  className="flex lg:gap-2 msm:gap-2 msm:justify-center items-center msm:mx-2">
                        <p className="text-white lg:text-base msm:text-xs">Developed by Zeal Zoft Private Limited</p>
                        <img src={zeal} className="lg:w-40 msm:w-32" />
                    </div>
                    </a>
                </div>
            </div>
            <div>
                {/* <p onClick={handleRate} className="fixed text-lg font-semibold bg-[#FBB337] py-2 px-5 rounded-3xl z-20 bottom-10 right-3">Rate us now</p> */}
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="contact-modal-title"
                    aria-describedby="contact-modal-description"
                >
                    <Box >
                        <div className=''>
                            <RateingBox setOpen={setOpen} />
                        </div>
                    </Box>
                </Modal>

            </div>
        </div>
    );
};

export default Footer;
