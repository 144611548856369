import React from 'react'
import { useStepperContext } from './StepperContext'
import { InputAdornment, TextField } from '@mui/material'
import { keyboard } from '@testing-library/user-event/dist/keyboard'
import { updateValueInArray } from '../../PostForm/component'
interface PriceTextField2Props{
    label:string
    formName:string
    inputId?:number
    label2?:string
    keyboard?:any
    required?:boolean
    userselectedData?:any
    initial?:any
    baseData?:any
    setDataB:any
}

const PriceTextField2:React.FC<PriceTextField2Props> = ({setDataB,label,baseData,formName,inputId,label2,keyboard,required,initial,userselectedData}) => {
    const {addUserData,userData}=useStepperContext()
    const handleChange=(event: React.ChangeEvent<HTMLInputElement | { value: unknown }>)=>{
       
        const updatedData = updateValueInArray(baseData, label, event.target.value);
    console.log("new", updatedData)
    setDataB(updatedData)
       
        const data={
            id:inputId,
            [label]:event.target.value,
            formName:formName,
            required:required
        }
        addUserData(data as any)
    }
    console.log("lease",userData)
    const hasError=userselectedData?.includes(label)
  return (
    <div>
        <TextField id={label}
        type={keyboard?keyboard:"text"}
        defaultValue={initial}
         label={label} onChange={handleChange} variant='standard' className='w-[100%]'
        InputProps={{
            endAdornment: <InputAdornment position="start">{label2}</InputAdornment>,
         }} >


        </TextField>
        {hasError && <div>This field is required</div>}
    </div>
  )
}

export default PriceTextField2