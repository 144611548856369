/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState, useCallback, useRef } from 'react';
import {
  Box,
  IconButton,
  Typography,
  TextField,
  CircularProgress,
  Avatar,
} from '@mui/material';
import {
  ArrowBack as ArrowBackIcon,
  Phone as PhoneIcon,
  Send as SendIcon,
} from '@mui/icons-material';
import { io, Socket } from 'socket.io-client'; 
import axios from 'axios';
import { Message as ChatMessage } from 'react-chat-ui'; // Import Message type
import { useChat } from '../context/ChatContext';
import { useSelector } from 'react-redux';

const ENDPOINT = 'http://192.168.1.7:5000';

interface ExtendedMessage extends ChatMessage {
  image?: string | null;
  createdAt?: Date;
  senderPic?: string;
}

interface ChatProps {
  chat: {
    chatId: string;
    userId: string;
    username: string;
    userpic: string;
  };
  initialMessageText?: string;
  details?: any;
  postId?: string | null;
}

const SingleChat: React.FC<ChatProps> = ({
  chat,
  initialMessageText,
  details,
  postId,
}) => {
  const loginData = useSelector((state: any) => state.userData.userLoginData);
  const [socketConnected, setSocketConnected] = useState<boolean>(false);
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [newMessage, setNewMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [messages, setMessages] = useState<ExtendedMessage[]>([]);
  const [socket, setSocket] = useState<Socket | null>(null);
  const [imageDisplayed, setImageDisplayed] = useState<boolean>(false);

  const { selectedChat } = useChat();
  console.log('select', selectedChat);

  const chatId = selectedChat.chatId;
  const username = selectedChat.username;
  const userpic = selectedChat.userpic;
  const userId = selectedChat.userId;

  if (!selectedChat) {
    return <div>No chat selected</div>;
  }

  const imageUrl = details?.image;
  console.log('image Check ', imageUrl);

  const selectedChatCompare = useRef<string | null>(null);

  useEffect(() => {
    console.log('chatId:', chatId);
    console.log('userId:', userId);
    if (!userId) return;

    const newSocket = io(ENDPOINT);
    setSocket(newSocket);

    newSocket.on('connect', () => {
      console.log('WebSocket connected');
      newSocket.emit('setup', loginData.user_id);
      setSocketConnected(true);
    });

    newSocket.on('typing', () => {
      setIsTyping(true);
    });

    newSocket.on('stop typing', () => {
      setIsTyping(false);
    });

    newSocket.on('message received', (newMessageReceived: any) => {
  
    if (newMessageReceived.chatId === chatId) {
      const formattedMessage: ExtendedMessage = {
        id: newMessageReceived.id,
        message: newMessageReceived.content,
        createdAt: new Date(newMessageReceived.createdAt),
        senderName: newMessageReceived.sender?.name || 'Unknown',
        senderPic: newMessageReceived.sender?.user_pic || '',
        image: newMessageReceived.image || null,
      };

      
      setMessages((prevMessages) => [...prevMessages, formattedMessage]);
    }
  });

    if (chatId) {
      fetchMessages();
      selectedChatCompare.current = chatId;
    }

    return () => {
      if (newSocket) {
        newSocket.emit('leave room', chatId);
        newSocket.off('message received');
        newSocket.off('typing');
        newSocket.off('stop typing');
      }
    };
  }, [userId, chatId]);

  

  const fetchMessages = useCallback(async () => {
    if (!chatId) return;

    try {
      const { data } = await axios.get(`${ENDPOINT}/api/message/${chatId}`);
      console.log('Fetched messages:', data);
      const formattedMessages = data.map((message: any) => ({
        id: message.id,
        message: message.content,
        createdAt: new Date(message.createdAt),
        senderName: message.sender?.name || 'Unknown',
        senderPic: message.sender?.user_pic || '',
        image: message.image || null,
      }));

      const sortedMessages = formattedMessages.sort(
        (a: ExtendedMessage, b: ExtendedMessage) => {
          const dateA = a.createdAt ? new Date(a.createdAt) : new Date(0);
          const dateB = b.createdAt ? new Date(b.createdAt) : new Date(0);
          return dateA.getTime() - dateB.getTime();
        }
      );

      setMessages(sortedMessages);
      setLoading(false);

      // Send the initial message only once per chat session
      if (!imageDisplayed && (initialMessageText || imageUrl)) {
        handleSend([
          {
            message: initialMessageText || '',
            image: imageUrl,
            id: chatId,
          },
        ]);
        setImageDisplayed(true);
      }

      if (socket) {
        socket.emit('join chat', chatId);
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
      setLoading(false);
    }
  }, [chatId, initialMessageText, imageUrl, socket, imageDisplayed]);

  const handleSend = async (newMessages: ExtendedMessage[] = []) => {
    if (newMessages.length === 0) return;

    const newMessage = newMessages[0];

    try {
      const messageData = {
        content: newMessage.message || '',
        chatId,
        image: newMessage.image || null,
        req_user_id: loginData.user_id,
        senderName: username,
        senderPic: userpic,
        post_id: postId,
      };
console.log("body",messageData)
      const response = await axios.post(`${ENDPOINT}/api/message`, messageData);
      const formattedMessage: ExtendedMessage = {
        id: response.data.newMessage.id,
        message: response.data.newMessage.content,
        createdAt: response.data.newMessage.createdAt
          ? new Date(response.data.newMessage.createdAt)
          : new Date(), // Fallback to current time if undefined
        senderName:
          response.data.newMessage.sender?.name || username || 'Unknown',
        senderPic: response.data.newMessage.sender?.user_pic || userpic || '',
        image: response.data.newMessage.image || null,
      };

      setMessages((prevMessages) => [...prevMessages, formattedMessage]);

      if (socket) {
        // Send the message to the specific chat room
        console.log("col",response.data.newMessage, chatId)
        socket.emit('new message', { ...response.data.newMessage, chatId });
        socket.emit('stop typing', chatId);
        
      }
      
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewMessage(event.target.value);
    if (socketConnected && userId) {
      socket?.emit('typing', chatId);
    }
  };

  const handleSubmit = () => {
    if (newMessage.trim()) {
      handleSend([
        {
          message: newMessage,
          id: chatId,
        },
      ]);
      setNewMessage('');
    }
  };

  const renderHeader = () => (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: 2,
        borderBottom: '1px solid #ddd',
      }}
    >
      <Avatar src={userpic} sx={{ marginRight: 2 }} />
      <Box sx={{ flex: 1 }}>
        <Typography variant="h6">{username}</Typography>
        {isTyping && (
          <Typography variant="body2" color="textSecondary">
            Typing...
          </Typography>
        )}
      </Box>
      <IconButton>
        <PhoneIcon />
      </IconButton>
    </Box>
  );

  const renderMessage = (message: ExtendedMessage) => {
    const isSender = message.senderName !== username;

    return (
      <Box
        key={message.id}
        sx={{
          display: 'flex',
          justifyContent: isSender ? 'flex-end' : 'flex-start',
          marginBottom: 1,
        }}
      >
        <Box
          sx={{
            maxWidth: '60%',
            padding: 1,
            borderRadius: 2,
            backgroundColor: isSender ? '#dcf8c6' : '#fff',
            boxShadow: 1,
          }}
        >
          <Typography variant="body1">{message.message}</Typography>
          {message.image && (
            <img
              src={message.image}
              alt="Message"
              style={{ maxWidth: '100%' }}
            />
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      {renderHeader()}
      <Box sx={{ flex: 1, padding: 2, overflowY: 'auto' }}>
        {loading ? (
          <CircularProgress />
        ) : (
          messages.map((message) => renderMessage(message))
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: 2,
          borderTop: '1px solid #ddd',
        }}
      >
        <TextField
          value={newMessage}
          onChange={handleInputChange}
          fullWidth
          placeholder="Type a message"
          variant="outlined"
          size="small"
        />
        <IconButton onClick={handleSubmit} color="primary">
          <SendIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default SingleChat;
