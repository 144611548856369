import { useNavigate } from 'react-router-dom';
import { useState, useEffect, Profiler ,useRef} from 'react';
import MessageIcon from './images/MessageIcon.png';
import { Box, Button, Divider, Modal, Typography } from '@mui/material';
import Default from './images/default.png';
import AD from './images/AD.png';
import phone from './images/phone.png';
import goldtick from './images/goldtick.png';
import { useSelector,useDispatch } from 'react-redux';
import { axiosChat, axiosInstance } from '../compounts/api/APIs';
import view from './images/view.png';
import heart from '../compounts/images/Heart.png';
import LikedHeart from '../compounts/images/LikedHeart.png';
import { truncateString } from './ReuseableCompounts/TruncateString';
import star from './images/star.png';
import { formatPrice } from '../compounts/ReuseableCompounts/PriceFormat';
import { formatSQPrice } from '../compounts/ReuseableCompounts/PriceSQFormat';
import DefultScroll from '../compounts/images/defaultScroll.png';
import noPer from './images/noProperty.svg';
// import loaction from '../../compounts/images/location.png';
import 'react-multi-carousel/lib/styles.css';
import { getResponsiveSettings } from '../compounts/ReuseableCompounts/CarouselResponsive';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import profile from './images/profile.png';
import chat from './images/chat.svg';
import { handleChange } from './ReuseableCompounts/PostDetail';
import loaction from './images/location.png';
import axios from 'axios';
import { useChat } from '../compounts/context/ChatContext'
import { MdArrowBackIosNew } from "react-icons/md";
import { FaGreaterThan } from "react-icons/fa6";
import { setUsers } from '../slices/filterSlice';
import { GoHeart } from "react-icons/go";
import { GoHeartFill } from "react-icons/go";

// import { fetchUsers } from "./data";
// import { RootState, AppDispatch } from '../App/store'

interface SimilarProperty {
  message: Property[];
  userDetails: {
    bhk(
      user_id: any,
      post_id: (
        user_id: any,
        post_id: any,
        looking_to: any,
        district: any,
        building_name: any,
        property_type: any,
        bhk: any
      ) => void,
      looking_to: (
        user_id: any,
        post_id: (
          user_id: any,
          post_id: any,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        looking_to: any,
        district: any,
        building_name: any,
        property_type: any,
        bhk: any
      ) => void,
      district: (
        user_id: any,
        post_id: (
          user_id: any,
          post_id: any,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        looking_to: (
          user_id: any,
          post_id: (
            user_id: any,
            post_id: any,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        district: any,
        building_name: (
          user_id: any,
          post_id: (
            user_id: any,
            post_id: any,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          looking_to: (
            user_id: any,
            post_id: (
              user_id: any,
              post_id: any,
              looking_to: any,
              district: any,
              building_name: any,
              property_type: any,
              bhk: any
            ) => void,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        property_type: (
          user_id: any,
          post_id: (
            user_id: any,
            post_id: any,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          looking_to: (
            user_id: any,
            post_id: (
              user_id: any,
              post_id: any,
              looking_to: any,
              district: any,
              building_name: any,
              property_type: any,
              bhk: any
            ) => void,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          district: any,
          building_name: (
            user_id: any,
            post_id: (
              user_id: any,
              post_id: any,
              looking_to: any,
              district: any,
              building_name: any,
              property_type: any,
              bhk: any
            ) => void,
            looking_to: (
              user_id: any,
              post_id: (
                user_id: any,
                post_id: any,
                looking_to: any,
                district: any,
                building_name: any,
                property_type: any,
                bhk: any
              ) => void,
              looking_to: any,
              district: any,
              building_name: any,
              property_type: any,
              bhk: any
            ) => void,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          property_type: any,
          bhk: any
        ) => void,
        bhk: any
      ) => void,
      building_name: (
        user_id: any,
        post_id: (
          user_id: any,
          post_id: any,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        looking_to: (
          user_id: any,
          post_id: (
            user_id: any,
            post_id: any,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        district: any,
        building_name: any,
        property_type: any,
        bhk: any
      ) => void,
      property_type: (
        user_id: any,
        post_id: (
          user_id: any,
          post_id: any,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        looking_to: (
          user_id: any,
          post_id: (
            user_id: any,
            post_id: any,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        district: any,
        building_name: (
          user_id: any,
          post_id: (
            user_id: any,
            post_id: any,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          looking_to: (
            user_id: any,
            post_id: (
              user_id: any,
              post_id: any,
              looking_to: any,
              district: any,
              building_name: any,
              property_type: any,
              bhk: any
            ) => void,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        property_type: any,
        bhk: any
      ) => void,
      bhk: any
    ): void;
    district(
      user_id: any,
      post_id: (
        user_id: any,
        post_id: any,
        looking_to: any,
        district: any,
        building_name: any,
        property_type: any,
        bhk: any
      ) => void,
      looking_to: (
        user_id: any,
        post_id: (
          user_id: any,
          post_id: any,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        looking_to: any,
        district: any,
        building_name: any,
        property_type: any,
        bhk: any
      ) => void,
      district: any,
      building_name: (
        user_id: any,
        post_id: (
          user_id: any,
          post_id: any,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        looking_to: (
          user_id: any,
          post_id: (
            user_id: any,
            post_id: any,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        district: any,
        building_name: any,
        property_type: any,
        bhk: any
      ) => void,
      property_type: (
        user_id: any,
        post_id: (
          user_id: any,
          post_id: any,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        looking_to: (
          user_id: any,
          post_id: (
            user_id: any,
            post_id: any,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        district: any,
        building_name: (
          user_id: any,
          post_id: (
            user_id: any,
            post_id: any,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          looking_to: (
            user_id: any,
            post_id: (
              user_id: any,
              post_id: any,
              looking_to: any,
              district: any,
              building_name: any,
              property_type: any,
              bhk: any
            ) => void,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        property_type: any,
        bhk: any
      ) => void,
      bhk: any
    ): void;
    property_type(
      user_id: any,
      post_id: (
        user_id: any,
        post_id: any,
        looking_to: any,
        district: any,
        building_name: any,
        property_type: any,
        bhk: any
      ) => void,
      looking_to: (
        user_id: any,
        post_id: (
          user_id: any,
          post_id: any,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        looking_to: any,
        district: any,
        building_name: any,
        property_type: any,
        bhk: any
      ) => void,
      district: any,
      building_name: (
        user_id: any,
        post_id: (
          user_id: any,
          post_id: any,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        looking_to: (
          user_id: any,
          post_id: (
            user_id: any,
            post_id: any,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        district: any,
        building_name: any,
        property_type: any,
        bhk: any
      ) => void,
      property_type: any,
      bhk: any
    ): void;
    building_name(
      user_id: any,
      post_id: (
        user_id: any,
        post_id: any,
        looking_to: any,
        district: any,
        building_name: any,
        property_type: any,
        bhk: any
      ) => void,
      looking_to: (
        user_id: any,
        post_id: (
          user_id: any,
          post_id: any,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        looking_to: any,
        district: any,
        building_name: any,
        property_type: any,
        bhk: any
      ) => void,
      district: any,
      building_name: any,
      property_type: any,
      bhk: any
    ): void;
    looking_to(
      user_id: any,
      post_id: (
        user_id: any,
        post_id: any,
        looking_to: any,
        district: any,
        building_name: any,
        property_type: any,
        bhk: any
      ) => void,
      looking_to: any,
      district: any,
      building_name: any,
      property_type: any,
      bhk: any
    ): void;
    post_id(
      user_id: any,
      post_id: any,
      looking_to: any,
      district: any,
      building_name: any,
      property_type: any,
      bhk: any
    ): void;
    user_id: any;
    name: any;
    mobile_no: any;
    category: any;
    user_pic: any;
  };
}
interface Property {
  sublocality: string;
  // district(
  //   user_id: any,
  //   post_id: number,
  //   looking_to: string | undefined,
  //   district: string,
  //   building_name: string,
  //   property_type: any,
  //   bhk: string
  // ): void;
  district:string;
  id: number;
  post_id: number;
  property_type?: any;
  looking_to?: string;
  rent_or_lease?: string;
  property_available_for?: string;
  images?: Image[];
  view_post_count: number;
  bhk: string;
  city: string;
  building_name: string;
  landmark_street: string;
  build_up_area?: string;
  built_up_area?: string;
  plot_area?: string;
  PriceperSqft?: string;
  price?: number;
  cost?: number;
  monthly_rent?: number;
  enter_lease_amount?: number;
  lease_amount?: any;
  liked?: any;
  user_id?: any;
  uri?: any;
  kbl_property?: any;
}
interface scrollmini {
  value: any;
  selectedPostId2: number | null;

}
interface Image {
  filename: string;
  id: string;
  isCover: boolean;
  isvisible: boolean;
  name: string;
  type: string;
  uri: any;
}

const Scrollmini: React.FC<scrollmini> = ({ value, selectedPostId2  }) => {
  const users = useSelector((state: any) => state.usersInfo.users);
  const loginData = useSelector((state: any) => state.userData.userLoginData);
  const navigate = useNavigate();
  const [ad, setAd] = useState<any[]>([]);
  const [receivedValue, setReceivedValue] = useState<any>(value);
  const [sortedUsers, setSortedUsers] = useState<Property[]>([]);
  const [likedState, setLikedState] = useState<Record<number, boolean>>({});
  const [like, setlike] = useState<any>(users.map((item: any) => item.liked));
  const [chatvalue, setChatValue] = useState<any>("")
  const { setSelectedChat } = useChat();
  const dispatch = useDispatch();
  const containerRef = useRef<HTMLDivElement>(null);

useEffect(() => {
  if (selectedPostId2 && containerRef.current) {
    const card = document.querySelector(`.card-${selectedPostId2}`) as HTMLElement;
    if (card) {
      const container = containerRef.current;
      const cardRect = card.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();

      // Calculate the horizontal offset to center the card
      const offset = cardRect.right - containerRect.right + (cardRect.width / 1) - (containerRect.width / 2);

      container.scrollTo({
        left: container.scrollLeft + offset,
        behavior: 'smooth',
      });
    }
  }
}, [selectedPostId2]);
  const [similarProperty, setsimilarProperty] = useState<SimilarProperty>({
    message: [],
    userDetails: {
      user_id: '',
      name: '',
      mobile_no: '',
      category: '',
      user_pic: '',
      bhk: function (
        user_id: any,
        post_id: (
          user_id: any,
          post_id: any,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        looking_to: (
          user_id: any,
          post_id: (
            user_id: any,
            post_id: any,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        district: (
          user_id: any,
          post_id: (
            user_id: any,
            post_id: any,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          looking_to: (
            user_id: any,
            post_id: (
              user_id: any,
              post_id: any,
              looking_to: any,
              district: any,
              building_name: any,
              property_type: any,
              bhk: any
            ) => void,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          district: any,
          building_name: (
            user_id: any,
            post_id: (
              user_id: any,
              post_id: any,
              looking_to: any,
              district: any,
              building_name: any,
              property_type: any,
              bhk: any
            ) => void,
            looking_to: (
              user_id: any,
              post_id: (
                user_id: any,
                post_id: any,
                looking_to: any,
                district: any,
                building_name: any,
                property_type: any,
                bhk: any
              ) => void,
              looking_to: any,
              district: any,
              building_name: any,
              property_type: any,
              bhk: any
            ) => void,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          property_type: (
            user_id: any,
            post_id: (
              user_id: any,
              post_id: any,
              looking_to: any,
              district: any,
              building_name: any,
              property_type: any,
              bhk: any
            ) => void,
            looking_to: (
              user_id: any,
              post_id: (
                user_id: any,
                post_id: any,
                looking_to: any,
                district: any,
                building_name: any,
                property_type: any,
                bhk: any
              ) => void,
              looking_to: any,
              district: any,
              building_name: any,
              property_type: any,
              bhk: any
            ) => void,
            district: any,
            building_name: (
              user_id: any,
              post_id: (
                user_id: any,
                post_id: any,
                looking_to: any,
                district: any,
                building_name: any,
                property_type: any,
                bhk: any
              ) => void,
              looking_to: (
                user_id: any,
                post_id: (
                  user_id: any,
                  post_id: any,
                  looking_to: any,
                  district: any,
                  building_name: any,
                  property_type: any,
                  bhk: any
                ) => void,
                looking_to: any,
                district: any,
                building_name: any,
                property_type: any,
                bhk: any
              ) => void,
              district: any,
              building_name: any,
              property_type: any,
              bhk: any
            ) => void,
            property_type: any,
            bhk: any
          ) => void,
          bhk: any
        ) => void,
        building_name: (
          user_id: any,
          post_id: (
            user_id: any,
            post_id: any,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          looking_to: (
            user_id: any,
            post_id: (
              user_id: any,
              post_id: any,
              looking_to: any,
              district: any,
              building_name: any,
              property_type: any,
              bhk: any
            ) => void,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        property_type: (
          user_id: any,
          post_id: (
            user_id: any,
            post_id: any,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          looking_to: (
            user_id: any,
            post_id: (
              user_id: any,
              post_id: any,
              looking_to: any,
              district: any,
              building_name: any,
              property_type: any,
              bhk: any
            ) => void,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          district: any,
          building_name: (
            user_id: any,
            post_id: (
              user_id: any,
              post_id: any,
              looking_to: any,
              district: any,
              building_name: any,
              property_type: any,
              bhk: any
            ) => void,
            looking_to: (
              user_id: any,
              post_id: (
                user_id: any,
                post_id: any,
                looking_to: any,
                district: any,
                building_name: any,
                property_type: any,
                bhk: any
              ) => void,
              looking_to: any,
              district: any,
              building_name: any,
              property_type: any,
              bhk: any
            ) => void,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          property_type: any,
          bhk: any
        ) => void,
        bhk: any
      ): void {
        throw new Error('Function not implemented.');
      },
      district: function (
        user_id: any,
        post_id: (
          user_id: any,
          post_id: any,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        looking_to: (
          user_id: any,
          post_id: (
            user_id: any,
            post_id: any,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        district: any,
        building_name: (
          user_id: any,
          post_id: (
            user_id: any,
            post_id: any,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          looking_to: (
            user_id: any,
            post_id: (
              user_id: any,
              post_id: any,
              looking_to: any,
              district: any,
              building_name: any,
              property_type: any,
              bhk: any
            ) => void,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        property_type: (
          user_id: any,
          post_id: (
            user_id: any,
            post_id: any,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          looking_to: (
            user_id: any,
            post_id: (
              user_id: any,
              post_id: any,
              looking_to: any,
              district: any,
              building_name: any,
              property_type: any,
              bhk: any
            ) => void,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          district: any,
          building_name: (
            user_id: any,
            post_id: (
              user_id: any,
              post_id: any,
              looking_to: any,
              district: any,
              building_name: any,
              property_type: any,
              bhk: any
            ) => void,
            looking_to: (
              user_id: any,
              post_id: (
                user_id: any,
                post_id: any,
                looking_to: any,
                district: any,
                building_name: any,
                property_type: any,
                bhk: any
              ) => void,
              looking_to: any,
              district: any,
              building_name: any,
              property_type: any,
              bhk: any
            ) => void,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          property_type: any,
          bhk: any
        ) => void,
        bhk: any
      ): void {
        throw new Error('Function not implemented.');
      },
      property_type: function (
        user_id: any,
        post_id: (
          user_id: any,
          post_id: any,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        looking_to: (
          user_id: any,
          post_id: (
            user_id: any,
            post_id: any,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        district: any,
        building_name: (
          user_id: any,
          post_id: (
            user_id: any,
            post_id: any,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          looking_to: (
            user_id: any,
            post_id: (
              user_id: any,
              post_id: any,
              looking_to: any,
              district: any,
              building_name: any,
              property_type: any,
              bhk: any
            ) => void,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        property_type: any,
        bhk: any
      ): void {
        throw new Error('Function not implemented.');
      },
      building_name: function (
        user_id: any,
        post_id: (
          user_id: any,
          post_id: any,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        looking_to: (
          user_id: any,
          post_id: (
            user_id: any,
            post_id: any,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        district: any,
        building_name: any,
        property_type: any,
        bhk: any
      ): void {
        throw new Error('Function not implemented.');
      },
      looking_to: function (
        user_id: any,
        post_id: (
          user_id: any,
          post_id: any,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        looking_to: any,
        district: any,
        building_name: any,
        property_type: any,
        bhk: any
      ): void {
        throw new Error('Function not implemented.');
      },
      post_id: function (
        user_id: any,
        post_id: any,
        looking_to: any,
        district: any,
        building_name: any,
        property_type: any,
        bhk: any
      ): void {
        throw new Error('Function not implemented.');
      },
    },
  });
  const [open, setOpen] = useState<any>(false);
  const Bview = "Sview"

  const handleClose = () => {
    setOpen(false);
  };
  console.log(loginData.token);
  console.log(loginData);


//   const fetchUserData = () => {
//     dispatch(fetchUsers())
// }; 

// useEffect(() => {
//     fetchUserData(); // Fetch users when the component mounts

//     // Handle visibility change
//     const handleVisibilityChange = () => {
//         if (document.visibilityState === 'visible') {
//             fetchUserData(); // Fetch users when the tab becomes visible
//         }
//     };

//     // Add the event listener
//     window.addEventListener('visibilitychange', handleVisibilityChange);

//     // Cleanup event listener on unmount
//     return () => {
//         window.removeEventListener('visibilitychange', handleVisibilityChange);
//     };
// }, [fetchUserData]);



  useEffect(() => {
    setSortedUsers([...users]);

    const initialLikedState = users.reduce(
      (acc: Record<number, boolean>, user: Property) => {
        acc[user.post_id] = user.liked || false;
        return acc;
      },
      {}
    );
    setLikedState(initialLikedState);
  }, [users]);

  // const handleChange = async (id: number, property: string, looking_to?: string) => {
  //   const body = {
  //     post_id: id,
  //     selected_property_type: property,
  //     looking_to: looking_to || '',
  //     user_id: loginData.user_id
  //   };

  //   try {
  //     const response = await axiosInstance.post("/filter/get/postDetail", body);

  //     const queryString = new URLSearchParams({
  //       data: JSON.stringify(response.data),
  //       look: looking_to || ''
  //     }).toString();

  //     const newWindow = window.open(`/PropertyDetails?${queryString}`, '_blank');

  //     if (newWindow) {
  //       newWindow.focus();
  //     }
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };

  useEffect(() => {
    const handleChildData = () => {
      console.log('hello');

      console.log(receivedValue);

      if (value === 'Price (Low to High)') {
        const sortedItems = [...users].sort((a, b) => {
          const costA = parseInt(
            String(
              a.cost ||
              a.price ||
              (a.monthly_rent == null || a.monthly_rent == ''
                ? a.lease_amount || a.enter_lease_amount
                : a.monthly_rent)
            ).replace(/[^0-9.-]+/g, ''),
            10
          );
          const costB = parseInt(
            String(
              b.cost ||
              b.price ||
              (b.monthly_rent == null || b.monthly_rent == ''
                ? b.lease_amount || b.enter_lease_amount
                : b.monthly_rent)
            ).replace(/[^0-9.-]+/g, ''),
            10
          );

          return costA - costB || 0;
        });
        setSortedUsers(sortedItems);
      } else if (value === 'Price (High to Low)') {
        const sortedItems = [...users].sort((a, b) => {
          const costA = parseInt(
            String(
              a.cost ||
              a.price ||
              (a.monthly_rent == null || a.monthly_rent == ''
                ? a.lease_amount || a.enter_lease_amount
                : a.monthly_rent)
            ).replace(/[^0-9.-]+/g, ''),
            10
          );
          const costB = parseInt(
            String(
              b.cost ||
              b.price ||
              (b.monthly_rent == null || b.monthly_rent == ''
                ? b.lease_amount || b.enter_lease_amount
                : b.monthly_rent)
            ).replace(/[^0-9.-]+/g, ''),
            10
          );

          // Reverse the order
          return costB - costA || 0; // Add fallback to handle undefined or NaN values
        });
        setSortedUsers(sortedItems);
      } else if (value === 'Newest Listings') {
        const sortedItems = [...users].sort((a, b) => {
          // Check for createdAt property and convert to Date objects
          const dateA = a.createdAt ? new Date(a.createdAt) : null;
          const dateB = b.createdAt ? new Date(b.createdAt) : null;

          // Compare dates
          return dateA && dateB ? dateB.getTime() - dateA.getTime() : 0; // Add fallback to handle undefined dates
        });
        setSortedUsers(sortedItems);
      } else if (value === 'Older Listings') {
        const sortedItems = [...users].sort((a, b) => {
          const dateA = a.createdAt ? new Date(a.createdAt) : null;
          const dateB = b.createdAt ? new Date(b.createdAt) : null;

          // Compare dates in reverse order
          return dateB && dateA ? dateA.getTime() - dateB.getTime() : 0; // Add fallback to handle undefined dates
        });
        setSortedUsers(sortedItems);
      } else {
        setSortedUsers([...users]);
      }
    };
    handleChildData();
    console.log(sortedUsers);
  }, [value]);

  async function handleContact(
    property: any,
    looking: any,
    user: any,
    post: any,
    dis: any,
    buld: any,
    bhk: any,
    image: any,
  ) {
    console.log(property, looking);



    if (!loginData || loginData.phone === '' || loginData.phone === null) {
      navigate('/contact');
    } else {
      console.log('work');

      const chatBody: any = {
        property: property,
        user: user,
        post: post,
        looking_to: looking,
        district: dis,
        building_name: buld,

        Bhk: bhk,
        image: image,
      };

      setChatValue(chatBody)
      console.log("chat check", chatvalue)
      setOpen(true);

      try {
        const Response = await axiosInstance.get(
          `/api/ads/allAds/${'Property Contact View Ads'}`
        );
        setAd(Response.data.message);
        console.log(Response.data.message);
      } catch (error) {
        console.log('error', error);
      }
      console.log('Ad', ad);

      const body = {
        user_id: loginData.user_id,
        property_type: property,
        looking_to: looking,
        post_id: post,
        post_user_id: user,
      };
      console.log(body);

      try {
        const response = await axiosInstance.post(
          '/filter/contactUserDetail',
          body
        );
        setsimilarProperty(response.data);
        console.log(response.data);
        console.log('hello', similarProperty);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  }

  console.log('hello', similarProperty);

  const handleSaved = async (id: number, property: any, look: any, index: any) => {

    if (loginData.user_id === undefined) {
      navigate('/contact')
    } 
    else {
      const newLikedState = !likedState[id];
      setLikedState((prevState) => ({ ...prevState, [id]: newLikedState }));
      if (like === index) {
        setlike(false);
      } else {
        setlike(index);
      }
      const body = {
        post_id: id,
        liked_user_id: loginData.user_id,
        property_type: property,
        rent_or_sell: look,
        liked: newLikedState,
      };

      try {
        const response = await axiosInstance.post('/like/postLike', body);
        console.log('response', response);
        const updatedProperties = users.map((item: any) => {
          if (item.post_id === id) {
              console.log(`Updating item with ID ${id}:`, { ...item, liked: newLikedState });
              return { ...item, liked: newLikedState }; // Return a new object with the updated liked state
          }
          return item; // Return the unchanged item
      });
      dispatch(setUsers(updatedProperties))
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  const handleChat = async (
    userid: any,
    postid: any,
    look: any,
    dis: any,
    build: any,
    prop: any,
    bhk: any,

  ) => {
    const userId = userid;
    const postId = postid;
    const looking_to = look;
    const district = dis;
    const building_name = build;
    const property_type = prop;
    const Bhk = bhk;


    console.log('User ID:', userId, postId, look, dis, chatvalue);

    try {
      const response = await axiosChat.post('/api/chat', {
        userId,
        req_user_id: loginData.user_id,
      });
      console.log('chat click', response.data);
      if (response.status === 200) {
        console.log('Chat created successfully');
        console.log('ss', sortedUsers);
        console.log('gg', response);

        const data = response.data.users.filter(
          (item: any) => item.user_id !== loginData.user_id
        );
        console.log(data);
        setSelectedChat({
          chatId: response.data.id,
          userId: data[0].user_id,
          username: data[0].name,
          userpic: data[0].user_pic,
        });

        navigate('/chatscreen', {
          state: {
            chatId: response.data.id,
            userId,
            postId: chatvalue.post,
            username: response.data.users[0].name,
            userpic: response.data.users[0].user_pic,
            initialMessageText: `${chatvalue.building_name !==null?chatvalue.building_name:"" } ${chatvalue.property} for ${chatvalue.looking_to} in ${chatvalue.district}`,
            details: {
              userId: chatvalue.user,
              postId: chatvalue.post, 
              looking_to: chatvalue.looking_to,
              district: chatvalue.dis,
              building_name: chatvalue.buld,
              property_type: chatvalue.property,
              Bhk: chatvalue.bhk,
              image: chatvalue.image,
            },
          },
        });
      } else {
        console.error('Failed to create chat. Status code:', response.status);
      }
    } catch (error) {
      console.error('Error creating chat:', error);
    }
  };

  console.log(sortedUsers)





 async function handleReq( userId: any, postid: any, property: any,looking: any,dis: any, buld: any, bhk: any,image: any,){
  console.log('chat click',postid);
  const chatBody: any = {
    property: property,
    user: userId,
    post: postid,
    looking_to: looking,
    district: dis,
    building_name: buld,

    Bhk: bhk,
    image: "https://vattara-img.s3.amazonaws.com/image_1727274600329.jpg",
  };

  setChatValue(chatBody)
    if(loginData=== undefined){
      navigate('/contact')
    } else{
      console.log('chat click',chatvalue);
      try {
        const response = await axiosChat.post('/api/chat', {
          userId,
          req_user_id: loginData.user_id,
        });
        console.log('chat click', response.data);
        if (response.status === 200) {
          console.log('Chat created successfully');
          console.log('ss', sortedUsers);
          console.log('gg', response);
  
          const data = response.data.users.filter(
            (item: any) => item.user_id !== loginData.user_id 
          );
          console.log(data);

          setSelectedChat({
            chatId: response.data.id,
            userId: data[0].user_id,
            username: data[0].name,
            userpic: data[0].user_pic,
          });
  
          navigate('/chatscreen', {
            state: {
              chatId: response.data.id,
              userId:userId,
              postId: chatvalue.post,
              username: response.data.users[0].name,
              userpic: response.data.users[0].user_pic,
              initialMessageText: ` Request Image for this property ${buld !==null?buld:""} ${property}  ${dis}`,
              details: {
                userId: userId,
                postId: postid,
                looking_to: looking,
                district:dis,
                building_name: buld,
                property_type: ` ${chatvalue.property}`,
                Bhk: bhk,
                image: "https://vattara-img.s3.amazonaws.com/image_1727274600329.jpg",
              },
            },
          });
        } else {
          console.error('Failed to create chat. Status code:', response.status);
        }
      } catch (error) {
        console.error('Error creating chat:', error);
      }
    }
  }

  return (
<div className='w-[100%] p-1 bottom-0 '>

      <div ref={containerRef}

   
    className='msm:flex msm:flex-row gap-2 lg:hidden overflow-y-auto whitespace-nowrap mt-2 top-20'>        {sortedUsers && sortedUsers.length > 0 && users !== 'No property' ? (
          sortedUsers.map((item: Property, index: number) => {
            const isSelected = selectedPostId2 === item.post_id;

            const price: any = item.price || item.cost || item.enter_lease_amount || item.lease_amount || (item.monthly_rent ? `${item.monthly_rent} / Month` : null);
            const formattedPrice = formatPrice(price);
            const coverimg: any = item.images && item.images.length > 0 ? item.images?.find(img => img.isCover) || "" : "";
            const sqtv: any = item.PriceperSqft ? item.PriceperSqft : "";
            const priceSQTV = formatSQPrice(sqtv);
            
            const propertyTypeDisplay = item.property_type === "Plot / Land" ? "Plot" : item.property_type;
            const locality = truncateString(item.city, 28)

            const propertyView = {
              postId: item.post_id,
              property_type: item.property_type,
              looking: item.looking_to || item.rent_or_lease || item.property_available_for,
              userId: loginData.user_id,
              Bview: Bview
            }
            console.log(propertyView)

            return (

              <div className={` card-${item.post_id}`} key={item.post_id}>
              <div
  style={{
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
    transition: 'transform 0.2s, box-shadow 0.2s', 
    padding:"4px",
    borderRadius:"10px"

  }}
  className={`bg-[#F5F5F5] relative  w-[250px] mx-[5%] ${isSelected ? 'border-2 border-blue-500' : ''} 
    ${window.innerWidth >= 968 ? 'hover:shadow-lg hover:scale-105' : ''}`} 
>         
                <Box sx={{display:"flex",flexDirection:"row"}}>  
                    <div style={{width:"35%",}}>

                    <div className="relative w-[95px] h-[95px]">
  <img 
    className='w-full h-full rounded-lg mt-1' // Use rounded-lg for consistent border radius
    src={typeof coverimg === 'object' && coverimg?.uri ? coverimg.uri : DefultScroll}
    alt={`Property ${index} Image`}
  />
  <div className={`${item.kbl_property ? "flex" : "hidden"} absolute bottom-0 left-0 items-center mt-1`} style={{ borderTopRightRadius: "15px", borderBottomLeftRadius: "15px", background: 'linear-gradient(90deg, #1063b5,#02a7e4, #0cb7d1)' }}>
    <img className="w-3 h-3" src={star} alt="Star Icon" />
    <div className="text-white mr-1" style={{ fontSize: "7px" }}>VATTARA VERIFIED</div>
  </div>
</div>
                    {/* <div style={{padding:"5px"}} onClick={()=>{handleReq(item.user_id,item.post_id, item.property_type,
                            item.looking_to ||
                            item.rent_or_lease ||
                            item.property_available_for,
                            item.district,
                            item.building_name,

                            item.bhk,
                            coverimg.uri)}} className={`${coverimg == "" ? "flex gap-1 items-center cursor-pointer  bg-[#F5F4F880] text-[#252B5C] border-[#252B5C] border-1 left-[50%] transform -translate-x-[50%] rounded-full" : "hidden"} absolute bottom-4`}>
                     
                      <Box sx={{fontSize:"10px",color:"#252B5C"}}>Request Images</Box>
                      <FaGreaterThan style={{ width: '14px', height: '14px' }}  />

                    </div> */}

          
                    {/* <Box
      sx={{
        position: 'absolute',
        top: 5,
        left: 5,
   
        cursor: 'pointer',
        backgroundColor: '#D9D9D9',
        borderRadius: '20px',
        padding: '3px',
        opacity: 0.8,
        transition: 'opacity 0.3s',
        '&:hover': {
          opacity: 1, // Change opacity on hover
        },
      }}
        onClick={() => handleSaved(item.post_id, item.property_type, item.looking_to || item.rent_or_lease || item.property_available_for, index)}
        >
       <>{item.looking_to}{item.property_available_for} {likedState[item.post_id] ? (
          <GoHeartFill className='text-red-500' size={25} style={{marginTop:"2px"}}/>
        ) : (
          <GoHeart className='text-[#000]'  size={25}  style={{marginTop:"2px"}}/>
        )}</>
      </Box> */}
                  </div>
                  {/* <div className='absolute rounded-bl-xl h-6 top-7 right-1 flex items-center justify-center text-white bg-[#234F68B2] opacity-90 p-1'>
                    <img src={view} className='mr-0.5 w-4 h-4' alt="View Icon" />
                    <div style={{fontSize:"10px"}}>{item.view_post_count} Viewers</div>
                  </div> */}
                  <div style={{width:"65%"}}>
                    <div  style={{display:"flex",flexDirection:"column",justifyItems:"left",paddingLeft:"10px"}}>

                      <Box 
      sx={{ 
        display: 'flex', 
        // backgroundColor: "red",
        flexDirection: "row",
        alignItems: 'center', 
        position: 'relative', 
        justifyContent: "flex-start",
        overflow: 'hidden', // Prevent overflow
        maxWidth: '100%', // Ensure the Box respects the parent width
      }}
    >
      <img src={goldtick} alt="Gold Tick" className='w-4 h-4' />
      <Typography
        sx={{ 
          fontSize: '14px', // Adjust font size
          fontWeight: 'bold', 
          overflow: 'hidden', 
          fontFamily: 'K2D, sans-serif', // Apply K2D font family
color:"#000",
          whiteSpace: 'nowrap', // Ensure text does not wrap
          textOverflow: 'ellipsis', // Add ellipsis for overflow
        }}
      >
        {item.bhk} {propertyTypeDisplay}
      </Typography>
    </Box>
    <Box 
      sx={{ 
        display: 'flex', 
        // backgroundColor: "red",
        flexDirection: "row",
        alignItems: 'center', 
        position: 'relative', 
        justifyContent: "flex-start",
        overflow: 'hidden', // Prevent overflow
        maxWidth: '100%', // Ensure the Box respects the parent width
      }}
    >                        <img src={loaction} alt="Gold Tick" className='w-4 h-4' />

                        <Typography
        sx={{ 
          fontSize: '12px', // Adjust font size
          // fontWeight: 'bold',
          color:"#000", 
          overflow: 'hidden', 
          fontFamily: 'K2D, sans-serif', // Apply K2D font family

          whiteSpace: 'nowrap', // Ensure text does not wrap
          textOverflow: 'ellipsis', // Add ellipsis for overflow
        }}
      > {item.sublocality}, {item.district} </Typography>
                        </Box>

                      <div className='flex ml-4 ' style={{display:"flex",flexDirection:"column"}}>

                        <div className='flex flex-row  ' style={{right:0,left:0}}>
                          <div  style={{display:"flex",justifyContent:"left",alignItems:"center"}}>
                            <div className='text-[#404040] ' style={{fontSize:"12px",textAlign:"left",display:"flex"}}>{item.build_up_area || item.built_up_area} {item.plot_area} sq.ft.</div>
                          </div>
                          <div style={{display:"flex",justifyContent:"left",flexWrap:"wrap",alignItems:"center"}}>

                          <Divider orientation="vertical" variant="middle" sx={{backgroundColor:"#000",color:"#000", width:"1px",height:"9px"}} />
                          {item.PriceperSqft ?  <div className='text-[#404040]' style={{fontSize:"12px"}}>₹ {priceSQTV}</div> : ""}
                          </div>
                        </div>
                        <div className='text-[#404040] ' style={{fontSize:"14px",textAlign:"left",display:"flex",fontWeight:"bold"}}>₹ {formattedPrice}</div>
                        <Button
  onClick={() => { handleChange(propertyView) }}
  sx={{
    height: '20px', // Equivalent to h-5
    width: '80px', // Equivalent to w-20 (adjusted for padding)
    padding: '4px', // Equivalent to p-1
    borderRadius: '8px', // Equivalent to rounded-lg
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#1063b5',
    color: '#FFF',
    fontSize: '10px',
    fontFamily: 'K2D, sans-serif', // Apply K2D font family

    '&:hover': {
      backgroundColor: '#0b4a87', // Optional: change color on hover
    },
  }}
>
  Full View
</Button>
                      </div>
                   
                    </div>
                 
                  </div>
                  </Box>
                </div>
              </div>
            );
          })
        ) : (
          <div className='flex absolute top-4 justify-center items-center '>
            <img src={noPer} />
            <p className='absolute bg-[#D1D1D1] py-3 px-6 rounded-3xl'>Can’t found properties nearby you</p>
          </div>
        )}
        <div className='md:hidden sm:hidden hidden lg:hidden xl:grid fixed z-10'>

        </div>
      </div>
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="contact-modal-title"
        aria-describedby="contact-modal-description"
      >
        <Box >
          <div className='absolute top-[5%] right-[5%] w-[35%] xl:mx z-20 transform rounded-2xl bg-white   outline-none'>
            <div className="z-20  ">
              <div className='flex mt-10 flex-col mx-[20%]'>
                <div className='flex  gap-10'>
                  <div>
                    <img className='w-20 h-20 bg-black rounded-full' src={similarProperty.userDetails.user_pic ? similarProperty.userDetails.user_pic : profile} alt="User" />
                  </div>
                  <div>
                    <p className='text-lg font-semibold'>{similarProperty.userDetails?.name}</p>
                    <p>{similarProperty.userDetails?.category}</p>
                    <p className={`'text-lg font-semibold' ${similarProperty.userDetails?.category === "Owner" ? "hidden" : "flex"}`}>{`+91 ${similarProperty.userDetails?.mobile_no?.slice(3)}`}</p>
                  </div>
                </div>

                <div className='flex mt-10 justify-between' >
                  <div className={`bg-[#2E368F] py-3 px-16 gap-1 rounded-full  justify-center items-center ${similarProperty.userDetails?.category !== "Owner" ? "flex" : "hidden"}`}>
                    <img src={phone} alt="Phone" />
                    <p className='text-white'>Call</p>
                  </div>
                  <div
                    onClick={() =>
                      handleChat(
                        similarProperty.userDetails.user_id,
                        similarProperty.userDetails.post_id,
                        similarProperty.userDetails.looking_to,
                        similarProperty.userDetails.district,
                        similarProperty.userDetails.building_name,
                        similarProperty.userDetails.property_type,
                        similarProperty.userDetails.bhk,

                      )
                    }
                    className="bg-[#2E368F] py-4 px-16 gap-1 rounded-full flex justify-center items-center"
                  >
                    <img src={chat} alt="Chat" />
                    <p className='text-white flex items-center justify-center'>Chat</p>
                  </div>
                </div>
              </div>

              <div className="border-solid border-1 border-t mt-3 mx-3 border-[#DBDBDB] mr- msm:text-right "></div>



              <Carousel className=" mt-4 px-3  w-[100%]" indicators={false}>
                {ad.map((item) => (
                  <Carousel.Item key={item.id} interval={2000}>
                    <div
                      className="flex "

                    >
                      {item ? (
                        <img
                          className="object-cover w-full rounded-2xl h-[25vh] "
                          src={item.link}
                          alt={`Project ${item.id}`}
                        />
                      ) : (
                        <div className="bg-gray-200 w-full  h-full flex items-center justify-center">
                          <p>No Image Available</p>
                        </div>
                      )}
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>



              <div>
                {similarProperty.message?.length > 0 && (
                  <p className='text-xl font-semibold my-4 ml-5'>Similar Properties</p>
                )}
                <div className='flex gap-3 mx-3  overflow-x-scroll w-[94%]'>
                  {similarProperty.message?.map((item: Property, index: number) => {
                    const price = item.price || item.cost || item.enter_lease_amount || item.lease_amount || item.monthly_rent;
                    const formattedPrice = formatPrice(price);
                    const coverim: any = item.images && item.images.length > 0 ? item.images.find(img => img.isCover) || "" : "";
                    const locality = truncateString(item.city, 20)
                    const sqtv: any = item.PriceperSqft ? item.PriceperSqft : "";
                    const priceSQTV = formatSQPrice(sqtv);

                    const propertyView = {
                      postId: item.post_id,
                      property_type: item.property_type,
                      looking: item.looking_to || item.rent_or_lease || item.property_available_for,
                      userId: loginData.user_id,
                      Bview: Bview
                    }
                    return (
                      <div key={index} className=' '>
                        <div className='relative mt- bg-[#FFFFFF]   h-[31vh  w-[15vw] border-[#000080] border-opacity-30 border-2 p-2 rounded-2xl  '>
                          <div className='relative '>

                            <p className='absolute right-0 mr-1  top-3 bg-[#2E368F] text-white py-1 px-3 rounded-2xl '>{item.looking_to || item.rent_or_lease || item.property_available_for}</p>
                            <img onClick={() => { handleChange(propertyView) }}
                              className='w-[15vw] h-[20vh]  flex justify-center bg-black items-center object-contain  rounded-3xl ' src={typeof coverim === 'object' && coverim?.uri ? coverim.uri : ''} />

<div 
  className={`${item.kbl_property ? "flex" : "hidden"} items-center absolute bottom-0 rounded-bl-3xl p-1 rounded-tr-3xl left-0`} 
  style={{ background: 'linear-gradient(90deg, #6135DD, #9B5CDA)' }}
>
  <img className="w-5 h-5" src={star} alt="Star" />
  <p className="text-white text-sm mr-1">VATTARA VERIFIED</p>
</div>                          </div>
                          <div className='ml-3'>
                            <div className='flex items-center '>
                              <p className='text-base flex font-bold'>{item.bhk} {item.property_type}   </p>
                              <img src={goldtick} />
                            </div>
                            <div className='flex gap-2 items-center'>
                              <img className="h-5" src={loaction} />
                              <p className='text-sm capitalize'>{locality}</p>

                            </div>

                            <div className='flex items-center gap-2 '>
                              <p className='text-xl font-bold text-[#404040] '>₹ {formattedPrice} </p>
                              <div className='border-[#404040] h-6 border-1 border-l  '></div>
                              <div className='ml-2'>
                                <div className='flex flex-wra items-center text-sm font-medium text-[#404040]  '>
                                  <p>{item.built_up_area || item.plot_area} </p>
                                  {item.built_up_area || item.plot_area ? <p className='text-sm'>sq.ft</p> : ""}
                                </div>
                                <div className='flex items-center text-sm  font-medium text-[#404040]'>

                                  {item.PriceperSqft ? <p>₹ {priceSQTV}</p> : ""}
                                  {item.PriceperSqft ? <p>/sq.ft</p> : ""}
                                </div>

                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    )
                  })
                  }
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal> */}
    </div>
  );
};

export default Scrollmini;