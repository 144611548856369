import { Button } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux';
import { axiosInstance } from '../api/APIs';
import { useNavigate } from "react-router-dom";

const EditFormPost = () => {
  const passedData = useSelector((state: any) => state.usersInfo.users);
  const property = passedData.find((item: any) => (item.res_or_com)).res_or_com
  const property_type = passedData.find((item: any) => item.property_type).property_type;
  const lookingTo = (passedData.find((item: any) => item) || {}) as any;
  const looking_to = lookingTo.property_available_for || lookingTo.looking_to || lookingTo.rent_or_lease;
  console.log("review setup", property_type, property, looking_to)
const navigate =useNavigate()
console.log("readux check post",passedData)
  async function handlesumbit() {

    let url = ''
    if (property === "Residential") {
      if (looking_to === "Rent" || looking_to === "Lease") {
        url = "/rent/resRentDetail";
      } else if (looking_to === "Sale") {
        url = '/sell/resSellDetail'

      }
    } 
    else if (property === "Commercial") {
      if (looking_to === "Rent" || looking_to === "Lease") {
        switch (property_type) {
          case "Office Space":
            url = "/rent/comOffRent"
            break
          case "Retail Shop":
          case "Showroom":
            url = "/rent/comShopRent"
            break;
          case "Warehouse":
            url = "/rent/comWarehouseRent"
            break;
          case "Industrial Space":
            url = "/rent/comLandRent"
            break;
          default:
            console.error(
              "Unsupported subtype for Commercial Rent/Lease:",
              property_type
            );
        }
      } 
      else if (looking_to === "Sale") {
        switch (property_type) {
          case "Office Space":
            url = '/sell/comOfficeSellPost'
            break;
          case "Retail Shop":
          case "Showroom":
            url=  '/sell/comShopSellPost'
            break;
            case "Warehouse":
              url='/sell/comWarehouseSellPost'
              break;
          case "Industrial Space":
            url='/sell/comLandSellPost'
            break;
            default:
              console.error(
                "Unsupported subtype for Commercial Sell:",
                property_type
              );
        }
      }

    }





    try {
      const response = await axiosInstance.post(url,passedData[0])
      console.log("response",response.data)
      navigate("/list")

    }
    catch (e) {
      console.log("error",e)
    }

  }


  return (
    <div className="md:flex md:justify-end mt-8">
    <Button
     onClick={handlesumbit}
      style={{
        backgroundColor: "#2E368F",
        borderRadius: "3rem",
        padding: "5px 3rem",

      }}
      variant="contained"
      className="w-full md:w-auto"
    >
      Post
    </Button>
  </div>
  )
}

export default EditFormPost