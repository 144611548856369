import { Box, Button, IconButton } from '@mui/material';
import React, { useState } from 'react';
import image1 from '../images/login-2.svg';
import image2 from '../images/log-1.svg';
import profile from '../images/profile.png';
import { axiosInstance } from "../api/APIs";
import { useDispatch } from "react-redux";
import { setUserLoginData } from '../../slices/userDataSlice'
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';

interface ListProps {
    onClose: () => void;
    phone: any;
    UserId?: any
    token?: any
    encryptedToken?: any
}

const data = [
    { label: "Owner" },
    { label: "Agent" },
    { label: "Builder" },
    { label: "Promoter" }
];

interface FormErrors {
    name?: string;
    email?: string;
    companyName?: string;
}
interface BodyType {
    user_id: string;
    name: string;
    email: string;
    lang: string;
    company_logo?: string;
    category: string | undefined;
    company_name?: string;

}
interface UserLoginData {
    user_id: any;
    name: string;
    phone: any;
    email: string;
    category: any;
    lang: string;
    token?: any;
    encryptedToken?: any

}

const List: React.FC<ListProps> = ({ onClose, phone, UserId, token, encryptedToken }) => {
    const loginData = useSelector((item: any) => (item.userData.userLoginData))
    const [details, setDetails] = useState(false);
    const [profileImage, setProfileImage] = useState<string>();
    const [category, setCategory] = useState<string | undefined>();
    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [companyName, setCompanyName] = useState<string>("");
    const [formErrors, setFormErrors] = useState<FormErrors>({});
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const dispatch = useDispatch();
    const Navigate = useNavigate()

    console.log(loginData)

    function handleChange(value: string) {
        if (value !== "Owner") {
            setDetails(true);
        } else {
            setDetails(false);
        }
        setCategory(value);
    }



    function handleName(event: React.ChangeEvent<HTMLInputElement>) {
        setName(event.target.value);
    }

    function handleEmail(event: React.ChangeEvent<HTMLInputElement>) {
        setEmail(event.target.value);
    }




    async function handleSubmitForm() {
        console.log("hg");

        let errors: FormErrors = {};

        if (!name) {
            errors.name = 'Please enter your name';
        }

        if (!email) {
            errors.email = 'Please enter your email';
        }



        // Check if the category requires a company name


        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 2000);
            return;
        }

        setFormErrors({});

        const body: UserLoginData = {
            user_id: UserId,
            name: name,
            email: email,
            phone: phone,
            lang: "en",
            category: "",
            token: loginData.token,
            encryptedToken: loginData.encryptedToken
        };

        console.log(body);

        try {
            const response = await axiosInstance.put("/user/update/userNameEmail", body);
            console.log('Response:', response);
            dispatch(setUserLoginData(body)) // Dispatch a single object
            Navigate('/')
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }



    return (<Box
        sx={{
          display: 'flex',
          // display:"flex",
          alignItems: 'center',
          justifyContent:"center",
          position: 'absolute',
          height:"100%",
          width:"100%",
        
          zIndex: 10,
        }}
        >
        <Box
          sx={{
            bgcolor: 'white',
          //   border: '0.5px solid',
            borderColor: 'gray.300',
            borderRadius: '20px',
            mx: { xs: 4, md: 10 }, // Responsive margins
            overflowY: "hidden",
            width: { xs: '90vw', sm: '70vw', md: '100vw', lg: '70vw', xl: '60vw' },
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            position: 'relative',
            boxShadow: 3, // Add box shadow here
        
          }}
        >
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row',height:{xs:"400px",sm:"400px",md:"400px",lg:"400px",xl:"500px"}, }}> 
                                   <Box sx={{width:"10%",display:{xs:"none",sm:"none",md:"none",lg:"flex"}}}>
              
                        <div className=" z-10">
                              <div className="absolute md:-top-[10%] md:-left-[10%] lg:-left-[10%]  sm:-top-[12%] sm:-left-[12%] msm:-top-[20%] msm:-left-[20%] md:w-56 md:h-56  rounded-full" style={{ backgroundColor: "#0CB7D1" }}></div>
        
                          </div>
              </Box>
              <Box sx={{width:{xs:"100%",sm:"100%",md:"100%",lg:"45%"},height:"100%",justifyContent:"center",display:"flex",alignItems:"center",flexDirection:"column"}}>
              <IconButton
onClick={onClose}                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  display:{xs:"flex",sm:"flex",md:"none",lg:"none"},
                  color: 'gray.500',
                }}
              >
                <CloseIcon />
              </IconButton>   
              <div className='w-[100%]'>
                         <div className=' msm:ml-8 md:ml-[20%] sm:flex sm:flex-col justify-center items-center md:block'>
                             <p className='mb-3 font-bold'>Enter your contact information</p>
                             <div className='mb-3'>
                                 <div className='flex gap-3 mb-1'>
                                     <p>Name</p>
                                     <input onChange={handleName} className='border-none outline-none' />
                                 </div>
                                 {showAlert && formErrors.name && (
                                    <div className='absolute ml-4 z-10 font-semibold text-red-500'>
                                        {formErrors.name}
                                    </div>
                                )}
                                <div className='border border-1 border-[#1F1F1F] w-64'></div>
                            </div>
                            <div className='mb-3'>
                                <div className='flex gap-3 mb-1'>
                                    <p>E-Mail</p>
                                    <input onChange={handleEmail} className='border-none lowercase outline-none' />
                                </div>
                                {showAlert && formErrors.email && (
                                    <div className='absolute ml-4 z-10 font-semibold text-red-500'>
                                        {formErrors.email}
                                    </div>
                                )}
                                <div className='border border-1 border-[#1F1F1F] w-64'></div>
                            </div>
                            <div>
                                <div className='flex gap-3 mb-1'>
                                    <p>Phone</p>
                                    <input value={phone} className='border-none outline-none' readOnly />
                                </div>
                                <div className='border border-1 border-[#1F1F1F] w-64'></div>
                            </div>
                        </div>


                        <div  style={{width:"100%",justifyContent:"center",alignContent:"center",display:"flex",flexDirection:"column",alignItems:"center",marginTop:"10px"}}>
                                    <button 
                                        className="bg-[#1063b5] hover:bg-[#1063b5]  mt-2 py-2 px-1 w-40 flex justify-center items-center text-[#fff] font-bold capitalize rounded-2xl "
                                        onClick={handleSubmitForm}                                    >
Submit                                        </button>
<p className='font-light text-sm'>This helps us personalize your experience.</p>
</div>
                    </div>
          </Box>
                  <Box sx={{width:"45%",height:"100%",display:{xs:"none",sm:"none",md:"none",lg:"flex"},flexDirection:"column",justifyContent:"end",alignItems:"end"}}>
                
          <Box sx={{flexDirection:"row",display:"flex",justifyContent:"end",alignItems:"end"}}>
          <IconButton
onClick={onClose}        sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  color: 'gray.500',
                }}
              >
                <CloseIcon />
              </IconButton>  
             

               <Box sx={{marginX:"20px"}} >
                  <img src={image1} alt='img'  />
                              </Box>
                              <Box sx={{marginX:"20px",marginTop:"10px"}} >
                              <img src={image2} alt='img' />
                              </Box> </Box>
                  </Box>
          </Box>
                   
            </Box>
            </Box>
        // <div className="flex items-center    lg:justify-center absolute top-1/2 lg:left-1/2 transform lg:-translate-x-1/2 -translate-y-1/2 z-10 ">
        //     <div className="bg-white    border border-gray-300 border-solid msm:mx-4 md:mx-0   msm:w-[90vw] custom-xxl:w-[70vw] md:w-[100vw] lg:w-[70vw] xl:w-[60vw] w-[70vw] md:h-[50vh] lg:h-[70vh] xl:h-[600px] rounded-[2%] flex flex-col relative sm:flex sm:flex-col sm:gap-2 sm:align-middle">

        //         <div className="flex flex-row h-full p-0">
        //             <div className='relative z-10'>
        //             <div className="absolute md:-top-[12%] md:-left-20 sm:-top-[12%] sm:-left-[12%] msm:-top-[20%] msm:-left-[20%] md:w-56 md:h-56  rounded-full" style={{ backgroundColor: "#FCEAB6" }}></div>
        //             </div>
        //             <div className='z-60 md:w-[50%] msm:w-[100%]'>
        //                 <div className="absolute right-4 top-3 text-gray-500 text-2xl font-bold cursor-pointer z-20 md" onClick={onClose}>&times;</div>
        //                 <div className='mt-[42%] msm:ml-8 md:ml-[20%] sm:flex sm:flex-col justify-center items-center md:block'>
        //                     <p className='mb-3 font-bold'>Enter your contact information</p>
        //                     <div className='mb-3'>
        //                         <div className='flex gap-3 mb-1'>
        //                             <p>Name</p>
        //                             <input onChange={handleName} className='border-none outline-none' />
        //                         </div>
        //                         {showAlert && formErrors.name && (
        //                             <div className='absolute ml-4 z-10 font-semibold text-red-500'>
        //                                 {formErrors.name}
        //                             </div>
        //                         )}
        //                         <div className='border border-1 border-[#1F1F1F] w-64'></div>
        //                     </div>
        //                     <div className='mb-3'>
        //                         <div className='flex gap-3 mb-1'>
        //                             <p>E-Mail</p>
        //                             <input onChange={handleEmail} className='border-none lowercase outline-none' />
        //                         </div>
        //                         {showAlert && formErrors.email && (
        //                             <div className='absolute ml-4 z-10 font-semibold text-red-500'>
        //                                 {formErrors.email}
        //                             </div>
        //                         )}
        //                         <div className='border border-1 border-[#1F1F1F] w-64'></div>
        //                     </div>
        //                     <div>
        //                         <div className='flex gap-3 mb-1'>
        //                             <p>Phone</p>
        //                             <input value={phone} className='border-none outline-none' readOnly />
        //                         </div>
        //                         <div className='border border-1 border-[#1F1F1F] w-64'></div>
        //                     </div>
        //                 </div>


        //                 <div className='flex mt-[20%] justify-center md:items-start sm:items-center  flex-col'>
        //                     <div onClick={handleSubmitForm} className='flex justify-center md:ml-[20%] sm:ml-0 msm:ml-[20%] w-[40%] items-center py-2 px-20 rounded-3xl bg-[#FBB337]'>
        //                         <button className='  '>Submit</button>
        //                     </div>
        //                     <p className='font-light text-sm md:ml-[16%] lg:ml-[16%] sm:ml-0 msm:ml-[10%]'>This helps us personalize your experience.</p>
        //                 </div>
        //             </div>
        //             <div className=" lg:flex z-30 bottom-0 right-1  absolute bottom-  sm:hidden msm:hidden  md:flex justify-around items-center gap-4">
        //                 <div className="lg:h-[19vh] md:h-[16vh] w-auto ">
        //                     <img src={image1} alt='img' className='md:hidden lg:flex' />
        //                 </div>
        //                 <div className="lg:h-[52vh] md:h-[38vh] w-auto">
        //                     <img src={image2} alt='img' />
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    );
};

export default List;
