import React from 'react';
import { useStepperContext } from './StepperContext';
import { Card, Typography, Button } from '@mui/material';
import dayjs from 'dayjs';
import BackComponent from './icon/BackComponent';
import { axiosInstance } from "../../api/APIs";
import { useSelector } from "react-redux";
import { log } from 'console';
import { useNavigate } from "react-router-dom";

interface ReviewPageProps {
  onNext: () => void;
  onBack: () => void;
  onReset: () => void;
}

interface PropertyDetails {
  negotiable: boolean;
  property_available_for: string;
  building___project_name__optional_: string;
}

const ReviewPage: React.FC<ReviewPageProps> = ({ onBack }) => {
  const {
    userData,
    propImages,
    costPerBuiltUpArea,
    costPerBuiltUpArea2,
    costPerBuiltUpArea3,
    location
  } = useStepperContext();

  const navigate=useNavigate()
  const transformKeysToLowerCaseWithUnderscores = (
    data: Record<string, any>
  ): Record<string, any> => {
    return Object.keys(data).reduce((acc, key) => {
      const newKey = key.toLowerCase().replace(/ /g, '_');
      acc[newKey] = data[key];
      return acc;
    }, {} as Record<string, any>);
  };
  const loginData = useSelector((state: any) => state.userData.userLoginData);
  console.log('userData', userData, propImages);

  const postData = async (url: string, data: Record<string, any>) => {
    try {
      const response = await axiosInstance.post(url, data);
      console.log("body", data)
      console.log("response", response.data)
      navigate("/") 


    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };

  // Extracting user data
  const property = userData.find((item) => item?.['Property Type'])?.[
    'Property Type'
  ];
  const looking_to = userData.find((item) => item?.['Looking To'])?.[
    'Looking To'
  ];
  const property_type = userData.find((item) => item?.['Property Subtype'])?.[
    'Property Subtype'
  ];
  const available_for = userData.find(
    (item) => item?.['Property available for']
  )?.['Property available for'];
  const rooms = userData.find((item) => item?.['BHK'])?.['BHK'];
  // const project = userData.find((item) => item?.["Building/Project(Optional)"])?.["Building/Project(Optional)"];
  const buildingProject = userData.find(
    (item) => item?.['Building/Project(Optional)']
  )?.['Building/Project(Optional)'];
  const city = userData.find((item) => item?.['City'])?.[
    'City'
  ];
  const locality = userData.find((item) => item?.['Locality'])?.['Locality'];
  const landMark = userData.find((item) => item?.['Landmark'])?.['Landmark'];
  const Monthly = userData.find((item) => item?.['Monthly Rent'])?.[
    'Monthly Rent'
  ];
  const Lease = userData.find((item) => item?.['Lease Amount'])?.[
    'Lease Amount'
  ];
  const cost = userData.find((item) => item?.['Cost'])?.['Cost'];
  const plotinsq = userData.find((item) => item?.['Plot Area'])?.['Plot Area'];
  const plprice = userData.find((item) => item?.['Plot Area'])?.['Plot Area'];
  const cprice = userData.find((item) => item?.['Price'])?.['Price'];

  const bhk = userData.find((item) => item?.['BHK'])?.['BHK'];
  const builtUpArea = userData.find((item) => item?.['Built up Area'])?.[
    'Built up Area'
  ];
  const BuiltUpArea = userData.find((item) => item?.['Built Up Area'])?.[
    'Built Up Area'
  ];
  const furnishType = userData.find((item) => item?.['Furnish Type'])?.[
    'Furnish Type'
  ];
  const flatFurnishing = userData.find((item) => item?.['Flat Furnishing'])?.[
    'Flat Furnishing'
  ];
  const societyAmenities = userData.find(
    (item) => item?.['Society Amenities']
  )?.['Society Amenities'];
  const monthlyRent = userData.find((item) => item?.['Monthly Rent'])?.[
    'Monthly Rent'
  ];
  console.log(monthlyRent);

  const availableFrom = userData.find((item) => item?.['Available From'])?.[
    'Available From'
  ];
  const securityDeposit = userData.find((item) => item?.['Select'])?.['Select'];
  // const additionalDetails = userData.find((item) => item?.["Additional Details"])?.["Additional Details"];
  const latitude = userData.find((item) => item?.['Latitude'])?.['Latitude'];
  const longitude = userData.find((item) => item?.['Longitude'])?.['Longitude'];
  const negotiable = userData.find((item) => item?.['Negotiable'])?.[
    'Negotiable'
  ];
  const subLocal = userData.find((item) => item?.["Sublocality"])?.["Sublocality"]
  const images = userData.find((item) => item?.['Images'])?.['Images'];

  const state = userData.find((item) => item?.['State'])?.['State'];
  const kblProperty = userData.find((item) => item?.['KBL Property'])?.[
    'KBL Property'
  ];
  const propertyAvailableFor = userData.find(
    (item) => item?.['Property available for']
  )?.['Property available for'];
  const landmarkStreet = userData.find((item) => item?.['Landmark'])?.[
    'Landmark'
  ];
  const leaseAmount = userData.find((item) => item?.['Lease Amount'])?.[
    'Lease Amount'
  ];
  console.log("location",location);

  const AMENITIES = userData.find((item) => item?.['Amenities'])?.['Amenities'];
  const Furnishing = userData.find((item) => item?.['Furnishing'])?.[
    'Furnishing'
  ];
  const Construction = userData.find((item) => item?.['Construction Status'])?.[
    'Construction Status'
  ];

  const Amenitie = userData.find((item) => item?.["AMENITIES"])?.['AMENITIES']
  const price = userData.find((item) => item?.['Cost'])?.['Cost'];
  const Price = userData.find((item) => item?.['Price'])?.['Price'];
  const width = userData.find((item) => item?.['Width'])?.['Width'];
  const Length = userData.find((item) => item?.['Length'])?.['Length'];
  const Plot = userData.find((item) => item?.['Plot Area'])?.['Plot Area'];
  const Status = userData.find((item) => item?.['Property Status'])?.[
    'Property Status'
  ];
  const Patta = userData.find((item) => item?.['Patta Available'])?.[
    'Patta Available'
  ];
  const Plot_Price = userData.find((item) => item?.['Plot Price'])?.[
    'Plot Price'
  ];
  const Possession = userData.find((item) => item?.['Possession Status'])?.[
    'Possession Status'
  ];
  const carpet_area = userData.find((item) => item?.['Carpet Area'])?.[
    'Carpet Area'
  ];
  const floor = userData.find((item) => item?.['Select Your Floor'])?.[
    'Select Your Floor'
  ];
  const strFloor = floor?.join()
  console.log("floor", strFloor)
  const Sfloor = userData.find((item) => item?.['Total Floors'])?.[
    'Total Floors'
  ];
  const facing = userData.find((item) => item?.['Facing'])?.['Facing'];
  const Lifts = userData.find((item) => item?.['Lifts'])?.['Lifts'];
  const parking = userData.find((item) => item?.['Parking'])?.['Parking'];
  const Min = userData.find((item) => item?.['Min. Number of seats'])?.[
    'Min. Number of seats'
  ];
  const Max = userData.find((item) => item?.['Max. Number of seats'])?.[
    'Max. Number of seats'
  ];
  const Washroom = userData.find((item) => item?.['Washroom'])?.['Washroom'];
  const Meeting = userData.find((item) => item?.['Meeting Room'])?.[
    'Meeting Room'
  ];
  const Condition = userData.find((item) => item?.['Property Condition'])?.[
    'Property Condition'
  ];
  const Property_available = userData.find(
    (item) => item?.['Property available for']
  )?.['Property available for'];
  const property_description = userData.find(
    (item) => item?.['Property Description']
  )?.['Property Description'];
  const Suitable = userData.find((item) => item?.['Suitable For'])?.[
    'Suitable For'
  ];
  const Min_Seats = userData.find((item) => item?.['Min.Number of seats'])?.[
    'Min.Number of seats'
  ];
  const Max_Seats = userData.find((item) => item?.['Max.Number of seats'])?.[
    'Max.Number of seats'
  ];
  const measure = userData.find((item) => item?.["measure"])?.["measure"]
const plot_area_house =userData.find((item)=>item?.['Plot Area'])?.['Plot Area']
  const additionalDetails = {
    balcony: userData.find((item) => item?.['Balcony'])?.['Balcony'],
    bathroom: userData.find((item) => item?.['Bathroom'])?.['Bathroom'],
    parking: userData.find((item) => item?.['Parking'])?.['Parking'],
    preferred_tenant_type: userData.find(
      (item) => item?.['Preferred Tenant Type']
    )?.['Preferred Tenant Type'],
    facing: userData.find((item) => item?.['Facing'])?.['Facing'],
    carpet_area: userData.find((item) => item?.['Carpet Area'])?.[
      'Carpet Area'
    ],
    maintenance_charges: userData.find(
      (item) => item?.['Maintenance Charges']
    )?.['Maintenance Charges'],
    servant_room: userData.find((item) => item?.['Servant Room'])?.[
      'Servant Room'
    ],
    property_description: userData.find(
      (item) => item?.['Property Description']
    )?.['Property Description'],
    eligible_for_loan: userData.find((item) => item?.['Loan Availability'])?.[
      'Loan Availability'
    ],
  };
  const formattedAvailableFrom = availableFrom
    ? dayjs(availableFrom).format('MMMM DD YYYY')
    : undefined;

  const formatPriceToIndianLocale = (price: string) => {
    if (price === undefined || price === null) {
      return '';
    }

    // Convert to string and reverse for easier processing
    let str = price.toString().split('').reverse().join('');
    let formattedStr = '';

    // Add commas every 2 digits for the first segment (hundreds, thousands)
    for (let i = 0; i < str.length; i++) {
      if (i > 2 && (i - 2) % 2 === 1) {
        formattedStr += ',';
      }
      formattedStr += str[i];
    }

    // Reverse back to the original order and return
    return `₹ ${formattedStr.split('').reverse().join('')}`;
  };


  const formatPrice = (value: any) => {

    if (value === null || value === undefined || value === '') {
      return '';
    }


    if (isNaN(value)) {
      return value;
    }

    return `₹ ${new Intl.NumberFormat('en-IN', {
      style: 'decimal',
      maximumFractionDigits: 0,
    }).format(Number(value))}`;
  };

  const handlePostData = () => {
    // const transformedValues = transformKeysToLowerCaseWithUnderscores(userData);

    // Prepare the data object
    // const body = {
    //   user_id: "2eedf810-58c3-4604-948d-1d4e3631e897",
    //   property_subtype: property_type,
    //   building___project_name__optional_: buildingProject,
    //   bhk: bhk,
    //   built_up_area: builtUpArea,
    //   furnish_type: furnishType,
    //   flat_furnishing: Furnishing,
    //   society_amenities: AMENITIES,
    //   monthly_rent: `₹${monthlyRent} `,
    //   available_from: formattedAvailableFrom,
    //   security_deposit: securityDeposit,
    //   additional_details: additionalDetails,
    //   latitude: 13.0012,
    //   longitude: 80.2565,
    //   negotiable: negotiable,
    //   images: propImages,
    //   locality: locality,
    //   city: city,
    //   state: "TAMILNADU",
    //   property_available_for: available_for,
    //   landmark_street: landmarkStreet,
    //   lease_amount: leaseAmount,
    //   construction_status: Construction === "Under Construction" ? formattedAvailableFrom : Construction,
    //   price: `₹${price}`,
    //   plot_price: `₹`,
    //   // width,
    //   // length,
    //   // plot_area,
    //   // measure,
    //   // approval_status,
    //   // landmark_street,
    //   // patta_available

    // }
    // console.log(body);

    const postData1 = {
      user_id: loginData.user_id,
      property_subtype: property_type,
      building___project_name__optional_: buildingProject,
      bhk: bhk,
      built_up_area: builtUpArea,
      furnish_type: furnishType,
      flat_furnishing: Furnishing,
      society_amenities: AMENITIES,
      monthly_rent:
        available_for === 'Rent' ? monthlyRent : '',
      available_from: formattedAvailableFrom,
      security_deposit: securityDeposit,
      additional_details: additionalDetails,
      latitude: location.latitude,
      longitude: location.longitude,
      negotiable: negotiable,
      images: propImages,
      locality: locality,
      city: city,
      sublocality: subLocal,
      property_available_for: available_for,
      landmark_street: landmarkStreet,
      lease_amount:
        available_for === 'Lease' ? leaseAmount : '',
    };

    const postData2 = {
      user_id: loginData.user_id,
      property_subtype: property_type,
      building___project_name__optional_: buildingProject,
      bhk: bhk,
      built_up_area: builtUpArea,
      furnish_type: furnishType,
      flat_furnishing: Furnishing,
      society_amenities: AMENITIES,
      additional_details: property_type === 'Plot / Land' ? '' : additionalDetails,
      facing: property_type === 'Plot / Land' ? facing : '',
      latitude: location.latitude,
      longitude: location.longitude,
      negotiable: negotiable,
      images: propImages,
      locality: locality,
      city: city,
      landmark_street: landmarkStreet,
      price: price,
      construction_status:
        Construction === 'Under Construction'
          ? formattedAvailableFrom
          : Construction,
      plot_price: Plot_Price,
      width: width,
      length: Length,
      plot_area: Plot,
      measure: measure,
      property_status: Status,
      patta_available: Patta,
      property_description: property_type === 'Plot / Land' ? property_description : "",
      sublocality: subLocal,
      plot_area_house:property_type ==="Apartment"? null : plot_area_house 
    };

    const postData3 = {
      user_id: loginData.user_id,
      property_subtype: property_type,
      building___project_name__optional_: buildingProject,
      possession_status:
        Possession === 'Under Construction'
          ? formattedAvailableFrom
          : Possession,
      built_up_area: BuiltUpArea,
      carpet_area: carpet_area,
      monthly_rent:
        available_for === 'Rent' ? monthlyRent : '',
      security_deposit: securityDeposit,
      negotiable: negotiable,
      total_floors: Sfloor,
      select_your_floor: strFloor,
      facing: facing,
      lifts: Lifts,
      parking: parking,
      min__number_of_seats: Min,
      max__number_of_seats: Max,
      washroom: Washroom,
      meeting_room: Meeting,
      property_condition: Condition,
      property_available_for: Property_available || available_for,
      additional_details: {
        amenities: Array.isArray(Amenitie) ? Amenitie.join(', ') : Amenitie,
      },
      latitude: location.latitude,
      longitude: location.longitude,
      images: propImages,
      locality: locality,
      lease_amount:
        available_for === 'Lease' ? leaseAmount : '',
      city: city,
      available_from: formattedAvailableFrom,
      landmark_street: landmarkStreet,
      property_description: property_description,
      sublocality: subLocal
    };

    const postData4 = {
      user_id: loginData.user_id,
      property_subtype: property_type,
      building___project_name__optional_: buildingProject,
      built_up_area: BuiltUpArea,
      possession_status:
        Possession === 'Under Construction'
          ? formattedAvailableFrom
          : Possession,
      suitable_for: Suitable,
      carpet_area: carpet_area,
      negotiable: negotiable,
      security_deposit: formatPrice(securityDeposit),
      monthly_rent:
        available_for === 'Rent' ? monthlyRent : '',
      facing,
      total_floors: Sfloor,
      select_your_floor: strFloor,
      parking: parking,
      washroom: Washroom,
      additional_details: {
        amenities: Array.isArray(Amenitie) ? Amenitie.join(', ') : Amenitie,
      },
      latitude: location.latitude,
      longitude: location.longitude,
      images: propImages,
      property_available_for: available_for,
      property_description: property_description,
      locality: locality,
      city: city,
      lease_amount:
        available_for === 'Lease' ? leaseAmount : '',
      landmark_street: landmarkStreet,
      sublocality: subLocal
    };

    const postData5 = {
      user_id: loginData.user_id,
      property_subtype: property_type,
      building___project_name__optional_: buildingProject,
      possession_status:
        Possession === 'Under Construction'
          ? formattedAvailableFrom
          : Possession,
      built_up_area: BuiltUpArea,
      carpet_area: carpet_area,
      security_deposit: formatPrice(securityDeposit),
      negotiable: negotiable,
      parking: parking,
      additional_details: {
        amenities: Array.isArray(Amenitie) ? Amenitie.join(', ') : Amenitie,
      },
      monthly_rent:
        available_for === 'Rent' ? monthlyRent : '',
      facing,
      property_available_for: available_for,
      property_description: property_description,
      lease_amount:
        available_for === 'Lease' ? leaseAmount : '',
      latitude: location.latitude,
      longitude: location.longitude,
      images: propImages,
      locality: locality,
      city: city,
      // available_from:formattedAvailableFrom ,
      landmark_street: landmarkStreet,
      sublocality: subLocal
    };

    const postData6 = {
      user_id: loginData.user_id,
      property_subtype: property_type,
      building___project_name__optional_: buildingProject,
      possession_status:
        Possession === 'Under Construction'
          ? formattedAvailableFrom
          : Possession,
      plot_area: Plot,
      monthly_rent:
        available_for === 'Rent' ? monthlyRent : '',
        security_deposit: formatPrice(securityDeposit),
      negotiable: negotiable,
      facing,
      additional_details: {
        amenities: Array.isArray(Amenitie) ? Amenitie.join(', ') : Amenitie,
      },
      latitude: location.latitude,
      longitude: location.longitude,
      images: propImages,
      locality: locality,
      property_available_for: available_for,
      lease_amount:
        available_for === 'Lease' ? leaseAmount : '',
      property_description: property_description,
      city: city,
      //available_from,
      landmark_street: landmarkStreet,
      sublocality: subLocal
    };

    const postData7 = {
      user_id:loginData.user_id,
      property_subtype: property_type,
      building___project_name__optional_: buildingProject,
      property_condition: Condition,
      possession_status:
        Possession === 'Under Construction'
          ? formattedAvailableFrom
          : Possession,
      built_up_area: builtUpArea,
      carpet_area: carpet_area,
      negotiable: negotiable,
      price: Price,
      total_floors: Sfloor,
      select_your_floor: strFloor,
      lifts: Lifts,
      parking: parking,
      min_number_of_seats: Min_Seats,
      max_number_of_seats: Max_Seats,
      meeting_room: Meeting,
      washroom: Washroom,
      additional_details: {
        amenities: Array.isArray(Amenitie) ? Amenitie.join(', ') : Amenitie,
      },
      facing,
      latitude: location.latitude,
      longitude: location.longitude,
      property_description: property_description,
      images: propImages,
      locality: locality,
      city: city,
     
      landmark_street: landmarkStreet,
      sublocality: subLocal
    };

    const postData8 = {
      user_id:loginData.user_id,
      property_subtype: property_type,
      building___project_name__optional_: buildingProject,
      possession_status:
        Possession === 'Under Construction'
          ? formattedAvailableFrom
          : Possession,
      suitable_for: Suitable,
      built_up_area: builtUpArea,
      carpet_area: carpet_area,
      price: Price,
      negotiable: negotiable,
      total_floors: Sfloor,
      select_your_floor: strFloor,
      facing,
      parking: parking,
      washroom: Washroom,
      additional_details: {
        amenities: Array.isArray(Amenitie) ? Amenitie.join(', ') : Amenitie,
      },
      latitude: location.latitude,
      longitude: location.longitude,
      images: propImages,
      locality: locality,
      property_description: property_description,
      city: city,
      
      landmark_street: landmarkStreet,
      sublocality: subLocal
    };

    const postData9 = {
      user_id:loginData.user_id,
      property_subtype: property_type,
      building___project_name__optional_: buildingProject,
      possession_status:
        Possession === 'Under Construction'
          ? formattedAvailableFrom
          : Possession,
      built_up_area: builtUpArea,
      carpet_area: carpet_area,
      price: Price,
      negotiable: negotiable,
      facing,
      parking: parking,
      additional_details: {
        amenities: Array.isArray(Amenitie) ? Amenitie.join(', ') : Amenitie,
      },
      property_description: property_description,
      latitude: location.latitude,
      longitude: location.longitude,
      images: propImages,
      locality: locality,
      city: city,
      landmark_street: landmarkStreet,
      sublocality: subLocal
    };

    const postData10 = {
      user_id:loginData.user_id,
      property_subtype: property_type,
      building___project_name__optional_: buildingProject,
      possession_status:
        Possession === 'Under Construction'
          ? formattedAvailableFrom
          : Possession,
      plot_area: Plot,
      price: Price,
      negotiable: negotiable,
      measure:measure ,
      facing,
      additional_details: {
        amenities: Array.isArray(Amenitie) ? Amenitie.join(', ') : Amenitie,
      },
      property_description: property_description,
      latitude: location.latitude,
      longitude: location.longitude,
      images: propImages,
      locality: locality,
      city: city,
     
      landmark_street: landmarkStreet,
      sublocality: subLocal
    };
    console.log('Hello');

    console.log(postData8);
    if (property === 'Residential') {
      // Handling Residential properties
      if (looking_to === 'Rent/Lease') {
        postData('/rent/resRentDetail', postData1);
      } else if (looking_to === 'Sell') {
        postData('/sell/resSellDetail', postData2);
      }
    } else if (property === 'Commercial') {
      // Handling Commercial properties
      if (looking_to === 'Rent/Lease') {
        switch (property_type) {
          case 'Office Space':
            postData('/rent/comOffRent', postData3);
            break;
          case 'Retail Shop':
          case 'Showroom':
            postData('/rent/comShopRent', postData4);
            break;
          case 'Warehouse':
            postData(
              '/rent/comWarehouseRent',
              postData5
            );
            break;
          case 'Industrial Space':
            postData('/rent/comLandRent', postData6);
            break;
          default:
            console.error(
              'Unsupported subtype for Commercial Rent/Lease:',
              property_type
            );
        }
      } else if (looking_to === 'Sell') {
        switch (property_type) {
          case 'Office Space':
            postData(
              '/sell/comOfficeSellPost',
              postData7
            );
            break;
          case 'Retail Shop':
          case 'Showroom':
            postData(
              '/sell/comShopSellPost',
              postData8
            );
            break;
          case 'Warehouse':
            postData(
              '/sell/comWarehouseSellPost',
              postData9
            );
            break;
          case 'Industrial Space':
            postData(
              '/sell/comLandSellPost',
              postData10
            );
            break;
          default:
            console.error(
              'Unsupported subtype for Commercial Sell:',
              property_type
            );
        }
      } else {
        console.error('Unknown transaction type for Commercial:', looking_to);
      }
    } else {
      console.error('Unknown property type:', property);
    }
  };

  const GetCoverImage = () => {
    const Image = propImages.filter((e) => e.isCover);
    return Image[0]?.uri;
  };

  return (
    <div className="min-h-[3200px] shadow bg-white rounded-lg">
      <Button
        onClick={onBack}
        style={{
          position: 'relative',
          right: '20px',
        }}
      >
        <BackComponent />
      </Button>
      <Typography
        style={{
          fontWeight: '700',
          marginLeft: '40%',
          position: 'relative',
          bottom: '30px',
        }}
      >
        Review
      </Typography>
      <div className="mb-6  ">
        <img
          src={GetCoverImage()}
          alt="Cover Image"
          className="w-full h-[20%] rounded"
        />
      </div>

      <div className="mb-6">
        <Typography variant="h6" className="text-lg font-semibold mb-4">
          Basic Details
        </Typography>
        <div className="flex flex-wrap gap-4">
          {userData
            .filter((item) => item.formName === 'Basic Details')
            .map((item, index) => (
              <div key={index} className="w-full sm:w-11/12">
                {Object.keys(item).map(
                  (key) =>
                    key !== 'formName' &&
                    key !== 'id' &&
                    key !== 'required' && (
                      <div key={key} className="flex justify-between mb-2">
                        <span className="text-sm text-gray-700 font-semibold ">
                          {key === 'Locality' ? 'Locality' : key}:
                        </span>
                        {key === 'Locality' ? (
                          <div className="text-xs text-gray-700 bg-white p-2 w-[50%] rounded-md">
                            {item[key]}
                          </div>
                        ) : (
                          <span className="text-sm text-gray-700">
                            {item[key]}
                          </span>
                        )}
                      </div>
                    )
                )}
              </div>
            ))}
        </div>
      </div>

      <hr className="border-gray-300 mb-6" />

      {/* Property Details */}
      <div className="mb-6">
        <Typography variant="h6" className="text-lg font-semibold mb-4">
          Property Details
        </Typography>
        <div className="flex flex-wrap gap-4">
          {userData
            .filter((item) => item.formName === 'Property Details')
            .map((item, index) => (
              <div key={index} className="w-full sm:w-11/12">
                {Object.keys(item).map(
                  (key) =>
                    key !== 'formName' &&
                    key !== 'id' &&
                    key !== 'required' && (
                      <div key={key} className="flex justify-between mb-2">
                        <span className="text-sm text-gray-700 font-semibold">
                          {key}:
                        </span>
                        <span className="text-sm text-gray-700">
                          {item[key]}
                        </span>
                      </div>
                    )
                )}
              </div>
            ))}
        </div>
      </div>

      <hr className="border-gray-300 mb-6" />

      {/* Price Details */}
      <div className="mb-6">
        <Typography variant="h6" className="text-lg font-semibold mb-4">
          Price Details
        </Typography>
        <div className="flex flex-wrap gap-4">
          {userData
            .filter((item) => item.formName === 'Price Details')
            .map((item, index) => (
              <div key={index} className="w-full sm:w-11/12">
                {Object.keys(item).map(
                  (key) =>
                    key !== 'formName' &&
                    key !== 'id' &&
                    key !== 'required' && (
                      <div key={key} className="flex justify-between mb-2">
                        <span className="text-sm text-gray-700 font-semibold ">
                          {key === 'Property Description'
                            ? 'Property Description'
                            : key}
                          :
                        </span>
                        {key === 'Property Description' ? (
                          <div className="text-xs text-gray-700 bg-white p-2 w-[50%] rounded-md">
                            {item[key]}
                          </div>
                        ) : (
                          <span className="text-sm text-gray-700">
                            {item[key]}
                          </span>
                        )}
                      </div>
                    )
                )}
              </div>
            ))}
        </div>
      </div>

      <hr className="border-gray-300 mb-6" />

      {/* Additional Details */}
      {/* Additional Details */}
      <div>
        <Typography variant="h6" className="text-lg font-semibold mb-4">
          Additional Details
        </Typography>
        <div className="flex flex-wrap gap-4">
          {userData
            .filter((item) => item.formName === 'Additional Details')
            .map((item, index) => (
              <div key={index} className="w-full sm:w-11/12">
                {Object.keys(item).map(
                  (key) =>
                    key !== 'formName' &&
                    key !== 'id' &&
                    key !== 'required' && (
                      <div key={key} className="flex justify-between mb-2">
                        <span className="text-sm text-gray-700 font-semibold">
                          {key === 'Property Description' || key === 'AMENITIES'
                            ? key
                            : key}
                          :
                        </span>
                        {key === 'Property Description' ? (
                          <div className="text-xs text-gray-700 bg-white p-2 w-[50%] rounded-md">
                            {item[key]}
                          </div>
                        ) : key === 'AMENITIES' ? (
                          <div className="text-xs text-gray-700 bg-white p-2 w-[50%] rounded-md">
                            {item[key]}
                          </div>
                        ) : (
                          <span className="text-sm text-gray-700">
                            {item[key]}
                          </span>
                        )}
                      </div>
                    )
                )}
              </div>
            ))}
        </div>
      </div>

      <hr className="border-gray-300 mb-6 mt-2" />

      {/* Photos */}
      <div className="mb-6">
        <Typography variant="h6" className="text-lg font-semibold mb-4">
          Photos
        </Typography>
        <div className="grid grid-cols-3 gap-4">
          {propImages &&
            propImages.length > 0 &&
            propImages.map((item, index) => (
              <div key={index} className="relative">
                <img
                  src={item.uri}
                  alt={`Image ${index}`}
                  className="custom-xxl:w-full custom-xxl:h-[90%] xl:w-full xl:h-[90%] lg:w-full lg:h-[80%] md:w-full md:h-[80%] sm:w-full sm:h-[80%] msm:w-[full] msm:h-[80%] object-cover rounded-md"
                />
              </div>
            ))}
        </div>
        <button
          onClick={handlePostData}
          className="bg-blue-500 text-white px-4 py-2 rounded-md mb-20"
        >
          Submit
        </button>

      </div>
    </div>
  );
};

export default ReviewPage;
