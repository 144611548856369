import { Radio } from "@mui/material";
import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { axiosInstance } from "../compounts/api/APIs";
import { useNavigate } from "react-router-dom";
type RadioValue = string

type OtherReasonValue = string;

const reportReasons = [
  {
    reason: "Incorrect Information",
    description: "The listing contains false or misleading information",
    value: "incorrect_information",
  },
  {
    reason: "Duplicate Listing",
    description: "This property is listed more than once",
    value: "duplicate_listing",
  },
  {
    reason: "Inappropriate Content",
    description: "The listing contains inappropriate or offensive content",
    value: "inappropriate_content",
  },
  {
    reason: "Fraudulent Listing",
    description: "I suspect this listing is a scam or fraudulent",
    value: "fraudulent_listing",
  },
  {
    reason: "Other",
    description: "(Please specify)",
    value: "other",
  },
];

interface ReportPopUpProps {

  setShowReport: any
  showReport: any
  property: any
  user: any
  post: any
  look: any

}

const ReportPopUp: React.FC<ReportPopUpProps> = ({ showReport, setShowReport, property, user, post, look }) => {
  const [selectedValue, setSelectedValue] = useState<RadioValue>("");
  const [otherReason, setOtherReason] = useState<OtherReasonValue>("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const Navigate = useNavigate()
  const handleRadioChange = (value: RadioValue) => {
    setSelectedValue(value);
    setShowErrorMessage(false);
  };

  const handleOtherReasonText = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setOtherReason(event.target.value);
  };

  const handleSummit = async () => {
    if (selectedValue === "other") {
      console.log(
        `Selected reason for the report : ${selectedValue} (${otherReason})`
      );

    } else if (selectedValue.length === 0) {
      setShowErrorMessage(true);
    } else {
      console.log("Selected reason for the report : ", selectedValue);
      const body = {
        user_id: user,
        post_id: post,
        property_type: property,
        looking_to: look,
        post_report: selectedValue === "other" ? otherReason : selectedValue
  
  
      }
    console.log(body)
      try {
        const response = await axiosInstance.post("/delete/post/report", body)
        console.log(response);
        // Navigate('/PropertyDetails')
        setShowReport(false)
  
      } catch (error) {
        console.log("reportApi ", error)
      }

    }
    
  }
  function handleClose() {
    setShowReport(false)
  }
  ;
  return (
    <section
      className={` bg-white w-[100%] md:w-[60%] lg:w-[60%] custom-lg:w-[100%] mx-auto my-9 p-5 rounded-[19px] shadow-lg transition-opacity duration-300`}
    >
      <header className="relative text-[#202020] text-center pb-4 border-b-[0.5px] border-gray-300">
        <div className="text-md md:text-lg lg:text-xl font-bold">
          Report an Issue <span onClick={handleClose} className="absolute top-0 right-0 md:right-2 lg:right-4 text-gray-500 text-2xl cursor-pointer"><IoClose /></span>
        </div>
        <div className="text-sm md:text-md lg:text-lg font-semibold mt-4">
          Help us maintain a high-quality listing
        </div>
      </header>
      <main className="w-[90%] lg:w-[80%] mx-auto">
        <div className="text-xs md:text-sm lg:text-md mt-2 font-normal text-black text-center">
          Please select the reason for reporting this property. Your feedback is
          important to us.
        </div>
        <div className="md:ml-4 lg:ml-10">
          {reportReasons.map((report) => (
            <div onClick={() => handleRadioChange(report.value as RadioValue)} className="flex mt-6 cursor-pointer" key={report.value}>
              <Radio
                value={report.value}
                checked={selectedValue === report.value}
                sx={{
                  color: "#80848E",
                  "&.Mui-checked": { color: "#2E368F" },
                  "& svg": {
                    fontSize: 20,
                  },
                  marginLeft: 2,
                }}
              />
              <div className="ml-8">
                <h3 className="font-bold text-sm md:text-md lg:text-lg">
                  {report.reason}
                </h3>
                <p className="text-xs md:text-sm lg:text-md font-normal">
                  {report.description}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div
          className={`${selectedValue === "other"
            ? "max-h-96 opacity-100"
            : "max-h-0 opacity-0"
            } transition-all duration-700 my-4 overflow-hidden`}
        >
          <textarea
            onChange={handleOtherReasonText}
            placeholder="Describe the issue in detail"
            className="rounded-lg border-2 transition-colors duration-100 w-full h-[150px] border-gray-300 focus:border-gray-400 outline-none p-2"
          ></textarea>
        </div>
        <div
          className={`${showErrorMessage && "opacity-100 max-h-8"
            } opacity-0 max-h-0 overflow-hidden text-red-600 text-sm text-center font-semibold transition-opacity duration-200`}
        >
          Please select any reason
        </div>
        <div className="flex justify-center mt-4">
          <button
            onClick={handleSummit}
            className="bg-[#2E368F] hover:bg-opacity-95 active:bg-opacity-90 text-[#f5f5f5] px-20 py-3    mx-auto rounded-full"
          >
            Submit Report
          </button>
        </div>
      </main>
    </section>
  );
};

export default ReportPopUp;
