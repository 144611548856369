import { Button } from '@mui/material';
import React, { useState } from 'react';
import profile from '../images/profile.png';
import { axiosInstance } from "../api/APIs";
import { useDispatch } from "react-redux";
import { setUserLoginData } from '../../slices/userDataSlice'
import { Navigate, useNavigate } from 'react-router-dom';
import AWS from 'aws-sdk';
import Resizer from 'react-image-file-resizer';
import image1 from '../images/login-2.svg';
import image2 from '../images/log-1.svg';
import { useSelector } from "react-redux";


// AWS S3 Configuration
AWS.config.update({
    region: 'us-east-1',
    accessKeyId: 'AKIAQ3EGVP6CLIGM245V',
    secretAccessKey: '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',
});

const S3 = new AWS.S3();

interface ListProps {
    onClose: () => void;
    phone: any;
    UserId?: any
    token?:any
}

const data = [
    { label: "Owner" },
    { label: "Agent" },
    { label: "Builder" },
    { label: "Promoter" }
];

interface FormErrors {
    name?: string;
    email?: string;
    companyName?: string;
}

interface BodyType {
    user_id: string;
    name: string;
    email: string;
    lang: string;
    company_logo?: string;
    category: string | undefined;
    company_name?: string;
}

interface UserLoginData {
    user_id: any;
    name: string;
    phone: any;
    email: string;
    company_logo?: string;
    company_name?: string;
    lang: string;
    category: string | undefined;
    token?:any
    encryptedToken?:any
}

const List: React.FC<ListProps> = ({ onClose, phone, UserId,token }) => {
    const loginData =useSelector((item:any)=>(item.userData.userLoginData))
    const [details, setDetails] = useState(false);
    const [profileImage, setProfileImage] = useState<string>();
    const [category, setCategory] = useState<string | undefined>();
    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [companyName, setCompanyName] = useState<string>("");
    const [formErrors, setFormErrors] = useState<FormErrors>({});
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const dispatch = useDispatch();
    const Navigate = useNavigate();

    function handleChange(value: string) {
        if (value !== "Owner") {
            setDetails(true);
        } else {
            setDetails(false);
        }
        setCategory(value);
    }

    // Function to handle profile image upload with compression
    const handleProfileImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            // Resize the image
            Resizer.imageFileResizer(
                file,
                800, // maxWidth
                800, // maxHeight
                'JPEG', // compressFormat
                90, // quality
                0, // rotation
                (uri) => {
                    uploadFileToS3('vattara-image', uri as File).then((fileUrl) => {
                        setProfileImage(fileUrl);
                    }).catch((error) => {
                        console.error('Error uploading profile image:', error);
                    });
                },
                'blob' // outputType
            );
        }
    };

    const uploadFileToS3 = async (bucketName: string, file: Blob) => {
        const fileName = `profile_${Date.now()}`;
        const params = {
            Bucket: bucketName,
            Key: fileName,
            Body: file,
            ContentType: file.type,
        };
        try {
            const uploadResponse = await S3.upload(params).promise();
            return uploadResponse.Location;
        } catch (error) {
            throw new Error(`Error uploading ${fileName} to S3: ${error}`);
        }
    };

    function handleName(event: React.ChangeEvent<HTMLInputElement>) {
        setName(event.target.value);
    }

    function handleEmail(event: React.ChangeEvent<HTMLInputElement>) {
        setEmail(event.target.value);
    }

    function handleCompanyName(event: React.ChangeEvent<HTMLInputElement>) {
        setCompanyName(event.target.value);
    }

    async function handleSubmitForm() {
        let errors: FormErrors = {};

        if (!name) {
            errors.name = 'Please enter your name';
        }

        if (!email) {
            errors.email = 'Please enter your email';
        }
       

        // Check if the category requires a company name
        if (category !== "Owner" && !companyName) {
            errors.companyName = 'Please enter your company name';
        }

        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 2000);
            return;
        }

        setFormErrors({});

        const body: UserLoginData = {
            user_id: UserId,
            name: name,
            email: email,
            phone: phone,
            lang: "en",
            category: category,
            company_logo: profileImage || '',
            company_name: category !== "Owner" ? companyName : undefined,
            token: loginData.token,
            encryptedToken: loginData.encryptedToken
        };

        console.log(body);

        try {
            const response = await axiosInstance.put("/user/update/userDetail", body);
            console.log('Response:', response);
            dispatch(setUserLoginData(body));
            Navigate('/PostForm');
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }


    return (
        <div className="fixed flex justify-center items-center z-10 left-0 top-0 w-full h-full overflow-auto">
           <div className="bg-white   border border-gray-300 border-solid msm:mx-4 md:mx-0 md:overflow-y-scroll lg:overflow-hidden msm:w-[90vw] custom-xxl:w-[70vw] md:w-[100vw] lg:w-[70vw] xl:w-[60vw] w-[70vw] md:h-[50vh] lg:h-[70vh] xl:h-[700px] rounded-[2%] flex flex-col relative sm:flex sm:flex-col sm:gap-2 sm:align-middle">
                <div className="flex flex-row h-full p-0">
                    <div className='relative z-10'>
                    <div className="absolute md:-top-[12%] md:-left-20 sm:-top-[12%] sm:-left-[12%] msm:-top-[20%] msm:-left-[20%] md:w-56 md:h-56  rounded-full" style={{ backgroundColor: "#FCEAB6" }}></div>
                    </div>
                    <div className='z-50 md:w-[50%] msm:w-[100%]'>
                        <div className="absolute right-4 top-3 text-gray-500 text-2xl font-bold cursor-pointer z-20 md" onClick={onClose}>&times;</div>
                        <div className='mt-[17%] z-50 msm:ml-8 md:ml-[20%] sm:flex sm:flex-col justify-center items-center md:block'>
                            <p className='mb-3 font-bold'>Enter your contact information</p>
                            <div className='mb-3'>
                                <div className='flex gap-3 mb-1'>
                                    <p>Name</p>
                                    <input onChange={handleName} className='border-none outline-none' />
                                </div>
                                {showAlert && formErrors.name && (
                                    <div className='absolute ml-4 z-10 font-semibold text-red-500'>
                                        {formErrors.name}
                                    </div>
                                )}
                                <div className='border border-1 border-[#1F1F1F] w-64'></div>
                            </div>
                            <div className='mb-3'>
                                <div className='flex gap-3 mb-1'>
                                    <p>E-Mail</p>
                                    <input onChange={handleEmail} className='border-none lowercase outline-none' />
                                </div>
                                {showAlert && formErrors.email && (
                                    <div className='absolute ml-4 z-10 font-semibold text-red-500'>
                                        {formErrors.email}
                                    </div>
                                )}
                                <div className='border border-1 border-[#1F1F1F] w-64'></div>
                            </div>
                            <div>
                                <div className='flex gap-3 mb-1'>
                                    <p>Phone</p>
                                    <input value={phone} className='border-none outline-none' readOnly />
                                </div>
                                <div className='border border-1 border-[#1F1F1F] w-64'></div>
                            </div>
                        </div>
                        <div className={`${details ? "" : ""} sm:flex sm:flex-col justify-center items-center md:block`}>
                            <p className='flex items-end md:ml-44 sm:ml-4 msm:ml-2 mt-4'>I’m a {category}</p>
                            <div className='flex flex-wrap gap-3 ml-4 mt-3'> 
                                {
                                    data.map((item) => (
                                        <div key={item.label}>
                                            <p onClick={() => (handleChange(item.label))} className={`p-2 px-3 rounded-2xl ${category === item.label ? 'bg-yellow-400' : 'bg-[#FCEAB6]'}`}>{item.label}</p>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className={`${details ? "sm:flex sm:flex-col justify-center items-center md:block" : "hidden"} `}>
                            <p className='flex justify-start md:ml-20 msm:ml-2 items-center mt-10'>Company Details</p>
                            <div className='flex flex-col md:ml-20 msm:ml-2 mt-3'>
                                <input className='outline-none placeholder:font-light placeholder:pl-4 mb-1' onChange={handleCompanyName} placeholder='Company name ' />
                                {showAlert && formErrors.companyName && (
                                    <div className='absolute ml-4 z-10 font-semibold text-red-500'>
                                        {formErrors.companyName}
                                    </div>
                                )}
                                <div className='border border-1 flex justify-center items-center border-[#1F1F1F] w-64'></div>
                            </div>
                             <div className='flex items-center md:ml-20 msm:ml-2 mt-4 gap-4'>
                                <label htmlFor="profile-image-upload" className="cursor-pointer">
                                    <img src={profileImage ||profile
                                    } alt="Profile" className="rounded-full bg-slate-500 w-16 h-16 cursor-pointer" />
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleProfileImageUpload}
                                        className="hidden"
                                        id="profile-image-upload"
                                    />
                                </label>
                                <div className="flex flex-col ml-2">
                                <p
                                onClick={() => {
                                    const inputElement = document.getElementById('profile-image-upload') as HTMLInputElement | null;
                                    if (inputElement) {
                                        inputElement.click();
                                    }
                                }}>Company logo</p>
                                </div>
                            </div>
                        </div>
                        <div className='flex mt-8 justify-center md:items-start sm:items-center  flex-col'>
                            <div onClick={handleSubmitForm} className='flex justify-center md:ml-[20%] sm:ml-0 msm:ml-[20%] w-[40%] items-center py-2 px-20 rounded-3xl bg-[#FBB337]'>
                                <button className='  '>Submit</button>
                            </div>
                            <p className='font-light text-sm md:ml-[16%] lg:ml-[16%] sm:ml-0 msm:ml-[10%]'>This helps us personalize your experience.</p>
                        </div>
                    </div>
                    <div className=" lg:flex z-30 bottom-0 right-1  absolute bottom-  sm:hidden msm:hidden  md:flex justify-around items-center gap-4">
                        <div className="lg:h-[19vh] md:h-[16vh] w-auto ">
                            <img src={image1} alt='img' className='md:hidden lg:flex' />
                        </div>
                        <div className="lg:h-[52vh] md:h-[38vh] w-auto">
                            <img src={image2} alt='img' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default List;
