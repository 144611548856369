import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate } from "react-router-dom";
import { Radio } from "@mui/material";

const Thankyou: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="p-1">
      <ul className="flex flex-row mt-1">
        <li className="font-medium">Languages</li>
      </ul>

      <div className="flex flex-col">
        <div className="flex flex-row">
          <Radio />
          <p className="mt-2">Tamil</p>
        </div>
        <div className="flex flex-row">
          <Radio />
          <p className="mt-2">English</p>
        </div>
      </div>
    </div>
  );
};

export default Thankyou;
