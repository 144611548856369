import { CleaningServices } from '@mui/icons-material';
import { Avatar, Box, Button, Grid, IconButton, Modal, Typography, } from '@mui/material';
import React, { useEffect, useState } from 'react';
import MessageIcon from "./images/MessageIcon.png";
import phone from './images/phone.png';
import CancelIcon from '@mui/icons-material/Cancel';import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import overview7 from './images/overview7.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { getOverviewTemplate } from './data';
import { PropertDetailsData } from './data'
import { Furnishings } from './data'
import Report from "./images/report.svg"
import { dataAmenities, report } from "./data"
import { Link } from 'react-router-dom';
import profile from './images/profile.png'
import chat from './images/chat.svg'
import goldtick from './images/goldtick.png'
import goldticks from './images/goldticks.png'
import AD from "./images/AD.png"
import "bootstrap/dist/css/bootstrap.min.css";
import { Carousel } from "react-bootstrap";
import loaction from './images/location.png';
import heart from '../compounts/images/Heart.png'
import LikedHeart from '../compounts/images/LikedHeart.png'
import Default from "./images/default.png";
import { useSelector, useDispatch } from 'react-redux';
import { setUsers } from '../slices/filterSlice'
import { axiosChat, axiosInstance } from '../compounts/api/APIs';
import star from './images/star.svg'
import ReportPopUp from './ReportPopUp';
import { formatPrice } from "../compounts/ReuseableCompounts/PriceFormat";
import { formatSQPrice } from "../compounts/ReuseableCompounts/PriceSQFormat";
import { truncateString } from "./ReuseableCompounts/TruncateString";
import Carouse from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import noPer from "./images/noProperty.svg";
import { Warning as WarningIcon } from '@mui/icons-material'; // Adjust the icon import as necessary

import DefultScroll2 from '../compounts/images/defaultScroll.png'

import DefultScroll from '../compounts/images/defaultScroll.svg'
import { getResponsiveSettings } from "./ReuseableCompounts/CarouselResponsive";
import axios from 'axios';
import { useChat } from './context/ChatContext';
import { IoIosShareAlt } from "react-icons/io";
import { MdArrowBackIos, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";

import { FaGreaterThan, FaShareNodes } from "react-icons/fa6";
import ShareCom from './share/ShareCom';
import CustomDot from './CustomDot';

interface AdditionalDetails {
    bathroom: string;
    balcony: string;
    preferred_tenant_type: string;
    parking: string;
    carpet_area: string;
}

interface Image {
    filename: string;
    id: string;
    isCover: boolean;
    isFirst: boolean;
    isVisible: boolean;
    name: string;
    type: string;
    uri: string;
}

interface Property {
    id: number;
    post_id: number;
    property_type?: any;
    looking_to?: string;
    rent_or_lease?: string;
    property_available_for?: string;
    images?: Image[];
    view_post_count: number;
    bhk: string;
    city: string;
    building_name: string;
    landmark_street: string;
    build_up_area?: string;
    built_up_area?: string;
    plot_area?: string;
    PriceperSqft?: string;
    price?: number;
    cost?: number;
    monthly_rent?: number;
    enter_lease_amount?: number;
    lease_amount?: any
    liked?: any
    user_id?: any
    uri?: any
    kbl_property?: any

}



interface SimilarProperty {
    message: Property[];
    userDetails: {
        user_id: any;
        name: any;
        mobile_no: any
        category: any
        company_logo: any

        user_pic: any
    }
}












const Home: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const loginData = useSelector((state: any) => state.userData.userLoginData)
    const users = useSelector((state: any) => state.usersInfo.users);
    const [open, setOpen] = useState(false);
    const [Sopen, setSopen] = useState(false)
    const [open2, setOpen2] = useState(false);

    const queryParams = new URLSearchParams(location.search);
    const data = JSON.parse(queryParams.get('data') || '{}');
    const look = queryParams.get('look') || '';
    const Bview: any = queryParams.get('Bview') || ""
    const [showReport, setShowReport] = useState(false);
    const [likedState, setLikedState] = useState<any>(data.liked);
    const [chatvalue, setChatValue] = useState<any>("")
    const { setSelectedChat } = useChat();
    const dispatch = useDispatch()

    const [similarProperty, setsimilarProperty] = useState<SimilarProperty>({
        message: [],
        userDetails: {
            user_id: '',
            name: '',
            mobile_no: '',
            category: '',
            user_pic: "",
            company_logo: ""
        }
    });
    const [ad, setAd] = useState<any[]>([])
    const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);

    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    console.log(data, likedState);


    const handleClose2 = () => {
        setOpen2(false);
        setSelectedImage(null);
    };

    const handleNext = () => {
        setSelectedImageIndex((prevIndex) => (prevIndex + 1) % data.images.length);
    };

    const handlePrev = () => {
        setSelectedImageIndex((prevIndex) => (prevIndex - 1 + data.images.length) % data.images.length);
    };
    async function handleContact(
        property: any,
        looking: any,
        user: any,
        post: any,
        dis: any,
        buld: any,
        bhk: any,
        image: any,
    ) {
        console.log(property, looking);



        if (!loginData || loginData.phone === '' || loginData.phone === null) {
            navigate('/contact');
        } else {
            console.log('work');

            const chatBody: any = {
                property: property,
                user: user,
                post: post,
                looking_to: looking,
                district: dis,
                building_name: buld,

                Bhk: bhk,
                image: image,
            };

            setChatValue(chatBody)
            console.log("chat check", chatBody)
            setOpen(true);

            try {
                const Response = await axiosInstance.get(
                    `/api/ads/allAds/${'Property Contact View Ads'}`
                );
                setAd(Response.data.message);
                console.log(Response.data.message);
            } catch (error) {
                console.log('error', error);
            }
            console.log('Ad', ad);

            const body = {
                user_id: loginData.user_id,
                property_type: property,
                looking_to: looking,
                post_id: post,
                post_user_id: user,
            };
            console.log(body);

            try {
                const response = await axiosInstance.post(
                    '/filter/contactUserDetail',
                    body
                );
                setsimilarProperty(response.data);
                console.log(response.data);
                console.log('hello', similarProperty);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    }


    const handleClose = () => {
        console.log("jheki");

        setOpen(false);
    }


    const handleImageClick = (index: number) => {
        if (data.images && data.images[index]) {
            setSelectedImage(data.images[index].uri);
            setOpen2(true);
        }
    };
    


    const handleChange = async (id: number, property: string, looking_to?: string) => {
        const body = {
            post_id: id,
            selected_property_type: property,
            looking_to: looking_to || '',
            user_id: loginData.user_id
        };

        try {
            const response = await axiosInstance.post("/filter/get/postDetail", body);


            const queryString = new URLSearchParams({
                data: JSON.stringify(response.data),
                look: looking_to || ''
            }).toString();


            const newWindow = window.open(`/PropertyDetails?${queryString}`, '_blank');

            if (newWindow) {
                newWindow.focus();
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };




    const flatFurnishing: string | undefined = data?.flat_furnishing;

    // console.log(flatFurnishing);
    const FlatFurnishing = (key: string): boolean => {
        if (flatFurnishing) {
            const Array: string[] = flatFurnishing.split(', ').map(item => item.trim());
            return Array.includes(key);
        }
        return false;
    };


    const updatedFurnishings: { key: string; value: boolean; label: string; image: string }[] = Furnishings.map(item => ({
        ...item,
        value: FlatFurnishing(item.key)
    }));
    // console.log(updatedFurnishings)
    const trueValues: { key: string; value: boolean; label: string; image: string }[] = updatedFurnishings.filter(item => item.value === true);
    // console.log(trueValues);



    const Amenities: string = (data && data.amenities && data.amenities.amenities) || (data && data.society_amenities) || "";
    // console.log(Amenities);

    const AmenitiesKey = (key: string): boolean => {
        if (Amenities) {
            const Array: string[] = Amenities.split(',').map(item => item.trim());
            return Array.includes(key);
        }

        return false;
    };

    const updatedAmenities: { key: string; value: boolean; label: string; image: string }[] = dataAmenities.map(item => ({
        ...item,
        value: AmenitiesKey(item.key)
    }));
    // console.log(updatedAmenities);

    const trueAmenities: { key: string; value: boolean; label: string; image: string }[] = updatedAmenities.filter(item => item.value === true);
    // console.log("work", trueAmenities);

    const hasTrueFurnishings: boolean = trueValues.length > 0;
    const hasTrueAmenities: boolean = trueAmenities.length > 0;


    const overview = getOverviewTemplate(data).map(item => ({
        ...item,
        value: item.label === "Built up Area"
          ? `${data[item.key] || ""} sq.ft`
          : item.label === "Property Type" && (data[item.key] === "Plot / Land" )
            ? "Plot"
            : data[item.key] || "",
      }));


      
    

if (look === "Rent" && data.property_available_for === "Rent") {
        overview.push({
            label: "Security Deposit",
            key: "security_deposit",
            image: overview7,
            value: data.security_deposite || "",
        });
    }
    ;
    const PropertyDetails = PropertDetailsData.map(item => {
        if (item.label === 'Seats' && item.subKeys && Array.isArray(item.subKeys)) {
            const minSeat: number | undefined = data[item.subKeys[0]];
            const maxSeat: number | undefined = data[item.subKeys[1]];

            if (minSeat !== undefined && maxSeat !== undefined) {
                return {
                    ...item,
                    value: `${minSeat} - ${maxSeat}`
                };
            }
        }

        if (item.label === 'Dimension (in ft)' && item.subKeys && Array.isArray(item.subKeys)) {
            const length: number | undefined = data[item.subKeys[0]];
            const width: number | undefined = data[item.subKeys[1]];

            if (length !== undefined && length !== null && length.toString() !== "" &&
                width !== undefined && width !== null && width.toString() !== "") {
                return {
                    ...item,
                    value: `${length} - ${width}`
                };
            }
        }

        if (data.rent_or_lease === 'Rent' && item.key === 'security_deposit') {
            return {
                ...item,
                value: data.security_deposit || ''
            };
        }


        switch (item.key) {
            case 'lifts':
                return {
                    ...item,
                    value: data.lifts || ""
                };
            case 'parking':
                return {
                    ...item,
                    value: data.parking || (data.additional_details?.parking) || ""
                };
            case 'meeting_room':
                return {
                    ...item,
                    value: data.meeting_room || ""
                };
            case 'select_your_floor':
                return {
                    ...item,
                    value: data.select_your_floor || ""
                };
            case 'carpet_area':
                return {
                    ...item,
                    value: data.carpet_area || (data.additional_details?.carpet_area) || ""
                };
            case 'facing':
                return {
                    ...item,
                    value: data.facing || (data.additional_details?.facing) || ""
                };
            case 'washroom':
                return {
                    ...item,
                    value: data.washroom || ""
                };
            case 'property_condition':
                return {
                    ...item,
                    value: data.property_condition || ""
                };
            case 'bathroom':
                return {
                    ...item,
                    value: data.bathroom || (data.additional_details?.bathroom) || ""
                };
            case 'eligible_for_loan':
                return {
                    ...item,
                    value: data.additional_details?.eligible_for_loan || ""
                };
            case 'balcony':
                return {
                    ...item,
                    value: data.balcony || data.additional_details?.balcony || ""
                };
            case 'servant_room':
                return {
                    ...item,
                    value: data.additional_details?.servant_room || ""
                };
            case 'preferred_tenant_type':
                return {
                    ...item,
                    value: data.additional_details?.preferred_tenant_type || data.preferred_tenant_type || ""
                };
            case 'patta_available':
                return {
                    ...item,
                    value: data.patta_available || ""
                };
            case 'approval_status':
                return {
                    ...item,
                    value: data.approval_status || ""
                };
            case 'maintenance_charges':
                return {
                    ...item,
                    value: data.additional_details?.maintenance_charges || data.maintenance_charges || ""
                };
            case 'Suitable_for':
                return {
                    ...item,
                    value: data.additional_details?.Suitable_for || data.Suitable_for || ""
                };
            default:
                return {
                    ...item,
                    value: ""
                };
        }

    });

    function handleReport() {


        if (loginData.user_id === undefined) {
            navigate('/contact')
        } else {
            setShowReport(true)
        }

    }


    const handleChat = async (
        userid: any,


    ) => {
        const userId = userid;


        console.log('User ID:', userId, look, chatvalue);

        try {
            const response = await axiosChat.post('/api/chat', {
                userId,
                req_user_id: loginData.user_id,
            });
            console.log('chat click', response.data);
            if (response.status === 200) {
                console.log('Chat created successfully');
                ;
                console.log('gg', response);

                const data = response.data.users.filter(
                    (item: any) => item.user_id !== loginData.user_id
                );
                console.log(data);
                setSelectedChat({
                    chatId: response.data.id,
                    userId: data[0].user_id,
                    username: data[0].name,
                    userpic: data[0].user_pic,
                });

                navigate('/chatscreen', {
                    state: {
                        chatId: response.data.id,
                        userId,
                        postId: chatvalue.post,
                        username: response.data.users[0].name,
                        userpic: response.data.users[0].user_pic,
                        initialMessageText: `${chatvalue.building_name} ${chatvalue.property} ${chatvalue.district}`,
                        details: {
                            userId: chatvalue.user,
                            postId: chatvalue.post,
                            looking_to: chatvalue.looking,
                            district: chatvalue.dis,
                            building_name: chatvalue.buld,
                            property_type: chatvalue.property,
                            Bhk: chatvalue.bhk,
                            image: chatvalue.image,
                        },
                    },
                });
            } else {
                console.error('Failed to create chat. Status code:', response.status);
            }
        } catch (error) {
            console.error('Error creating chat:', error);
        }
    };


    // console.log(PropertyDetails);

    async function handleproperty(id: any) {


        if (loginData.user_id === undefined) {
            navigate('/contact')
        }
        else {

            if (loginData.user_id !== id) {
                try {
                    const response = await axiosInstance.get(`/filter/userPost/property/${data.user_id}/${loginData.user_id}`)
                    console.log(response.data.rows)
                    // Correct usage of navigate with state
                    navigate('/builder', {
                        state: {
                            rows: response.data.rows,
                            look: data.tbl_user_reg,
                        },
                    });

                }
                catch (error) {

                }
            } else {

            }
        }
    }



    async function handleReq( userId: any, postid: any, property: any,looking: any,dis: any, buld: any, bhk: any,image: any,){
        console.log('chat click',postid);
        const chatBody: any = {
          property: property,
          user: userId,
          post: postid,
          looking_to: looking,
          district: dis,
          building_name: buld,
      
          Bhk: bhk,
          image: "https://vattara-img.s3.amazonaws.com/image_1727274600329.jpg",
        };
      
        setChatValue(chatBody)
          if(loginData=== undefined){
            navigate('/contact')
          } else{
            console.log('chat click',chatvalue);
            try {
              const response = await axiosChat.post('/api/chat', {
                userId,
                req_user_id: loginData.user_id,
              });
              console.log('chat click', response.data);
              if (response.status === 200) {
                console.log('Chat created successfully');
                console.log('gg', response);
        
                const data = response.data.users.filter(
                  (item: any) => item.user_id !== loginData.user_id 
                );
                console.log(data);
      
                setSelectedChat({
                  chatId: response.data.id,
                  userId: data[0].user_id,
                  username: data[0].name,
                  userpic: data[0].user_pic,
                });
        
                navigate('/chatscreen', {
                  state: {
                    chatId: response.data.id,
                    userId:userId,
                    postId: chatvalue.post,
                    username: response.data.users[0].name,
                    userpic: response.data.users[0].user_pic,
                    initialMessageText: ` Request Image for this property ${buld !==null?buld:""} ${property}  ${dis}`,
                    details: {
                      userId: userId,
                      postId: postid,
                      looking_to: looking,
                      district:dis,
                      building_name: buld,
                      property_type: ` ${chatvalue.property}`,
                      Bhk: bhk,
                      image: "https://vattara-img.s3.amazonaws.com/image_1727274600329.jpg",
                    },
                  },
                });
              } else {
                console.error('Failed to create chat. Status code:', response.status);
              }
            } catch (error) {
              console.error('Error creating chat:', error);
            }
          }
        }


    const handleSaved = async (id: number, property: any, look: any,) => {


        if (loginData.user_id === undefined) {
            navigate('/contact')
        } else {
            const newLikedState = !likedState;
            setLikedState(!likedState);

            const body = {
                post_id: id,
                liked_user_id: loginData.user_id,
                property_type: property,
                rent_or_sell: look,
                liked: !likedState
            };

            try {
                const response = await axiosInstance.post('/like/postLike', body);
                const updatedProperties = users.map((item: any) => {
                    if (item.post_id === id) {
                        console.log(`Updating item with ID ${id}:`, { ...item, liked: newLikedState });
                        return { ...item, liked: newLikedState }; // Return a new object with the updated liked state
                    }
                    return item; // Return the unchanged item
                });
                dispatch(setUsers(updatedProperties))
                console.log("updatelike", updatedProperties, users, !likedState)
                console.log("response", response);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    };

    function handleSClose() {
        setSopen(false)
    }

    function valueLabelFormat(value: number) {
        if (value >= 1000000000) {
            return (value / 10000000).toFixed(2) + ' Cr';
        }
        if (value >= 10000000) {
            return (value / 10000000).toFixed(2) + ' Cr';
        }
        if (value >= 1000000) {
            return (value / 100000).toFixed(2) + ' Lac';
        }
        if (value >= 100000) {
            return (value / 100000).toFixed(2) + ' Lac';
        }
        if (value >= 10000) {
            return (value / 1000).toFixed(0) + 'k';
        }
        if (value >= 1000) {
            return (value / 1000).toFixed(1) + 'k';
        }
        return value.toString();
    }
    console.log("bview", Bview)

    const formatPrice = (price: string | number | null | undefined): string => {
        if (price == null) {
            return "N/A"; // or handle the missing value as needed
        }

        if (typeof price === 'number') {
            return valueLabelFormat(price);
        }

        // Handle string price
        const numericPrice = parseFloat(price.replace(/[^\d.-]/g, ''));
        return valueLabelFormat(numericPrice);
    };
    const price = data.price || data.cost || data.enter_lease_amount || data.lease_amount || (data.monthly_rent ? `${data.monthly_rent} / Month` : null);
    const formattedPrice = formatPrice(price);
    const priceSq = data.PriceperSqft ? formatSQPrice(data.PriceperSqft) : ""
    const hasImages = data.images.length > 0;
    const coverimg: any = data.images && data.images.length > 0 ? data.images?.find((img: any) => img.isCover) || "" : "";
    const responsiveSettings = getResponsiveSettings(1.5, 1, 1);

    const propertyTypeDisplay = data.property_type === "Plot / Land" ? "Plot" : data.property_type;

    const fullText = `${data?.bhk ? `${data.bhk} BHK` : ''} ${data?.property_type !== 'Plot / Land' ? data.property_type : 'Plot'} for ${data?.looking_to || data?.property_available_for} in`;
    const fullSublocality = `${data?.sublocality || ''}`;
    const fullCity = `${data?.building_name ? `${data.building_name}, ` : ''}${data?.city || ''}`;
    const fullLocality = `${data?.landmark_street ? `${data?.landmark_street}, ` : ''}${data?.district || ''}`;

    const fullDescription = `${fullText} ${fullSublocality}, ${fullCity}, ${fullLocality}`;
    console.log(fullDescription);
    const message = `Check out this property on Vattara. It's a ${fullText} at ${data?.price || data?.monthly_rent || data?.lease_amount}.`;

    // const url =`https://vattara.com/property/${data.post_id}/${data.property_type}/${data.property_available_for||data.rent_or_lease||data.looking_to }`

// const shareProperty = (data: any) => {
//     const postId = encodeURIComponent(data?.post_id);
//     const propertyType = encodeURIComponent(data?.property_type);
//     const lookingTo = encodeURIComponent(data?.looking_to);

//     const url = `https://vattara.com/property/${postId}/${propertyType}/${lookingTo}`;




// };

// const shareurl = shareProperty(data)

function handleShare() {
    setSopen(true)
}
const CustomRightArrow: React.FC<{ onClick: () => void }> = ({ onClick }) => {
    return (
      <Button 
        onClick={onClick}
        style={{
          position: 'absolute',
          top: '50%',
          right: '10px',
          borderRadius:"20px",
        //   transform: 'translateY(-50%)',
          minWidth: 'auto', // Remove default min-width
          background: '#F5F4F8', // Make background transparent
          padding: '0', // Remove padding
        }}
      >
       <MdOutlineKeyboardArrowRight        size={30} color="#000" />
      </Button>
    );
  };
  const CustomLefttArrow: React.FC<{ onClick: () => void }> = ({ onClick }) => {
    return (
      <Button 
        onClick={onClick}
        style={{
          position: 'absolute',
          top: '50%',
          left: '10px',
          borderRadius:"20px",
        //   transform: 'translateY(-50%)',
          minWidth: 'auto', // Remove default min-width
          background: '#F5F4F8', // Make background transparent
          padding: '0', // Remove padding
        }}
      >
       <MdOutlineKeyboardArrowLeft        size={30} color="#000" />
      </Button>
    );
  };
return (
    <div style={{backgroundColor:"#F5F4F8"}}>
         <Box          className="fixed w-full h-15 left-1 z-40 bg-[#fff] lg:hidden md:flex items-center justify-center top-0"
 >

 <IconButton
      onClick={() => navigate(-1)} // Inline navigation logic
      aria-label="Back"
      sx={{
        borderRadius: '50%', // Optional: for a circular button
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)', // Optional: change background on hover
        },
      }}
    >
      <MdArrowBackIos className='w-8 h-7' />
    </IconButton>
  </Box>
    <div className={` xl:mx-[20%] custom-lg:mx-[25%] lg:mx-[20%]  mb-2   ${showReport ? "hidden" : "block"}`}>

    <Box className='mb-2 ' sx={{ margin:{ xs: 1, sm: 1 }}} >
    <div className='msm:flex msm:flex-col gap-5  rounded-xl relative'>
                        <div className='relative p-1 bg-[#fff] rounded-2xl mt-[6%] ' key={data?.id}     style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }} // Add your desired box shadow here
                        >
                            <div className='  rounded-xl bg-white sm:h-[45vh]  msm:h-[45vh] md:h-[57vh] lg:h-[55vh] h-[55vh]'>
                            {hasImages ? (
    data.images.length > 1 ? (
        <Carouse
            additionalTransfrom={0}
            arrows
            autoPlaySpeed={0}
            centerMode={false}
            className=""
            draggable
            infinite={false}
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            showDots={true}
            customDot={<CustomDot active={false} onClick={function (): void { throw new Error('Function not implemented.'); }} />}
            sliderClass=""
            slidesToSlide={1}
            customRightArrow={<CustomRightArrow onClick={function (): void {
                throw new Error('Function not implemented.');
            } }  />}
            customLeftArrow={<CustomLefttArrow onClick={function (): void {
                throw new Error('Function not implemented.');
            } }  />}

            swipeable
            responsive={responsiveSettings}
        >
            {data.images.map((image: any, index: number) => (
                <div key={index} className='p-2'>
                    <img
                        className='h-[55vh] md:h-[54vh] msm:h-[42vh] sm:h-[43vh] lg:h-[52vh] w-full cursor-pointer'
                        style={{ borderRadius: "10px", marginTop: "5px" }}
                        src={image.uri || DefultScroll}
                        alt={image.alt || 'Default image'}
                        onClick={() => handleImageClick(index)} // Pass index here
                    />
   
<div className={`${data.kbl_property ? "flex" : "hidden"}  items-center  absolute bottom-2  p-1 left-2 `} style={{borderTopRightRadius:"15px",borderBottomLeftRadius:"10px" ,background: 'linear-gradient(90deg, #1063b5,#02a7e4, #0cb7d1)' }}>
                    <img className="w-6 h-6" src={star} />
                    <div className="text-white mr-1" style={{fontSize:"12px"}}>VATTARA VERIFIED</div>
                  </div>  
                </div>
            ))}
        </Carouse>
    ) : (
        <div className='' style={{width:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}>
            <img
                className='h-[55vh] md:h-[55vh] msm:h-[44vh] sm:h-[43vh] lg:h-[53vh]  msm:w-full sm:w-full lg:w-[40vw] custom-lg:w-[40vw]'
                style={{ borderRadius: "1px",marginTop: "5px" }}
                src={data.images[0].uri || DefultScroll2}
                alt={data.images[0].alt || 'Default image'}
            />
                             <div className={`${data.kbl_property ? "flex" : "hidden"}  items-center  absolute bottom-2  p-1 left-2 `} style={{borderTopRightRadius:"15px",borderBottomLeftRadius:"10px" ,background: 'linear-gradient(90deg, #1063b5,#02a7e4, #0cb7d1)' }}>
                    <img className="w-6 h-6" src={star} />
                    <div className="text-white mr-1" style={{fontSize:"12px"}}>VATTARA VERIFIED</div>
                  </div>  
        </div>
    )
) : (
    <div className=''>
        
        <img
            className='h-[55vh] sm:h-[43vh] md:h-[53vh]  msm:h-[43vh] lg:h-[55vh] w-full'
            style={{ width: "100%", borderRadius: "10px" ,marginTop: "5px"}}
            src={DefultScroll2}
            alt='Default image'
        />
        
    </div>
)}

                          
                       <Box sx={{display:"flex",flexDirection:"row",position:"absolute",top:30,left:20,gap:3}}>
                       <img
                                onClick={() => handleSaved(data.post_id, data.property_type, data.looking_to || data.rent_or_lease || data.property_available_for,)}
                                src={likedState ? LikedHeart : heart} className='cursor-pointer top-5  w-9 h-9 left-5 text-2xl bg-[#D9D9D9] rounded-3xl'

                            />
                            <IoIosShareAlt onClick={() => { handleShare() }} className=' cursor-pointer  w-9 h-9 p-1 text-2xl text-[#000] bg-[#D9D9D9] rounded-3xl' />
           
                        </Box>  
                        <div style={{padding:"10px",position:"absolute",marginBottom:"200px"}} onClick={()=>{handleReq(data.user_id,data.post_id, data.property_type,
                            data.looking_to ||
                            data.rent_or_lease ||
                            data.property_available_for,
                            data.district,
                            data.building_name,

                            data.bhk,
                            coverimg.uri)}} className={`${coverimg == "" ? "flex gap-1 items-center cursor-pointer  bg-[#F5F4F880] text-[#252B5C] border-[#252B5C] left-[50%] border-1 tansform -translate-x-[50%] rounded-full" : "hidden"} absolute bottom-4`}>
                     
                      <Box sx={{fontSize:"15px",color:"#252B5C"}}>Request Images</Box>
                      <FaGreaterThan style={{ width: '14px', height: '14px' }}  />

                    </div>  
                            <Modal open={open2} onClose={handleClose2}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
        bgcolor: 'rgba(0, 0, 0, 0.5)', // Light black background
                        padding: 2,
                        position: 'relative',
                    }}
                >
                    <CancelIcon 
                        onClick={handleClose2} 
                        sx={{ position: 'absolute', top: 16, right: 16, cursor: 'pointer',color:"#fff" }} 
                    />
                    <img 
                        src={data.images[selectedImageIndex]?.uri || DefultScroll} 
                        alt="Selected" 
                        style={{ maxWidth: '90%', maxHeight: '90%' }} 
                    />
                    
                    <Button onClick={handlePrev} disabled={data.images.length <= 1} sx={{ position: 'absolute', left: 16, top: '50%', transform: 'translateY(-50%)' }}>
                        <ArrowBackIcon />
                    </Button>
                    <Button onClick={handleNext} disabled={data.images.length <= 1} sx={{ position: 'absolute', right: 16, top: '50%', transform: 'translateY(-50%)' }}>
                        <ArrowForwardIcon />
                    </Button>
                </Box>
            </Modal>   </div>
                      
                        <Box>
    <Box className='items-center justify-between lg:gap- xl:ml-2 flex'>
       <Box sx={{display:"flex",flexDirection:"column"}}>
      <Box sx={{display:"flex",flexDirection:"row",width:"100%"}}> 
         <Box className='capitalize text-[#252B5C] ml-1 flex flex-col ' sx={{width:"60%"}}>
            <Box className='flex items-center'>
            <img className="w-5 h-5 mr-2" src={goldticks} />
            <Box className='xl:text-xl font-[#252B5C] font-bold' sx={{fontSize: { xs:"14px", xl: "18px",lg:"18px",md:"17px",sm:"16px" }}}>
                    {data?.bhk} {propertyTypeDisplay} for {data?.looking_to || data?.rent_or_lease || data?.property_available_for} in {data?.sublocality}
                </Box>
            </Box>
            <Box>
                <Box className='text-md pt-1' sx={{fontSize:{xs:"12px",sm:"15px",md:"17px",lg:"17px",},color:"#000"}}>
                    {data?.building_name ? `${data.building_name}, ` : ''}{data?.city}
                </Box>
            </Box>
            <Box>
                <Box className='text-md pt-1'sx={{fontSize:{xs:"12px",sm:"15px",md:"17px",lg:"17px",},color:"#000"}}>
                    {data?.landmark_street ? `${data.landmark_street}, ` : ''}{data?.district}
                </Box>
            </Box>
          
        </Box>
                 <Box className="border-l border-solid border-1 opacity-80 border-black h-26 xl:my-2 my-4"></Box>

        <Box className=' mr-1' sx={{width:"40%",paddingLeft:{xs:0,sm:"20px",md:"20px",lg:"20px"},display:"flex",flexDirection:{sm:"row",xs:"column",lg:"row"},justifyContent:"center",alignItems:"center"}}>
            <Box className='flex flex-col ' sx={{marginLeft:{xs: '10px', }, width: {
          xs: '100%',  // 100% for mobile
          sm: '50%',   // 50% for small screens and above
        }}} >
<Box sx={{ display: { xs: "none", sm: "flex", lg: "flex", md: "flex" } }}>
  {data?.property_type === "Plot / Land"
    ? "Plot Area"
    : data?.build_up_area || data?.built_up_area
      ? "Built Up Area"
      : ""}
</Box>   <Box className='font-medium xl:ml- flex text-[#404040] text-md' sx={{width:"100%",fontSize:{xs:"14px",sm:"15px",md:"17px",lg:"17px",},color:"#000"}}>
        {data?.build_up_area || data?.plot_area || data?.built_up_area} 
        {data?.build_up_area || data?.plot_area || data?.built_up_area ? ' sq.ft.' : ''}
    </Box>

            </Box>
            <Box className={`${data?.looking_to || data?.rent_or_lease || data?.property_available_for === "Sale" ? "block" : "hidden"}`} sx={{marginLeft:{xs: '10px', },width: {   xs: '100%', sm: '50%',  }}}>
                <Box className='font-medium text-[#404040]' sx={{display:{xs:"none",sm:"flex",lg:"flex"}}}>Price per sq.ft</Box>
               <Box sx={{display:"flex",flexDirection:"row"}}> <Box className='xl:ml- font-medium text-[#404040]' sx={{fontSize:{xs:"14px",sm:"15px",md:"17px",lg:"17px",}}}>{`₹.${priceSq}`}</Box>
                <Box className='font-medium text-[#404040]' sx={{display:{xs:"flex",sm:"none",lg:"none"},fontSize:{xs:"12px",sm:"15px",md:"17px",lg:"17px",},}}>/sq.ft</Box>
                </Box>
            </Box>
        </Box></Box> 
               <Box className='flex flex-row items-center'  sx={{width:"100%"}}>
                <Box  sx={{width:"60%",marginLeft:"5px"}}>
                    <Box className='xl:text-xl msm:text-lg text-black font-semibold'>₹ {formattedPrice}</Box>
                </Box>
                <Box className='flex justify-between items-center xl:ml-4 msm:ml-1 mb-1'  sx={{width:"40%",paddingLeft:"20px"}} >
                    <Box className='flex flex-col gap-3 mr-3'>
                        <Button onClick={() =>
                                            handleContact(
                                                data.property_type,
                                                data.looking_to ||
                                                data.rent_or_lease ||
                                                data.property_available_for,
                                                data.user_id,
                                                data.post_id,
                                                data.district,
                                                data.building_name,

                                                data.bhk,
                                                coverimg.uri
                                            )} className={`h-10 flex gap-1 font-k2d `} variant="contained" color="primary" sx={{borderRadius:"10px"}}>
                            <Box>Contact</Box>
                            <img src={phone} alt='Phone Icon' className='h-5 w-5' />
                        </Button>
                    </Box>
                </Box>
            </Box>
</Box>
    </Box>
</Box>
                    </div>
                </div>
            </Box>
            <Box sx={{ backgroundColor: '#fff', borderRadius: '16px',boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', marginBottom: 4,padding:{xs:0,sm:2} ,margin:{ xs: 1, sm: 1 }}}>
  <Box sx={{ p: { xs: 0, lg: 0 } }}>
    <Box  sx={{ fontWeight: 'bold', ml: { xs: 1, sm: 0 }, paddingTop: { xs:1, xl: 2,sm:2 ,md:2,lg:2} ,        fontFamily: 'K2D, sans-serif',                         fontSize:  { xs:"16px", xl: "20px",lg:"20px",md:"18px",sm:"16px" },
 
}}>
      Overview
    </Box>
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: { xs: '1fr 1fr', sm: '1fr 1fr', md: '1fr 1fr' },
        gap: { xs: 1, sm: 3, xl: 4,md:4 },
        mx: 1,
    paddingBottom:"30px",
        mt:{ xs: 1, sm: 3, xl: 3 },
        mr: { xs: 0, xl: 5 },
      }}
    >
      {overview.map((item, index) =>
        item.value ? (
          <Box
            key={index}
            sx={{
              display: 'flex',
              gap: { xs: 2, xl: 3,lg:3,md:3,sm:2 },
              height: { xs:"65px", xl: "80px",lg:"80px",md:"80px",sm:"80px" },
              p: 1,
              backgroundColor: '#f5f4f8',
              borderRadius: '15px',
              alignItems: 'center',
            }}
          >
            <Box sx={{ ml: { xs: 0, lg: 3 ,sm:2}, display: 'flex', justifyContent: 'center' }}>
              <img src={item.image} alt={item.label}     className="w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 lg:w-10 lg:h-10"
              />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Typography sx={{ fontFamily: 'K2D, sans-serif',fontWeight:"bold",fontSize: { xs:"13px", xl: "17px",lg:"17px",md:"15px",sm:"15px" }}}>
                {item.value}
              </Typography>
              <Typography sx={{ fontFamily: 'K2D, sans-serif',fontSize: { xs:"12px", xl: "14px",lg:"14px",md:"13px",sm:"13px" }}}>
  {item.label}
</Typography>

            </Box>
          </Box>
        ) : null
      )}
    </Box>
  </Box>
</Box>

<Box
      sx={{
        borderRadius: '16px',
        marginBottom: '16px',
        backgroundColor: '#fff',
        fontFamily: 'K2D, sans-serif', 
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',padding:{xs:0,sm:2} ,margin:{ xs: 1, sm: 1 }
      }}
    >
      <Box sx={{ marginLeft: { xs: '16px', sm: '8px' }, paddingBottom: '16px' }}>
      <Box  sx={{ fontWeight: 'bold', paddingTop: { xs:1, xl: 2,sm:2 ,md:2,lg:2} ,  paddingBottom:"10px",      fontFamily: 'K2D, sans-serif',                         fontSize:  { xs:"16px", xl: "20px",lg:"20px",md:"18px",sm:"16px" },
 
    }}>
          Property Details
        </Box>

        <Box sx={{ marginLeft: '2px' }}>
          {PropertyDetails.map((item, index) => (
            item.value && (
              <Box key={index}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '8px',
                    marginRight: '40px',
                  }}
                >
                  <Box sx={{ marginLeft: '8px', width: '40%' }}>
                    <Typography
                      sx={{
                        fontWeight: 'medium',
                        fontSize:  { xs:"15px", xl: "18px",lg:"18px",md:"18px",sm:"16px" },
                        color: '#3C3C3C',
                        fontFamily: 'K2D, sans-serif', 

                      }}
                    >
                      {item.label}
                    </Typography>
                  </Box>
                  <Box sx={{ width: '40%' }}>
                    <Typography
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        textTransform: 'capitalize',
                        fontWeight: 'bold',
                        fontSize:  { xs:"15px", xl: "18px",lg:"18px",md:"18px",sm:"16px" },
                        textAlign: 'left',        fontFamily: 'K2D, sans-serif', 

                      }}
                    >
                      {item.value}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    borderTop: '1px solid #DBDBDB',
                    marginTop: '8px',
                    marginRight: '5%',
                    textAlign: 'right',
                  }}
                />
              </Box>
            )
          ))}
        </Box>
      </Box>
    </Box>
    <Box
      sx={{
        borderRadius: '16px',
        marginBottom: '16px',
        backgroundColor: '#fff',
        fontFamily: 'K2D, sans-serif', 
        height:{xs:"250px",sm:"400px",md:"350px",lg:"400px"},
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',padding:{xs:0,sm:2} ,margin:{ xs: 1, sm: 1 }
      }}
    >    
     <Box  sx={{ fontWeight: 'bold', ml: { xs: 1, sm: 0 },paddingBottom:'10px', paddingTop: { xs:1, xl: 2,sm:2 ,md:2,lg:2} ,        fontFamily: 'K2D, sans-serif',                         fontSize:  { xs:"16px", xl: "20px",lg:"20px",md:"18px",sm:"16px" },
 
}}>
Where you'll be    </Box>
     <iframe name="gMap" style={{width:"100%",height:"80%"}} src={`https://www.google.com/maps/embed/v1/place?q=${data.latitude},${ data.longitude}&key=AIzaSyDz2gppwqEkyckilX-FDJkvgEtcoS2OEvE`}></iframe>

</Box> 
<Box
      sx={{
        borderRadius: '16px',
        marginBottom: '16px',
        backgroundColor: '#fff',
        fontFamily: 'K2D, sans-serif', 
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',padding:{xs:0,sm:2} ,margin:{ xs: 1, sm: 1 }
      }}
    >
                    {hasTrueFurnishings || hasTrueAmenities ? (
                        <div className=''>
                            <p className='text-xl p-2 font-bold'>Facilities</p>
                            {hasTrueFurnishings && (
                                 <Box sx={{marginLeft:"10px"}}>
                                 <Box component="p" sx={{ marginBottom: '10px', marginLeft: '5px' }}>
                                   Furnishings
                                 </Box>
                                 <Box
                                   sx={{
                                     display: { xs:"none", xl: "flex",lg:"flex",md:"flex",sm:"flex" },

                                     overflowX: 'auto', // Enable horizontal scrolling
                                     padding: '16px 0',
                                     gap: 2, // Space between items
                                   }}
                                 >
                                   <Grid container spacing={2} sx={{ flexShrink: 0 }}>
                                     {trueValues.map((item, index) => (
                                       <Grid item key={index} xs={6} sm={4} md={2}>
                                         <Box
                                           sx={{
                                             backgroundColor: '#F2F2F7',
                                             display: 'flex',
                                             flexDirection: 'column',
                                             alignItems: 'center',
                                             justifyContent: 'center',
                                             borderRadius: '10px',
                                             width: '100%', // Full width for grid items
                                             height: '96px', // Equivalent to h-24
                                             padding: '16px',
                                           }}
                                         >
                                           <img
                                             src={item.image}
                                             alt={item.label}
                                             style={{ maxWidth: '100%', maxHeight: '100%' }}
                                           />
                                           <Box
                                             sx={{
                                               fontSize: '12px',
                                               textAlign: 'center',
                                             }}
                                           >
                                             {item.label}
                                           </Box>
                                         </Box>
                                       </Grid>
                                     ))}
                                   </Grid>
                                 </Box>
                                 <Box
    sx={{
      display: { xs: 'flex', xl: 'none', lg: 'none', md: 'none', sm: 'none' }, // Show in all sizes
      overflowX: 'auto', // Enable horizontal scrolling
    //   padding: '10px 0',
      gap: 1, // Space between items
    }}
  >
    {trueValues.map((item, index) => (
      <Box
        key={index}
        sx={{
            backgroundColor: '#F2F2F7',

          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '10px',
          width: '100%', // Full width for grid items
          height: '96px', // Equivalent to h-24
          padding: '10px',
        //   marginRight: '8px', // Optional spacing between items
        }}
   
      >
        <img
          src={item.image}
          alt={item.label}
          style={{ maxWidth: '100%', maxHeight: '100%' }}

        />
        <Box
          sx={{
            fontSize: '10px',
            textAlign: 'center',
            width:"70px"
          }}
        >
          {item.label}
        </Box>
      </Box>
    ))}
  </Box>
                               </Box>
 


                            )}
                            {hasTrueAmenities && (
                                 <Box sx={{marginLeft:"10px"}}>
                                 <Box component="p" sx={{ marginBottom: '10px', marginLeft: '5px' }}>
                                 Amenities                                 </Box>
                                 <Box
                                   sx={{
                                     display: { xs:"none", xl: "flex",lg:"flex",md:"flex",sm:"flex" },

                                     overflowX: 'auto', // Enable horizontal scrolling
                                     padding: '16px 0',
                                     gap: 2, // Space between items
                                   }}
                                 >
                                   <Grid container spacing={2} sx={{ flexShrink: 0 }}>
                                   {trueAmenities.map((item, index) => (
                                       <Grid item key={index} xs={6} sm={4} md={2}>
                                         <Box
                                           sx={{
                                             backgroundColor: '#F2F2F7',
                                             display: 'flex',
                                             flexDirection: 'column',
                                             alignItems: 'center',
                                             justifyContent: 'center',
                                             borderRadius: '10px',
                                             width: '100%', // Full width for grid items
                                             height: '96px', // Equivalent to h-24
                                             padding: '16px',
                                           }}
                                         >
                                           <img
                                             src={item.image}
                                             alt={item.label}
                                             style={{ maxWidth: '100%', maxHeight: '100%' }}
                                           />
                                           <Box
                                             sx={{
                                               fontSize: '12px',
                                               textAlign: 'center',
                                             }}
                                           >
                                             {item.label}
                                           </Box>
                                         </Box>
                                       </Grid>
                                     ))}
                                   </Grid>
                                 </Box>
                                 <Box
    sx={{
      display: { xs: 'flex', xl: 'none', lg: 'none', md: 'none', sm: 'none' }, // Show in all sizes
      overflowX: 'auto', // Enable horizontal scrolling
    //   padding: '10px 0',
      gap: 1, // Space between items
    }}
  >
                                        {trueAmenities.map((item, index) => (
      <Box
        key={index}
        sx={{
            backgroundColor: '#F2F2F7',

          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '10px',
          width: '100%', // Full width for grid items
          height: '96px', // Equivalent to h-24
          padding: '10px',
        //   marginRight: '8px', // Optional spacing between items
        }}
   
      >
        <img
          src={item.image}
          alt={item.label}
          style={{ maxWidth: '100%', maxHeight: '100%' }}

        />
        <Box
          sx={{
            fontSize: '10px',
            textAlign: 'center',
            width:"70px"
          }}
        >
          {item.label}
        </Box>
      </Box>
    ))}
  </Box>
                            </Box>
                            )}
                        </div>
                    ) : null}

                    <div>
                        <p className='text-xl p-4 font-bold'>About Property</p>
                        <div className='xl:mx-10 msm:mx-4'>
                            <Box sx={{fontSize:{xs:"14px",sm:"15px",lg:"18px",},textAlign:"left"}}>{data?.additional_details?.property_description || data?.property_description && data?.property_description}</Box>
                        </div>
                    </div>
                    <div >
                        <p className='text-xl p-4 font-bold '>Contact</p>
                        <div className='flex justify-between items-center'>
                        <Box className='flex ml-10 gap-5'>
    <Box>
        {data?.tbl_user_reg.company_logo ? (
            <Avatar alt={data?.tbl_user_reg.name} src={data.tbl_user_reg.company_logo} />
        ) : (
            <Box
                className='bg-yellow-300 rounded-full w-16 h-16 flex items-center justify-center'
                style={{ fontSize: '2rem', fontWeight: 'bold' }} // Adjust size and weight as needed
            >
                {data.tbl_user_reg.name.charAt(0)}
            </Box>
        )}
    </Box>
  
    <Box>
        <Box>{data?.tbl_user_reg.name}</Box>
        <Box className='flex'>
            {/* <Box>+91 91*******01</Box> */}
            <Box>{data?.tbl_user_reg.category}</Box>
        </Box>
    </Box>
</Box>
<p onClick={() => handleproperty(data.user_id)} className={` ${Bview == "Bview" || data.kbl_property === true || data.user_id === loginData.user_id ? "hidden" : "flex"} text-blue-600 mr-10 cursor-pointer`}>View</p>
</div>
                    </div>

                    <div className='flex xl:mt-14 msm:mt-4 flex-col gap-4 justify-center items-center'>
                        <div className='bg-[#2E368F] px-24 py-4 rounded-3xl'  onClick={() =>
                        handleContact(
                            data.property_type,
                            data.looking_to ||
                            data.rent_or_lease ||
                            data.property_available_for,
                            data.user_id,
                            data.post_id,
                            data.district,
                            data.building_name,

                            data.bhk,
                            coverimg.uri
                        )}><p className='  text-[#ffffff] rounded-3xl cursor-pointer'>Contact</p></div>
                        <p className='msm:flex msm:justify-center msm:items-center mx-10'>By submitting I accept Real Estate Terms & Conditions</p>
                        <img className=' cursor-pointer  ' onClick={handleReport} src={Report} alt="Report Icon" />
                    </div>
                </Box>
            
            <Modal
    open={open}
    onClose={handleClose}
    sx={{height:"99%"}}
    aria-labelledby="contact-modal-title"
    aria-describedby="contact-modal-description"
>
    <Box sx={{width:"100%",justifyContent:"center",alignItems:"center",display:"flex"}}>
    <Box
    sx={{
        position: 'absolute',
       
        width: { xs: '90%', sm: '90%', md: "70%", lg: "40%" ,xl:"40%"},
        // height:"100%",
        // zIndex: 20,
        borderRadius: '16px', // equivalent to rounded-2xl
        backgroundColor: 'white',
        outline: 'none',
        paddingBottom:"30px",
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid #000', // Add your desired border here
        boxShadow: 24,
    }}
>            <Box   >
                <Box className='flex flex-col ' sx={{marginTop:{ xs: '10px', sm: '13px', md: "13px", lg: "20px" ,xl:"20px"}}}>
                    <Box sx={{display:"flex",flexDirection:"row"}}>
                    <Box sx={{width:"20%",justifyContent:"center",alignItems:"center",display:"flex"}}>
        {data?.tbl_user_reg.company_logo ? (
            <Avatar alt={data?.tbl_user_reg.name} src={data.tbl_user_reg.company_logo} sx={{width:70,height:70}}/>
        ) : (
            <Box
                className='bg-yellow-300 rounded-full w-16 h-16 flex items-center justify-center'
                style={{ fontSize: '2rem', fontWeight: 'bold' }} // Adjust size and weight as needed
            >
                {data.tbl_user_reg.name.charAt(0)}
            </Box>
        )}
    </Box>
    <Box sx={{width:"75%",}}>
    <Box className='text-lg font-semibold'   sx={{ 
            width: "90%", 
            overflow: 'hidden', 
            textOverflow: 'ellipsis' ,
            WebkitLineClamp: 2 // Limit to 2 lines

        }}>{similarProperty.userDetails?.name}</Box>
                            <Box>{similarProperty.userDetails?.category}</Box>
                            <Box className={`text-lg font-semibold ${similarProperty.userDetails?.category !== "Owner" ? "flex" : "hidden"}`}>
                                {`+91 ${similarProperty.userDetails?.mobile_no?.slice(3)}`}
                            </Box>
                        </Box>
                    </Box>
                    <Box className='flex' sx={{marginTop:"10px"}}>
    {similarProperty.userDetails?.user_id !== loginData.user_id ? (
        <>
            <Box sx={{ width: "100%", justifyContent: "space-evenly", alignItems: "center", display: "flex" }}>
                {/* Show Chat Box if the user is an Owner */}
                {similarProperty.userDetails?.category === "Owner" ? (
                    <Box 
                        onClick={handleChat} 
                        sx={{ 
                            backgroundColor: '#2E368F', 
                            py: 2, 
                            px: 5,
                            gap: 2, 
                            borderRadius: '9999px', 
                            display: 'flex', 
                            justifyContent: 'center', 
                            alignItems: 'center',
                            width: { xs: '50%', sm: '50%', md: "40%", lg: "40%" }
                        }}
                    >
                        <img src={chat} alt="Chat" />
                        <Box sx={{ color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            Chat
                        </Box>
                    </Box>
                ) : (
                    // Show both Phone and Chat buttons if not an Owner
                    <>
                        <Box sx={{ backgroundColor: '#2E368F', py: 2, px: 5, gap: 2, borderRadius: '9999px', display: 'flex', justifyContent: 'center', alignItems: 'center',                                width: { xs: '50%', sm: '50%', md: "40%", lg: "40%" }
 }}>
                            <img src={phone} alt="Phone" />
                            <Box sx={{ color: 'white' }}>Call</Box>
                        </Box>
                        <Box 
                            onClick={handleChat} 
                            sx={{ 
                                backgroundColor: '#2E368F', 
                                py: 2, 
                                px: 5,
                                gap: 2, 
                                borderRadius: '9999px', 
                                display: 'flex', 
                                justifyContent: 'center', 
                                alignItems: 'center',
                                width: { xs: '50%', sm: '50%', md: "40%", lg: "40%" }
                            }}
                        >
                            <img src={chat} alt="Chat" />
                            <Box sx={{ color: 'white' }}>Chat</Box>
                        </Box>
                    </>
                )}
            </Box>
        </>
    ) : (
        <Box 
        sx={{ 
            width: '100%', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center' 
        }}
    >
        <Box 
            sx={{ 
                width: '60%', 
                display: 'flex', 
                flexDirection: 'row', 
                justifyContent: 'center', 
                gap: 2, // Equivalent to gap: 10 (you can adjust it based on your needs)
                alignItems: 'center', 
                border: '0.6px solid red', 
                borderRadius: 2 // Rounded corners, adjust as necessary
            }}
        >
            <WarningIcon sx={{ color: 'red', fontSize: 20 }} />
            <Typography variant="body1" sx={{ color: 'red', fontWeight: 'bold' }}>
                It's your Post
            </Typography>
        </Box>
    </Box>    )}
</Box>


                  
                </Box>

                <Box className="border-solid border-1 border-t mt-2 mx-3 border-[#DBDBDB] mr- msm:text-right"></Box>

                <Carousel className="mt-2 px-3 w-[100%]" indicators={false} interval={1000}>
                    {ad.map((item) => (
                        <Carousel.Item key={item.id} interval={2000}>
                            {item ? (
                                <a href={item.link} target="_blank" rel="noopener noreferrer">
                                    <img
  className="w-full h-[17vh] sm:h-[12vh] md:h-[25vh] lg:h-[28vh] object-cover rounded-2xl"
  src={item.image}
                                        alt={`Project ${item.id}`}
                                    />
                                </a>
                            ) : (
                                <Box className="bg-gray-200 w-full h-full flex items-center justify-center">
                                    <Box>No Image Available</Box>
                                </Box>
                            )}
                        </Carousel.Item>
                    ))}
                </Carousel>

                <Box>
                    {similarProperty.message?.length > 0 && (
                        <Box className='text-xl font-semibold my-2 ml-5'>Similar Properties</Box>
                    )}
                    <Box className='flex gap-2 mx-1 overflow-x-scroll'>
                        {similarProperty.message?.map((item: Property, index: number) => {
                            const price = item.price || item.cost || item.enter_lease_amount || item.lease_amount || item.monthly_rent;
                            const formattedPrice = formatPrice(price);
                            const coverim: any = item.images && item.images.length > 0 ? item.images.find(img => img.isCover) || "" : "";
                            const locality = truncateString(item.city, 20);
                            const sqtv: any = item.PriceperSqft ? item.PriceperSqft : "";
                            const priceSQTV = formatSQPrice(sqtv);
                            return (
                                <Box key={index} className=''>
<Box className="relative bg-[#f5f4f8] border-2 rounded-2xl p-1 w-[30vw] sm:w-[28vw] md:w-[13vw] max-w-[400px]">
<Box className='relative'>
                                            <Box className='absolute right-0 mr-1 top-3 bg-[#2E368F] text-white py-1 px-3 rounded-2xl' sx={{fontSize:"8px"}}>{item.looking_to || item.rent_or_lease || item.property_available_for}</Box>
                                            <img onClick={() => { handleChange(item.post_id, item.property_type, item.looking_to || item.rent_or_lease || item.property_available_for) }}
 className='w-full h-[100px] sm:h-[180px] md:h-[180px] lg:h-[150px] xl:h-[140px] flex justify-center bg-black items-center object-cover rounded-[15px]'
 src={typeof coverim === 'object' && coverim?.uri ? coverim.uri : DefultScroll }/>
                                             <div className={`${item.kbl_property ? "flex" : "hidden"}  items-center bg-[#6135DD] absolute bottom-0  p-1 left-0`} style={{borderTopRightRadius:"15px",borderBottomLeftRadius:"15px"}}>
                    <img className="w-3 h-3" src={star} />
                    <div className="text-white mr-1" style={{fontSize:"8px"}}>VATTARA VERIFIED</div>
                  </div>
                                        </Box>
                                        <Box className='ml-1'>
                                            <Box className='flex items-center'>
                                                <Box className='text-base flex font-bold' sx={{fontSize:"13px"}}>{item.bhk} {item.property_type}</Box>
                                                <img src={goldtick} />
                                            </Box>
                                            <Box className='flex gap-2 items-center'>
                                                <img className="h-3" src={loaction} />
                                                <Box className='text-sm capitalize' sx={{fontSize:"13px",    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",}}>{locality}</Box>
                                            </Box>
                                            <div className='flex items-center w-full'>
                                                <Box sx={{ fontSize: {xs:"10px",lg:"13px",md:"13px",sm:"13px"}, fontWeight: 800 ,width:{xs:"90px",md:"80px",lg:"80px"}}}>₹ {formattedPrice}</Box>
                                                <div className='border-[#404040] h-6 border-l'></div>
                                                <div className='ml-1'>
                                                <Box sx={{ fontSize: {xs:"8px",lg:"11px",sm:"11px",md:"11px"} }}  className='flex items-center text-sm font-sm text-[#404040]' >
                                                        <div className='truncate'>{item.built_up_area || item.plot_area}</div>
                                                        {item.built_up_area || item.plot_area ? <div className='text-sm'>sq.ft</div> : ""}
                                                    </Box>
                                                    <Box sx={{ fontSize: {xs:"8px",lg:"11px",sm:"11px",md:"11px"} }} className='flex items-center text-sm font-sm text-[#404040]'>
                                                        {item.PriceperSqft ? <div>₹ {priceSQTV}</div> : ""}
                                                        {item.PriceperSqft ? <div>/sq.ft</div> : ""}
                                                    </Box>
                                                </div>
                                            </div>     <Box className='flex items-center gap-2'>
                                          
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>
                </Box>
            </Box>
        </Box>
    </Box>
</Modal>

            <Modal
                open={Sopen}
                onClose={handleSClose}
                aria-labelledby="contact-modal-title"
                aria-describedby="contact-modal-description"
            >
                <Box >
                    <div className=' absolute top-10 right-[20%] flex justify-center items-center '>
                        <ShareCom title={message} post_id={data.post_id} property_type={data.property_type} looking_to={data?.looking_to || data?.rent_or_lease || data?.property_available_for} />
                    </div>

                </Box>

            </Modal>

        </div>
        <div className={showReport ? "grid mx-[20%] items-center justify-center" : "hidden"}>
            <ReportPopUp showReport={showReport} setShowReport={setShowReport} property={data.property_type} user={loginData.user_id}
                post={data.post_id} look={data?.looking_to || data?.rent_or_lease || data?.property_available_for} />
        </div>
    </div>
);
}

export default Home;
