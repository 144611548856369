import CurrentOpenings from "../Career/CurrentOpenings"
import Hero from "../Career/Hero"
import WhoWeAre from "../Career/WhoWeAre"

const Career = () => {
  return (
    <div>
      <Hero />
      <CurrentOpenings />
      <WhoWeAre />
    </div>
  )
}

export default Career