import { CleaningServices } from '@mui/icons-material';
import { Avatar, Box, Button, Modal, } from '@mui/material';
import React, { useEffect, useState } from 'react';
import MessageIcon from "./images/MessageIcon.png";
import phone from '../images/phone.png';
import overview7 from '../images/overview7.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { getOverviewTemplate } from '../data';
import { PropertDetailsData } from '../data'
import { Furnishings } from '../data'
import Report from "../images/report.svg"
import { dataAmenities, report } from "../data"
import { Link } from 'react-router-dom';
import profile from '../images/profile.png'
import chat from '../images/chat.svg'
import goldtick from '../images/goldtick.png'
import AD from "./images/AD.png"
import "bootstrap/dist/css/bootstrap.min.css";
import { Carousel } from "react-bootstrap";
import loaction from '../images/location.png';
import heart from '../images/Heart.png'
import LikedHeart from '../images/LikedHeart.png'
import Default from "../images/default.png";
import { useSelector } from 'react-redux';
import { axiosChat, axiosInstance } from '../api/APIs';
import star from '../images/star.svg'
import ReportPopUp from '../ReportPopUp';
import { formatPrice } from "../ReuseableCompounts/PriceFormat";
import { formatSQPrice } from "../ReuseableCompounts/PriceSQFormat";
import { truncateString } from "../ReuseableCompounts/TruncateString";
import Carouse from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css'; 
import noPer from "./images/noProperty.svg";
import DefultScroll from '../images/defaultScroll.svg'
import { getResponsiveSettings } from "../ReuseableCompounts/CarouselResponsive";
import axios from 'axios';
import { useChat } from '../context/ChatContext';
import { useParams } from 'react-router-dom';
;

interface AdditionalDetails {
    bathroom: string;
    balcony: string;
    preferred_tenant_type: string;
    parking: string;
    carpet_area: string;
}

interface Image {
    filename: string;
    id: string;
    isCover: boolean;
    isFirst: boolean;
    isVisible: boolean;
    name: string;
    type: string;
    uri: string;
}

interface Property {
    id: number;
    post_id: number;
    property_type?: any;
    looking_to?: string;
    rent_or_lease?: string;
    property_available_for?: string;
    images?: Image[];
    view_post_count: number;
    bhk: string;
    city: string;
    building_name: string;
    landmark_street: string;
    build_up_area?: string;
    built_up_area?: string;
    plot_area?: string;
    PriceperSqft?: string;
    price?: number;
    cost?: number;
    monthly_rent?: number;
    enter_lease_amount?: number;
    lease_amount?: any
    liked?: any
    user_id?: any
    uri?: any
    kbl_property?: any

}



interface SimilarProperty {
    message: Property[];
    userDetails: {
        user_id: any;
        name: any;
        mobile_no: any
        category: any
        user_pic: any
    }
}












const SharepropDetails: React.FC = () => {
    const [data, setData] = useState<any >({});
    const { id, propertyType, lookingTo } = useParams();
    const navigate = useNavigate();
    const loginData = useSelector((state: any) => state.userData.userLoginData)
    const [open, setOpen] = useState(false);
    const look = lookingTo
    // const look = queryParams.get('look') || '';
    const [showReport, setShowReport] = useState(false);
    const [likedState, setLikedState] = useState<any>(data.liked);
    const [chatvalue, setChatValue] = useState<any>("")
    const { setSelectedChat } = useChat();
    const [similarProperty, setsimilarProperty] = useState<SimilarProperty>({
        message: [],
        userDetails: {
            user_id: '',
            name: '',
            mobile_no: '',
            category: '',
            user_pic: ""


        }
    });
    const [ad, setAd] = useState<any[]>([])

    console.log(id, propertyType, lookingTo);

  useEffect(() => {
     console.log("hello share")
    handleChange(id, propertyType, lookingTo)
           
        
    }, [id, propertyType, lookingTo]); 


    const handleChange = async (id: any, property:any, looking_to?: any) => {
        const body = {
            post_id: id,
            selected_property_type: property,
            looking_to: looking_to || '',
            user_id: loginData.user_id
        };

        try {
            const response :any =  await axiosInstance.post("/filter/get/postDetail", body);
            const propertyType = encodeURIComponent(property);
            const lookingTo = encodeURIComponent(looking_to);


            // navigate(`/property/${id}/${propertyType}/${lookingTo || ''}`);
           

            setData(response.data) 
            console.log("share",data,response.data)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    async function handleContact(
        property: any,
        looking: any,
        user: any,
        post: any,
        dis: any,
        buld: any,
        bhk: any,
        image: any,
    ) {
        console.log(property, looking);



        if (!loginData || loginData.phone === '' || loginData.phone === null) {
            navigate('/contact');
        } else {
            console.log('work');

            const chatBody: any = {
                property: property,
                user: user,
                post: post,
                looking_to: looking,
                district: dis,
                building_name: buld,

                Bhk: bhk,
                image: image,
            };

            setChatValue(chatBody)
            console.log("chat check", chatBody)
            setOpen(true);

            try {
                const Response = await axiosInstance.get(
                    `/api/ads/allAds/${'Property Contact View Ads'}`
                );
                setAd(Response.data.message);
                console.log(Response.data.message);
            } catch (error) {
                console.log('error', error);
            }
            console.log('Ad', ad);

            const body = {
                user_id: loginData.user_id,
                property_type: property,
                looking_to: looking,
                post_id: post,
                post_user_id: user,
            };
            console.log(body);

            try {
                const response = await axiosInstance.post(
                    '/filter/contactUserDetail',
                    body
                );
                setsimilarProperty(response.data);
                console.log(response.data);
                console.log('hello', similarProperty);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    }


    const handleClose = () => {
        console.log("jheki");

        setOpen(false);
    }





 




    const flatFurnishing: string | undefined = data?.flat_furnishing;

    // console.log(flatFurnishing);
    const FlatFurnishing = (key: string): boolean => {
        if (flatFurnishing) {
            const Array: string[] = flatFurnishing.split(', ').map(item => item.trim());
            return Array.includes(key);
        }
        return false;
    };


    const updatedFurnishings: { key: string; value: boolean; label: string; image: string }[] = Furnishings.map(item => ({
        ...item,
        value: FlatFurnishing(item.key)
    }));
    // console.log(updatedFurnishings)
    const trueValues: { key: string; value: boolean; label: string; image: string }[] = updatedFurnishings.filter(item => item.value === true);
    // console.log(trueValues);



    const Amenities: string = (data && data.amenities && data.amenities.amenities) || (data && data.society_amenities) || "";
    // console.log(Amenities);

    const AmenitiesKey = (key: string): boolean => {
        if (Amenities) {
            const Array: string[] = Amenities.split(',').map(item => item.trim());
            return Array.includes(key);
        }

        return false;
    };

    const updatedAmenities: { key: string; value: boolean; label: string; image: string }[] = dataAmenities.map(item => ({
        ...item,
        value: AmenitiesKey(item.key)
    }));
    // console.log(updatedAmenities);

    const trueAmenities: { key: string; value: boolean; label: string; image: string }[] = updatedAmenities.filter(item => item.value === true);
    // console.log("work", trueAmenities);

    const hasTrueFurnishings: boolean = trueValues.length > 0;
    const hasTrueAmenities: boolean = trueAmenities.length > 0;



    const overview = getOverviewTemplate(data).map(item => ({
        ...item,
        value: data[item.key] || "",
    }));

    if (look === "Rent" && data.property_available_for === "Rent") {
        overview.push({
            label: "Security Deposit",
            key: "security_deposit",
            image: overview7,
            value: data.security_deposite || "",
        });
    }
    ;
    const PropertyDetails = PropertDetailsData.map(item => {
        if (item.label === 'Seats' && item.subKeys && Array.isArray(item.subKeys)) {
            const minSeat: number | undefined = data[item.subKeys[0]];
            const maxSeat: number | undefined = data[item.subKeys[1]];

            if (minSeat !== undefined && maxSeat !== undefined) {
                return {
                    ...item,
                    value: `${minSeat} - ${maxSeat}`
                };
            }
        }

        if (item.label === 'Dimension (in ft)' && item.subKeys && Array.isArray(item.subKeys)) {
            const length: number | undefined = data[item.subKeys[0]];
            const width: number | undefined = data[item.subKeys[1]];

            if (length !== undefined && length !== null && length.toString() !== "" &&
                width !== undefined && width !== null && width.toString() !== "") {
                return {
                    ...item,
                    value: `${length} - ${width}`
                };
            }
        }

        if (data.rent_or_lease === 'Rent' && item.key === 'security_deposit') {
            return {
                ...item,
                value: data.security_deposit || ''
            };
        }


        switch (item.key) {
            case 'lifts':
                return {
                    ...item,
                    value: data.lifts || ""
                };
            case 'parking':
                return {
                    ...item,
                    value: data.parking || (data.additional_details?.parking) || ""
                };
            case 'meeting_room':
                return {
                    ...item,
                    value: data.meeting_room || ""
                };
            case 'select_your_floor':
                return {
                    ...item,
                    value: data.select_your_floor || ""
                };
            case 'carpet_area':
                return {
                    ...item,
                    value: data.carpet_area || (data.additional_details?.carpet_area) || ""
                };
            case 'facing':
                return {
                    ...item,
                    value: data.facing || (data.additional_details?.facing) || ""
                };
            case 'washroom':
                return {
                    ...item,
                    value: data.washroom || ""
                };
            case 'property_condition':
                return {
                    ...item,
                    value: data.property_condition || ""
                };
            case 'bathroom':
                return {
                    ...item,
                    value: data.bathroom || (data.additional_details?.bathroom) || ""
                };
            case 'eligible_for_loan':
                return {
                    ...item,
                    value: data.additional_details?.eligible_for_loan || ""
                };
            case 'balcony':
                return {
                    ...item,
                    value: data.balcony || data.additional_details?.balcony || ""
                };
            case 'servant_room':
                return {
                    ...item,
                    value: data.additional_details?.servant_room || ""
                };
            case 'preferred_tenant_type':
                return {
                    ...item,
                    value: data.additional_details?.preferred_tenant_type || data.preferred_tenant_type || ""
                };
            case 'patta_available':
                return {
                    ...item,
                    value: data.patta_available || ""
                };
            case 'approval_status':
                return {
                    ...item,
                    value: data.approval_status || ""
                };
            case 'maintenance_charges':
                return {
                    ...item,
                    value: data.additional_details?.maintenance_charges || data.maintenance_charges || ""
                };
            case 'Suitable_for':
                return {
                    ...item,
                    value: data.additional_details?.Suitable_for || data.Suitable_for || ""
                };
            default:
                return {
                    ...item,
                    value: ""
                };
        }

    });

    function handleReport() {


        if (loginData.user_id === undefined) {
            navigate('/contact')
        } else {
            setShowReport(true)
        }

    }


    const handleChat = async (
        userid: any,


    ) => {
        const userId = userid;


        console.log('User ID:', userId, look, chatvalue);

        try {
            const response = await axiosChat.post('/api/chat', {
                userId,
                req_user_id: loginData.user_id,
            });
            console.log('chat click', response.data);
            if (response.status === 200) {
                console.log('Chat created successfully');
                ;
                console.log('gg', response);

                const data = response.data.users.filter(
                    (item: any) => item.user_id !== loginData.user_id
                );
                console.log(data);
                setSelectedChat({
                    chatId: response.data.id,
                    userId: data[0].user_id,
                    username: data[0].name,
                    userpic: data[0].user_pic,
                });

                navigate('/chatscreen', {
                    state: {
                        chatId: response.data.id,
                        userId,
                        postId: chatvalue.post,
                        username: response.data.users[0].name,
                        userpic: response.data.users[0].user_pic,
                        initialMessageText: `${chatvalue.building_name} ${chatvalue.property} ${chatvalue.district}`,
                        details: {
                            userId: chatvalue.user,
                            postId: chatvalue.post,
                            looking_to: chatvalue.looking,
                            district: chatvalue.dis,
                            building_name: chatvalue.buld,
                            property_type: chatvalue.property,
                            Bhk: chatvalue.bhk,
                            image: chatvalue.image,
                        },
                    },
                });
            } else {
                console.error('Failed to create chat. Status code:', response.status);
            }
        } catch (error) {
            console.error('Error creating chat:', error);
        }
    };


    // console.log(PropertyDetails);

    async function handleproperty(id: any) {


        if (loginData.user_id === undefined) {
            navigate('/contact')
        }
        else {

            if (loginData.user_id !== id) {
                try {
                    const response = await axiosInstance.get(`/filter/userPost/property/${data.user_id}/${loginData.user_id}`)
                    console.log(response.data.rows)
                    // Correct usage of navigate with state
                    navigate('/builder', {
                        state: {
                            rows: response.data.rows,
                            look: data.tbl_user_reg,
                        },
                    });

                }
                catch (error) {

                }
            } else {

            }
        }
    }






    const handleSaved = async (id: number, property: any, look: any,) => {


        if (loginData.user_id === undefined) {
            navigate('/contact')
        } else {
            setLikedState(!likedState);

            const body = {
                post_id: id,
                liked_user_id: loginData.user_id,
                property_type: property,
                rent_or_sell: look,
                liked: !likedState
            };

            try {
                const response = await axiosInstance.post('/like/postLike', body);
                console.log("response", response);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    };



    function valueLabelFormat(value: number) {
        if (value >= 1000000000) {
            return (value / 10000000).toFixed(2) + ' Cr';
        }
        if (value >= 10000000) {
            return (value / 10000000).toFixed(2) + ' Cr';
        }
        if (value >= 1000000) {
            return (value / 100000).toFixed(2) + ' Lac';
        }
        if (value >= 100000) {
            return (value / 100000).toFixed(2) + ' Lac';
        }
        if (value >= 10000) {
            return (value / 1000).toFixed(0) + 'k';
        }
        if (value >= 1000) {
            return (value / 1000).toFixed(1) + 'k';
        }
        return value.toString();
    }

    const formatPrice = (price: string | number | null | undefined): string => {
        if (price == null) {
            return "N/A"; // or handle the missing value as needed
        }

        if (typeof price === 'number') {
            return valueLabelFormat(price);
        }

        // Handle string price
        const numericPrice = parseFloat(price.replace(/[^\d.-]/g, ''));
        return valueLabelFormat(numericPrice);
    };
    const price = data.price || data.cost || data.enter_lease_amount || data.lease_amount || (data.monthly_rent ? `${data.monthly_rent} / Month` : null);
    const formattedPrice = formatPrice(price);
    const priceSq = data.PriceperSqft ? formatSQPrice(data.PriceperSqft) : ""
    const hasImages = data?.images?.length > 0;
    const coverimg: any = data.images && data.images.length > 0 ? data.images?.find((img: any) => img.isCover) || "" : "";
    const responsiveSettings = getResponsiveSettings(1.5, 1, 1);


    return (
        <div>
            <div className={` xl:mx-[23%] lg:mx-[10%]  mb-2   ${showReport ? "hidden" : "block"}`}>

                <div className=' p-1 mb-2 '>
                    <div className='msm:flex msm:flex-col gap-5  rounded-xl relative'>
                        <div className='relative p-1 bg-[#F5F5F5] rounded-2xl' key={data?.id}>
                            <div className='  rounded-xl bg-black h-[50vh]'>
 

                                <Carouse
                                    additionalTransfrom={0}
                                    arrows
                                    autoPlaySpeed={0}
                                    centerMode={false}
                                    className=""
                                    containerClass="container"
                                    dotListClass=""
                                    draggable

                                    infinite={false}
                                    itemClass=""
                                    keyBoardControl
                                    minimumTouchDrag={80}


                                    rewind={false}
                                    rewindWithAnimation={false}
                                    rtl={false}

                                    showDots={false}
                                    sliderClass=""
                                    slidesToSlide={1}
                                    swipeable
                                    responsive={responsiveSettings}
                                >

                                    {hasImages ? (
                                        data.images.map((image: any, index: number) => (
                                            <div key={index} className='p-2'>
                                                <img
                                                    className='h-[45vh] lg:w-[40vw]'
                                                    src={image.uri || DefultScroll} // Use default image if uri is empty or undefined
                                                    alt={image.alt || 'Default image'} // Fallback alt text
                                                />
                                            </div>
                                        ))
                                    ) : (
                                        <div className='px-2'>
                                            <img
                                                className='h-[45vh] lg:w-[40vw]'
                                                src={DefultScroll} // Display default image when no images are available
                                                alt='Default image' // Default alt text
                                            />
                                        </div>
                                    )}
                                </Carouse>
                                <img
                                    onClick={() => handleSaved(data.post_id, data.property_type, data.looking_to || data.rent_or_lease || data.property_available_for,)}
                                    src={likedState ? LikedHeart : heart} className='absolute cursor-pointer top-3 left-3 text-2xl bg-[#D9D9D9] rounded-2xl'

                                />
                            </div>
                            <div>
                                <div className='items-center justify-between lg:gap- xl:ml-2 flex'>
                                    <div className='capitalize text-[#252B5C] ml-1 flex flex-col w-[60%]'>
                                        <div className='flex items-center justify-between'>
                                            <p className='xl:text-xl font-bold'>{data?.bhk} {data?.property_type} for {data?.looking_to || data?.rent_or_lease || data?.property_available_for} in {data?.city}</p>
                                        </div>
                                        <div>
                                            <p className='text-md pt-1'>{data?.building_name}, {data?.landmark_street}, {data?.district}</p>
                                        </div>
                                        <div>
                                            <p className='xl:text-xl msm:text-lg text-black font-semibold'>₹ {formattedPrice}</p>
                                        </div>
                                    </div>
                                    <div className='flex  justify-between gap-3 items-center mr-1'>
                                        <div className="border-solid border-1 border-l opacity-80 border-black h-16 xl:my-6 my-4"></div>
                                        <div className='flex flex-col '>
                                            <p className=''>Built Up Area</p>
                                            <div className='flex gap-1 '>
                                                <p className='font-medium xl:ml- flex text-[#404040] text-md'>{data?.build_up_area || data?.plot_area || data?.built_up_area}</p>
                                                {data?.build_up_area || data?.plot_area || data?.built_up_area !== "" ? <p className='font-medium text-[#404040]'>sq.ft.</p> : ""}

                                            </div>

                                        </div>
                                    </div>
                                    <div className={`${data?.looking_to || data?.rent_or_lease || data?.property_available_for === "Sale" ? "block" : "hidden"}`}>
                                        <p className={` font-medium text-[#404040]`}>Price per sq.ft</p>
                                        <p className='xl:ml- font-medium text-[#404040]' >{`RS.${priceSq}`}</p>
                                    </div>
                                    <div className='flex  justify-between items-center xl:ml-4 msm:ml-1 mb-1'>

                                        <div className='flex flex-col gap-3 mr-3'>
                                            {/* <div onClick={handlechat} className='flex mt-2 justify-between mx- items-center bg-[#D9D9D9] p-3 rounded-lg gap-1 h-7  '>
                                                <p className='text-[#404040]'>Chat</p>
                                                <img className='h-4' src={MessageIcon} alt='Chat Icon' />
                                            </div> */}
                                            <Button onClick={() =>
                                                handleContact(
                                                    data.property_type,
                                                    data.looking_to ||
                                                    data.rent_or_lease ||
                                                    data.property_available_for,
                                                    data.user_id,
                                                    data.post_id,
                                                    data.district,
                                                    data.building_name,

                                                    data.bhk,
                                                    coverimg.uri
                                                )}
                                                className={`h-7  flex gap-1`} variant="contained" color="primary">
                                                <p>Contact</p>
                                                <img src={phone} alt='Phone Icon' className='h-' />
                                            </Button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className='  bg-[#F5F5F5] rounded-2xl mb-4'>
                    <div className=' lg:p-4'>
                        <p className='text-xl msm:ml-1 xl:mt-0 font-bold msm:mt-3'>Overview</p>
                        <div className='grid grid-cols-2 msm:gap-3 xl:gap-6 mx-2 xl:mr-5 xl:mt-5'>
                            {overview.map((item, index) => (
                                item.value && (
                                    <div key={index} className='flex rounded-2xl xl:gap-10 msm:gap-2 msm:h-20 xl:p-3 bg-[#ffffff] '>
                                        <div className='flex ml-5 msm:ml-3   items-center justify-center'>
                                            <img className='' src={item.image} alt={item.label} />
                                        </div>
                                        <div className='flex flex-col justify-center'>
                                            <p className='font-semibold  text-black'>{item.value}</p>
                                            <p className=''>{item.label}</p>
                                        </div>
                                    </div>
                                )
                            ))}
                        </div>
                    </div>
                </div>

                <div className='rounded-2xl mb-4 bg-[#F5F5F5]'>
                    <div className='ml-5 msm:ml-1 pb-2 '>
                        <p className='text-xl p-4 msm:p-2 font-bold'>Property Details</p>

                        <div className=' ml-5  '>
                            {
                                PropertyDetails.map((item, index) => (
                                    item.value && (
                                        <div className=''>
                                            <div className='flex justify-between Xl:gap-40 xl:mr-10 p-2'>
                                                <div className=' ml-2 w-[40%]'>
                                                    <p className='font-medium text-md  text-[#3C3C3C]' >{item.label}</p>
                                                </div>
                                                <div className='w-[40%]'>
                                                    <p className='flex justify-start items-start capitalize font-semibold text-lg text-left'>{item.value}</p>
                                                </div>

                                            </div>
                                            <div className="border-solid border-100 border-t mt-1 border-[#DBDBDB] mr-[5%] msm:text-right ">
                                            </div>

                                        </div>
                                    )

                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className='bg-[#F5F5F5] rounded-3xl pb-4 mt-2'>

                    {hasTrueFurnishings || hasTrueAmenities ? (
                        <div className=''>
                            <p className='text-xl p-4 font-bold'>Facilities</p>
                            {hasTrueFurnishings && (
                                <div>
                                    <p className='mb-4 ml-6'>Furnishings</p>
                                    <div className='lg:grid msm:flex msm:flex-col lg:grid-cols-5   gap-5 ml-7   lg:overflow-x-hidden msm:overflow-x-scroll  items-center'>
                                        {trueValues.map((item, index) => (
                                            <div key={index} className='bg-[#F2F2F7] flex flex-col items-center msm:p-10 justify-center w-32 h-24'>
                                                <img className='flex items-center justify-center' src={item.image} />
                                                <p className='flex items-center justify-center'>{item.label}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            {hasTrueAmenities && (
                                <div className='mt-4'>
                                    <p className='mb-4 ml-6'>Amenities</p>
                                    <div className='grid lg:grid-cols-5 gap-5 ml-7 items-center msm:grid-cols-2 '>
                                        {trueAmenities.map((item, index) => (
                                            <div key={index} className='bg-[#F2F2F7] flex flex-col items-center msm:p-10 justify-center w-32 h-24 '>
                                                <img className=' flex items-center justify-center' src={item.image} />
                                                <p className='flex items-center justify-center text-center'>{item.label}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : null}

                    <div>
                        <p className='text-xl p-4 font-bold'>About Property</p>
                        <div className='xl:mx-10 msm:mx-4'>
                            <p>{data?.additional_details?.property_description || data?.property_description && data?.property_description}</p>
                        </div>
                    </div>
                    <div >
                        <p className='text-xl p-4 font-bold '>Contact</p>
                        <div className='flex justify-between items-center'>
                            <div className='flex ml-10 gap-5'>
                                <div>
                                    {/* <img className='w-16 h-16 rounded-3xl' src={data?.tbl_user_reg.user_pic} alt="User Pic" />  */}
                                    <Avatar alt={data?.tbl_user_reg?.name} src={data?.tbl_user_reg?.user_pic} />
                                </div>
                                <div>
                                    <p>{data?.tbl_user_reg?.name}</p>
                                    <div className='flex'>


                                        <p>+91 91*******01</p>


                                        <p>{data?.tbl_user_reg?.category}</p>
                                    </div>

                                </div>
                            </div>
                            <p onClick={() => handleproperty(data.user_id)} className='text-blue-600 mr-10 cursor-pointer'>View</p>
                        </div>
                    </div>

                    <div className='flex xl:mt-14 msm:mt-4 flex-col gap-4 justify-center items-center'>
                        <div className='bg-[#2E368F] px-24 py-4 rounded-3xl' onClick={() =>
                            handleContact(
                                data.property_type,
                                data.looking_to ||
                                data.rent_or_lease ||
                                data.property_available_for,
                                data.user_id,
                                data.post_id,
                                data.district,
                                data.building_name,

                                data.bhk,
                                coverimg.uri
                            )}><p className='  text-[#ffffff] rounded-3xl cursor-pointer'>Contact</p></div>
                        <p className='msm:flex msm:justify-center msm:items-center mx-10'>By submitting I accept Real Estate Terms & Conditions</p>
                        <img className=' cursor-pointer  ' onClick={handleReport} src={Report} alt="Report Icon" />
                    </div>
                </div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="contact-modal-title"
                    aria-describedby="contact-modal-description"
                >
                    <Box >
                        <div className='absolute top-[5%] right-[5%] w-[35%] xl:mx z-20 transform rounded-2xl bg-white   outline-none'>
                            <div className="z-20  ">
                                <div className='flex mt-10 flex-col mx-[20%]'>
                                    <div className='flex  gap-10'>
                                        <div>
                                            <img className='w-20 h-20 bg-black rounded-full' src={similarProperty.userDetails.user_pic ? similarProperty.userDetails.user_pic : profile} alt="User" />
                                        </div>
                                        <div>
                                            <p className='text-lg font-semibold'>{similarProperty.userDetails?.name}</p>
                                            <p>{similarProperty.userDetails?.category}</p>
                                            <p className={`text-lg font-semibold ${similarProperty.userDetails?.category !== "Owner" ? "flex" : "hidden"}`}>{`+91 ${similarProperty.userDetails?.mobile_no?.slice(3)}`}</p>
                                        </div>
                                    </div>

                                    <div className='flex mt-10 justify-between' >
                                        <div className='bg-[#2E368F] py-3 px-16 gap-2 rounded-full flex justify-center items-center'>
                                            <img src={phone} alt="Phone" />
                                            <p className='text-white'>Call</p>
                                        </div>
                                        <div onClick={() =>
                                            handleChat(
                                                similarProperty.userDetails.user_id,


                                            )
                                        } className={`${similarProperty.userDetails?.category !== "Owner" ? "flex" : "hidden"} bg-[#2E368F] py-3 px-16 gap-2 rounded-full flex justify-center items-center`}>
                                            <img src={chat} alt="Chat" />
                                            <p className='text-white flex items-center justify-center'>Chat</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="border-solid border-1 border-t mt-3 mx-3 border-[#DBDBDB] mr- msm:text-right "></div>



                                <Carousel className=" mt-4 px-3  w-[100%]" indicators={false}>
                                    {ad.map((item) => (
                                        <Carousel.Item key={item.id} interval={2000}>
                                            <Link
                                                className="flex "
                                                to={`/upcoming-projects/${item.id}`}
                                            >
                                                {item ? (
                                                    <img
                                                        className="object-cover w-full rounded-2xl h-[25vh] "
                                                        src={item.link}
                                                        alt={`Project ${item.id}`}
                                                    />
                                                ) : (
                                                    <div className="bg-gray-200 w-full  h-full flex items-center justify-center">
                                                        <p>No Image Available</p>
                                                    </div>
                                                )}
                                            </Link>
                                        </Carousel.Item>
                                    ))}
                                </Carousel>



                                <div>
                                    {similarProperty.message?.length > 0 && (
                                        <p className='text-xl font-semibold my-4 ml-5'>Similar Properties</p>
                                    )}
                                    <div className='flex gap-3 mx-3  overflow-x-scroll w-[97%]'>
                                        {similarProperty.message?.map((item: Property, index: number) => {
                                            const price = item.price || item.cost || item.enter_lease_amount || item.lease_amount || item.monthly_rent;
                                            const formattedPrice = formatPrice(price);
                                            const coverim: any = item.images && item.images.length > 0 ? item.images.find(img => img.isCover) || "" : "";
                                            const locality = truncateString(item.city, 20)
                                            const sqtv: any = item.PriceperSqft ? item.PriceperSqft : "";
                                            const priceSQTV = formatSQPrice(sqtv);
                                            return (
                                                <div key={index} className=' '>
                                                    <div className='relative mt- bg-[#FFFFFF]  h-[31vh  w-[15vw]    border-2 p-2 rounded-2xl  '>
                                                        <div className='relative '>

                                                            <p className='absolute right-0 mr-1  top-3 bg-[#2E368F] text-white py-1 px-3 rounded-2xl '>{item.looking_to || item.rent_or_lease || item.property_available_for}</p>
                                                            <img onClick={() => { handleChange(item.post_id, item.property_type, item.looking_to || item.rent_or_lease || item.property_available_for) }}
                                                                className='w-[15vw] h-[20vh]  flex justify-center bg-black items-center object-contain  rounded-3xl ' src={typeof coverim === 'object' && coverim?.uri ? coverim.uri : ''} />

                                                            <div className={`${item.kbl_property ? "flex" : "hidden"}  items-center bg-[#6135DD] absolute bottom-0 rounded-bl-3xl p-1 rounded-tr-3xl left-0`}>
                                                                <img className="w-5 h-5" src={star} />
                                                                <p className="text-white text-sm mr-1">VATTARA VERIFIED</p>
                                                            </div>
                                                        </div>
                                                        <div className='ml-3'>
                                                            <div className='flex items-center '>
                                                                <p className='text-base flex font-bold'>{item.bhk} {item.property_type}   </p>
                                                                <img src={goldtick} />
                                                            </div>
                                                            <div className='flex gap-2 items-center'>
                                                                <img className="h-5" src={loaction} />
                                                                <p className='text-sm capitalize'>{locality}</p>

                                                            </div>

                                                            <div className='flex items-center gap-2 '>
                                                                <p className='text-xl font-bold text-[#404040] '>₹ {formattedPrice} </p>
                                                                <div className='border-[#404040] h-6 border-1 border-l  '></div>
                                                                <div className='ml-2'>
                                                                    <div className='flex flex-wra items-center text-sm font-medium text-[#404040]  '>
                                                                        <p>{item.built_up_area || item.plot_area} </p>
                                                                        {item.built_up_area || item.plot_area ? <p className='text-sm'>sq.ft</p> : ""}
                                                                    </div>
                                                                    <div className='flex items-center text-sm  font-medium text-[#404040]'>

                                                                        {item.PriceperSqft ? <p>₹ {priceSQTV}</p> : ""}
                                                                        {item.PriceperSqft ? <p>/sq.ft</p> : ""}
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Modal>




            </div>
            <div className={showReport ? "grid mx-[20%] items-center justify-center" : "hidden"}>
                <ReportPopUp showReport={showReport} setShowReport={setShowReport} property={data.property_type} user={loginData.user_id}
                    post={data.post_id} look={data?.looking_to || data?.rent_or_lease || data?.property_available_for} />
            </div>
        </div>
    );
}

export default SharepropDetails;






// import React, { useEffect, useState } from 'react'
// import { axiosInstance } from './api/APIs';
// import { useParams } from 'react-router-dom';

// export default function SharepropDetails() {
//     const { id, propertyType, lookingTo } = useParams();
//     const [data,setData]=useState<any>()

//         useEffect(() => {
//         console.log("hello share")
//     handleChange(id, propertyType, lookingTo)
           
       
//     }, [id, propertyType, lookingTo]); 
//         const handleChange = async (id: any, property:any, looking_to?: any) => {
//         const body = {
//             post_id: id,
//             selected_property_type: property,
//             looking_to: looking_to || '',
//             user_id: undefined
//         };

//         try {
//             const response :any = await  axiosInstance.post("/filter/get/postDetail", body);
//             const propertyType = encodeURIComponent(property);
//             const lookingTo = encodeURIComponent(looking_to);


//             // navigate(`/property/${id}/${propertyType}/${lookingTo || ''}`);
//            console.log("myresponse",response)

//             setData(response.data) 
//         } catch (error) {
//             console.error('Error fetching data:', error);
//         }
//     };
//   return (
//     <div>SharepropDetails</div>
//   )
// }
