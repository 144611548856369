// CustomDot.tsx
import React from 'react';

interface CustomDotProps {
  active: boolean;
  onClick: () => void;
}

const CustomDot: React.FC<CustomDotProps> = ({ active, onClick }) => {
  return (
    <button
      onClick={onClick}
      style={{
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        backgroundColor: active ? '#2E368F' : 'gray',
        margin: '0 5px',
        border: 'none',
        cursor: 'pointer',
      }}
    />
  );
};

export default CustomDot;
