import React, { useContext, useState } from 'react';
import Basic from './Basic';
import PropertyDetails from './PropertyDetails';
import Price from './Price';
import AdditionalDetails from './AdditionalDetails';
import UploadPhoto from './UploadPhoto';
import Review from './Review';
import { Button, Step, StepLabel, Stepper } from '@mui/material';
import { useStepperContext } from './StepperContext';
import { useTheme } from '@mui/material/styles';
import image from './Internship-pana 2.jpg';
import { axiosInstance } from '../../api/APIs';
import { useSelector } from 'react-redux';
import userDataSlice from '../../../slices/userDataSlice';

interface StepperFormProps {
  label?: React.ReactNode;
  description?: React.ReactNode;
  required?: boolean;
  labelText?: any
}

const StepperForm: React.FC<StepperFormProps> = () => {
  const { addUserData, userData } = useStepperContext();
  const theme = useTheme();
  const loginData = useSelector((state: any) => state.userData.userLoginData);
  const property = userData.find((item) => item?.['Property Subtype'])?.[
    'Property Subtype'
  ];
  console.log("On", property)
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const steps: StepperFormProps[] = [
    {
      labelText: "Basic Detaisl",
      label: (
        <span className="text-white text-lg hidden custom-lg:block">
          {"Basic Details"}
        </span>
      ),
      description: (
        <Basic onNext={handleNext} onBack={handleBack} onReset={handleReset} />
      ),
      required: true,
    },
    {
      labelText: "Property Details",
      label: (
        <span className="text-white text-lg hidden custom-lg:block">
          {"Property Details"}
        </span>
      ),
      description: (
        <PropertyDetails
          onNext={handleNext}
          onBack={handleBack}
          onReset={handleReset}
        />
      ),
    },
    {
      labelText: "Price",
      label: (
        <span className="text-white text-lg hidden custom-lg:block">{"Price"}</span>
      ),
      description: (
        <Price onNext={handleNext} onBack={handleBack} onReset={handleReset} />
      ),
    },
    {
      labelText: "Additional Details",
      label: (
        <span className="text-white text-lg hidden custom-lg:block">
          {"Additional Details"}
        </span>
      ),
      description: (
        <AdditionalDetails
          onNext={handleNext}
          onBack={handleBack}
          onReset={handleReset}
        />
      ),
    },
    {
      labelText: "Upload Photos",
      label: (
        <span className="text-white text-lg hidden custom-lg:block">
          {"Upload Photos"}
        </span>
      ),
      description: (
        <UploadPhoto
          onNext={handleNext}
          onBack={handleBack}
          onReset={handleReset}
        />
      ),
    },
    {
      labelText: "Review",
      label: (
        <span className="text-white text-lg hidden custom-lg:block">
          {"Review"}
        </span>
      ),
      description: (
        <Review onNext={handleNext} onBack={handleBack} onReset={handleReset} />
      ),
    },
  ];

 async function handlehelp() {

    try {
      const response = await axiosInstance.post("/user/UserHelp/Update",{user_id:loginData.user_id})
      console.log(response)
    } catch (e) {

    }
  }

  const filteredSteps =
    property === 'Plot / Land'
      ? steps.filter((step) => step.labelText !== 'Additional Details')
      : steps;

  console.log("Yup", filteredSteps)




  return (
    <div
      style={{ display: 'flex', alignItems: 'flex-start' }}
      className="bg-customBlue h-dvh"
    >
      <div className="flex justify-center h-dvh gap-9 w-[80%] ml-[10%] mt-8 fixed ">
        {/* Stepper and Side Divs Hidden on Screens 1440px or Smaller */}
        <div className="hidden lg:block custom-xxl:w-[30%] custom-xl:w-[30%] custom-lg:w-[31%] xl:w-[8%] lg:w-[10%]  bg-customDarkBlue h-dvh rounded-t-3xl text-white">
          <h1 className="relative top-[20px] left-[30px] text-[22px] font-semibold hidden custom-lg:block">
            Post Property
          </h1>
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            style={{
              borderRight: '1px solid transparent',
              position: 'relative',
              top: '100px',
              left: '20px',
            }}
          >
            {filteredSteps.map((step, index) => (
              <Step key={index}>
                <StepLabel
                  sx={{
                    color: 'white', // Ensure the text color is white
                    fontSize: '1.2rem', // Increase font size
                    fontWeight: 'bold', // Optional: Make text bold
                  }}
                  className="hidden custom-lg:block" // Hide StepLabel on medium screens and up
                >
                  {step.label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>

        {/* Main Content Div, Hidden on Screens Larger than 1440px */}
        <div
          className=" msm:w-[110%] bg-customWhite h-dvh rounded-t-3xl  overflow-scroll"
          style={{
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
          }}
        >
          <div>{filteredSteps[activeStep]?.description}</div>
        </div>
        <div className="hidden custom-lg:block md:w-[35%] bg-customDarkBlue h-dvh rounded-t-3xl ">
          <img
            src={image}
            alt="description"
            style={{ width: '100%', marginTop: '50px' }}
          />

          <h1 className="text-white ml-5">Need guidance from our team</h1>

          <div className="mt-2 ml-9">
            <h5 className="text-white text-sm ">
              For Advertising properties
              <span className="text-white text-base">?</span>{' '}
            </h5>
          </div>

          <Button
            onClick={handlehelp}
            style={{
              width: '200px',
              backgroundColor: 'transparent',
              color: '#FBB337',
              borderRadius: '10px',
              fontFamily: 'K2D',
              marginTop: '30px',
              border: '1px solid #FBB337',
              marginLeft: '30px',
            }}
          >
            Connect with us
          </Button>
        </div>
      </div>
    </div>
  );
};

export default StepperForm;
