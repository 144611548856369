import React, { useState } from 'react';
import { useStepperContext } from './StepperContext';
import { Accept, useDropzone } from 'react-dropzone';
import { Button, LinearProgress, Typography } from '@mui/material';
import AWS from 'aws-sdk';
import siva from './Picture.jpg';
import { makeStyles } from '@mui/styles';
import RemoveComponent from '../component/icon/RemoveComponent';
import BackComponent from './icon/BackComponent';
import { ImageTagsR, ImageTagsC } from '../data'

interface UploadedImage {
  id: number;
  name: string;
  file: File;
  uri: string;
  isCover: boolean;
  isVisible: boolean;
  ImageTag: string | null;
  progress: number;
  isUploaded: boolean;
}

interface UploadPhotoProps {
  onNext: () => void;
  onBack: () => void;
  onReset: () => void;
}

const useStyles = makeStyles({
  imagePreviewContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)', // Adjust the number of columns
    gap: '20px',
    marginTop: '20px',
  },
  imagePreview: {
    position: 'relative',
    border: '2px solid #cccccc',
    borderRadius: '10px',
    padding: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s, box-shadow 0.3s',
    height: '200px', // Fixed height for each image preview
    overflow: 'hidden',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)',
    },
  },
  imageWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    borderRadius: '10px',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    cursor: 'pointer',
  },
  setCoverButton: {
    position: 'absolute',
    top: '10px',
    left: '10px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    fontSize: '12px',
    padding: '5px 10px',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  removeButton: {
    position: 'absolute',
    top: '10px', // Adjusted for better positioning
    right: '10px', // Adjusted for better positioning
  },
  tagSelect: {
    width: '100%',
    marginTop: '10px',
    padding: '5px',
    borderRadius: '5px',
  },
  progressBar: {
    marginTop: '10px',
  },
  progressText: {
    textAlign: 'right',
    marginTop: '5px',
  },
});

const UploadPhoto: React.FC<UploadPhotoProps> = ({
  onNext,
  onBack,
  onReset,
}) => {
  const classes = useStyles();
  const { propImages, addPropImage, userData } = useStepperContext();
  const [selectedFiles, setSelectedFiles] =
    useState<UploadedImage[]>(propImages);
  const proptype = userData.find((item) => item?.['Property Subtype'])?.[
    'Property Subtype'
  ];
  const imageTags = ['hall', 'kitchen', 'room', 'balcony'];
  const res_com = userData.find((item: any) => item?.['Property Type'])?.['Property Type']
  const s3 = new AWS.S3({
    region: 'us-east-1',
    accessKeyId:  process.env.REACT_APP_AWS_ACCESSKEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRETACCESS, 
  });
  const generateUniqueNumber = () => Math.floor(Math.random() * 100000);

  const handleUploadChange = async (files: File[]) => {
    if (files.length === 0) return;

    const maxFiles = 6;
    const newFiles: File[] = files.slice(0, maxFiles - selectedFiles.length);

    if (selectedFiles.length + newFiles.length > maxFiles) {
      alert(`You can upload a maximum of ${maxFiles} files.`);
      return;
    }

    const newSelectedFiles = await Promise.all(
      newFiles.map(async (file) => {
        const s3Url = await uploadImageToS3(file);

        return {
          id: generateUniqueNumber(),
          name: file.name,
          file,
          uri: s3Url,
          isCover: false,
          isVisible: true,
          ImageTag: null,
          progress: 100,
          isUploaded: true,
        };
      })
    );

    setSelectedFiles((prevFiles: any) => {
      const updatedFiles = [...prevFiles, ...newSelectedFiles];
      addPropImage(updatedFiles);
      return updatedFiles;
    });
  };

  const uploadImageToS3 = async (file: File): Promise<string | null> => {
    const fileName = `image_${Date.now()}.jpg`;
    const params = {
      Bucket: 'vattara-img',
      Key: fileName,
      Body: file,
      ContentType: file.type,
    };

    try {
      const uploadPromise = s3
        .upload(params)
        .on('httpUploadProgress', (event) => {
          const percent = Math.round((event.loaded / event.total) * 100);
          setSelectedFiles((prevFiles) =>
            prevFiles.map((f) =>
              f.file === file ? { ...f, progress: percent } : f
            )
          );
        })
        .promise();

      const uploadResponse = await uploadPromise;
      console.log("neweruu",uploadResponse)
      return uploadResponse.Location;
    } catch (error) {
      console.error(`Error uploading ${file.name} to S3: ${error}`);
      return null;
    }
  };

  const handleSetCover = (id: number) => {
    const updatedFiles = selectedFiles.map((file) => ({
      ...file,
      isCover: file.id === id,
    }));
    setSelectedFiles(updatedFiles);
    addPropImage(updatedFiles);
  };

  const handleTagSelect = (id: number, tag: string) => {
    const updatedFiles = selectedFiles.map((file) => ({
      ...file,
      ImageTag: file.id === id ? tag : file.ImageTag,
    }));
    setSelectedFiles(updatedFiles);
    addPropImage(updatedFiles);
  };

  const handleRemove = (id: number) => {
    const updatedFiles = selectedFiles.filter((file) => file.id !== id);
    setSelectedFiles(updatedFiles);
    addPropImage(updatedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => handleUploadChange(acceptedFiles),
    accept: {
      'image/*': [],
    } as Accept,
    maxFiles: 6,
  });

  const handleReset = () => {
    setSelectedFiles([]);
    addPropImage([]);
    onReset();
  };

  return (
    <div className="min-h-[2000px] p-2 overflow-hidden">
      <Button
        onClick={onBack}
        style={{
          position: 'relative',
          right: '20px',
        }}
      >
        <BackComponent />
      </Button>
      <Typography
        style={{
          fontWeight: '700',
          marginLeft: '40%',
          position: 'relative',
          bottom: '30px',
        }}
      >
        Upload Photos
      </Typography>
      <div
        {...getRootProps()}
        style={{
          border: '2px dashed #cccccc',
          padding: '50px',
          textAlign: 'center',
          marginTop: '10px',
        }}
      >
        <input {...getInputProps()} />
        <img
          src={siva}
          alt="description"
          className="w-[100px] mb-[10px] custom-xxl:ml-[40%] sm:ml-[40%] msm:ml-[25%]"
        />
        <p style={{ marginRight: '10%' }}>
          Drop your image here, or <span>browse</span>{' '}
        </p>
        <h4 style={{ marginRight: '10%' }}>Supports: PNG, JPG, JPEG</h4>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 mt-5">
        {selectedFiles.map((file) => (
          <div
            key={file.id}
            className={`relative w-full h-[200px] border-2 border-gray-300 rounded-lg p-2 shadow-md transition-transform duration-300 ease-in-out ${
              file.isCover ? 'transform scale-105 shadow-lg' : ''
            }`}
          >
            <div className="relative w-full h-full overflow-hidden rounded-lg">
              <img
                src={file.uri}
                alt={file.name}
                className="w-full h-full object-cover cursor-pointer"
                onClick={() => {}}
              />
              <button
                className="absolute top-2 left-2 bg-black bg-opacity-50 text-white text-xs px-2 py-1 rounded cursor-pointer"
                onClick={() => handleSetCover(file.id)}
              >
                {file.isCover ? 'Cover Image' : 'Set as Cover'}
              </button>
              <button
                className="absolute top-2 right-2 bg-black bg-opacity-50 text-white text-xs px-2 py-1 rounded cursor-pointer"
                onClick={() => handleRemove(file.id)}
              >
                Remove
              </button>
              {proptype !== 'Plot / Land' && (
                <div className="absolute bottom-2 w-full px-2">
                  <select
                     className="w-full px-2 relative py-1 rounded border border-gray-300 cursor-pointer bg-white mt-2"
                    onChange={(e) => handleTagSelect(file.id, e.target.value)}
                    value={file.ImageTag || ''}
                  >
                    <option value="">Select Tag</option>

                    {/* Residential Tags */}
                    {res_com === "Residential" && (
                      <>
                        {/* Exterior */}
                        {Array.isArray(ImageTagsR.Exterior) && (
                          <optgroup label="Exterior">
                            {ImageTagsR.Exterior.map((tag: any) => (
                              <option key={tag.id} value={tag.label}>
                                {tag.label}
                              </option>
                            ))}
                          </optgroup>
                        )}

                        {/* Interior */}
                        {Array.isArray(ImageTagsR.Interior) && (
                          <optgroup label="Interior">
                            {ImageTagsR.Interior.map((tag: any) => (
                              <option key={tag.id} value={tag.label}>
                                {tag.label}
                              </option>
                            ))}
                          </optgroup>
                        )}

                        {/* Amenities */}
                        {Array.isArray(ImageTagsR.Amenities) && (
                          <optgroup label="Amenities">
                            {ImageTagsR.Amenities.map((tag: any) => (
                              <option key={tag.id} value={tag.label}>
                                {tag.label}
                              </option>
                            ))}
                          </optgroup>
                        )}

                        {/* Miscellaneous */}
                        {Array.isArray(ImageTagsR.Miscellaneous) && (
                          <optgroup label="Miscellaneous">
                            {ImageTagsR.Miscellaneous.map((tag: any) => (
                              <option key={tag.id} value={tag.label}>
                                {tag.label}
                              </option>
                            ))}
                          </optgroup>
                        )}
                      </>
                    )}

                    {/* Commercial Tags */}
                    {res_com === "Commercial" && (
                      <>
                        {/* Exterior */}
                        {Array.isArray(ImageTagsC.Exterior) && (
                          <optgroup label="Exterior">
                            {ImageTagsC.Exterior.map((tag: any) => (
                              <option key={tag.id} value={tag.label}>
                                {tag.label}
                              </option>
                            ))}
                          </optgroup>
                        )}

                        {/* Interior */}
                        {Array.isArray(ImageTagsC.Interior) && (
                          <optgroup label="Interior">
                            {ImageTagsC.Interior.map((tag: any) => (
                              <option key={tag.id} value={tag.label}>
                                {tag.label}
                              </option>
                            ))}
                          </optgroup>
                        )}

                        {/* Amenities */}
                        {Array.isArray(ImageTagsC.Amenities) && (
                          <optgroup label="Amenities">
                            {ImageTagsC.Amenities.map((tag: any) => (
                              <option key={tag.id} value={tag.label}>
                                {tag.label}
                              </option>
                            ))}
                          </optgroup>
                        )}

                        {/* Miscellaneous */}
                        {Array.isArray(ImageTagsC.Miscellaneous) && (
                          <optgroup label="Miscellaneous">
                            {ImageTagsC.Miscellaneous.map((tag: any) => (
                              <option key={tag.id} value={tag.label}>
                                {tag.label}
                              </option>
                            ))}
                          </optgroup>
                        )}
                      </>
                    )}
                  </select>
                </div>
              )}

            </div>
            {file.isUploaded && (
              <div className="mt-2">
                <LinearProgress variant="determinate" value={file.progress} />
                <Typography className="text-right mt-1">
                  {file.progress}%
                </Typography>
              </div>
            )}
          </div>
        ))}
      </div>

      <div
        style={{
          marginTop: '50px',
          display: 'flex',
          justifyContent: 'space-between',
          gap: '10px',
        }}
      >
        <button
          onClick={handleReset}
          className="w-[150px] bg-[#2E368F] text-white rounded-[20px] p-2"
        >
          Reset
        </button>
        <button
          className="w-[150px] bg-[#2E368F] text-white rounded-[20px] p-2"
          onClick={onNext}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default UploadPhoto;
