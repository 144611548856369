import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { AccountCircle, } from "@mui/icons-material";
import vattaraimg from "../../compounts/images/vattaraLogo.png";
import vattaraimg2 from "../../compounts/images/vattaraLogo2.png";

import chat from '../../assets/chat.png';
import bell from '../../assets/bell.png'
import MNavbar from './MNavber';
import bell1 from '../images/bellmsm.svg'
import vattara from '../images/vattaralogomsm.svg'
import io from 'socket.io-client';
import { useDispatch } from 'react-redux';
import { useStepperContext } from '../PostForm/component/StepperContext';
import { resetUserLoginData } from '../../slices/userDataSlice';
import { resetUser } from '../../slices/filterSlice';
import { axiosInstance } from '../api/APIs';
import { fontSize } from '@mui/system';


const SOCKET_URL = 'https://vattaram.in';



const NavbarMain: React.FC = () => {
  const dispatch=useDispatch()
  const {resetForm}=useStepperContext()
  const loginData = useSelector((state: any) => state.userData.userLoginData)
  const [Data,setData]=useState<any>([])
const navigate = useNavigate()

  useEffect(()=>{
    const socket = io(SOCKET_URL);
    fetch()

    socket.on('connect', () => {
        console.log('connected');
      });
  
      // Handle incoming 'userDetails' event
      socket.on('userDetails', (data) => {
        const deviceExists = data.logged_device.some((device:any) => device.id === loginData.Id);
        console.log("exists",deviceExists)
        if (data.userId ===loginData?.user_id && !deviceExists) {
            dispatch(resetUserLoginData())
            dispatch(resetUser())
            navigate('/')
            resetForm()
        }
      });
  
      // Clean up the socket connection on component unmount
      return () => {
        socket.off('connect');
        socket.off('userDetails');
        socket.disconnect();
      };
},[loginData])

const fetch = async () => {
    try {
        if (loginData?.user_id) {  // Ensure loginData and user_id exist before making the request
            const response = await axiosInstance(`/user/login/deviceGet/${loginData.user_id}`);
           
            if(response.status===200){
                setData(response.data.Data)
            }
        }
    } catch (error) {
        console.error("Error fetching device history:", error);
    }
};





  const Navigate = useNavigate()
  const state=useSelector((state)=>state)

  console.log("mysatte",state)


  const handleProfile = () => {
    if (loginData.phone !== "" && loginData.name !=="" && loginData.email !=="") {
      Navigate('/profile')
    }
    else {
      Navigate('/contact',{state:{post:false}})
    }
  }


  const handlePost = () => {

    if (loginData.phone == "" && loginData.name =="" && loginData.email =="" ) {
      Navigate('/loginPost',{state:{post:true}})
    }
    else if (loginData.category === "" || loginData.category === null) {
      Navigate('/loginPostList')
    }
    else if (loginData.name !== "" && loginData.category !== "" && loginData.category !== null) {
     Navigate("/PostForm");

    }


  }

  const gradientStyle = {
    background: 'linear-gradient(to right, #9C55D3, #2D6B8E)',
    fontSize:"10px"

  };

  function handleservices() {
    Navigate('/services')
  }
  function handleHome() {
    Navigate('/')
  }

  const handleChatClick = () => {


    if (loginData.phone == "" || loginData.name =="" || loginData.email =="") {
      Navigate('/contact')
    }
    else {
      Navigate('/chatscreen');
    }

  };



  return (
    <div className=''>
      <div style={{display:"flex",alignItems:"center",    zIndex:999,    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)"}} className={`sm:fixed msm:fixed  msm:h-[6%] sm:h-[5%] md:h-[5%] lg:h-[8%] xl:h-[8%] top-0 w-full z-20 msm:bg-[#FFFFFF] sm:bg-[#1068b5] sm:block lg:block border-b-slate-400  `}>
        <ul style={{justifyContent:"space-between",display:"flex",width:"100%"}} className={` msm:p-2 lg:px-4 sm:px-2 sm:py-0 lg:py-0 xl:py- md:py-0  `}>
          <li onClick={handleHome} className="flex items-center">
            <img src={vattaraimg} className="w-40 h-11 msm:hidden sm:flex md:flex" alt="Vattara Image" />
            <img src={vattaraimg2} className='sm:hidden msm:flex w-30 h-6' />

            {/* <span className="ml-2 sm:font-bold lg:text-3xl md:text-xl  msm:hidden sm:flex  sm:text-white">Vattara</span> */}
          </li>

          <li className="flex  items-center gap- mt-1 sm:p-0 msm:p-2">
            <p onClick={handlePost} className='msm:bg-[#1068b5] py-1 cursor-pointer sm:text-black  sm:bg-[#F5F4F8] msm:text-white rounded-xl px-4 mr-5 msm:flex md:hidden sm:flex '> Post Ad</p>
            <button onClick={handlePost} className="text-[#121212] cursor-pointer msm:hidden sm:hidden md:flex font-semibold text-lg py-1 px-3 bg-[#F5F4F8] rounded-lg mr-5">
              Post Free Property Ad 
            </button >
            <li className="border h-9 text-white opacity-30 lg:flex"></li>

            <div onClick={handleservices} className=" cursor-pointer sm:flex msm:hidden  items-center relative sm:px-5 lg:px-5 ">

              <p className=" font-medium text-lg  mt-1 text-[#FFFFFF]  ">
                Services
              </p>
              <p  style={{
    ...gradientStyle, 
    marginTop: '-15px',
    padding: '0.25rem 0.5rem',
    borderRadius: '1.5rem',
    color: 'white', 
  }}  >NEW</p>
            </div>
            <li className="border h-9 text-white   opacity-30  lg:flex"></li>


           
            <div onClick={handleChatClick}
              className=' gap-2 items-center msm:hidden sm:flex  sm:px-5 lg:px-5 '>
              <img src={chat} />
              <p className='text-white cursor-pointer text-lg lg:flex sm:hidden'>Messages</p>
            </div>
            <div className='border-1 border-white h-9  msm:hidden sm:flex opacity-30  sm:mr-1'></div>

            <div onClick={handleProfile} className='cursor-pointer sm:flex msm:hidden justify-center items-center gap-2'>

              <AccountCircle className="text-white text-lg" />
              <span className="text-white capitalize text-lg font-medium" >{loginData.name !== "" ? loginData.name : "Login"}</span>
            </div>


          </li>
        </ul>
        {/* <div className='border-1 border-[#F5F5F5] shadow-xl msm:flex sm:hidden'></div> */}
      </div>

    </div>
  )
}

export default NavbarMain