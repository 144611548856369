// import React, { useState } from 'react'
// import Map from './Map'
// import Search from './Search'
// import Scroll from './Scroll'

// import Skeleton from './Skeleton'
// import Scrollmini from './Scrollmini'

// const Property = () => {
//   const[sortOpen,setSortOpen]=useState<any>(false)
//   const  [value,setValue]=useState<any>()
//   const [loading,setLoading]=useState<boolean>(false)
//   const [filterLoaction, setfilterLoaction] = useState<any[]>([])
//   const [selectedPostId, setSelectedPostId] = useState<number | null>(null);


//   return (


//     <div className=''>
//       {loading?
//       <Skeleton/>:
//       <Map setValue={setValue} setSortOpen={setSortOpen} sortOpen={sortOpen} setSelectedPostId={setSelectedPostId}  value={value}    >
// <Search sortOpen={sortOpen} setLoading={setLoading} filterLoaction={filterLoaction} setfilterLoaction={setfilterLoaction} setSortOpen={setSortOpen} setValue={setValue}/> 
// <Scroll selectedPostId={selectedPostId} value={value}/>
//     </Map>}</div>
//   )
// }

// export default Property


import React, { useState } from 'react';
import { useMediaQuery } from '@mui/material';
import Map from './Map';
import Search from './Search';
import Scroll from './Scroll';
import Skeleton from './Skeleton';
import Scrollmini from './Scrollmini';
import { Box } from '@mui/material';
import Mapmini from './mapmini';

const Property = () => {
  const [sortOpen, setSortOpen] = useState<any>(false);
  const [value, setValue] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [filterLoaction, setfilterLoaction] = useState<any[]>([]);
  const [selectedPostId, setSelectedPostId] = useState<number | null>(null);

  // Determine if the screen size is mobile
  const isMobile = useMediaQuery('(max-width:950px)');

  return (
    <Box>
      {loading ? (
        <Skeleton />
      ) : (
        <>
          {isMobile ? (
            // Render Scrollmini for mobile
<Mapmini
              setValue={setValue}
              setSortOpen={setSortOpen}
              sortOpen={sortOpen}
              setSelectedPostId={setSelectedPostId}
              value={value}
            >
              <Search
                sortOpen={sortOpen}
                setLoading={setLoading}
                filterLoaction={filterLoaction}
                setfilterLoaction={setfilterLoaction}
                setSortOpen={setSortOpen}
                setValue={setValue}
              />
                            <Scroll selectedPostId={selectedPostId} value={value} />

            </Mapmini>          ) : (
            // Render Map for desktop
            <Map
              setValue={setValue}
              setSortOpen={setSortOpen}
              sortOpen={sortOpen}
              setSelectedPostId={setSelectedPostId}
              value={value}
            >
              <Search
                sortOpen={sortOpen}
                setLoading={setLoading}
                filterLoaction={filterLoaction}
                setfilterLoaction={setfilterLoaction}
                setSortOpen={setSortOpen}
                setValue={setValue}
              />
              <Scroll selectedPostId={selectedPostId} value={value} />
            </Map>
          )}
        </>
      )}
    </Box>
  );
};

export default Property;
