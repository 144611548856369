import React, { useState } from 'react'
import editIcon from '../../assets/editForm.svg'
import { AdditionalDatails } from "./dataJson";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import AdditionalDatail from '../EditFrom/component/AdditionalDetails'
import { useSelector } from 'react-redux';


interface AdditionalDetails {
    bathroom: string;
    balcony: string;
    preferred_tenant_type: string;
    parking: string;
    carpet_area: string;
    facing: any
    servant_room: any
    eligible_for_loan: any
    property_description: any
    // Add other fields if necessary
}

interface User {
    name: string;
    mobile_no: string;
    category: string;
    company_logo: string;
    company_name: string;
}

interface PropertyImage {
    filename: string;
    id: number;
    isCover: boolean;
    name: string;
    type: string;
    uri: string;
}

interface Property {
    active: boolean;
    additional_details: AdditionalDetails;
    available_from: string;
    bhk: string;
    building_name: string;
    built_up_area: string;
    city: string;
    createdAt: string;
    delete_post_reason: string;
    district: string;
    enter_lease_amount: string;
    flat_furnishing: string;
    furnish_type: string;
    home_screen_post: boolean;
    id: number;
    images: PropertyImage[];
    kbl_property: boolean;
    landmark_street: string;
    latitude: string;
    liked: boolean;
    longitude: string;
    monthly_rent: string;
    negotiable: string;
    post_approval: string;
    post_id: string;
    propertyID: string;
    property_available_for: string;
    property_type: string;
    res_or_com: string;
    security_deposit: string;
    shareUrl: string;
    society_amenities: any; // Adjust type based on actual data or make it optional
    state: string | null;
    sublocality: string;
    tbl_user_reg: User;
    updatedAt: string;
    user_id: string;
    view_post_count: number;
    looking_to: any
    price: any
    cost: any
    lease_amount: any
    facing: any
    servant_room: any
    amenities: any
}

interface DetailsProps {
    passedData?: Property;
    setFinalValue: any
    PassedData?:Property
}



const EditAdditionalDetails: React.FC<DetailsProps> = ({ PassedData, setFinalValue }) => {
    // const passedData = useSelector((state: any) => state.usersInfo.users);
    // console.log("redux check add",passedData)

    const [formVisible, setFormVisible] = useState<any>(false)
    const [FullData, setFullData] = useState<any>(PassedData)
    
    // console.log("redux check ", passedData)



    const handleForm = () => {
        setFormVisible(true);

    };

    const closeModal = () => {
        setFormVisible(false);
 
    };



    const handleAdditional = () => {
        const fullData = FullData[0] || {};
        return Object.keys(AdditionalDatails).map((item) => {
            switch (item) {
                case "Bathroom":
                    return { [item]: fullData.additional_details?.bathroom || "" }
                case "Balcony":
                    return { [item]: fullData.additional_details?.balcony || "" }
                case "Preferred Tenant Type":
                    return { [item]: fullData.additional_details?.preferred_tenant_type || "" }
                case "Parking":
                    return { [item]: fullData.additional_details?.parking || "" }
                case "Carpet Area":
                    return { [item]: fullData.additional_details?.carpet_area || "" }
                case "Maintenance Charges":
                    return { [item]:removeSymbols( fullData.additional_details?.maintenance_charges|| "") }
                case "Facing":
                    return { [item]: fullData.additional_details?.facing || "" }
                case "Servant Room":
                    return { [item]: fullData.additional_details?.servant_room || "" }
                case "Property Description":
                    return { [item]: fullData.additional_details?.property_description || "" }
                case "Loan Availability":
                    return { [item]: fullData.additional_details?.eligible_for_loan || "" }
                case "AMENITIES":
                    return { [item]: fullData.amenities?.amenities || "" };
            }
        })

    }
    const removeSymbols = (value: string) => {
        // Remove any non-numeric characters except for dots (to keep decimals)
        return value.replace(/[^0-9.]/g, '');
    };
    const additional = handleAdditional()

    console.log("AdditionalDatails", additional)













    return (
        <div className='relative mt-3'>
        <div className={`' ${FullData[0].property_type === "Plot / Land" ? "hidden" : "block"}`}>
            <p className={`${FullData[0].property_type === "Independent House" || FullData[0].property_type === "Apartment" || FullData[0].property_type === "Villa" ? "flex text-xl font-semibold  text-[#2E368F] mb-3" : "hidden"}`}>Additional Details</p>
            <p className={`${FullData[0].res_or_com === "Commercial" ? "flex text-xl font-semibold mb-3 text-[#2E368F] " : "hidden"}`}>Amenities</p>
            <img onClick={handleForm} className='absolute right-0 top-0' src={editIcon} />
            <div>
                <div>
                    {FullData[0].res_or_com === 'Residential' &&
                        (FullData[0].property_type === 'Independent House' ||
                            FullData[0].property_type === 'Apartment' ||
                            FullData[0].property_type === 'Villa') &&
                        FullData[0].looking_to === 'Sale' && (
                            <div className="flex flex-col gap-2">
                                <div className='flex gap-2'>
                                    <p className="w-[50%] font-bold">Bathroom</p>
                                    <p className="font-medium w-[50%]">{FullData[0].additional_details.bathroom}</p>
                                </div>
                                <div className='flex gap-2'>
                                    <p className="w-[50%] font-bold">Balcony</p>
                                    <p className="font-medium w-[50%]">{FullData[0].additional_details.balcony}</p>
                                </div>
                                <div className='flex gap-2'>
                                    <p className="w-[50%] font-bold">Parking</p>
                                    <p className="font-medium w-[50%]">{FullData[0].additional_details.parking}</p>
                                </div>

                                <div className='flex gap-2'>
                                    <p className="w-[50%] font-bold">Carpet Area</p>
                                    <p className="font-medium w-[50%]">{FullData[0].additional_details.carpet_area}</p>
                                </div>
                                <div className='flex gap-2'>
                                    <p className="w-[50%] font-bold">Eligible For Home Loan?</p>
                                    <p className="font-medium w-[50%]">{FullData[0].additional_details.eligible_for_loan}</p>
                                </div>
                                <div className='flex gap-2'>
                                    <p className="w-[50%] font-bold">Facing</p>
                                    <p className="font-medium w-[50%]">{FullData[0].additional_details.facing}</p>
                                </div>
                                <div className='flex gap-2'>
                                    <p className="w-[50%] font-bold">Servant Room</p>
                                    <p className="font-medium w-[50%]">{FullData[0].additional_details.servant_room}</p>
                                </div>
                                <div className='flex gap-2'>
                                    <p className="w-[50%] font-bold">Property Description</p>
                                    <p className="font-medium w-[50%]">{FullData[0].additional_details.property_description}</p>
                                </div>
                            </div>
                        )}
                </div>

                <div>
                    {FullData[0].res_or_com === 'Residential' &&
                        (FullData[0].property_type === 'Independent House' ||
                            FullData[0].property_type === 'Apartment' ||
                            FullData[0].property_type === 'Villa') &&
                        (FullData[0].property_available_for === 'Rent' ||
                            FullData[0].property_available_for === 'Lease') && (
                            <div className="flex flex-col gap-2">
                                <div className='flex '>
                                    <p className="w-[50%] font-bold">Bathroom</p>
                                    <p className="font-medium w-[50%]">{FullData[0].additional_details.bathroom}</p>
                                </div>
                                <div className='flex '>
                                    <p className="w-[50%] font-bold">Balcony</p>
                                    <p className="font-medium w-[50%]">{FullData[0].additional_details.balcony}</p>
                                </div>
                                <div className='flex '>
                                    <p className="w-[50%] font-bold">Parking</p>
                                    <p className="font-medium w-[50%]">{FullData[0].additional_details.parking}</p>
                                </div>
                                <div className='flex '>
                                    <p className="w-[50%] font-bold">Preferred Tenant Type</p>
                                    <p className="font-medium w-[50%]">{FullData[0].additional_details.preferred_tenant_type}</p>
                                </div>
                                <div className='flex '>
                                    <p className="w-[50%] font-bold">Carpet Area</p>
                                    <p className="font-medium w-[50%]">{FullData[0].additional_details.carpet_area}</p>
                                </div>
                                <div className='flex '>
                                    <p className="w-[50%] font-bold">Maintenance Charges</p>
                                    <p className="font-medium w-[50%]">{FullData[0].additional_details?.maintenance_charges}</p>
                                </div>
                                <div className='flex '>
                                    <p className="w-[50%] font-bold">Facing</p>
                                    <p className="font-medium w-[50%]">{FullData[0].additional_details.facing}</p>
                                </div>
                                <div className='flex '>
                                    <p className="w-[50%] font-bold">Servant Room</p>
                                    <p className="font-medium w-[50%]">{FullData[0].additional_details.servant_room}</p>
                                </div>
                                <div className='flex '>
                                    <p className="w-[50%] font-bold">Property Description</p>
                                    <p className="font-medium w-[50%]">{FullData[0].additional_details.property_description}</p>
                                </div>
                            </div>
                        )}
                </div>
                {/* <div>
                    {passedData.res_or_com === "Commercial" &&
                        passedData.property_type === "Office Space" &&
                        (passedData.property_available_for === "Rent" ||
                            passedData.property_available_for === "Lease") && (
                            <div className='mt-2'>
                                <div className='flex gap-3'>
                                    <p>Amenities</p>
                                    <p>{passedData.amenities.amenities}</p>
                                </div>
                            </div>
                        )}
                </div> */}

                {/* <div>
                    {passedData.res_or_com === "Commercial" &&
                        passedData.property_type === "Warehouse" &&
                        (passedData.property_available_for === "Rent" ||
                            passedData.property_available_for === "Lease") && (
                            <div className='mt-2'>
                                <div className='flex gap-3'>
                                    <p>Amenities</p>
                                    <p>{passedData.amenities.amenities}</p>
                                </div>
                            </div>
                        )}
                </div> */}


                {/* <div>
                    {passedData.res_or_com === "Commercial" &&
                        passedData.property_type === "Industrial Space" &&
                        (passedData.property_available_for === "Rent" ||
                            passedData.property_available_for === "Lease") && (
                            <div className='mt-2'>
                                <div className='flex gap-3'>
                                    <p>Amenities</p>
                                    <p>{passedData.amenities.amenities}</p>
                                </div>
                            </div>
                        )}
                </div> */}

                {/* <div>
                    {passedData.res_or_com === "Commercial" &&
                        passedData.property_type === "Retail Shop" &&
                        (passedData.property_available_for === "Rent" ||
                            passedData.property_available_for === "Lease") && (
                            <div className='mt-2'>
                                <div className='flex gap-3'>
                                    <p>Amenities</p>
                                    <p>{passedData.amenities.amenities}</p>
                                </div>
                            </div>
                        )}
                </div> */}

                {/* 
                <div>
                {passedData.res_or_com === "Commercial" &&
                    passedData.property_type === "Office Space" &&
                    (passedData.looking_to === "Sale" 
                       ) && (
                            <div className='mt-2'>
                                <div className='flex gap-3'>
                                    <p>Amenities</p>
                                    <p>{passedData.amenities.amenities}</p>
                                </div>
                            </div>
                        )}
                </div> */}

                <div>
                    {FullData[0].res_or_com === "Commercial" &&
                        (FullData[0].property_type === "Warehouse" || FullData[0].property_type === "Office Space" || FullData[0].property_type === "Retail Shop" || FullData[0].property_type === "Industrial Space" || FullData[0].property_type === "Warehouse" || FullData[0].property_type === "Office Space" || FullData[0].property_type === "Showroom") &&
                        (FullData[0].looking_to === "Sale" || FullData[0].property_available_for === "Rent" || FullData[0].property_available_for === "Lease"
                        ) && (
                            <div className='mt-2'>
                                <div className='flex gap-3'>
                                    <p className="w-[50%] font-bold">Amenities</p>
                                    <p className="font-medium w-[50%]">{FullData[0].amenities.amenities}</p>
                                </div>
                            </div>
                        )}
                </div>






            </div>
            <div className='border-1 border-[#8E8E8E] mt-3 '></div>

            <Modal
                open={formVisible}
                onClose={closeModal}
                aria-labelledby="basic-modal-title"
                aria-describedby="basic-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: {md:"80%",lg:"50%" ,xs:"95%"},
                        maxHeight: '90vh',
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                        overflowY: 'auto',
                    }}
                >
                    <AdditionalDatail closeModal={closeModal} setFullData={setFullData} setFinalValue={setFinalValue}  AdditionalDatails={additional} />
                </Box>
            </Modal>

        </div>
        </div>
    )
}

export default EditAdditionalDetails