import React, { useState } from "react";

import { axiosInstance } from "../api/APIs";
import { useSelector } from "react-redux";
import { capitalizeWords } from "../data";
import { useDispatch } from "react-redux";
import { Box, Typography, CircularProgress } from "@mui/material";

import { setUsers } from "../../slices/filterSlice";
import { useNavigate } from 'react-router-dom'
import { useEffect } from "react";


interface CityImage {
  url: string;
  imagename: string;
}

interface CityData {
  id?: string | number;
  image?: CityImage;
  popular_districts?: string[];
  imagePath: string;
  value: string;
}


interface props {
  lookingToValue: any
  Clook:any
}


const Card: React.FC<props> = ({ lookingToValue,Clook }) => {
 
  const Navigate = useNavigate()
  const dispatch = useDispatch()
  const [cart, setCart] = useState<any[]>([])
  const [loadingDistrict, setLoadingDistrict] = useState<string | null>(null);

  const loginData = useSelector((state: any) => (state.userData.userLoginData))
  const [data, setData] = useState<any[]>([]);

  console.log(lookingToValue);
  
  useEffect(() => {
    const fetchData = async () => {
      console.log(lookingToValue)

      try {
        const response = await axiosInstance.get('/api/vattara/app/homescreen/get/districts');
        setData(response.data.message);
        console.log(response.data.message);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [lookingToValue,Clook]);

  async function handleCityFilter(value: string) {
    // const capitalizedValue = capitalizeWords(value);

    setLoadingDistrict(value);


    const url = `/filter/city/filter/Res_Sell/`;
    // console.log('Request URL:', url);

    const body: any = {
      user_id: loginData.user_id,
      district: value,
      rent_or_sell:lookingToValue

    };

    // console.log(body);

    try {

      const response = await axiosInstance.post(url,body);
      dispatch(setUsers(response.data.message))
      Navigate('/property')
      console.log(response.data.message);
    } catch (error) {
      console.error('Error fetching city filter data:', error);
    }
    finally {
      setLoadingDistrict(null);
    }
  }


  return (
    <Box className="xl:mx-[20%] mt-8 overflow-hidden">
    <Box className="relative lg:flex lg:w-full lg:mt-4">
      <div 
     className="md:text-3xl msm:text-2xl ml-3 md:ml-8   font-semibold text-[#202020] sl:flex sl:justify-start lg:justify-start lg:items-center lg:flex sl:items-center">
        Popular Places
      </div>
    </Box>

    <div className="sl:justify-center msm:text-2xl ml-3 md:ml-8 msm:font-semibold font sl:items-center lg:justify-start lg:flex lg:items-center mt-2 mb-4 text-[#404040]  font-medium lg:text-[20px] msm:text-sm lg:w-full">
      Explore {lookingToValue === "Commercial" ? "Commercial" : "Residential"} Properties for {lookingToValue === "Commercial" ? Clook : lookingToValue} in Tamil Nadu
    </div>

    <Box className="lg:w-full lg:h-full lg:flex lg:justify-center lg:items-center sl:h-52 sl:w-full sl:p-2 sl:gap-4 sl:ml-0">
      <Box className="mb-4 grid grid-cols-2 msm:gap-3 msm:mx-2 sm:mx-2 msm:grid-cols-2 lg:grid-cols-3 lg:gap-5">
        {data.length ? (
          data.map((item: any, index: number) => (
            <Box
              onClick={() => handleCityFilter(item.popular_districts)}
              key={index}
              className="relative flex flex-col items-center justify-center cursor-pointer"
            >
              <img
                className="rounded-2xl w-full sm:h-52 msm:h-28 object-cover"
                src={item.image.url}
                alt={item.image.imagename}
              />
              {loadingDistrict === item.popular_districts && (
                <Box className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-50 rounded-2xl">
                  <CircularProgress color="inherit" />
                </Box>
              )}
             <div
  className="absolute md:bottom-0 msm:bottom-0 rounded-2xl left-1/2 transform -translate-x-1/2 text-white text-lg font-bold p-2 w-full"
  style={{ backgroundImage: 'linear-gradient(180deg,  transparent,#222)' ,justifyContent:"center",display:"flex"}}
>
  {item.popular_districts}
</div>

            </Box>
          ))
        ) : (
          <div>Loading...</div>
        )}
      </Box>
    </Box>
  </Box>
  );
};

export default Card;


