import React from 'react';
import image1 from "../images/ser1.svg";
import image2 from "../images/ser2.svg";
import image3 from "../images/ser3.svg";

import {  getResponsiveSettings  } from "../ReuseableCompounts/CarouselResponsive";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const ImageCarousel = () => {
  // Step 1: Store the images in an array
  const images = [
    { src: image1, alt: "Image 1" },
    { src: image2, alt: "Image 2" },
    { src: image3, alt: "Image 3" },
   
  ];



  const responsiveSettings = getResponsiveSettings(2.2, 2, 1);


  return (
    <div className='mx-2 xl:mx-0'>
      <Carousel
        swipeable
        draggable
        showDots={false}
        responsive={ responsiveSettings }
        ssr // means to render carousel on server-side.
        infinite
        autoPlay
        autoPlaySpeed={3000}
        keyBoardControl
        customTransition="transform 500ms ease-in-out"
        transitionDuration={500}
        
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {/* Step 2: Use .map() to render the images */}
        {images.map((image, index) => (
          <div key={index} className='mx-1'>                    
            <img className="w-[100vw] h-full object-cover rounded-lg" src={image.src} alt={image.alt} />
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default ImageCarousel;
