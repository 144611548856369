import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { ErrorMessage, Form, Formik } from 'formik';
import CustomisedButton from './CustomisedButton';
import { useStepperContext } from './StepperContext';
import ApartmentIcon from "@mui/icons-material/Apartment";
import BusinessIcon from "@mui/icons-material/Business";
import TextBox from './TextBox';
import { basic_details } from '../data'
import DropBox from './DropBox';
import DropDownBox from './DropDownBox';
import CustomisedButton1 from './CustomisedButton1';
import { tndistrict } from './index';
import { datafinder } from '../../PostForm/component';
import { useDispatch,useSelector } from 'react-redux';
import { setUsers } from '../../../slices/filterSlice';
import { MdArrowBackIosNew } from "react-icons/md";


const icons = {
  ApartmentIcon: <ApartmentIcon />,
  BusinessIcon: <BusinessIcon />
};

interface NextProps {
  onNext: () => void
  onBack: () => void
  onReset: () => void
  basicDetails: any;
  passedData?:any
  setFullData:any
  setFinalValue:any
  FullData:any
  closeModal:any
  

}

interface InitialValues {
  [key: string]: string;
}

const Basic: React.FC<NextProps> = ({ onNext,FullData, onBack, onReset,setFinalValue, basicDetails,setFullData,closeModal }) => {
  const { userData, location, } = useStepperContext(); // Access currentPage from StepperContext
  const [userselectedData, setuserSelectedData] = useState([])
  const passedData = useSelector((state: any) => state.usersInfo.users);
  const [dataB,setDataB]=useState(basicDetails)
  const [mypass,setMypass]=useState(passedData)
  const dispatch = useDispatch();
  const generateValidationSchema = (basic_details: any, userData: any) => {

    console.log("lat long",location)
    // Filter required labels from form fields
    const requiredLabels = basic_details.fields.filter((field: any) => field.required).map((field: any) => field.label);
    console.log('require', requiredLabels)
    // const missingLabels=requiredLabels
    // console.log('Missing Labels:', missingLabels);
    const existingLabels = requiredLabels.filter((label: any) => (
      // Check if any object in userData has a property matching the current label
      !userData.some((item: any) => item.hasOwnProperty(label))

    ));
    console.log('exist', existingLabels)

    if (existingLabels.length > 0) {
      setuserSelectedData(existingLabels)
    }
    else {
      // onNext()
    }
  };

  const transformValue = (value: any) => {
    if (value === 'Rent' || value === 'Lease') {
      return 'Rent/Lease';
    } else if (value === 'Sale') {
      return 'Sell';
    } else {
      return value; // or return a default value if needed
    }
  }

  // const initialValues = {
  //   selectedPropertyType: '',
  //   lookingTo: '',
  //   city: '',
  //   locality: '',
  //   landMark: '',
  // };

  const getInitialValues = (basicDetails: any): InitialValues => {
    const initialValues: InitialValues = {};

    basicDetails.forEach((detail: any) => {
      const key = Object.keys(detail)[0];
     const value = detail[key] ||""
     initialValues[key] =transformValue(value)
    });

    return initialValues;
    
  };

  const initialValues = getInitialValues(basicDetails);
  console.log(initialValues)


  return (
    <div className='w-[100%] gap-4'>
      {/* <p>Current Page: {currentPage}</p> */}
      <div className='flex items-center gap-2'>
      <MdArrowBackIosNew onClick={ closeModal}  className=' w-8 h-8  z-40 lg:hidden md:flex' />
      <Typography>Basic Details</Typography>
      </div>
     
     

      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
         
          console.log("check001",dataB);

          const update = mypass.map((item:any) => ({
            ...item,
            district: datafinder(dataB as any,"City" ),
            city:datafinder(dataB as any,"Locality" ),
            landmark_street:datafinder(dataB as any,"Landmark" ),
            latitude:datafinder(dataB as any ,"latitude"),
            longitude:datafinder(dataB as any,'longitude'),
            sublocality:datafinder(dataB as any,'sublocality'),

          }));
          console.log("for",update)
          setMypass(update)
          console.log("fedfcew",mypass)
          dispatch(setUsers(update));
          setFullData(update)
          setFinalValue(mypass)
          closeModal()
          
          
          console.log("check for arry basic",FullData)

          
        }}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit} className='form'>
            {basic_details.fields.map((basicItem, basicIndex) => (
              <div key={basicIndex}>
                {basicItem.type === 'button' && (
                  <div className='my-4'>
                    <Typography sx={{ marginBottom: "12px" }} className='text-start gap-28'>{basicItem.label}</Typography>
                    <div className='flex md:flex-row gap-24 justify-start'>
                      <CustomisedButton1
                        data={basicItem.data}
                        label={basicItem.label}
                        initial={initialValues[basicItem.label]}
                        inputId={basicItem.inputId}
                        formName={'Basic Details'}
                        required={basicItem.required}
                        userselectedData={userselectedData}
                      />
                    </div>
                  </div>
                )}
                {basicItem.type === 'DropDownBox' && (
                  <div className='w-[100%]'>
                    <Typography sx={{ marginY: "12px" }} className='text-start w-[100%]'>{basicItem.label}</Typography>
                    <DropDownBox
                      label={basicItem.label}
                      options={basicItem.data}
                      inputId={basicItem.inputId}
                      baseData={dataB}
                      initial={initialValues[basicItem.label]}
                      formName={'Basic Details'}
                      required={basicItem.required}
                      userselectedData={userselectedData}
                    />
                  </div>
                )}
                {basicItem.type === 'DropDown' && (
                  <div>
                    <Typography sx={{ marginTop: "16px" }} className='text-start w-[100%]'>{basicItem.title}</Typography>
                    <DropBox
                      label={basicItem.label}
                      options={tndistrict.districts}
                      inputId={basicItem.inputId}
                      formName={'Basic Details'}
                      baseData={dataB}
                      setDataB={setDataB}
                      initial={initialValues[basicItem.label]}
                      required={basicItem.required}
                      userselectedData={userselectedData}
                    />
                  </div>
                )}
                {basicItem.type === 'textField' && (
                  <div className='my-4'>
                    <TextBox
                      label={basicItem.label}
                      inputId={basicItem.inputId}
                      baseData={dataB}
                      setDataB={setDataB}
                      formName={'Basic Details'}
                      initial={initialValues[basicItem.label]}
                      required={basicItem.required}
                      userselectedData={userselectedData}
                    />
                  </div>
                )}
              </div>
            ))}
            <Button variant='contained'  type="submit">Continue</Button>
          </Form>
        )}
      </Formik>

    </div>
  );
};

export default Basic;





