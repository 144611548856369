import React, { useEffect, useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import image1 from '../images/login-2.svg';
import image2 from '../images/log-1.svg';
import OtpInput from 'react-otp-input';
import { axiosInstance } from "../api/APIs";
import { useDispatch } from "react-redux";
import { setUpdateToken, setUpdateEncryptedToken, setUserLoginData, setUser_Id } from '../../slices/userDataSlice';
import { useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';

import { Box, IconButton } from '@mui/material';


interface ModalProps {
    onClose: () => void;
    phone?: string;
    setShowVerification?: (value: string) => void;
    setUserId?: (id: any) => void;
    setToken?: any
    setEncryptedToken?: any
}

const Verification: React.FC<ModalProps> = ({ onClose, phone, setShowVerification, setUserId, setToken, setEncryptedToken }) => {
    const [otp, setOtp] = useState('');
    const [counter, setCounter] = useState(30);
    const [canResend, setCanResend] = useState(false);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [alart, setAlart] = useState<boolean>(false)
    const [MyId, setMyId] = useState(0)
    const [browser, setBrowser] = useState('');
    const [os, setOS] = useState('');
    const [wholeObject,setWholeObject] =useState<any>("")

    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase();

        // Detecting Operating System
        let osName = 'Unknown OS';
        if (userAgent.includes('android')) {
            osName = 'Android';
        } else if (userAgent.includes('win')) {
            osName = 'Windows';
        } else if (userAgent.includes('mac')) {
            osName = 'macOS';
        } else if (userAgent.includes('linux')) {
            osName = 'Linux';
        } else if (userAgent.includes('iphone') || userAgent.includes('ipad')) {
            osName = 'iOS';
        }

        // Detecting Browser
        let browserName = 'Unknown Browser';
        if (userAgent.includes('chrome') && !userAgent.includes('edg')) {
            browserName = 'Google Chrome';
        } else if (userAgent.includes('firefox')) {
            browserName = 'Mozilla Firefox';
        } else if (userAgent.includes('safari') && !userAgent.includes('chrome')) {
            browserName = 'Apple Safari';
        } else if (userAgent.includes('edg')) {
            browserName = 'Microsoft Edge';
        } else if (userAgent.includes('opr') || userAgent.includes('opera')) {
            browserName = 'Opera';
        } else if (userAgent.includes('trident')) {
            browserName = 'Internet Explorer';
        }

        setOS(osName);
        setBrowser(browserName);
        generateId()
    }, []);

    const generateId = () => {
        const min = 100000;
        const max = 999999;
        const digits = Math.floor(min + Math.random() * (max - min + 1));
        setMyId(digits)
    };


    let lastFourDigits = phone?.slice(-4);

    useEffect(() => {
        if (otp.length === 4) {
            tiggerApi();
        }
    }, [otp]);

    useEffect(() => {
        if (counter === 0) {
            setCanResend(true);
            return;
        }
        const timer = setInterval(() => {
            setCounter((prevCounter) => prevCounter - 1);
        }, 1000);
        return () => clearInterval(timer);
    }, [counter]);

    const tiggerApi = async () => {
        setWholeObject({ id: MyId, os: os, name: browser, last_login: new Date() })
        const body: any = {
            mobile_no: phone,
            otp: otp,
            device_name: { id: MyId, os: os, name: browser, last_login: new Date() }

        }; 
        console.log("mobile",body)

        try {
            const response = await axiosInstance.post("/user/user/OtpVerify", body);
            console.log('Response:', response.data);
            const verf = response.data
            if (verf.message === "Valid OTP") {
                if (verf.userName !== "") {
                    const reduxSent = {
                        category: verf.userCategory,
                        token: verf.encryptedToken,
                        encryptedToken: verf.encryptedToken,
                        name: verf.userName,
                        phone: verf.userMobileno,
                        email: verf.userEmail,
                        company_logo: verf.company_logo,
                        company_name: verf.company_name,
                        lang: verf.lang,
                        user_id: verf.UserId,
                        Id: MyId





                    }
                    dispatch(setUserLoginData(reduxSent))
                     navigate("/")

                }
                else {
                    if (setUserId) setUserId(response.data.UserId);
                    if (setShowVerification) setShowVerification("tick");
                    setToken(response.data.token)
                    setOtp("");
                    setEncryptedToken(response.data.encryptedToken)
                    dispatch(setUpdateToken(response.data.token));
                    dispatch(setUpdateEncryptedToken(response.data.encryptedToken));


                }
            } else if (verf.message === "Device limit reached") {
                const body = {
                    token: '',
                    Id: null,
                    encryptedToken: '',
                    user_id:  response.data.Userid,
                    name: "",
                    phone:phone,
                    email: "",
                    company_logo: "",
                    company_name: "",
                    lang: "",
                    category: "",
                }
                console.log("userid", response.data.user_id)
                dispatch(setUserLoginData(body))
                navigate('/loginhistory', {
                     state: { 
                    id: MyId, 
                    os: os, 
                    name: browser, 
                    last_login: new Date() 
                } })
            }
            else {
                setAlart(true)
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setOtp("");
        }
    };

    const handleResendOTP = async () => {
        // Logic to resend OTP goes here
        console.log("Resend OTP");

        // Reset counter and disable resend button
        setCounter(30);
        setCanResend(false);

        // Call the API to resend OTP
        try {
            await axiosInstance.post("/user/ResendOtp", { mobile_no: phone });
            console.log('OTP resent successfully');
        } catch (error) {
            console.error('Error resending OTP:', error);
        }
    };

    return (
        <Box
        sx={{
          display: 'flex',
          // display:"flex",
          alignItems: 'center',
          justifyContent:"center",
          position: 'absolute',
          height:"100%",
          width:"100%",
    
          zIndex: 10,
        }}
      >
        <Box
          sx={{
            bgcolor: 'white',
          //   border: '0.5px solid',
            borderColor: 'gray.300',
            borderRadius: '20px',
            mx: { xs: 4, md: 10 }, // Responsive margins
            overflowY: "hidden",
            width: { xs: '90vw', sm: '70vw', md: '100vw', lg: '70vw', xl: '60vw' },
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            position: 'relative',
            boxShadow: 3, // Add box shadow here
  
          }}
        >
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row',height:{xs:"300px",sm:"300px",md:"400px",lg:"400px",xl:"500px"}, }}> 
                                   <Box sx={{width:"10%",display:{xs:"none",sm:"none",md:"none",lg:"flex"}}}>
              
                        <div className=" z-10">
                              <div className="absolute md:-top-[10%] md:-left-[10%] lg:-left-[10%]  sm:-top-[12%] sm:-left-[12%] msm:-top-[20%] msm:-left-[20%] md:w-56 md:h-56  rounded-full" style={{ backgroundColor: "#1063b5" }}></div>
  
                          </div>
              </Box>
              <Box sx={{width:{xs:"100%",sm:"100%",md:"100%",lg:"45%"},height:"100%",justifyContent:"center",display:"flex",alignItems:"center",flexDirection:"column"}}>
              <IconButton
onClick={onClose}                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  display:{xs:"flex",sm:"flex",md:"none",lg:"none"},
                  color: 'gray.500',
                }}
              >
                <CloseIcon />
              </IconButton>  
                              <Box sx={{ '& > :not(style)': { m: 1 } ,width:"100%",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}} className=''>
             
                              <div className="flex-1 w-80 flex md:items-start sm:items-center flex-col  justify-center ml-[5%] mt-[11%] gap-2.5">
                          {/* <div className="absolute right-4 top-3 text-gray-500 text-2xl font-bold cursor-pointer z-20 md" onClick={onClose}>&times;</div> */}
                          <h4 className="relative z-20  font-extrabold mb-3">Enter Verification Code</h4>
                          <div className="">
                              <p className="w-[75%  font-normal "> <span> send to your mobile Number ********{lastFourDigits}</span></p>
                          </div>
                          <OtpInput
                              containerStyle={{  marginTop: "12px" }}
                              value={otp}
                              onChange={setOtp}
                              numInputs={4}
                              shouldAutoFocus={true} // Auto-focus the first input
  
                              inputType="tel" // Use 'tel' for numeric input
                              renderSeparator={<span></span>}
                              renderInput={(props) => <input {...props} />}
                              inputStyle={{
                                  backgroundColor: "#0CB7D1",
                                  height: "45px",
                                  width: "45px",
                              
                                  borderRadius: "10px",
                                  marginLeft: "10px",
                                  alignItems: "center",
                              }}
                             
                          />
                                                  {alart && <p className='text-red-600 '>Please enter the valid OTP</p>}
  
                          <div className='sm:mb-3 msm:mb-3 md:mb-0'>
                              {canResend ? (
                                  <h1 className='mt-6 ' style={{ fontWeight: "bold" }}>Don't receive the OTP? <span style={{ color: "#1063b5", cursor: "pointer" }} onClick={handleResendOTP}>RESEND OTP</span></h1>
                              ) : (
                                  <h1 className='mt-6 ' style={{ fontWeight: "bold" }}>Resend OTP in {counter} seconds</h1>
                              )}
                          </div>
                      </div>               </Box>    </Box>
                  <Box sx={{width:"45%",height:"100%",display:{xs:"none",sm:"none",md:"none",lg:"flex"},flexDirection:"column",justifyContent:"end",alignItems:"end"}}>
                
          <Box sx={{flexDirection:"row",display:"flex",justifyContent:"end",alignItems:"end"}}>
          <IconButton
onClick={onClose}                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  color: 'gray.500',
                }}
              >
                <CloseIcon />
              </IconButton>   <Box sx={{marginX:"20px"}} >
                  <img src={image1} alt='img'  />
                              </Box>
                              <Box sx={{marginX:"20px",marginTop:"10px"}} >
                              <img src={image2} alt='img' />
                              </Box> </Box>
                  </Box>
          </Box>
                      {/* <div className="flex flex-row h-full p-0">
                        
                          <div className="flex-1 w-80 flex md:items-start sm:items-center flex-col  justify-center ml-[5%] mt-[11%] gap-2.5">
                              <div className="absolute right-4  top-3 text-gray-500 text-2xl font-bold cursor-pointer z-20 md"     onClick={() => navigate(-1)} // Inline navigation logic
                              >&times;</div>
                            
                          </div>
                        
                      </div> */}
            </Box>
            </Box>
    );
};

export default Verification;
