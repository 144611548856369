// import React, { useState } from 'react'
// import { useStepperContext } from './StepperContext'
// import { MenuItem, TextField } from '@mui/material'
// interface DropDown1Props{
//     label:string,
// inputId:number,
//     formName:string,
//     options:any[],
//     title:any[]


// }

// const DropDown1:React.FC = ({label,inputId,formName,options,title}) => {
//     const {addUserData,userData}=useStepperContext()
//     const [selectedValue, setSelectedValue] = useState<string>('');
//      const [isCustom, setIsCustom] = useState<boolean>(false);


//     // const deposit=userData.find((item)=>item?.[""])?.[""])


//      const textFieldWidth = label === 'Select'? '50%' : '100%';
     

 


//     const handleChange=(event: React.ChangeEvent<HTMLInputElement | { value: unknown }>)=>{

//         const isSelected=event.target.value
//         setSelectedValue(isSelected)


//         if (isSelected === 'custom') {
//             // Handle custom input case
//             setIsCustom(true);
//         } else {
//             // Handle regular selection
//             setSelectedValue(isSelected);
//             const data = {
//                 id: inputId,
//                 [label]: selectedTitle,
//                 formName: formName,
//             };
//             addUserData(data);
//         }




//         // const data={
//         //     id:inputId,
//         //     [label]:event.target.value,
//         //     formName:formName
//         // }
//         // addUserData(data)
//     }

    
//         const handleCustomInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//         const customValue = event.target.value;
//         const data = {
//             id: inputId,
//             [label]: customValue,
//             formName: formName,
//         };
//         addUserData(data);
       
//     };

//   return (
//     <div >
//         <TextField variant='standard' select id={label} label={label} onChange={handleChange} sx={{width:textFieldWidth,display:"flex"}}>

//                {options.map((option,index)=>(
//             <MenuItem key={index} value={option.title}>{option.title}</MenuItem>
//            ))}
//             <MenuItem value="Custom">Custom</MenuItem>


//         </TextField>
//          {isCustom && (
//                 <TextField
//                     variant="standard"
//                     id={`${label}-custom-input`}
//                     label="Custom Value"
//                     value={selectedValue}
//                     onChange={handleCustomInputChange}
//                     // onBlur={handleCustomInputBlur}
//                     fullWidth
//                     sx={{ marginTop: '8px' }}
//                 />
//             )}
//     </div>
//   )
// }

// export default DropDown1

import React, { useEffect, useState } from 'react';
import { TextField, MenuItem } from '@mui/material';
import { useStepperContext } from './StepperContext'; // Import your context hook
import { number } from 'yup';
import { keyboard } from '@testing-library/user-event/dist/keyboard';
import { updateValueInArray } from '../../PostForm/component';

interface DropDown1Props {
  label: string;
  inputId: number;
  formName: string;
  options: { title: string }[];
  title?: string;
  required:boolean,
  userselectedData?:any
  initial:any
  baseData:any
  setDataB:any
}

const DropDown1: React.FC<DropDown1Props> = ({
  label,
  inputId,
  formName,
  options,
  title,
  baseData,
  setDataB,
  required,userselectedData,initial
}) => {
  const { addUserData } = useStepperContext();
  const [selectedValue, setSelectedValue] = useState<string>('');
  const [value,setValue]=useState<number>()
  const [isCustom, setIsCustom] = useState<boolean>(false);

  const textFieldWidth = label === 'Select' ? '50%' : '100%';

  console.log(label,initial)

  useEffect(()=>{
    const isNumeric = /^[0-9]+$/.test(initial);
if(isNumeric){
setIsCustom(true)
setSelectedValue('Custom')
setValue(initial as any)
}else{
  setSelectedValue(initial)
}
  },[initial])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | { value: unknown }>) => {
    const isSelected = event.target.value as string;


    const updatedData = updateValueInArray(baseData, label, isSelected);

    console.log("new", updatedData)
    setDataB(updatedData)


    if (isSelected === 'Custom') {
      // Handle custom input case
      setIsCustom(true);
      setSelectedValue(isSelected)
    } else {
      // Handle regular selection
      setIsCustom(false); // Reset isCustom to false for regular selections
      setSelectedValue(isSelected);
      const data = {
        id: inputId,
        [label]: isSelected,
        formName: formName,
        required:required
      };
      addUserData(data as any);
    }
  };

  const handleCustomInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const customValue = event.target.value;
    console.log("helo value custom ",customValue)
    setValue(Number(customValue));
    const updatedData = updateValueInArray(baseData, label, customValue);

    console.log("new", updatedData)
    setDataB(updatedData)
    const data = {
      id: inputId,
      [label]: customValue,
      formName: formName,
    };
    addUserData(data as any);
  };

  const hasError=userselectedData?.includes(label)



  return (
    <div>
      <TextField
        variant="standard"
        select
        id={label}
        label={label}
        defaultValue={initial}
        value={selectedValue}
        onChange={handleChange}
        sx={{ width: textFieldWidth, display: 'flex' }}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option.title}>
            {option.title}
          </MenuItem>
        ))}
        <MenuItem  value="Custom">Custom</MenuItem>
      </TextField>
      {isCustom && (
        <TextField
          variant="standard"
          id={`${label}-custom-input`}
          label="Custom Value"
          type="number"
          value={value}
          onChange={handleCustomInputChange}
          fullWidth
          sx={{ marginTop: '8px' }}
        />
      )}
      {hasError && <div>This field is required</div>}
    </div>
  );
};

export default DropDown1;

