import * as React from "react";

interface SVGComponentProps extends React.SVGProps<SVGSVGElement> {}

const BackComponent: React.FC<SVGComponentProps> = (props) => (
  <svg
    width={14}
    height={20}
    viewBox="0 0 14 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 2L2 10L12 18"
      stroke="#202020"
      strokeWidth={2.8125}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BackComponent;
