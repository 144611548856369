import overview1 from './images/overview1.png';
import overview2 from './images/overview2.png';
import overview3 from './images/overview3.png';
import overview4 from './images/overview4.png';
import overview5 from './images/overview5.png';
import overview6 from './images/overview6.png';
import ac from "./furnishings/Ac.png";
import cctv from "./furnishings/CCTV.png";
import club from "./furnishings/Club.png";
import cupboard from "./furnishings/Cupboard.png";
import diningtable from "./furnishings/Diningtable.png";
import fridge from "./furnishings/Fridge.png";
import garden from "./furnishings/Garden.png";
import gas from "./furnishings/Gas.png";
import gym from "./furnishings/Gym.png";
import hall from "./furnishings/Hall.png";
import intercom from "./furnishings/Intercom.png";
import kids from "./furnishings/Kids.png";
import lift from "./furnishings/Lift.png";
import microwave from "./furnishings/Microwave.png";
import pets from "./furnishings/Pets.png";
import pool from "./furnishings/Pool.png";
import sofa from "./furnishings/Sofa.png";
import sports from "./furnishings/Sports.png";
import stove from "./furnishings/Stove.png";
import television from "./furnishings/Television.png";
import water from "./furnishings/Water.png";
import waterpurifier from "./furnishings/Waterpurifier.png";
import washingmachine from "./furnishings/Washingmachine.png";
import Cafeteria from "./Amenities/Cafeteria.png";
import Furnishing from "./Amenities/Furnishing.png";
import Cctv from "./Amenities/CCTV.png";
import Ups from "./Amenities/UPS.png";
import WaterSupply from "./Amenities/Water_Supply.png";
import Power from "./Amenities/Power_Back_Up.png";
import Vastu from "./Amenities/Vastu_Complaint.png";
import Oxygen from "./Amenities/Oxygen_Duct.png";
import Water_Storage from "./Amenities/Water_storage.png";
import Security from "./Amenities/Security.png";
import Internet_Connectivity from "./Amenities/internet.png";
import Air_Conditioning from "./Amenities/Conditioning.png";
import Fire_sensors from "./Amenities/Fire_Sensors.png";
import Certificate from "./Amenities/Certified.png";
import Pantry from "./Amenities/Pantry.png";
import Fire from "./Amenities/Fire.png";
import Fire_NOC_Certified from "./Amenities/Certified.png";
import DG_Availability from "./Amenities/DG_Availability.png";
import Reception_Area from "./Amenities/Reception_Area.png";
import Community_Hall from "./Amenities/Community_Hall.png";
import Kids_Area from "./Amenities/Kids_Area.png";
import Sports from "./Amenities/Sports.png";
import Swimming_Pool from "./Amenities/Swimming_Pool.png";
import Lift from "./Amenities/Lift.png";
import Gym from "./Amenities/Gym.png";
import Gated from "./Amenities/Gated.png";
import Intercom from "./Amenities/Intercom.png";
import Pets from "./Amenities/Pets.png";
import Garden from "./Amenities/Garden.png";
import Club from "./Amenities/Club.png";
import carouselImage1 from "../assets/carouselImage1.svg";
import carouselImage2 from "../assets/carouselImage2.svg";
import carouselImage3 from "../assets/carouselImage3.svg";
import carouselImage4 from "../assets/carouselImage4.svg";
import carouselImage5 from "../assets/carouselImage4.svg";
import projectImg1 from "../assets/image.svg";
import projectImg2 from "../assets/image2.svg";
import projectImg3 from "../assets/image3.svg";
import projectImg4 from "../assets/image4.svg";
import projectImg5 from "../assets/image5.svg";
import Services1 from "../compounts/images/Services1.png";
import Services2 from "../compounts/images/Services2.png";
import Services3 from "../compounts/images/Services3.png";
import Services4 from "../compounts/images/Services4.png";
import Services5 from "../compounts/images/Services5.png";
import Services6 from "../compounts/images/Services6.png";
import { createAsyncThunk } from '@reduxjs/toolkit';

export const reportReasons = [
  {
    reason: "Incorrect Rented/Sold Through Other Platforms",
    description: "The listing contains false or misleading information",
    value: "Incorrect Rented/Sold Through Other Platforms",
  },
  {
    reason: "Rented/Sold Through Our Service Listing",
    description: "This property is listed more than once",
    value: "Rented/Sold Through Our Service Listing",
  },
  {
    reason: "No Longer Available for Rent/Sale Content",
    description: "The listing contains inappropriate or offensive content",
    value: "No Longer Available for Rent/Sale Content",
  },
 
  {
    reason: "Other Reason (Please Specify)",
    description: "(Please specify)",
    value: "other",
  },
];


const projects = [
  {
    coverImage: carouselImage1,
    images: [projectImg1, projectImg2, projectImg3, projectImg4, projectImg5],
    id: "0owjhfqw1230jwffkp",
    name: "Project 1",
  },
  {
    coverImage: carouselImage2,
    images: [projectImg1, projectImg2, projectImg3, projectImg4, projectImg5],
    id: "nykcwefjwpfj23",
    name: "Project 2",
  },
  {
    coverImage: carouselImage3,
    images: [projectImg1, projectImg2, projectImg3, projectImg4, projectImg5],
    id: "fwehfw213qwdff0we",
    name: "Project 3",
  },
  {
    coverImage: carouselImage4,
    images: [projectImg1, projectImg2, projectImg3, projectImg4, projectImg5],
    id: "fow123ffwetw311",
    name: "Project 4",
  },
  {
    coverImage: carouselImage5,
    images: [projectImg1, projectImg2, projectImg3, projectImg4, projectImg5],
    id: "ciwbfi31ffof0",
    name: "Project 5",
  },
];

export default projects;
interface OverviewTemplateItem {
  label: string;
  key: string;
  image: string;
}

export function calculatePossessionKey(data: any) {
  
  return data.possession_status ? "possession_status" : data.available_from ? "available_from" : "construction_status";
}

// export function calculateBuild(data: any) {
//   return data.build_up_area ? "build_up_area" : "built_up_area";
// }

export function getOverviewTemplate(data: any): OverviewTemplateItem[] {
  return [
    { label: "Built up Area", key:"built_up_area", image: overview1 },
    { label: "Property Type", key: "property_type", image: overview2 },
    { label: "Possession", key: calculatePossessionKey(data), image: overview3 },
    { label: "Posted On", key: "createdAt", image: overview4 },
    { label: "Negotiable", key: "negotiable", image: overview5 },
    { label: "Furnish Type", key: "furnish_type", image: overview6 },
  ];
}

interface PropertyDetailItem {
    label: string;
    key: string;
    subKeys?: string[]; 
  }

export const PropertDetailsData: PropertyDetailItem[] = [
  { label: 'Bathroom', key: 'bathroom' },
  { label: 'Balcony', key: 'balcony' },
  { label: 'Parking', key: 'parking' },
  { label: 'Preferred Tenant Type', key: 'preferred_tenant_type' },
  { label: 'Carpet Area', key: 'carpet_area' },
  { label: 'Facing', key: 'facing' },
  { label: 'Maintenance charges', key: 'maintenance_charges' },
  { label: 'Servant Room', key: 'servant_room' },
  { label: 'Security Deposit', key: 'security_deposit' },
  { label: 'Dimension (in ft)', key: 'dimension', subKeys: ['length', 'width'] },
  { label: 'Approval Status', key: 'approval_status' },
  { label: 'Patta Available', key: 'patta_available' },
  { label: 'Eligible For Home Loan?', key: 'eligible_for_loan' },
  { label: 'Floors', key: 'select_your_floor' },
  { label: 'Lifts', key: 'lifts' },
  { label: 'Seats', key: 'seats', subKeys: ['min_num_seat', 'max_num_seat'] },
  { label: 'Washroom', key: 'washroom' },
  { label: 'Meeting Room', key: 'meeting_room' },
  { label: 'Property Condition', key: 'property_condition' },
  { label: 'Suitable For', key: 'Suitable_for' },
];

interface FurnishingItem {
  label: string;
  image: string;
  key: string;
}

export const Furnishings: FurnishingItem[] = [
  { label: "Ac", image: ac, key: "AC" },
  { label: "Dining Table", image: diningtable, key: "Dining Table" },
  { label: "Microwave", image: microwave, key: "Microwave" },
  { label: "Sofa", image: sofa, key: "Sofa" },
  { label: "Stove", image: stove, key: "Stove" },
  { label: "Cupboard", image: cupboard, key: "Cupboard" },
  { label: "Fridge", image: fridge, key: "Fridge" },
  { label: "Television", image: television, key: "Television" },
  { label: "Water", image: water, key: "Water" },
  { label: "Water Purifier", image: waterpurifier, key: "Water Purifier" },
  { label: "Washing Machine", image: washingmachine, key: "Washing Machine" },
  { label: "Gas Pipe", image: gas, key: "Gas Pipe" },
];

interface AmenityItem {
  label: string;
  image: string;
  key: string;
}

export const dataAmenities: AmenityItem[] = [
  { label: "Furnishing", image: Furnishing, key: "Furnishing" },
  { label: "CCTV", image: Cctv, key: "CCTV" },
  { label: "UPS", image: Ups, key: "UPS" },
  { label: "Water Supply", image: WaterSupply, key: "Water Supply" },
  { label: "Power backup", image: Power, key: "Power backup" },
  { label: "Power Back Up", image: Power, key: "Power Back Up" },
  { label: "Cafeteria", image: Cafeteria, key: "Cafeteria" },
  { label: "Vastu", image: Vastu, key: "Vastu" },
  { label: "Oxygen Duct", image: Oxygen, key: "Oxygen Duct" },
  { label: "Water Storage", image: Water_Storage, key: "Water Storage" },
  { label: "Security Personnel", image: Security, key: "Security Personnel" },
  { label: "Internet Connectivity", image: Internet_Connectivity, key: "Internet Connectivity" },
  { label: "Central Air Conditioning", image: Air_Conditioning, key: "Central Air Conditioning" },
  { label: "Fire sensors", image: Fire_sensors, key: "Fire sensors" },
  { label: "Fire NOC Certified", image: Fire_NOC_Certified, key: "Fire NOC Certified" },
  { label: "Pantry", image: Pantry, key: "Pantry" },
  { label: "Fire extinguishers", image: Fire, key: "Fire extinguishers" },
  { label: "Occupancy Certificate", image: Certificate, key: "Occupancy Certificate" },
  { label: "DG Availability", image: DG_Availability, key: "DG Availability" },
  { label: "Reception Area", image: Reception_Area, key: "Reception Area" },
  { label: "Community Hall", image: Community_Hall, key: "Community Hall" },
  { label: "Kids Area", image: Kids_Area, key: "Kids Area" },
  { label: "Sports", image: Sports, key: "Sports" },
  { label: "Swimming Pool", image: Swimming_Pool, key: "Swimming Pool" },
  { label: "Lift", image: Lift, key: "Lift" },
  { label: "Gym", image: Gym, key: "Gym" },
  { label: "Gated Community", image: Gated, key: "Gated Community" },
  { label: "Intercom", image: Intercom, key: "Intercom" },
  { label: "Pet Allowed", image: Pets, key: "Pet Allowed" },
  { label: "Garden", image: Garden, key: "Garden" },
  { label: "Club House", image: Club, key: "Club House" },
];

type ReportItem = {
  label: string;
  value: string;
};
export const report: ReportItem[] = [
  { label: "Incorrect Information", value: "The listing contains false or misleading information" },
  { label: "Duplicate Listing", value: "This property is listed more than once" },
  { label: "Inappropriate Content", value: "The listing contains inappropriate or offensive content" },
  { label: "Fraudulent Listing", value: "I suspect this listing is a scam or fraudulent" },
  { label: "Other", value: "(Please specify)" }
];

type SortData={
  id:any;
  value:any
}

export const SortData:SortData[]=[
  {id:1,value:"Price (Low to High)"},
  {id:2,value:"Price (High to Low)"},
  {id:3,value:"Newest Listings"},
  {id:4,value:"Older Listings"}
]

export const filterData = [
  {
    look: [
      { id: 1, button: 'Buy' },
      { id: 2, button: 'Rent' },
      { id: 3, button: 'Lease' },
      { id: 4, button: 'Commercial' }
    ],
    property: [
      { id: 1, value: 'Apartment' },
      { id: 2, value: 'Independent House' },
      { id: 3, value: 'Villa' },
      { id: 4, value: 'Plot / Land' }
    ],
    propertyCommercial: [
      { id: 1, value: 'Showroom' },
      { id: 2, value: 'Office Space' },
      { id: 3, value: 'Retail Shop' },
      { id: 4, value: 'Industrial Space' },
      { id: 5, value: 'Warehouse' }
    ],
    BHK: [
      { id: 1, value: '1RK' },
      { id: 2, value: '1BHk' },
      { id: 3, value: '2BHK' },
      { id: 4, value: '3BHK' },
      { id: 5, value: '3+BHK' }
    ],
    SubCategory: [
      { id: 1, button: 'Buy' },
      { id: 2, button: 'Rent' },
      { id: 3, button: 'Lease' }
    ],
    facing:[
      {id:1,button:"North"},
      {id:2,button:"South"},
      {id:3,button:"East"},
      {id:4,button:"West"},
    ],
    status:[
      {id:1,button:"Ready to Move"},
      {id:2,button:"Under Construction"}
    ]
  }
];

export interface User {
  id: number;
  building_name: string;
  // Add other fields as necessary 
}

// Create the fetchUsers thunk
export const fetchUsers = createAsyncThunk<User[]>('users/fetchUsers', async () => {
    const response = await fetch('YOUR_API_ENDPOINT'); // Replace with your API endpoint
    if (!response.ok) {
        throw new Error('Failed to fetch users');
    }
    const data = await response.json();
    return data; // Assuming the response is an array of users
});


export const districts = [
  'Ariyalur',
  'Chengalpattu',
  'Chennai',
  'Coimbatore',
  'Cuddalore',
  'Dharmapuri',
  'Dindigul',
  'Erode',
  'Kallakurichi',
  'Kancheepuram',
  'Karur',
  'Krishnagiri',
  'Madurai',
  'Mayiladuthurai',
  'Nagapattinam',
  'Kanniyakumari',
  'Namakkal',
  'Perambalur',
  'Pudukottai',
  'Ramanathapuram',
  'Ranipet',
  'Salem',
  'Sivagangai',
  'Tenkasi',
  'Thanjavur',
  'Theni',
  'Thiruvallur',
  'Thiruvarur',
  'Thoothukudi',
  'Trichirappalli',
  'Thirunelveli',
  'Tirupathur',
  'Tiruppur',
  'Tiruvannamalai',
  'Nilgiris',
  'Vellore',
  'Viluppuram',
  'Virudhunagar'
];


export function capitalizeWords(value: string | string[]): string | string[] {
  // Check if the value is an array
  if (Array.isArray(value)) {
    // If it's an array, map through each string and apply capitalization
    return value.map(item =>
      item
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    );
  }

  // If it's a single string, apply capitalization
  return value
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export const ServiceImage=[
  {id:1,label:"Electrician",image:Services1},
  {id:2,label:"Packers & Movers",image:Services2},
  {id:3,label:"Home Renovations",image:Services3},
  {id:4,label:"Painting",image:Services4},
  {id:5,label:"Construction Service",image:Services5},
  {id:6,label:"Home Interior",image:Services6}
]

export const categorydata=[
  {id:1,value:"Electrician"},
  {id:2,value:"Home Interior"},
  {id:3,value:"Construction Service"},
  {id:4,value:"Painting"},
  {id:5,value:"Home Renovations"},
  {id:6,value:"Packers & Movers"}
] 

