import { Link } from "react-router-dom";
import locationPin from "../../assets/pin-location.png";

interface JobDetailCardProps {
  jobDetail: {
    _id: string;
    jobTitle: string;
    location: string;
    experience: string;
    postedOn: string;
  };
}

const JobDetailCard: React.FC<JobDetailCardProps> = ({ jobDetail }) => {
  return (
    <div className="bg-gradient-to-t from-[#DCDCDC] to-[#FFFFFF] rounded-[30px] font-normal p-4 w-[80%] md:w-[250px] lg:w-[300px] cursor-context-menu relative">
      <h4 className="text-[#00456C] pb-2 text-base md:text-lg lg:text-xl">
        {jobDetail.jobTitle}
      </h4>
      <div className="text-[#626262] text-[13px] md:text-sm lg:text-base flex flex-col gap-2">
        <p className="flex items-center gap-1">
          {" "}
          <img src={locationPin} alt="" /> {jobDetail.location}
        </p>
        <p>{jobDetail.experience}</p>
        <p>{jobDetail.postedOn}</p>
      </div>
      <div className="flex flex-col items-end text-[13px] md:text-sm lg:text-base">
        <Link to={`/jobs/${jobDetail._id}`}>
          <div className="text-[#2684F194] pb-1 px-3 cursor-pointer">
            Job Description
          </div>
        </Link>
        <Link to={`/jobs/${jobDetail._id}`}>
          <button className="bg-[#FBB337] text-[#404040] rounded-full px-7 py-2">
            Apply Now
          </button>
        </Link>
      </div>
    </div>
  );
};

export default JobDetailCard;
