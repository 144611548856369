// import React, { useEffect, useState } from 'react';
// import { useSelector } from "react-redux";
// import { axiosInstance } from "../api/APIs";
// import Carousel from 'react-multi-carousel';
// import 'react-multi-carousel/lib/styles.css';
// import { truncateString } from "../ReuseableCompounts/TruncateString";
// import { formatPrice } from "../ReuseableCompounts/PriceFormat";
// import { formatSQPrice } from "../ReuseableCompounts/PriceSQFormat";
// import star from '../images/star.svg';
// import goldtick from '../images/goldtick.png';
// import loaction from '../../compounts/images/location.png';
// import { handleChange } from "../ReuseableCompounts/PostDetail";

// import { getResponsiveSettings } from "../ReuseableCompounts/CarouselResponsive";

// interface Props {
//     lookingToValue: any
// }

// interface Image {
//     id: number;
//     isCover: any;
//     isvisible: any;
//     name: any;
//     uri: any;
// }

// interface Rows {
//     id: number;
//     post_id: number;
//     property_type?: any;
//     looking_to?: string;
//     rent_or_lease?: string;
//     property_available_for?: string;
//     images?: Image[];
//     view_post_count: number;
//     bhk: string;
//     city: string;
//     building_name: string;
//     landmark_street: string;
//     build_up_area?: string;
//     built_up_area?: string;
//     plot_area?: string;
//     PriceperSqft?: string;
//     price: number;
//     cost?: number;
//     monthly_rent?: number;
//     enter_lease_amount?: number;
//     lease_amount?: any;
//     liked?: any;
//     user_id?: any;
//     district?: string;
//     kbl_property: any;
// }

// const RecommendedProperties: React.FC<Props> = ({ lookingToValue }) => {
//     const [cart, setCart] = useState<Rows[]>([]);
//     const [projects, setProjects] = useState<any[]>([]);
//     const loginData = useSelector((item: any) => item.userData.userLoginData);

    

//     const responsiveSettings = getResponsiveSettings(4.2, 3, 2.5);

//     // const handleChange = async (id: number, property: string, looking_to?: string) => {
//     //     const body = {
//     //         post_id: id,
//     //         selected_property_type: property,
//     //         looking_to: looking_to || ''
//     //     };

//     //     try {
//     //         const response = await axiosInstance.post("/filter/get/postDetail", body);

//     //         // Convert response data to a query string
//     //         const queryString = new URLSearchParams({
//     //             data: JSON.stringify(response.data),
//     //             look: looking_to || ''
//     //         }).toString();

//     //         // Open a new window with the query parameters
//     //         const newWindow = window.open(`/PropertyDetails?${queryString}`, '_blank');

//     //         if (newWindow) {
//     //             newWindow.focus(); // Focus the new window/tab if needed
//     //         }
//     //     } catch (error) {
//     //         console.error('Error fetching data:', error);
//     //     }
//     // };

//     useEffect(() => {
//         async function handleCityFilter() {
//             const url = `/filter/city/filter/Res_Sell/`;
//             const body: any = {
//                 user_id: loginData.user_id,
//                 district: "Vellore",
//                 rent_or_sell: lookingToValue
//             };

//             try {
//                 const response = await axiosInstance.post(url, body);
//                 const data = response.data.message;
//                 if (Array.isArray(data)) {
//                     setCart(data);
//                 } else {
//                     console.error('Expected an array but got:', data);
//                     setCart([]);
//                 }
//             } catch (error) {
//                 console.error('Error fetching city filter data:', error);
//             }
//         }
//         handleCityFilter();
//     }, [lookingToValue]);

//     return (
//         <div className="xl:mx-[20%] msm:mx-2 max-w-full mt-">
//             <p className='text-xl font-bold text-[#202020]'>Recommended Properties for Sale</p>
//             <div>
//                 <Carousel
//                     swipeable
//                     draggable
//                     showDots={false}
//                     responsive={responsiveSettings}
//                     ssr // means to render carousel on server-side.
//                     infinite
//                     autoPlay={false} // Stop auto-scrolling
//                     keyBoardControl
//                     customTransition="transform 500ms ease-in-out"
//                     transitionDuration={500}
//                     containerClass="carousel-container"
//                     removeArrowOnDeviceType={["tablet", "mobile"]}
//                     dotListClass="custom-dot-list-style"
//                     itemClass="carousel-item-padding-20-px" // Adjusted padding
//                 >
//                     {cart && cart.length > 0 ? (
//                         cart.map((item, index) => {
//                             const price = item.price || item.cost || item.enter_lease_amount || item.lease_amount || item.monthly_rent;
//                             const formattedPrice = formatPrice(price);
//                             const coverimg = item.images && item.images.length > 0 ? item.images.find(img => img.isCover) || "" : "";
//                             const locality = truncateString(item.city, 20);
//                             const sqtv: any = item.PriceperSqft ? item.PriceperSqft : "";
//                             const priceSQTV = formatSQPrice(sqtv);

//                             const propertyView = {
//                                 postId: item.post_id,
//                                 property_type: item.property_type,
//                                 looking: item.looking_to || item.rent_or_lease || item.property_available_for,
//                                 userId: loginData.user_id
//                               }


//                             return (
//                                 <div key={index} className='relative mt- bg-[#F5F4F8]  p-2 rounded-2xl w-[95%] h-[100%]'>
//                                     <div className='relative'>
//                                         <p className='absolute right-0 mr-1 top-3 bg-[#2E368F] text-white py-1 px-3 rounded-2xl'>{item.looking_to || item.rent_or_lease || item.property_available_for}</p>
//                                         <img onClick={() => { handleChange(propertyView) }}
//                                             className='w-[15vw] h-[20vh] flex justify-center bg-black items-center object-contain rounded-3xl' src={typeof coverimg === 'object' && coverimg?.uri ? coverimg.uri : ''} />
//                                         <div className={`${item.kbl_property ? "flex" : "hidden"} items-center bg-[#6135DD] absolute bottom-0 rounded-bl-3xl p-1 rounded-tr-3xl left-0`}>
//                                             <img className="w-5 h-5" src={star} />
//                                             <p className="text-white text-sm mr-1">VATTARA VERIFIED</p>
//                                         </div>
//                                     </div>
//                                     <div className='ml-1'>
//                                         <div className='flex items-center'>
//                                             <p className='text-lg flex font-bold'>{item.bhk} {item.property_type} </p>
//                                             <img src={goldtick} />
//                                         </div>
//                                         <div className='flex gap-2 items-center'>
//                                             <img className="h-5" src={loaction} />
//                                             <p className='text-sm capitalize'>{locality}</p>
//                                         </div>
//                                         <div className='flex items-center w-[100%]'>
//                                             <p className='text-xl font-bold text-[#404040] w-[55%]'>₹ {formattedPrice} </p>
//                                             <div className='border-[#404040] h-6 border-1 border-l'></div>
//                                             <div className='ml-1'>
//                                                 <div className='flex items-center text-sm font-medium text-[#404040] w-[45%]'>
//                                                     <p>{item.built_up_area || item.plot_area} </p>
//                                                     {item.built_up_area || item.plot_area ? <p className='text-sm'>sq.ft</p> : ""}
//                                                 </div>
//                                                 <div className='flex items-center text-sm font-medium text-[#404040]'>
//                                                     {item.PriceperSqft ? <p>₹ {priceSQTV}</p> : ""}
//                                                     {item.PriceperSqft ? <p>/sq.ft</p> : ""}
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             )
//                         })
//                     ) : (
//                         <p className="text-center text-gray-500">No listings available.</p>
//                     )}
//                 </Carousel>
//             </div>
//         </div>
//     );
// }

// export default RecommendedProperties;


import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from "react-redux";
import { axiosInstance } from "../api/APIs";
import { truncateString } from "../ReuseableCompounts/TruncateString";
import { formatPrice } from "../ReuseableCompounts/PriceFormat";
import { formatSQPrice } from "../ReuseableCompounts/PriceSQFormat";
import star from '../images/star.svg';
import goldtick from '../images/goldtick.png';
import location from '../../compounts/images/location.png';
import DefaultScroll from '../../compounts/images/defaultScroll.png';
import { Box, CircularProgress } from '@mui/material'; // Import CircularProgress
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';


interface Props {
    lookingToValue: any;
}

interface Image {
    id: number;
    isCover: any;
    isvisible: any;
    name: any;
    uri: any;
}

interface Rows {
    id: number;
    post_id: number;
    property_type?: any;
    looking_to?: string;
    rent_or_lease?: string;
    property_available_for?: string;
    images?: Image[];
    view_post_count: number;
    bhk: string;
    city: string;
    building_name: string;
    landmark_street: string;
    build_up_area?: string;
    built_up_area?: string;
    plot_area?: string;
    PriceperSqft?: string;
    price: number;
    cost?: number;
    monthly_rent?: number;
    enter_lease_amount?: number;
    lease_amount?: any;
    liked?: any;
    user_id?: any;
    district?: string;
    kbl_property: any;
}

const RecommendedProperties: React.FC<Props> = ({ lookingToValue }) => {
    const [cart, setCart] = useState<Rows[]>([]);
    const [loadingCardId, setLoadingCardId] = useState<number | null>(null); // Track loading card ID
    const loginData = useSelector((item: any) => item.userData.userLoginData);
    const scrollRef = useRef<HTMLDivElement>(null);

    const handleChange = async (id: number, property: string, looking_to?: string) => {
        const body = {
            post_id: id,
            selected_property_type: property,
            looking_to: looking_to || ''
        };

        try {
            setLoadingCardId(id); // Set the loading card ID
            const response = await axiosInstance.post("/filter/get/postDetail", body);
            const queryString = new URLSearchParams({
                data: JSON.stringify(response.data),
                look: looking_to || ''
            }).toString();
            const newWindow = window.open(`/PropertyDetails?${queryString}`, '_blank');
            if (newWindow) newWindow.focus();
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoadingCardId(null); // Reset the loading card ID
        }
    };
    const scrollContainerStyle = {
      overflowX: "auto",
      whiteSpace: "nowrap",
      padding: "16px 0",
      scrollbarWidth: "none",  // Firefox
      msOverflowStyle: "none", // IE and Edge
      '&::-webkit-scrollbar': {
          display: 'none' // Chrome, Safari, and Opera
      }
  };
    useEffect(() => {
        async function handleCityFilter() {
            const url = `/filter/city/filter/Res_Sell/`;
            const body: any = {
                user_id: loginData.user_id,
                district: "Vellore",
                rent_or_sell: lookingToValue
            };

            try {
                const response = await axiosInstance.post(url, body);
                const data = response.data.message;
                if (Array.isArray(data)) {
                    setCart(data);
                } else {
                    console.error('Expected an array but got:', data);
                    setCart([]);
                }
            } catch (error) {
                console.error('Error fetching city filter data:', error);
            }
        }
        handleCityFilter();
    }, [lookingToValue, loginData.user_id]);

    const scroll = (direction: 'left' | 'right') => {
        if (scrollRef.current) {
            const scrollAmount = direction === 'right' ? 300 : -300;
            scrollRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
        }
    };

    return (
        <Box sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
            <Box sx={{ width: { sm: "100%", md: "100%", lg: "95%", xl: "60%" ,xs:"95%"}, position: "relative" }}>
                <p className='text-xl font-bold text-[#202020] ml-3'>Recommended Properties for Sale</p>
                <div className="flex items-center relative">
                    <Box sx={{display:{xs:"none",lg:"flex"}}}><button 
                        onClick={() => scroll('left')} 
                        aria-label="Scroll Left" 
                        style={{
                            backgroundColor: "#F5F4F8",
                            position: "absolute",
                            zIndex: 999,
                            borderRadius: "50%",
                            width: "50px",
                            height: "50px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "none",
                            cursor: "pointer",
                            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                            top: "50%",
                            transform: "translateY(-50%)"
                        }}
                    >
                        <ArrowBackIosIcon />
                    </button></Box>
                    {/* <Box sx={{ width: { sm: "100%", md: "95%", ld: "60%", xl: "60%"} }}> */}

                    <div ref={scrollRef} className="overflow-x-auto whitespace-nowrap py-4 flex space-x-4 " style={{                            cursor: "pointer",
}}>
                        {cart && cart.length > 0 ? (
                            cart.map((item, index) => {
                                const price = item.price || item.cost || item.enter_lease_amount || item.lease_amount || item.monthly_rent;
                                const formattedPrice = formatPrice(price);
                                const coverimg = item.images && item.images.length > 0 ? item.images.find(img => img.isCover) || "" : "";
                                const locality = truncateString(item.city, 23);
                                const sqtv: any = item.PriceperSqft ? item.PriceperSqft : "";
                                const priceSQTV = formatSQPrice(sqtv);

                                return (
                                    <div key={index} className='inline-block bg-[#F5F4F8] p-2 rounded-[21px] w-[300px] relative'>
                                        {loadingCardId === item.post_id && (
                                            <div className="absolute inset-0 flex justify-center items-center bg-opacity-50 bg-gray-800 z-10" style={{borderRadius:"20px"}}>
                                                <CircularProgress color="inherit" /> {/* Loader overlay */}
                                            </div>
                                        )}
                                        <div className='relative'>
                                            <div className='absolute right-0 mr-2 top-3 bg-[#2E368F] text-white py-1 px-3 rounded-2xl'>
                                                {item.looking_to || item.rent_or_lease || item.property_available_for}
                                            </div>
                                            <img
                                                onClick={() => { handleChange(item.post_id, item.property_type, item.looking_to || item.rent_or_lease || item.property_available_for) }}
                                                className='w-full h-[180px] flex justify-center bg-black items-center object-cover rounded-[15px]'
                                                src={typeof coverimg === 'object' && coverimg?.uri ? coverimg.uri : DefaultScroll}
                                            />
                                            <div className={`${item.kbl_property ? "flex" : "hidden"} items-center bg-[#6135DD] absolute bottom-0 rounded-bl-3xl p-1 rounded-tr-3xl left-0`}>
                                                <img className="w-5 h-5" src={star} />
                                                <div className="text-white text-sm mr-1">VATTARA VERIFIED</div>
                                            </div>
                                        </div>
                                        <div className='ml-1 w-[200px]'>
                                            <div className='flex gap-1 items-left' style={{alignItems:"center"}} >
                                                <img src={goldtick} className='w-5 h-5' style={{marginLeft:"-3px"}} />
                                                <div style={{ fontSize: "15px", fontWeight: 700 }}>{item.bhk} {item.property_type}</div>
                                            </div>
                                            <div className='flex gap-1 items-left'>
                                                <img className="h-4" src={location} />
                                                <div className='text-sm capitalize'>{locality}</div>
                                            </div>
                                            <div className='flex items-center w-full'>
                                                <div style={{ fontSize: "15px", fontWeight: 600 ,width:"100px"}}>₹ {formattedPrice}</div>
                                                <div className='border-[#404040] h-6 border-l'></div>
                                                <div className='ml-1'>
                                                    <div className='flex items-center text-sm font-sm text-[#404040]' >
                                                        <div className='truncate'>{item.built_up_area || item.plot_area}</div>
                                                        {item.built_up_area || item.plot_area ? <div className='text-sm'>sq.ft</div> : ""}
                                                    </div>
                                                    <div className='flex items-center text-sm font-sm text-[#404040]'>
                                                        {item.PriceperSqft ? <div>₹ {priceSQTV}</div> : ""}
                                                        {item.PriceperSqft ? <div>/sq.ft</div> : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        ) : (
                          <Box sx={{ width: "100%", justifyContent: "center", alignItems: "center", display: "flex" }}>
                            <div className="text-center text-gray-500" style={{marginLeft:"100px"}}>No listings available.</div>

                          </Box>
                        )}
                    </div>
{/* </Box> */}     <Box sx={{display:{xs:"none",lg:"flex"}}}>
                    <button 
                        onClick={() => scroll('right')} 
                        aria-label="Scroll Right" 
                        style={{
                            backgroundColor: "#F5F4F8",
                            zIndex: 999,
                            borderRadius: "50%",
                            width: "50px",
                            height: "50px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "absolute",
                            right: "-0px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            border: "none",
                            cursor: "pointer",
                            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                        }}
                    >
                        <ArrowForwardIosIcon />
                    </button></Box>
                </div>
            </Box>
        </Box>
    );
}

export default RecommendedProperties;
