import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  GoogleMap,
  Marker,
  InfoWindow,
  useJsApiLoader,
} from '@react-google-maps/api';
import mapIn from './images/mapIn.png';
import Filter from './filter/Filter';
import SortBy from './sortBy/SortBy';
import { CiCircleList } from "react-icons/ci";
import { TfiMapAlt } from "react-icons/tfi";
import { BsSortUp } from "react-icons/bs";
import { BiFilterAlt } from "react-icons/bi";
import { MdArrowBackIosNew } from "react-icons/md";
import { Link } from 'react-router-dom';
import Scrollmini from './Scrollmini';
import Scroll from './Scroll';
import { Box } from '@mui/material';

interface MapProps {
  children: React.ReactNode;
  setValue: any
  setSortOpen: any
  sortOpen: any
  setSelectedPostId: (postId: number) => void; // Add this line}
  value:number
}

interface MarkerData {
  title: string;
  latitude: number;
  longitude: number;
  image?: string;
  builtup: string;
  price: string;
  district: string;
  postid: number;

}

const Mapmini: React.FC<MapProps> = ({ children, setValue, sortOpen, setSortOpen,setSelectedPostId,value }) => {
  const [isMapVisible, setIsMapVisible] = useState<boolean>(true);
  const [isListVisible, setIsListVisible] = useState<boolean>(false);
  const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false)
  const [activeButton, setActiveButton] = useState<string>('Map');
  const [chat, setChat] = useState<any>()
  const [filterProperty, setFilterProperty] = useState<string[]>([]);
  const [filterLoaction, setfilterLoaction] = useState<any[]>([])
  const [filterPrice, setFilterPrice] = useState<any>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedPostId2, setSelectedPostId2] = useState<number | null>(null);

  const kb = false


  const [mapCenter, setMapCenter] = useState<
    google.maps.LatLngLiteral | undefined
  >(undefined);
  const [markers, setMarkers] = useState<MarkerData[]>([]);
  const [selectedMarkerIndex, setSelectedMarkerIndex] = useState<number | null>(
    null
  );
  const [addresses, setAddresses] = useState<string[]>([]);
  const [geofenceRegions, setGeofenceRegions] = useState<any[]>([]);
  const scrollViewRef = useRef<any>(null);




  const users = useSelector((state: any) => state.usersInfo.users);
  const apiKey = 'AIzaSyDz2gppwqEkyckilX-FDJkvgEtcoS2OEvE';

  const { isLoaded } = useJsApiLoader({ googleMapsApiKey: apiKey });

  const fetchAddress = async (latitude: number, longitude: number) => {
    // Replace with actual geocoding API call
    return 'Sample Address';
  };

  const fetchAddresses = async () => {
    const fetchedAddresses = await Promise.all(
      markers.map(async (marker) =>
        fetchAddress(marker.latitude, marker.longitude)
      )
    );
    setAddresses(fetchedAddresses);
  };

  useEffect(() => {
    if (users.length > 0) {
      const newMarkers = users.map((user: any) => ({
        title: `${user.bhk ? `${user.bhk} ` : ''}${user.property_type}`,
        latitude: parseFloat(user.latitude),
        longitude: parseFloat(user.longitude),
        image: user.images?.find((img: any) => img.isCover)?.uri,
        builtup: `${user.built_up_area} | ${user.PriceperSqft ? `${user.PriceperSqft}/sqft` : ''
          }`,
        price: user.cost || user.price,
        district: `${user.sublocality}, ${user.district}`,
        postid:`${user.post_id}`

      }));

      setMarkers(newMarkers);
      setMapCenter({
        lat: newMarkers[0]?.latitude || 0,
        lng: newMarkers[0]?.longitude || 0,
      });

      fetchAddresses();
      fetchGeofenceRegions(newMarkers);
    }
    else {
      // Handle the case when there are no users
      setMapCenter({ lat: 11.1271, lng: 78.6569 }); // Set a default center or handle accordingly
      setMarkers([]);
      setGeofenceRegions([]);
    }
  }, [users]);

  const fetchGeofenceRegions = (markers: MarkerData[]) => {
    const geofenceData = markers.map((marker, index) => ({
      id: index + 1,
      points: [
        { latitude: marker.latitude, longitude: marker.longitude - 0.01 },
        { latitude: marker.latitude, longitude: marker.longitude + 0.01 },
        {
          latitude: marker.latitude + 0.01,
          longitude: marker.longitude + 0.01,
        },
        {
          latitude: marker.latitude + 0.01,
          longitude: marker.longitude - 0.01,
        },
      ],
      name: marker.title,
    }));
    setGeofenceRegions(geofenceData);
  };

  const isPointInsidePolygon = (
    point: { latitude: number; longitude: number },
    polygon: any[]
  ) => {
    const x = point.latitude;
    const y = point.longitude;

    let inside = false;
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      const xi = polygon[i].latitude;
      const yi = polygon[i].longitude;
      const xj = polygon[j].latitude;
      const yj = polygon[j].longitude;

      const intersect =
        yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }

    return inside;
  };

  const handleMarkerClick = (index: number) => {
    setSelectedMarkerIndex(index);
    setMapCenter({
      lat: markers[index].latitude,
      lng: markers[index].longitude,
    });

    scrollViewRef.current?.scrollTo({ x: index * 370, animated: true });

    const selectedMarker = markers[index];
    checkGeofenceRegions(selectedMarker);
    setSelectedPostId(selectedMarker.postid);
    setSelectedPostId2(selectedMarker.postid);


  };

  const checkGeofenceRegions = (selectedMarker: MarkerData) => {
    geofenceRegions.forEach((geofence) => {
      if (
        isPointInsidePolygon(
          {
            latitude: selectedMarker.latitude,
            longitude: selectedMarker.longitude,
          },
          geofence.points
        )
      ) {
        console.log(`Entered ${geofence.name}`);
      }
    });
  };

  function toggleFilterVisibility() {
    setIsMapVisible(false);
    setIsListVisible(true);
    setIsFilterVisible(false)
    setSortOpen(false)
    setActiveButton('List');
    

  }

  const [isOn, setIsOn] = useState(true);

  // Step 2: Create a function to handle the toggle
  const toggle = () => {
    setIsOn(prevState => !prevState);
  };
  const toggleMapVisibility = (buttonId: string) => {
  
    setIsMapVisible(true);
    setIsListVisible(false);
    setIsFilterVisible(false)
    setSortOpen(false)
    setActiveButton(buttonId === 'Map' ? 'Map' : 'List');
  };

  const toggleListVisibility = (buttonId: string) => {
    setIsMapVisible(false);
    setIsListVisible(true);
    setIsFilterVisible(false)
    setSortOpen(false)
    setActiveButton(buttonId === 'List' ? 'List' : 'Map');
  };

  const togglefilterVisibility = (buttonId: any) => {
    setIsMapVisible(false);
    setIsListVisible(false);
    setIsFilterVisible(true)
    setSortOpen(false)
    setActiveButton(buttonId === 'Filter' ? 'Filter' : "Map")
  }

  const toggleSortVisibility = (buttonId: any) => {
    setIsMapVisible(false);
    setIsListVisible(false);
    setIsFilterVisible(false)
    setSortOpen(true)
    setActiveButton(buttonId === 'Sort' ? 'Sort' : "Map")
  }

  function handleSort() {
    // console.log("hello");
    // setIsFilterVisible(false);
    setSortOpen(false)
    setIsMapVisible(false);
    setIsListVisible(true);
    setIsFilterVisible(false)
    setActiveButton('List');
    // setActiveButton(buttonId === 'Sort' ? 'Sort' : "Map")


  }

  const getMarkerIcon = (postId: number) => {
    const selectedIcon = encodeURIComponent(`<?xml version="1.0" encoding="utf-8"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --><svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.3856 23.789L11.3831 23.7871L11.3769 23.7822L11.355 23.765C11.3362 23.7501 11.3091 23.7287 11.2742 23.7008C11.2046 23.6451 11.1039 23.5637 10.9767 23.4587C10.7224 23.2488 10.3615 22.944 9.92939 22.5599C9.06662 21.793 7.91329 20.7041 6.75671 19.419C5.60303 18.1371 4.42693 16.639 3.53467 15.0528C2.64762 13.4758 2 11.7393 2 10C2 7.34784 3.05357 4.8043 4.92893 2.92893C6.8043 1.05357 9.34784 0 12 0C14.6522 0 17.1957 1.05357 19.0711 2.92893C20.9464 4.8043 22 7.34784 22 10C22 11.7393 21.3524 13.4758 20.4653 15.0528C19.5731 16.639 18.397 18.1371 17.2433 19.419C16.0867 20.7041 14.9334 21.793 14.0706 22.5599C13.6385 22.944 13.2776 23.2488 13.0233 23.4587C12.8961 23.5637 12.7954 23.6451 12.7258 23.7008C12.6909 23.7287 12.6638 23.7501 12.645 23.765L12.6231 23.7822L12.6169 23.7871L12.615 23.7885C12.615 23.7885 12.6139 23.7894 12 23L12.6139 23.7894C12.2528 24.0702 11.7467 24.0699 11.3856 23.789ZM12 23L11.3856 23.789C11.3856 23.789 11.3861 23.7894 12 23ZM15 10C15 11.6569 13.6569 13 12 13C10.3431 13 9 11.6569 9 10C9 8.34315 10.3431 7 12 7C13.6569 7 15 8.34315 15 10Z" fill="#1063B5"/></svg>`);
  
    const defaultIcon = encodeURIComponent(`<?xml version="1.0" encoding="utf-8"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->
<svg width="800px" height="800px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><title>70 Basic icons by Xicons.co</title><path d="M24,1.32c-9.92,0-18,7.8-18,17.38A16.83,16.83,0,0,0,9.57,29.09l12.84,16.8a2,2,0,0,0,3.18,0l12.84-16.8A16.84,16.84,0,0,0,42,18.7C42,9.12,33.92,1.32,24,1.32Z" fill="#ec5044"/><path d="M25.37,12.13a7,7,0,1,0,5.5,5.5A7,7,0,0,0,25.37,12.13Z" fill="#a1362e"/></svg>`);
  
    return postId === selectedPostId2 // Check if the marker's post ID matches the selected post ID
      ? `data:image/svg+xml;utf-8,${selectedIcon}` // Selected marker color (red)
      : `data:image/svg+xml;utf-8,${defaultIcon}`; // Default marker color (blue)
  };
  

  return (
    <div className="relative">
      <div className=" mt-1 lg:flex">
        {/* Map View */}
        <div className={`lg:flex lg:w-[48%] xl:w-[65%] z- lg:h-auto  md: msm:h-[100vh] ${isMapVisible ? "flex" : "hidden"}`}>
          <Link to={'/'}>
            <MdArrowBackIosNew className='fixed w-8 h-8 top-2 left-1 z-40 lg:hidden md:flex' />
          </Link>
          {isLoaded && mapCenter && (
            <GoogleMap
              center={mapCenter}
              zoom={13}
              mapContainerStyle={{ height: '100%', width: '100%' }}
            >
              {markers.map((marker, index) => (
                <Marker

                  key={index}
                  icon={{
                    url: getMarkerIcon(marker.postid), // Use the function to get the marker color
                    scaledSize: new window.google.maps.Size(30, 30), // Adjust size here
                  }}  
                  position={{ lat: marker.latitude, lng: marker.longitude }}
                  onClick={() => handleMarkerClick(index)}
                />
              ))}

              
                                <Box sx={{height:"25%",width:"100%",bottom:20, position:"absolute",display:{xs:"flex",sm:"flex",md:"none",lg:"none"}}} 
                >

<Scrollmini selectedPostId2={selectedPostId2} value={value} />
</Box> 
            </GoogleMap>
          )}
  

        </div>
 
        {/* List View */}
        <div
          className={`xl:w-[55%]  lg:grid ${isListVisible ? 'msm:grid sm:grid' : 'hidden'
            } `}
        >
          {React.Children.map(children, (child, index) =>
            React.cloneElement(child as React.ReactElement, {
              isSelected: index === selectedMarkerIndex,
              onClick: () => handleMarkerClick(index),
            })
          )}
        </div>

        <div className={`lg:hidden ${isFilterVisible ? 'msm:grid sm:grid overflow-y-scroll' : 'hidden'}`}>
          <Filter kb={kb} setLoading={setLoading} toggleFilterVisibility={toggleFilterVisibility} setFilterProperty={setFilterProperty} setfilterLoaction={setfilterLoaction} setFilterPrice={setFilterPrice} />
        </div>
        <div className={` lg:hidden ${sortOpen ? 'msm:grid sm:grid' : 'hidden'}`}>
          <SortBy setValue={setValue} handleSort={handleSort} />
        </div>

      </div>

      {/* Buttons */}
      <div className="bg-transparent flex lg:hidden w-full z-60 fixed bottom-2 justify-center items-center">
      <div className="bg-[#F5F4F8] h-16 w-[95%] rounded-full border border-[#D6D3D1] flex justify-between items-center px-2">
        <div className="flex items-center border-r border-[#D6D3D1] flex-1 justify-center">
        <div className={`lg:hidden   rounded-2xl `} style={{display:"flex",flexDirection:"row"}} onClick={() => togglefilterVisibility('Filter')}>
            <BiFilterAlt className='h-7 w-7 mr-1 ' />
            <button className='text-lg  font-semibold'


            >
              Filter
            </button>
          </div>
        </div>
        <div className="flex items-center border-r border-[#D6D3D1] flex-1 justify-center">
        <button onClick={toggle} >
        {isOn ?  <div  className="lg:hidden    rounded-2xl " style={{display:"flex",flexDirection:"row"}} onClick={() => toggleListVisibility('List')}>
            {/* <div className={` ${activeButton === "Map" ? 'hidden' : "flex"} border-0.5 border-l border-[#80848E] h-full `}></div> */}
            <CiCircleList className='h-7 w-7 mr-1 ' />
            <button className='text-lg  font-semibold'


            >
              List
            </button>
          </div> : <div className="lg:hidden    rounded-2xl " style={{display:"flex",flexDirection:"row"}} onClick={() => toggleMapVisibility('Map')}>

<TfiMapAlt className='h-7 w-7 mr-1 ' />
<button className='text-lg  font-semibold'


>
  Map
</button>
</div>    }
      </button>
        </div>
        <div className="flex items-center flex-1 justify-center">
        <div className={`lg:hidden  rounded-2xl `}  style={{display:"flex",flexDirection:"row"}}onClick={() => toggleSortVisibility('Sort')}>

<BsSortUp className='h-7 w-7 mr-1 ' />
<button className='text-lg  font-semibold'


>
  Sort By
</button>
</div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Mapmini;
