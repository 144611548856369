


import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { useStepperContext } from './StepperContext'; // Adjust path if necessary
import { updateValueInArray } from '../../PostForm/component';

interface CustomBoxProps {
    title?: string;
    label: string;
    inputId?: number;
    formName: string;
    required?: boolean;
    userselectedData?: any;
    data: any;
    initial: string;
    onSelectionChange?: (selectedValue: string) => void;
    baseData: any
    setDataB: any
    

}

const CustomBoxButton: React.FC<CustomBoxProps> = ({
    label,
    inputId,
    formName,
    data,
    required,
    userselectedData,
    initial,
    baseData,
    onSelectionChange,
    setDataB,
   
}) => {
    const { addUserData } = useStepperContext(); // Ensure this is available

    const [activeButton, setActiveButton] = useState<number | null>(null);


    useEffect(() => {


        const isDate = !isNaN(Date.parse(initial));

        if (isDate) {
            // If initial is a date, call onSelectionChange with the date
            if (onSelectionChange) {
                onSelectionChange("Under Construction");
            }
            // Ensure no button is active if the initial value is a date
            setActiveButton(2);
            return;
        }


        const initValue = data.find((item: any) => item.title === initial);
        setActiveButton(initValue?.id || null);
        console.log("possessies", initial, data, initValue)
    }, [initial, data]);
    console.log("possessies", initial, data)

    const handleClick = (item: { id: number; title: string }) => {
        setActiveButton(item.id);
        console.log("ef")
        const data = {
            id: inputId,
            [label]: item.title,
            formName: formName,
            required: required,
        };
        const updateData = updateValueInArray(baseData, label, item.title)
        console.log("Possiesee", updateData)
        setDataB(updateData)
        addUserData(data as any); // Call addUserData
        console.log("cal sel", item.title)

        if (onSelectionChange) {
            onSelectionChange(item.title);
            console.log("cal sel", item.title)
        }
    };

    const hasError = userselectedData?.includes(label);
    const shouldDisable = initial === "Rent" || initial === "Lease";

    return (
        <div className='flex flex-wrap gap-5'>
            {data.map((item: any) => (
                <Button
                    key={item.id}
                    disabled={shouldDisable} 
                    className='h-10'
                    onClick={() => handleClick(item)}
                    sx={{
                        borderRadius: '999px',
                        padding: '20px',
                        width: 'fit-content',
                        bgcolor: activeButton === item.id ? '#515AA8' : "#F5F4F8",
                        color: activeButton === item.id ? 'white' : 'black',
                        textTransform: 'capitalize',
                        '&:hover': {
                            bgcolor: activeButton === item.id ? '#515AA8' : "#F5F4F8",
                        },
                    }}
                >
                    {item.title}
                </Button>
            ))}
            {hasError && <div>This field is required</div>}
        </div>
    );
};

export default CustomBoxButton;
