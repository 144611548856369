import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  GoogleMap,
  Marker,
  InfoWindow,
  useJsApiLoader,
} from '@react-google-maps/api';
import mapIn from './images/mapIn.png';
import Filter from './filter/Filter';
import SortBy from './sortBy/SortBy';
import { CiCircleList } from "react-icons/ci";
import { TfiMapAlt } from "react-icons/tfi";
import { BsSortUp } from "react-icons/bs";
import { BiFilterAlt } from "react-icons/bi";
import { MdArrowBackIosNew } from "react-icons/md";
import { Link } from 'react-router-dom';
import Scrollmini from './Scrollmini';
import Scroll from './Scroll';
import { Box } from '@mui/material';

interface MapProps {
  children: React.ReactNode;
  setValue: any
  setSortOpen: any
  sortOpen: any
  setSelectedPostId: (postId: number) => void; // Add this line}
  value:number
}

interface MarkerData {
  title: string;
  latitude: number;
  longitude: number;
  image?: string;
  builtup: string;
  price: string;
  district: string;
  postid: number;

}

const Map: React.FC<MapProps> = ({ children, setValue, sortOpen, setSortOpen,setSelectedPostId,value }) => {
  const [isMapVisible, setIsMapVisible] = useState<boolean>(true);
  const [isListVisible, setIsListVisible] = useState<boolean>(false);
  const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false)
  const [activeButton, setActiveButton] = useState<string>('Map');
  const [chat, setChat] = useState<any>()
  const [filterProperty, setFilterProperty] = useState<string[]>([]);
  const [filterLoaction, setfilterLoaction] = useState<any[]>([])
  const [filterPrice, setFilterPrice] = useState<any>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedPostId2, setSelectedPostId2] = useState<number | null>(null);

  const kb = false


  const [mapCenter, setMapCenter] = useState<
    google.maps.LatLngLiteral | undefined
  >(undefined);
  const [markers, setMarkers] = useState<MarkerData[]>([]);
  const [selectedMarkerIndex, setSelectedMarkerIndex] = useState<number | null>(
    null
  );
  const [addresses, setAddresses] = useState<string[]>([]);
  const [geofenceRegions, setGeofenceRegions] = useState<any[]>([]);
  const scrollViewRef = useRef<any>(null);




  const users = useSelector((state: any) => state.usersInfo.users);
  const apiKey = 'AIzaSyDz2gppwqEkyckilX-FDJkvgEtcoS2OEvE';

  const { isLoaded } = useJsApiLoader({ googleMapsApiKey: apiKey });

  const fetchAddress = async (latitude: number, longitude: number) => {
    // Replace with actual geocoding API call
    return 'Sample Address';
  };

  const fetchAddresses = async () => {
    const fetchedAddresses = await Promise.all(
      markers.map(async (marker) =>
        fetchAddress(marker.latitude, marker.longitude)
      )
    );
    setAddresses(fetchedAddresses);
  };

  useEffect(() => {
    if (users.length > 0) {
      const newMarkers = users.map((user: any) => ({
        title: `${user.bhk ? `${user.bhk} ` : ''}${user.property_type}`,
        latitude: parseFloat(user.latitude),
        longitude: parseFloat(user.longitude),
        image: user.images?.find((img: any) => img.isCover)?.uri,
        builtup: `${user.built_up_area} | ${user.PriceperSqft ? `${user.PriceperSqft}/sqft` : ''
          }`,
        price: user.cost || user.price,
        district: `${user.sublocality}, ${user.district}`,
        postid:`${user.post_id}`

      }));

      setMarkers(newMarkers);
      setMapCenter({
        lat: newMarkers[0]?.latitude || 0,
        lng: newMarkers[0]?.longitude || 0,
      });

      fetchAddresses();
      fetchGeofenceRegions(newMarkers);
    }
    else {
      // Handle the case when there are no users
      setMapCenter({ lat: 11.1271, lng: 78.6569 }); // Set a default center or handle accordingly
      setMarkers([]);
      setGeofenceRegions([]);
    }
  }, [users]);

  const fetchGeofenceRegions = (markers: MarkerData[]) => {
    const geofenceData = markers.map((marker, index) => ({
      id: index + 1,
      points: [
        { latitude: marker.latitude, longitude: marker.longitude - 0.01 },
        { latitude: marker.latitude, longitude: marker.longitude + 0.01 },
        {
          latitude: marker.latitude + 0.01,
          longitude: marker.longitude + 0.01,
        },
        {
          latitude: marker.latitude + 0.01,
          longitude: marker.longitude - 0.01,
        },
      ],
      name: marker.title,
    }));
    setGeofenceRegions(geofenceData);
  };

  const isPointInsidePolygon = (
    point: { latitude: number; longitude: number },
    polygon: any[]
  ) => {
    const x = point.latitude;
    const y = point.longitude;

    let inside = false;
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      const xi = polygon[i].latitude;
      const yi = polygon[i].longitude;
      const xj = polygon[j].latitude;
      const yj = polygon[j].longitude;

      const intersect =
        yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }

    return inside;
  };

  const handleMarkerClick = (index: number) => {
    setSelectedMarkerIndex(index);
    setMapCenter({
      lat: markers[index].latitude,
      lng: markers[index].longitude,
    });

    scrollViewRef.current?.scrollTo({ x: index * 370, animated: true });

    const selectedMarker = markers[index];
    checkGeofenceRegions(selectedMarker);
    setSelectedPostId(selectedMarker.postid);
    setSelectedPostId2(selectedMarker.postid);


  };

  const checkGeofenceRegions = (selectedMarker: MarkerData) => {
    geofenceRegions.forEach((geofence) => {
      if (
        isPointInsidePolygon(
          {
            latitude: selectedMarker.latitude,
            longitude: selectedMarker.longitude,
          },
          geofence.points
        )
      ) {
        console.log(`Entered ${geofence.name}`);
      }
    });
  };

  function toggleFilterVisibility() {
    setIsMapVisible(false);
    setIsListVisible(true);
    setIsFilterVisible(false)
    setSortOpen(false)
    setActiveButton('List');
    

  }

  const [isOn, setIsOn] = useState(true);

  // Step 2: Create a function to handle the toggle
  const toggle = () => {
    setIsOn(prevState => !prevState);
  };
  const toggleMapVisibility = (buttonId: string) => {
  
    setIsMapVisible(true);
    setIsListVisible(false);
    setIsFilterVisible(false)
    setSortOpen(false)
    setActiveButton(buttonId === 'Map' ? 'Map' : 'List');
  };

  const toggleListVisibility = (buttonId: string) => {
    setIsMapVisible(false);
    setIsListVisible(true);
    setIsFilterVisible(false)
    setSortOpen(false)
    setActiveButton(buttonId === 'List' ? 'List' : 'Map');
  };

  const togglefilterVisibility = (buttonId: any) => {
    setIsMapVisible(false);
    setIsListVisible(false);
    setIsFilterVisible(true)
    setSortOpen(false)
    setActiveButton(buttonId === 'Filter' ? 'Filter' : "Map")
  }

  const toggleSortVisibility = (buttonId: any) => {
    setIsMapVisible(false);
    setIsListVisible(false);
    setIsFilterVisible(false)
    setSortOpen(true)
    setActiveButton(buttonId === 'Sort' ? 'Sort' : "Map")
  }

  function handleSort() {
    // console.log("hello");
    // setIsFilterVisible(false);
    setSortOpen(false)
    setIsMapVisible(false);
    setIsListVisible(true);
    setIsFilterVisible(false)
    setActiveButton('List');
    // setActiveButton(buttonId === 'Sort' ? 'Sort' : "Map")


  }

  return (
    <div className="relative">
      <div className=" mt-1 lg:flex">
        {/* Map View */}
        <div className={`lg:flex lg:w-[48%] xl:w-[65%] h-[99vh] ${isMapVisible ? "flex" : "hidden"}`}>
          <Link to={'/'}>
            <MdArrowBackIosNew className='absolute w-8 h-8 top-2 left-1 z-40 lg:hidden md:flex' />
          </Link>
          {isLoaded && mapCenter && (
            <GoogleMap
              center={mapCenter}
              zoom={13}
              mapContainerStyle={{ height: '100%', width: '100%' }}
            >
              {markers.map((marker, index) => (
                <Marker

                  key={index}
                  position={{ lat: marker.latitude, lng: marker.longitude }}
                  onClick={() => handleMarkerClick(index)}
                />
              ))}

              {selectedMarkerIndex !== null && (
                <InfoWindow
                  position={{
                    lat: markers[selectedMarkerIndex].latitude,
                    lng: markers[selectedMarkerIndex].longitude,
                  }}
                  onCloseClick={() => setSelectedMarkerIndex(null)}
                >
                  <div
                    className={`p-2 flex gap-2.5 transition-height duration-300 ${selectedMarkerIndex === null ? 'lg:h-[35px]' : 'h-[auto]'
                      }`}
                  >
                    <div>
                      {markers[selectedMarkerIndex]?.image && (
                        <img className='lg:w-[180px] lg:h-[100%] rounded-md'
                          src={markers[selectedMarkerIndex].image}
                          alt="Property"

                        />
                      )}
                    </div>
                    <div className="">
                      <h4 className="font-bold text-[#252B5C]  text-lg">
                        {markers[selectedMarkerIndex]?.title}
                      </h4>
                      <p>{markers[selectedMarkerIndex]?.district}</p>
                      <p>Price: {markers[selectedMarkerIndex]?.price}</p>
                      <p>{markers[selectedMarkerIndex]?.builtup}</p>
                    </div>
                  </div>
                </InfoWindow>
              )}
          
            </GoogleMap>
          )}
             

        </div>
 
        {/* List View */}
        <div
          className={`xl:w-[55%]  lg:grid ${isListVisible ? 'msm:grid sm:grid' : 'hidden'
            } `}
        >
          {React.Children.map(children, (child, index) =>
            React.cloneElement(child as React.ReactElement, {
              isSelected: index === selectedMarkerIndex,
              onClick: () => handleMarkerClick(index),
            })
          )}
        </div>

        <div className={`lg:hidden ${isFilterVisible ? 'msm:grid sm:grid overflow-y-scroll' : 'hidden'}`}>
          <Filter kb={kb} setLoading={setLoading} toggleFilterVisibility={toggleFilterVisibility} setFilterProperty={setFilterProperty} setfilterLoaction={setfilterLoaction} setFilterPrice={setFilterPrice} />
        </div>
        <div className={` lg:hidden ${sortOpen ? 'msm:grid sm:grid' : 'hidden'}`}>
          <SortBy setValue={setValue} handleSort={handleSort} />
        </div>

      </div>

      {/* Buttons */}
      <div className="bg-transparent flex lg:hidden w-full z-60 fixed bottom-5 justify-center items-center">
      <div className="bg-[#F5F4F8] h-16 w-[95%] rounded-full border border-[#D6D3D1] flex justify-between items-center px-4">
        <div className="flex items-center border-r border-[#D6D3D1] flex-1 justify-center">
        <div className={`lg:hidden   rounded-2xl `} style={{display:"flex",flexDirection:"row"}} onClick={() => togglefilterVisibility('Filter')}>
            <BiFilterAlt className='h-8 w-8 ml-5' />
            <button className='text-lg  font-semibold'


            >
              Filter
            </button>
          </div>
        </div>
        <div className="flex items-center border-r border-[#D6D3D1] flex-1 justify-center">
        <button onClick={toggle} >
        {isOn ?  <div  className="lg:hidden    rounded-2xl " style={{display:"flex",flexDirection:"row"}} onClick={() => toggleListVisibility('List')}>
            {/* <div className={` ${activeButton === "Map" ? 'hidden' : "flex"} border-0.5 border-l border-[#80848E] h-full `}></div> */}
            <CiCircleList className='h-8 w-8 ml-8' />
            <button className='text-lg  font-semibold'


            >
              List
            </button>
          </div> : <div className="lg:hidden    rounded-2xl " style={{display:"flex",flexDirection:"row"}} onClick={() => toggleMapVisibility('Map')}>

<TfiMapAlt className='h-8 w-8 ml-8' />
<button className='text-lg  font-semibold'


>
  Map
</button>
</div>    }
      </button>
        </div>
        <div className="flex items-center flex-1 justify-center">
        <div className={`lg:hidden  rounded-2xl `}  style={{display:"flex",flexDirection:"row"}}onClick={() => toggleSortVisibility('Sort')}>

<BsSortUp className='h-8 w-8 ml-3' />
<button className='text-lg  font-semibold'


>
  Sort By
</button>
</div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Map;
