import React, { useEffect } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import animis from './Animation - 1721114927422.json';
import image1 from '../images/login-2.svg';
import image2 from '../images/log-1.svg';

interface VerifiedProps {
    onClose: () => void;
    showVerification: any
    setShowVerification:any
}

const  Verified: React.FC<VerifiedProps> = ({ onClose, showVerification,setShowVerification }) => {
    const animi = {
        animationData: animis,
    };

    const navigate = useNavigate();

    // const handleClick = () => {
    //     navigate('/list');
    // };
    useEffect(() => {
        if (showVerification === "tick") {
            const timer = setTimeout(() => {
                 setShowVerification("List"); 
                
            }, 1000); // 5 seconds

            return () => clearTimeout(timer); // Cleanup the timer on component unmount or state change
        }
    }, [showVerification, setShowVerification, navigate]);

    return (
        <div className="fixed flex justify-center items-center z-10 left-0 top-0 w-full h-full overflow-auto">
            <div className="bg-white   border border-gray-300 border-solid msm:mx-4 md:mx-0 md:overflow-y-scroll lg:overflow-hidden msm:w-[90vw] custom-xxl:w-[70vw] md:w-[100vw] lg:w-[70vw] xl:w-[60vw] w-[70vw] md:h-[50vh] lg:h-[70vh] xl:h-[600px] rounded-[2%] flex flex-col relative sm:flex sm:flex-col sm:gap-2 sm:align-middle">
                <div className="fixed top-2 right-2 text-gray-500 text-2xl font-bold cursor-pointer z-20 md" onClick={onClose}>&times;</div>
                <div className="flex flex-row h-full p-0">
                    <div className='relative z-10'>
                    <div className="absolute md:-top-[12%] md:-left-20 sm:-top-[12%] sm:-left-[12%] msm:-top-[20%] msm:-left-[20%] md:w-56 md:h-56  rounded-full" style={{ backgroundColor: "#FCEAB6" }}></div>
                    </div>



                    <div className="flex-1 flex gap-10 md:items-start sm:items-center msm:items-center flex-col justify-center z-40 mt-[6%] md:ml-[15%]">
                        <h4 className=" font-extrabold ">Phone Number Verified</h4>


                        <div className='md:ml-10 sm:ml-0 sm:justify-center md:justify-start flex mt-3'>
                            <Lottie
                                animationData={animi.animationData}
                                loop={true}
                                autoplay={true}
                                style={{ width: 100, height: 100 }}
                            />
                        </div>
                        <div className='sm:ml-10 md:ml-0 opacity-70 '>
                            <span className=''>Congratulations, your Phone</span><br />
                            <span className=' relative right-5' >number has been Verified. You can</span><br />
                            <span className='' >start using the app</span>
                        </div>
                    </div>

                    <div className=" lg:flex z-30 bottom-0 right-1  absolute bottom-  sm:hidden msm:hidden  md:flex justify-around items-center gap-4">
                        <div className="lg:h-[19vh] md:h-[16vh] w-auto ">
                            <img src={image1} alt='img' className='md:hidden lg:flex' />
                        </div>
                        <div className="lg:h-[52vh] md:h-[38vh] w-auto">
                            <img src={image2} alt='img' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Verified;
