import React, { useState, useEffect } from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { axiosInstance } from "../../compounts/api/APIs";
import { useNavigate } from "react-router-dom";
import { getResponsiveSettings } from "../ReuseableCompounts/CarouselResponsive";
import { Box } from "@mui/material";

const ProjectsCarousel = () => {
  const [projects, setProjects] = useState<any[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get("/api/vattara/app/homescreen/get/UpcomingProject");
        console.log(response.data.Created);
        setProjects(response.data.Created);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };
    fetchData();
  }, []);

  const responsiveSettings = getResponsiveSettings(2.2, 2, 1);

  function handleAD(value: any) {
    navigate('/upcoming-projects', { state: value });
  }

  // Filter projects to only those with a cover image
  const filteredProjects = projects.filter(item => 
    item.images?.some((img: any) => img.isCover && img.uri)
  );

  return (
    < Box sx={{ width: "100%", justifyContent: "center", alignItems: "center", display: "flex" }}>
            <Box sx={{ width: { sm: "95%", md: "95%", lg: "95%", xl: "95%",xs:"99%" }, position: "relative" }}>
    <div className="xl:mx-[20%] msm:mx-2 max-w-full mt-5">
  <p className='text-xl font-bold text-[#202020]'>        Our Upcoming Projects
      </p>

      {/* Only render the carousel if there are filtered projects */}
      {filteredProjects.length > 0 ? (
        <Carousel
          swipeable
          draggable
          showDots={false}
          responsive={responsiveSettings}
          ssr // means to render carousel on server-side.
          infinite
          autoPlay
          autoPlaySpeed={3000}
          keyBoardControl
          customTransition="transform 500ms ease-in-out"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-20-px" // Adjusted padding
        >
          {filteredProjects.map((item) => {
            // Find the cover image
            const coverImage = item.images.find((img: any) => img.isCover) || null;

            return (
              <div
                className="flex p-2 justify-center items-center"
                key={item.id} // Adding a unique key
              >
                {coverImage && (
                  <img
                    onClick={() => handleAD(item.images)}
                    className="w-[100vw] h-full object-cover rounded-lg"
                    src={coverImage.uri}
                    alt="Project Cover"
                  />
                )}
              </div>
            );
          })}
        </Carousel>
      ) : (
        <div className="text-center mt-4">No upcoming projects available.</div>
      )}
    </div>
    </Box>
    </Box>
  );
};

//   const [projects, setProjects] = useState<any[]>([]);
// const navigate =useNavigate()
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axiosInstance.get("/api/vattara/app/homescreen/get/UpcomingProject");
//         console.log(response.data.Created);
//         setProjects(response.data.Created);
//       } catch (error) {
//         console.error("Error fetching projects:", error);
//       }
//     };
//     fetchData();
//   }, []);



//   const responsiveSettings = getResponsiveSettings(2.2, 2, 1);


//   function handleAD(value:any){
//     navigate('/upcoming-projects',{state:value})
//   }

//   return (
//     <div className=" xl:mx-[20%] msm:mx-2 max-w-full mt-5">
//       <h2 className="text-[#252B5C] text-base md:text-lg lg:text-xl font-semibold mb-2 text-start">
//         Our Upcoming Projects
//       </h2>

//       <Carousel
//         swipeable
//         draggable
//         showDots={false}
//         responsive={responsiveSettings}
//         ssr // means to render carousel on server-side.
//         infinite
//         autoPlay
//         autoPlaySpeed={3000}
//         keyBoardControl
//         customTransition="transform 500ms ease-in-out"
//         transitionDuration={500}
//         containerClass="carousel-container"
//         removeArrowOnDeviceType={["tablet", "mobile"]}
//         dotListClass="custom-dot-list-style"
//         itemClass="carousel-item-padding-20-px" // Adjusted padding
//       >
//         {projects.map((item) => {
//           // Find the cover image
//           const coverImage = item.images?.find((img: any) => img.isCover) || null;

//           return (
//             <div
//               className="flex p-2 justify-center items-center"
//               key={item.id} // Adding a unique key
//             >
//               {coverImage ? (
//                 <img  onClick={()=>handleAD(item.images)}
//                   className="w-[100vw] h-full object-cover rounded-lg"
//                   src={coverImage.uri}
//                   alt="Project Cover"
//                 />
//               ) : (
//                 <div className="bg-gray-200 w-full h-full flex items-center justify-center">
//                   <p>No Image Available</p>
//                 </div>
//               )}
//             </div>
//           );
//         })}
//       </Carousel>
//     </div>
//   );
// };

export default ProjectsCarousel;
