import { Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useStepperContext } from './StepperContext';
import { blueGrey } from '@mui/material/colors';
import { updateValueInArray } from '../../PostForm/component';

interface CustomisedButtonProps {
  title?: string;
  label: string;
  inputId?: number;
  formName: string;
  required?:boolean,
  userselectedData?:any,
  data:any,
  initial:any
  setDataB:any
  baseData:any
  
}

const CustomisedButton: React.FC<CustomisedButtonProps> = ({ title,baseData,setDataB, label, inputId, formName,data,required,userselectedData,initial }) => {
  const { addUserData, userData } = useStepperContext();
  const [activeButton, setActiveButton] = useState<number | null>(null);
  console.log("re",initial,activeButton)
  useEffect(() => {
    const initValue = data.find((item:any) => (item.title === initial))
    setActiveButton(initValue?.id||null)
    console.log("readyuSe",initValue,data,initial)
  },[initial, data])



  const handleClick = (item:any) => {
    console.log("bhk item",item)
    setActiveButton(item.id);

    const updatedData =updateValueInArray(baseData,label,item.title)
    console.log("bhk update",updatedData)
    setDataB(updatedData)

    
    const data = {
      id:inputId,
      [label]:item.title,
     formName: formName,
     required:required
    };
    addUserData(data as any);
  };
  console.log(userData)

  const hasError=userselectedData?.includes(label)

  return (
    <div  >

     {data.map((item:any)=>(
      
      <Button
     
      // className='w-auto'
        // className=' w-10 '
        // style={{backgroundColor:"blue",color:"white"}}
        onClick={()=>handleClick(item)}
         sx={{
          width:"auto",
          px:2,
          borderRadius: '999px',
          my:1,
          mx:1,
          bgcolor: activeButton===item.id ? '#515AA8' : blueGrey[50], // Active or default background color
          color: activeButton===item.id ? 'white' : 'black', // Text color
          textTransform: 'capitalize', // Capitalize button text
          '&:hover': {
            bgcolor: activeButton===item.id ? '#515AA8' : blueGrey[100], // Hover background color
          },
        }}
       

       

       
      >
        {item.title}
      </Button>
     ))  
    }
    {hasError && <Typography sx={{color:"#C91C1C"}}>This field is required</Typography>}
    </div>
  );
 
}

export default CustomisedButton;
