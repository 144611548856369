// ChatScreen.tsx
import React, { useState, useEffect } from 'react';
import ChatList from '../Chat/Chatlist'; // Adjust path as needed
import SingleChat from '../Chat/SingleChat'; // Adjust path as needed
import { useChat } from '../context/ChatContext'; // Adjust path as needed
import { useLocation } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';

const ChatScreen: React.FC = () => {
  const { selectedChat } = useChat();
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(false);
  const [showChatList, setShowChatList] = useState(true);

  const {
    chatId,
    userId,
    postId,
    username,
    userpic,
    initialMessageText,
    details,
  } = location.state || {};

  useEffect(() => {
    // Detect if screen size is mobile (sm)
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480); // Tailwind's sm breakpoint (640px)
    };

    window.addEventListener('resize', handleResize);

    // Initial check
    handleResize();

    // Cleanup listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // When a chat is selected, hide the chat list on mobile
  useEffect(() => {
    if (selectedChat && isMobile) {
      setShowChatList(false);
    }
  }, [selectedChat, isMobile]);

  const handleBackToChatList = () => {
    setShowChatList(true); // Show chat list when back is clicked
  };

  return (
    <div className="chat-container grid custom-xxl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-4 h-screen custom-xxl:ml-96 xl:ml-44 lg:ml-0 md:ml-0 sm:ml-0">
      {/* Chat List Section */}
      <div
        className={`${(!selectedChat || showChatList || !isMobile) ? "block" : "hidden"
          } chat-list border-r border-gray-200 col-span-1 custom-xxl:w-full lg:w-full md:w-[300px] sm:w-[300px]`}
      >
        <ChatList />
      </div>

      {/* Single Chat Section */}
      <div
        className={`${(!showChatList || !isMobile) ? "block" : "hidden"
          } chat-content custom-xxl:col-span-2 xl:col-span-2 lg:col-span-3 md:col-span-3 sm:col-span-3 custom-xxl:ml-0 lg:ml-0 md:ml-11 sm:ml-28`}
      >
        {selectedChat ? (
          <>
            {isMobile && (
              <div>
                <IconButton onClick={handleBackToChatList}>
                  <ArrowBackIcon className="ml-4 text-black" />{' '}
                  <h2 className="ml-4 text-black">ChatList</h2>
                </IconButton>
              </div>
            )}
            <SingleChat
              chat={selectedChat}
              initialMessageText={initialMessageText}
              details={details}
            />
          </>
        ) : (
          <div className="flex items-center justify-center h-full text-gray-500">
            Select a chat to start messaging
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatScreen;
