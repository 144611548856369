import React from 'react'
import { ServiceImage } from "../data";
import ImageCarousel from './ImageCarousel';
import { axiosInstance } from "../api/APIs";
import { useNavigate } from "react-router-dom";
import MNavbar from '../homepage/MNavber';
import { Box, Button, Typography } from '@mui/material';
import { MdArrowBackIosNew } from "react-icons/md";



const ReliableServices = () => {
    const navigate = useNavigate()


    async function handleServices(label: any) {
        // const url = `/services/categoryBased/userlist/${label}`
        try {

            const response = await axiosInstance.get(`/services/categoryBased/userlist/${label}`)
            console.log(response.data.message);
            navigate('/servicesDetails', { state: { data: response.data.message, category: label } });

        } catch (error) {
            console.log("error", error)
        }
    }
    return (

        <Box sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center",backgroundColor:"#F5F4F8" }}>
        <Box sx={{ width: { sm: "95%", md: "95%", lg: "55%", xl: "95%", xs: "95%" }, position: "relative" }}>
   
  
          <Box sx={{ backgroundColor: "#F5F4F8", width: "100%", display: "flex", flexDirection: "column" }}>
       
            <Box sx={{ backgroundColor: "#fff", mx: { xl: "20%", lg: "0" },borderRadius:"20px",border:"20px" ,marginTop:"2%",paddingBottom:"2%",marginBottom:"1%"}}>
            <Box className='mt-1 mb-2 '  sx={{display:{xs:"flex",sm:"none",md:"none",lg:"none"},alignContent:"center",alignItems:"center"}}>

  <p className='text-left justify-center items-center align-content-center text-xl font-semibold  w-[100%]'>Services</p>

        </Box>  
          <Box className='mt-5 mb-5 '  sx={{display:{xs:"none",sm:"flex",md:"flex",lg:"flex"},alignContent:"center",alignItems:"center"}}>
  <button 
    onClick={() => navigate(-1)} // Inline navigation logic

  >
    <MdArrowBackIosNew className='w-8 h-8 sm:flex msm:flex md:flex' />
  </button>
  <p className='text-center justify-center items-center align-content-center text-xl font-semibold  w-[100%]'>Exciting New Services Coming Your Way!</p>

        </Box>  
              <ImageCarousel />
              <Box sx={{ border: "0.5px solid #000", opacity: 0.5, my:{xs:2,sm:3,md:4,lg:4},mx:1 }} />
              <Box sx={{ ml: 4,mb:{xs:1,sm:3,md:4,lg:4}, opacity: 0.95, lg: { mt: 4 }, msm: { ml: 2 }, sm: { ml: 4 }, fontSize: "1.25rem", fontWeight: "bold" }}>
                Find Reliable Services
              </Box>
              <Box sx={{ display: 'grid', gap: {sm:3,xs:1,lg:3,md:3},  justifyContent: "center", mx: { lg: "12%", xl: "15%",sm:"5%",xs:"5%" },marginY:"10px",marginBottom:{sm:3,xs:10,lg:3,md:3}, gridTemplateColumns: {       xs: "repeat(2, 1fr)", md: "repeat(3, 1fr)", sm: "repeat(2, 1fr)" } }}>
                {ServiceImage.map((item, index) => (
                  <Box key={index} sx={{ backgroundColor:"#E7ECEF",borderRadius:"20px",display:"flex",flexDirection:"column",justifyContent:"space-between" ,alignItems:"center",padding:"10px",    boxShadow: 1,               }}>
                    <Box sx={{fontSize:"14px",color:"#1063B5",fontWeight:"bold",marginY:{sm:"2px",xs:"0px",lg:"5px",md:"5px"}}}>{item.label}
                        </Box>
                  <Box sx={{marginBottom:"-10px"}} >
                      <img onClick={() => handleServices(item.label)} src={item.image} alt={`Service ${index}`} style={{width:"200px",height:"180px",objectFit:"contain"}} /></Box>
                  </Box>
                ))}
              </Box>
            </Box>
            
            <Box sx={{ display: { lg: "none", sm: "none", msm: "flex" }, position: "fixed", bottom: 5, zIndex: 50 }}>
              <MNavbar />
            </Box>
          </Box>
        </Box>
      </Box>
//         < Box sx={{ width: "100%", justifyContent: "center", alignItems: "center", display: "flex" }}>
  
//             <Box sx={{ width: { sm: "95%", md: "95%", lg: "55%", xl: "95%",xs:"95%" }, position: "relative" }}>
//             <Box className='flex mt-5 mb-3'  sx={{display:"flex",alignContent:"center",alignItems:"center"}}>
//   <button 
//     onClick={() => navigate(-1)} // Inline navigation logic

//   >
//     <MdArrowBackIosNew className='w-8 h-8 sm:flex msm:flex md:flex' />
//   </button>
//   <p className='text-center justify-center items-center align-content-center text-xl msm:hidden sm:flex md:flex font-semibold  w-[100%]'>Exciting New Services Coming Your Way!</p>

//         </Box>
//         <div className='bg-[#F5F4F8] w-full  min-h-screen flex flex-col'>
//             <div className='bg-white xl:mx-[20%] '>
//                 <div className=''>
//                     <ImageCarousel />
//                     <div className='border border-[#000000] opacity-50 mt-4 '></div>
//                     <div>
//                         <p className='text-left ml-4 opacity-95 lg:mt-4 msm:ml-2 sm:ml-4 text-xl font-semibold'>Find Reliable Services</p>
//                         <div className='md:grid md:grid-cols-3 msm:grid msm:grid-cols-2 sm:ml-10 mt-4 lg:ml-12  xl:mx-[15%] gap-3 msm:mx-2 lg:mx-0 justify-center mb-10 items-center '>
//                             {
//                                 ServiceImage.map((item, index) => (
//                                     <div key={index} className='sm:mt-5 msm:mt-0 '>
//                                         <img onClick={() => { handleServices(item.label) }} src={item.image} alt={`Service ${index}`} className='' />
//                                     </div>
//                                 ))
//                             }
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div className={`lg:hidden sm:hidden msm:flex fixed bottom-5  z-50`}>
//                 <MNavbar />
//             </div>
//             <div>
                
//             </div>
//         </div>
//         </Box></Box>
    )
}

export default ReliableServices