import React, { useState,useEffect } from 'react';
import { useSelector } from "react-redux";
import { axiosInstance } from "./api/APIs";
import ListingItem from "../compounts/ListingItem";
import { Box, Skeleton } from '@mui/material';
import { MdArrowBackIosNew } from "react-icons/md";
import { Link } from 'react-router-dom';

interface Property {
  id: number;
  post_id: number;
  type: string;
  property_type?: any;
  looking_to?: string;
  rent_or_lease?: string;
  property_available_for?: string;
  images?: Image[];
  view_post_count: number;
  bhk: string;
  city: string;
  building_name: string;
  landmark_street: string;
  build_up_area?: string;
  built_up_area?: string;
  plot_area?: string;
  PriceperSqft?: string;
  price: number;
  cost?: number;
  monthly_rent?: number;
  enter_lease_amount?: number;
  lease_amount?: any;
  liked?: any;
  district:any
  user_id:any
  active: boolean;
}
interface ListingItemProps {
  list: Property[];
}
interface Image {
  filename: string;
  id: string;
  isCover: boolean;
  isvisible: boolean;
  name: string;
  type: string;
  uri: string;
}
const Listings: React.FC = () => {
  const [filter, setFilter] = useState<string>('Residential');
  const [list, setlist] = useState<Property[]>([]);
  const loginData = useSelector((item: any) => item.userData.userLoginData);
  const [visiable,setVisable]= useState<any>(false)
  const [loading,setLoading] = useState<boolean>(false)
 const [render, setRender] = useState<any>(false)


  useEffect(() => {
    handleList(filter);
  
  }, [filter,render]);
  console.log(loginData.user_id);

  async function handleList(value: any) {
    setLoading(true)
    setFilter(value);
    const body = {
      user_id: loginData.user_id,
      property_sub_type: value
    };
    console.log(body);

    try {
      const response = await axiosInstance.post("/filter/myProperty", body);
      console.log(response.data.message);
      setlist(response.data.message);
      setLoading(false)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  return (
    <div className=' xl:mt-5 lg:mt-9 sm:mt-9 md:mt-10  mb-4 '>

       <div className='flex items-center msm:mt-2 font-semibold custom-xxl:ml-[20%] custom-xl:ml-[18%] lg:ml-[12%] xxl:ml-[15%]'>
       <Link to={'/profile'}>
        <MdArrowBackIosNew className=' w-8 h-8 top-2 left-1 z-40 lg:hidden md:flex' />
      </Link>
          <p>Home.</p>
          <p>Profile.</p>
          <p>My Listings</p>
        </div>
      <div className={`${visiable?"hidden":"flex mt-10 custom-xxl:ml-[30%] custom-xl:ml-[18%] lg:ml-[12%] xxl:ml-[15%]"} `}>
       
        <div className="bg-[#F5F4F8] rounded-[30px] p-1 relative">
          <div className="button-box relative flex h-auto w-full">
            <div
              id="btn"
              className={`bg-[#2E368F] rounded-[30px] absolute top-0 transition-all duration-300 ${filter === 'Commercial' ? 'left-1/2' : 'left-0'}`}
              style={{ width: '50%', height: '100%' }}
            ></div>
            <button
              type="button"
              className={`toggle-btn mr-3 relative z-10 ${filter === 'Residential' ? 'text-white rounded-l-full flex justify-center' : 'text-black hover:text-white'}`}
              onClick={() => handleList('Residential')}
              style={{ padding: '7px 45px', fontSize: '14px' }}
            >
              Residential
            </button>
            <button
              type="button"
              className={`toggle-btn relative ml-3 z-10 ${filter === 'Commercial' ? 'text-white rounded-r-full' : 'text-black hover:text-white'}`}
              onClick={() => handleList('Commercial')}
              style={{ padding: '7px 45px', fontSize: '14px' }}
            >
              Commercial
            </button>
          </div>
        </div>
      </div>
      <div className={`${visiable?"":"lg:mx-[12%] md:mx-[20% xl:mx-[18%]"}`}>
       {loading?
       <div>
       <p>loading...</p>
      <Skeleton animation="wave" sx={{height:200}} />
      <Skeleton animation="wave" sx={{height:200}}/>
      <Skeleton animation="wave" sx={{height:200}}/>
       </div> :<ListingItem list={list} render={render} setRender={setRender} filter={filter} visiable={visiable} setVisable={setVisable}/>}
      </div>
    </div>
  );
};

export default Listings;
