import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { ErrorMessage, Form, Formik } from 'formik';
import CustomisedButton from './CustomisedButton';
import { useStepperContext } from './StepperContext';
import ApartmentIcon from '@mui/icons-material/Apartment';
import BusinessIcon from '@mui/icons-material/Business';
import TextBox from './TextBox';
import { basic_details } from '../data';
import DropBox from './DropBox';
import DropDownBox from './DropDownBox';
import CustomisedButton1 from './CustomisedButton1';
import { tndistrict } from './index';

const icons = {
  ApartmentIcon: <ApartmentIcon />,
  BusinessIcon: <BusinessIcon />,
};

interface NextProps {
  onNext: () => void;
  onBack: () => void;
  onReset: () => void;
}

const Basic: React.FC<NextProps> = ({ onNext, onBack, onReset }) => {
  const { userData } = useStepperContext(); // Access currentPage from StepperContext
  const [userselectedData, setuserSelectedData] = useState([]);

  const generateValidationSchema = (basic_details: any, userData: any) => {
    // Filter required labels from form fields
    const requiredLabels = basic_details.fields
      .filter((field: any) => field.required)
      .map((field: any) => field.label);
    console.log('require', requiredLabels);
    // const missingLabels=requiredLabels
    // console.log('Missing Labels:', missingLabels);
    const existingLabels = requiredLabels.filter(
      (label: any) =>
        // Check if any object in userData has a property matching the current label
        !userData.some((item: any) => item.hasOwnProperty(label))
    );
    console.log('exist', existingLabels);

    if (existingLabels.length > 0) {
      setuserSelectedData(existingLabels);
    } else {
      onNext();
    }
  };

  const initialValues = {
    selectedPropertyType: '',
    lookingTo: '',
    city: '',
    locality: '',
    landMark: '',
  };

  const getInitialValue = (label: any) => {
    const entry = userData.find((obj) => obj.hasOwnProperty(label));
    return entry ? entry[label] : '';
  };

  return (
    <div className="w-[100%] min-h-[1000px] p-2 overflow-hidden gap-4">
      {/* <p>Current Page: {currentPage}</p> */}
      <Typography
        style={{
          fontWeight: '700',
          marginLeft: '40%',
        }}
      >
        Basic Details
      </Typography>

      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          generateValidationSchema(basic_details, userData);

          console.log(values);
          setSubmitting(false);
        }}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit} className="form">
            {basic_details.fields.map((basicItem, basicIndex) => (
              <div key={basicIndex}>
                {basicItem.type === 'button' && (
                  <div className=" mt-4 ">
                    <Typography className="text-start gap-28  ">
                      {basicItem.label}
                    </Typography>
                    <div className="flex w-[100px]">
                      <CustomisedButton1
                        data={basicItem.data}
                        label={basicItem.label}
                        initial={getInitialValue(basicItem.label)}
                        inputId={basicItem.inputId}
                        formName={'Basic Details'}
                        required={basicItem.required}
                        userselectedData={userselectedData}
                      />
                    </div>
                  </div>
                )}
                {basicItem.type === 'DropDownBox' && (
                  <div className="w-[100%] mt-4">
                    <Typography
                      sx={{ marginY: '12px' }}
                      className="text-start w-[100%]"
                    >
                      {basicItem.label}
                    </Typography>

                    <DropDownBox
                      label={basicItem.label}
                      options={basicItem.data}
                      // title={basicItem.title}
                      inputId={basicItem.inputId}
                      initial={getInitialValue(basicItem.label)}
                      formName={'Basic Details'}
                      required={basicItem.required}
                      userselectedData={userselectedData}
                    />
                  </div>
                )}

                {basicItem.type === 'DropDown' && (
                  <div>
                    <Typography
                      sx={{ marginTop: '30px' }}
                      className="text-start w-[100%]"
                    >
                      {basicItem.title}
                    </Typography>

                    <DropBox
                      label={basicItem.label}
                      options={tndistrict.districts}
                      // title={basicItem.title || ''}
                      inputId={basicItem.inputId}
                      formName={'Basic Details'}
                      initial={getInitialValue(basicItem.label)}
                      required={basicItem.required}
                      userselectedData={userselectedData}
                    />
                  </div>
                )}

                {basicItem.type === 'textField' && (
                  <div className="my-4">
                    <TextBox
                      label={basicItem.label}
                      inputId={basicItem.inputId}
                      // defaultValue={initialValues.locality}
                      formName={'Basic Details'}
                      initial={getInitialValue(basicItem.label)}
                      required={basicItem.required}
                      userselectedData={userselectedData}
                    />
                  </div>
                )}
              </div>
            ))}

            <button
              type="submit"
              className="w-[150px] bg-[#2E368F] text-white rounded-[20px]  mt-6 custom-xxl:ml-[80%] xl:ml-[80%] lg:ml-[75%] md:ml-[70%] sm:ml-[70%] p-2"
            >
              Continue
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Basic;
