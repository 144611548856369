
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import MessageIcon from "../images/MessageIcon.png";
import { Avatar, Box, Button, Modal, Card, CardActions, CardContent, CardMedia, Checkbox, Divider, FormControlLabel, Icon, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, CircularProgress } from '@mui/material';
import Default from "../images/default.png";
import AD from "../images/AD.png"
// import MessageIcon from "./images/chat.png";
import phone from '../images/phone.png';
import { FaChevronLeft, FaChevronRight, FaGreaterThan } from "react-icons/fa";

import { useSelector } from 'react-redux';
import Axios from 'axios';
import { axiosChat, axiosInstance } from '../api/APIs';
import view from "../images/view.png";

import { handleChange } from '../ReuseableCompounts/PostDetail';
import heart from '../images/Heart.png'
import LikedHeart from '../images/LikedHeart.png'
import { useLocation } from "react-router-dom";
import { dataAmenities } from '../data';
import { truncateString } from "../ReuseableCompounts/TruncateString";
import star from '../images/star.svg'
import { formatPrice } from "../ReuseableCompounts/PriceFormat";
import { formatSQPrice } from "../ReuseableCompounts/PriceSQFormat";
import DefultScroll from '../images/defaultScroll.png'
import noPer from "../images/noProperty.svg";
// import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { getResponsiveSettings } from "../ReuseableCompounts/CarouselResponsive";
import "bootstrap/dist/css/bootstrap.min.css";
import { Carousel } from "react-bootstrap";
import goldtick from '../images/goldtick.png'
import profile from '../images/profile.png'
import chat from '../images/chat.svg'
import { GoHeart } from "react-icons/go";
import { GoHeartFill } from "react-icons/go";
import loaction from '../images/location.png'
import { useChat } from '../context/ChatContext';


interface SimilarProperty {
  message: Property[];
  userDetails: {
    user_id: any;
    name: any;
    mobile_no: any
    category: any
    user_pic: any
  }
}
interface Property {
  sublocality: string;
  id: number;
  post_id: number;
  property_type?: any;
  looking_to?: string;
  rent_or_lease?: string;
  property_available_for?: string;
  images?: Image[];
  view_post_count: number;
  bhk: string;
  city: string;
  building_name: string;
  landmark_street: string;
  build_up_area?: string;
  built_up_area?: string;
  plot_area?: string;
  PriceperSqft?: string;
  price: number;
  cost?: number;
  monthly_rent?: number;
  enter_lease_amount?: number;
  lease_amount?: any
  liked?: any
  user_id?: any
  kbl_property: any
  district: any

}
interface Image {
  filename: string;
  id: string;
  isCover: boolean;
  isvisible: boolean;
  name: string;
  type: string;
  uri: any;
}
interface scroll {

}

const Builder: React.FC<scroll> = () => {
  const location = useLocation()
  const users = location.state.rows
  const look = location.state.look
  console.log("hello the builter", look);

  const loginData = useSelector((state: any) => state.userData.userLoginData)
  const navigate = useNavigate();
  const [currentIndexList, setCurrentIndexList] = useState<number[]>([]);
  const [ad, setAd] = useState<any[]>([])
  const [chatvalue, setChatValue] = useState<any>("")
  const { setSelectedChat } = useChat();
  const [loadingCardId, setLoadingCardId] = useState<number | null>(null); // Track loading card ID

  const [sortedUsers, setSortedUsers] = useState<Property[]>([]);
  const [likedState, setLikedState] = useState<Record<number, boolean>>({});
  const [like, setlike] = useState<any>(users.map((item: any) => (item.liked)))
  const Bview ="Bview"
  const [similarProperty, setsimilarProperty] = useState<SimilarProperty>({
    message: [],
    userDetails: {
      user_id: '',
      name: '',
      mobile_no: '',
      category: '',
      user_pic: ''

    }
  });
  const [open, setOpen] = useState<any>(false);

  const handleClose = () => {
    // console.log("jheki");

    setOpen(false);
  }

  useEffect(() => {
    setSortedUsers([...users]); // Initialize sortedUsers with users array
    setCurrentIndexList(Array(users.length).fill(0));

    const initialLikedState = users.reduce((acc: Record<number, boolean>, user: Property) => {
      acc[user.post_id] = user.liked || false;
      return acc;
    }, {});
    setLikedState(initialLikedState);
  }, [users]);



 



  async function handleContact(
    property: any,
    looking: any,
    user: any,
    post: any,
    dis: any,
    buld: any,
    bhk: any,
    image: any,
  ) {
    console.log(property, looking);



    if (!loginData || loginData.phone === '' || loginData.phone === null) {
      navigate('/contact');
    } else {
      console.log('work');

      const chatBody: any = {
        property: property,
        user: user,
        post: post,
        looking_to: looking,
        district: dis,
        building_name: buld,

        Bhk: bhk,
        image: image,
      };

      setChatValue(chatBody)
      console.log("chat check", chatBody)
      setOpen(true);

      try {
        const Response = await axiosInstance.get(
          `/api/ads/allAds/${'Property Contact View Ads'}`
        );
        setAd(Response.data.message);
        console.log(Response.data.message);
      } catch (error) {
        console.log('error', error);
      }
      console.log('Ad', ad);

      const body = {
        user_id: loginData.user_id,
        property_type: property,
        looking_to: looking,
        post_id: post,
        post_user_id: user,
      };
      console.log(body);

      try {
        const response = await axiosInstance.post(
          '/filter/contactUserDetail',
          body
        );
        setsimilarProperty(response.data);
        console.log(response.data);
        console.log('hello', similarProperty);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  }
  // console.log(liked);

  const handleSaved = async (id: number, property: any, look: any, index: any) => {

    if (loginData.user_id === undefined) {
      navigate('/contact')
    } else {
      const newLikedState = !likedState[id];
      setLikedState((prevState) => ({ ...prevState, [id]: newLikedState }));
      if (like === index) {
        setlike(false);
      } else {
        setlike(index);
      }
      const body = {
        post_id: id,
        liked_user_id: loginData.user_id,
        property_type: property,
        rent_or_sell: look,
        liked: newLikedState,
      };

      try {
        const response = await axiosInstance.post('/like/postLike', body);
        console.log('response', response);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };
  console.log(sortedUsers);

  function valueLabelFormat(value: number) {
    if (value >= 1000000000) {
      return (value / 10000000).toFixed(2) + ' Cr';
    }
    if (value >= 10000000) {
      return (value / 10000000).toFixed(2) + ' Cr';
    }
    if (value >= 1000000) {
      return (value / 100000).toFixed(2) + ' Lac';
    }
    if (value >= 100000) {
      return (value / 100000).toFixed(2) + ' Lac';
    }
    if (value >= 10000) {
      return (value / 1000).toFixed(0) + 'k';
    }
    if (value >= 1000) {
      return (value / 1000).toFixed(1) + 'k';
    }
    return value.toString();
  }



  // Handle string price
  const formatPrice = (price: string | number): string => {
    if (typeof price === 'number') {
      return valueLabelFormat(price);
    }

    // Handle string price
    const numericPrice = parseFloat(price.replace(/[^\d.-]/g, ''));
    return valueLabelFormat(numericPrice);
  };
  async function handleReq( userId: any, postid: any, property: any,looking: any,dis: any, buld: any, bhk: any,image: any,){
    console.log('chat click',postid);
    const chatBody: any = {
      property: property,
      user: userId,
      post: postid,
      looking_to: looking,
      district: dis,
      building_name: buld,
  
      Bhk: bhk,
      image: "https://vattara-img.s3.amazonaws.com/image_1727274600329.jpg",
    };
  
    setChatValue(chatBody)
      if(loginData=== undefined){
        navigate('/contact')
      } else{
        console.log('chat click',chatvalue);
        try {
          const response = await axiosChat.post('/api/chat', {
            userId,
            req_user_id: loginData.user_id,
          });
          console.log('chat click', response.data);
          if (response.status === 200) {
            console.log('Chat created successfully');
            console.log('ss', sortedUsers);
            console.log('gg', response);
    
            const data = response.data.users.filter(
              (item: any) => item.user_id !== loginData.user_id 
            );
            console.log(data);
  
            setSelectedChat({
              chatId: response.data.id,
              userId: data[0].user_id,
              username: data[0].name,
              userpic: data[0].user_pic,
            });
    
            navigate('/chatscreen', {
              state: {
                chatId: response.data.id,
                userId:userId,
                postId: chatvalue.post,
                username: response.data.users[0].name,
                userpic: response.data.users[0].user_pic,
                initialMessageText: ` Request Image for this property ${buld !==null?buld:""} ${property}  ${dis}`,
                details: {
                  userId: userId,
                  postId: postid,
                  looking_to: looking,
                  district:dis,
                  building_name: buld,
                  property_type: ` ${chatvalue.property}`,
                  Bhk: bhk,
                  image: "https://vattara-img.s3.amazonaws.com/image_1727274600329.jpg",
                },
              },
            });
          } else {
            console.error('Failed to create chat. Status code:', response.status);
          }
        } catch (error) {
          console.error('Error creating chat:', error);
        }
      }
    }
  

  const handleChat = async (
    userid: any,


  ) => {
    const userId = userid;



    console.log('User ID:', userId, look, chatvalue);

    try {
      const response = await axiosChat.post('/api/chat', {
        userId,
        req_user_id: loginData.user_id,
      });
      console.log('chat click', response.data);
      if (response.status === 200) {

        console.log('gg', response);

        const data = response.data.users.filter(
          (item: any) => item.user_id !== loginData.user_id
        );
        console.log(data);
        setSelectedChat({
          chatId: response.data.id,
          userId: data[0].user_id,
          username: data[0].name,
          userpic: data[0].user_pic,
        });

        navigate('/chatscreen', {
          state: {
            chatId: response.data.id,
            userId,
            postId: chatvalue.post,
            username: response.data.users[0].name,
            userpic: response.data.users[0].user_pic,
            initialMessageText: `${chatvalue.building_name} ${chatvalue.property} ${chatvalue.district}`,
            details: {
              userId: chatvalue.user,
              postId: chatvalue.post,
              looking_to: chatvalue.looking,
              district: chatvalue.dis,
              building_name: chatvalue.buld,
              property_type: chatvalue.property,
              Bhk: chatvalue.bhk,
              image: chatvalue.image,
            },
          },
        });
      } else {
        console.error('Failed to create chat. Status code:', response.status);
      }
    } catch (error) {
      console.error('Error creating chat:', error);
    }
  };

  return (
    <div className=' mt-2 xl:mx-[15%]'>
      <div className='bg-[#F5F4F8] p-3 mt-20'>
        <div className='flex gap-3 ml-5'>
          <Avatar className='' alt={look?.name} src={look?.company_logo} sx={{ width: 56, height: 56 }} />
          <div>
            <p className='text-lg font-semibold'>{look.company_name || look.name}</p>
            <div className='flex items-center gap-1'>
              <p>{look.category}</p>
              <p className='border-1 border-black h-4 opacity-50'></p>
              <p>+91 91*******01</p>
            </div>

          </div>

        </div>
        <div className='border-1 border-[#8E8E8E] opacity-75 my-2 ml-5'></div>
        <p className='ml-5 text-lg font-semibold'>Properties listed by <span>{look.company_name}</span></p>
      </div>

      <div className='custom-scrollbar grid grid-cols-2 lg:grid-cols-3 sm:grid-cols-2 msm:grid-cols-1 custom-lg:grid-cols-3 custom-xl:grid-cols-4 md:grid-cols-3 gap-3 rounded-xl relative  bg-white mt-2 p-4 lg:mx-{20%]'  >
        {sortedUsers && sortedUsers.length > 0 && users !== 'No property' ? (
          sortedUsers.map((item: Property, index: number) => {
            const price: any = item.price || item.cost || item.enter_lease_amount || item.lease_amount || (item.monthly_rent ? `${item.monthly_rent} / Month` : null);
            const displayPrice = formatPrice(price);
            const coverimg: any = item.images && item.images.length > 0 ? item.images.find(img => img.isCover) || "" : "";
            const sqtv: any = item.PriceperSqft ? item.PriceperSqft : "";
            const priceSQTV = formatSQPrice(sqtv);
            const propertyTypeDisplay = item.property_type === "Plot / Land" ? "Plot" : item.property_type;

            const locality = truncateString(item.city, 28)

            const propertyView = {
              postId: item.post_id,
              property_type: item.property_type,
              looking: item.looking_to || item.rent_or_lease || item.property_available_for,
              userId: loginData.user_id,
              Bview:Bview

            }

            return (

              <div className={` card-${item.post_id}`} key={item.post_id}>
              <div
  style={{
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
    transition: 'transform 0.2s, box-shadow 0.2s', 
    padding:"4px",
    borderRadius:"15px"

  }}
  className={`bg-[#F5F5F5] relative  w-full  xl:w-full 2xl:w-full '} 
    ${window.innerWidth >= 1068 ? 'hover:shadow-lg hover:scale-105' : ''}`} 
>         
                    <div className='relative'>

                    <img 
 className='w-full h-[230px] sm:h-[300px] lg:h-[200px] xl:h-[240px]'                                                onClick={() => { handleChange(propertyView) }}
style={{borderRadius:"15px"}}
                      src={typeof coverimg === 'object' && coverimg?.uri ? coverimg.uri : DefultScroll}

                      alt={`Property ${index} Image`}
                    />
                      {loadingCardId === item.post_id && (
                                            <div className="absolute inset-0 flex justify-center items-center bg-opacity-50 bg-gray-800 z-10" style={{borderRadius:"20px"}}>
                                                <CircularProgress color="inherit" /> {/* Loader overlay */}
                                            </div>
                                        )}
                    <div style={{padding:"5px"}} onClick={()=>{handleReq(item.user_id,item.post_id, item.property_type,
                            item.looking_to ||
                            item.rent_or_lease ||
                            item.property_available_for,
                            item.district,
                            item.building_name,

                            item.bhk,
                            coverimg.uri)}} className={`${coverimg == "" ? "flex gap-1 items-center cursor-pointer  bg-[#F5F4F880] text-[#252B5C] border-[#252B5C] border-1 left-[50%] transform -translate-x-[50%] rounded-full" : "hidden"} absolute bottom-4`}>
                     
                      <Box sx={{fontSize:"10px",color:"#252B5C"}}>Request Images</Box>
                      <FaGreaterThan style={{ width: '14px', height: '14px' }}  />

                    </div>

<div className={`${item.kbl_property ? "flex" : "hidden"}  items-center  absolute bottom-0  p-1 left-0`} style={{borderTopRightRadius:"15px",borderBottomLeftRadius:"15px" ,background: 'linear-gradient(90deg, #1063b5,#02a7e4, #0cb7d1)' }}>
                    <img className="w-4 h-4" src={star} />
                    <div className="text-white mr-1" style={{fontSize:"8px"}}>VATTARA VERIFIED</div>
                  </div>            
                    <Box
      sx={{
        position: 'absolute',
        top: 5,
        left: 5,
   
        cursor: 'pointer',
        backgroundColor: '#D9D9D9',
        borderRadius: '20px',
        padding: '3px',
        opacity: 0.8,
        transition: 'opacity 0.3s',
        '&:hover': {
          opacity: 1, // Change opacity on hover
        },
      }}
      
        onClick={() => handleSaved(item.post_id, item.property_type, item.looking_to || item.rent_or_lease || item.property_available_for, index)}
        >
       <> {likedState[item.post_id] ? (
          <GoHeartFill className='text-red-500' size={25} style={{marginTop:"2px"}}/>
        ) : (
          <GoHeart className='text-[#fff]'  size={25}  style={{marginTop:"2px"}}/>
        )}</>
      </Box>
                  </div>
                  <div className='absolute rounded-bl-xl h-6 top-7 right-1 flex items-center justify-center text-white bg-[#234F68B2] opacity-90 p-1'>
                    <img src={view} className='mr-0.5 w-4 h-4' alt="View Icon" />
                    <div style={{fontSize:"10px"}}>{item.view_post_count} Viewers</div>
                  </div>
                  <div>
                    <div className='w-[100%] justify-between items-center mt-1 flex'>
                      <div className='capitalize w-[75%] text-[#252B5C] ml-1 justify-center flex flex-col'>

                      <Box 
      sx={{ 
        display: 'flex', 
        // backgroundColor: "red",
        flexDirection: "row",
        alignItems: 'center', 
        position: 'relative', 
        justifyContent: "flex-start",
        overflow: 'hidden', // Prevent overflow
        maxWidth: '100%', // Ensure the Box respects the parent width
      }}
    >
      <img src={goldtick} alt="Gold Tick" className='w-5 h-5' />
      <Typography
        sx={{ 
          fontSize: '16px', // Adjust font size
          fontWeight: 'bold', 
          overflow: 'hidden', 
          fontFamily: 'K2D, sans-serif', // Apply K2D font family
color:"#000",
          whiteSpace: 'nowrap', // Ensure text does not wrap
          textOverflow: 'ellipsis', // Add ellipsis for overflow
        }}
      >
        {item.bhk} {propertyTypeDisplay} for {item.looking_to}{item.property_available_for} in {item.sublocality}
      </Typography>
    </Box>
                        <div>
                        <Typography
        sx={{ 
          fontSize: '14px', // Adjust font size
          // fontWeight: 'bold',
          color:"#000", 
          overflow: 'hidden', 
          fontFamily: 'K2D, sans-serif', // Apply K2D font family

          whiteSpace: 'nowrap', // Ensure text does not wrap
          textOverflow: 'ellipsis', // Add ellipsis for overflow
        }}
      >{item.landmark_street}, {item.district} </Typography>
                        </div>
                      </div>

                      <div className='flex gap-1 ' style={{width:"25%" ,height:"70px",display:"flex",alignItems:"center"}}>
                      {/* <Divider orientation="vertical" variant="middle" sx={{backgroundColor:"#000",color:"#000", width:"1px",height:"50px"}} /> */}

                        <div className="border-solid border-0.5 border-l border-[#404040] h-10 lg:h-10"></div>
                        <div className='flex flex-col  ' style={{right:0,left:0}}>
                          <div  style={{display:"flex",justifyContent:"left",flexWrap:"wrap",alignItems:"center"}}>
                            <div className='text-[#404040] ' style={{fontSize:"15px",textAlign:"left",display:"flex",marginRight:"2px"}}>{item.build_up_area || item.built_up_area} {item.plot_area}{" "}</div>
                            <div className='text-[#404040]' style={{fontSize:"12px"}}> sq.ft.</div>
                          </div>
                          <div style={{ display: "flex", justifyContent: "left", flexWrap: "wrap", alignItems: "center" }}>
  {item.PriceperSqft && (
    <div className='text-[#404040]' style={{ fontSize: "15px" }}>
      ₹ {priceSQTV} <span style={{ fontSize: "10px" }}>/ sq.ft</span>
    </div>
  )}
</div>
                        </div>
                      </div>
                    </div>
                    <div className='flex justify-between ml-2 items-center mb-1 mt-2'>
                      <div>
                        <p className='text-xl font-medium '> {displayPrice}</p>
                      </div>
                      <div className='flex items-center  gap-3'>
                        {/* <div
                          onClick={() =>
                            handleChat(
                              item.user_id,
                              item.post_id,
                              item.looking_to,
                              item.district,
                              item.building_name,
                              item.property_type,
                              item.bhk
                            )
                          }
                          className="flex bg-[#D9D9D9] p-2 rounded-lg gap-1 items-center"
                        >
                          <img className='h-4' src={MessageIcon} alt='Chat Icon' />
                        </div> */}
                        <button onClick={() =>
                          handleContact(
                            item.property_type,
                            item.looking_to                ||
                            item.rent_or_lease ||
                            item.property_available_for,
                            item.user_id,
                            item.post_id,
                            item.district,
                            item.building_name,

                            item.bhk,
                            coverimg.uri
                          )
                        } className='h-7 p-2  rounded-lg flex gap-1 items-center  bg-[#1063b5] ' >
                          <p className='font-normal text-base text-[#FFF] opacity-95'>Contact</p>
                          <img src={phone} alt='Phone Icon' className='h-4 w-4'  />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className='flex absolute top-4 justify-center items-center '>
            <img src={noPer} />
            <p className='absolute bg-[#D1D1D1] py-3 px-6 rounded-3xl'>Can’t found properties nearby you</p>
          </div>
        )}
        <div className='md:hidden sm:hidden hidden lg:hidden xl:grid fixed z-10'>

        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="contact-modal-title"
        aria-describedby="contact-modal-description"
      >
        <Box >
          <div className='absolute top-[7%] right-[5%] w-[35%] xl:mx z-20 transform rounded-2xl bg-white   outline-none'>
            <div className="z-20  ">
              <div className='flex mt-10 flex-col mx-[20%]'>
                <div className='flex  gap-10'>
                  <div>
                    <img className='w-20 h-20 bg-black rounded-full' src={similarProperty.userDetails.user_pic ? similarProperty.userDetails.user_pic : profile} alt="User" />
                  </div>
                  <div>
                    <p className='text-lg font-semibold'>{similarProperty.userDetails?.name}</p>
                    <p>{similarProperty.userDetails?.category}</p>
                    <p className='text-lg font-semibold'>{`+91 ${similarProperty.userDetails?.mobile_no?.slice(3)}`}</p>
                  </div>
                </div>

                <div className='flex mt-10 justify-between' >
                  <div className={`${similarProperty.userDetails?.category !== "Owner" ? "flex" : "hidden"} bg-[#2E368F] py-3 px-16 gap-1 rounded-full  justify-center items-center`}>
                    <img src={phone} alt="Phone" />
                    <p className='text-white'>Call</p>
                  </div>
                  <div onClick={() =>
                    handleChat(
                      similarProperty.userDetails.user_id,

                    )
                  } className={` bg-[#2E368F] py-3 px-16 gap-1 rounded-full flex justify-center items-center`}>
                    <img src={chat} alt="Chat" />
                    <p className='text-white flex items-center justify-center'>Chat</p>
                  </div>
                </div>
              </div>

              <div className="border-solid border-1 border-t mt-3 mx-3 border-[#DBDBDB] mr- msm:text-right "></div>



              <Carousel className=" mt-4 px-3  w-[100%]" indicators={false}>
                {ad.map((item) => (
                  <Carousel.Item key={item.id} interval={2000}>
                    <Link
                      className="flex "
                      to={`/upcoming-projects/${item.id}`}
                    >
                      {item ? (
                        <img
                          className="object-cover w-full rounded-2xl h-[25vh] "
                          src={item.link}
                          alt={`Project ${item.id}`}
                        />
                      ) : (
                        <div className="bg-gray-200 w-full  h-full flex items-center justify-center">
                          <p>No Image Available</p>
                        </div>
                      )}
                    </Link>
                  </Carousel.Item>
                ))}
              </Carousel>



              <div>
                {similarProperty.message?.length > 0 && (
                  <p className='text-xl font-semibold my-4 ml-5'>Similar Properties</p>
                )}
                <div className='flex gap-3 mx-3  overflow-x-scroll w-[90%]'>
                  {similarProperty.message?.map((item: Property, index: number) => {
                    const price = item.price || item.cost || item.enter_lease_amount || item.lease_amount || item.monthly_rent;
                    const formattedPrice = formatPrice(price);
                    const coverim: any = item.images && item.images.length > 0 ? item.images.find(img => img.isCover) || "" : "";
                    const locality = truncateString(item.city, 20)
                    const sqtv: any = item.PriceperSqft ? item.PriceperSqft : "";
                    const priceSQTV = formatSQPrice(sqtv);

                    const propertyView = {
                      postId: item.post_id,
                      property_type: item.property_type,
                      looking: item.looking_to || item.rent_or_lease || item.property_available_for,
                      userId: loginData.user_id
                    }

                    return (
                      <div key={index} className=' '>
                        <div className='relative mt- bg-[#FFFFFF]    w-[12vw] h-[30vh] border-[#000080] border-opacity-30 border-2 p-2 rounded-2xl  '>
                          <div className='relative '>

                            <p className='absolute right-0 mr-1  top-3 bg-[#2E368F] text-white py-1 px-3 rounded-2xl '>{item.looking_to || item.rent_or_lease || item.property_available_for}</p>
                            <img onClick={() => { handleChange(propertyView) }}
                              className='w-[15vw] h-[20vh]  flex justify-center bg-black items-center object-contain  rounded-3xl ' src={typeof coverim === 'object' && coverim?.uri ? coverim.uri : ''} />

                            <div className={`${item.kbl_property ? "flex" : "hidden"}  items-center bg-[#6135DD] absolute bottom-0 rounded-bl-3xl p-1 rounded-tr-3xl left-0`}>
                              <img className="w-5 h-5" src={star} />
                              <p className="text-white text-sm mr-1">VATTARA VERIFIED</p>
                            </div>
                          </div>
                          <div className='ml-3'>
                            <div className='flex items-center '>
                              <p className='text-base flex font-bold'>{item.bhk} {item.property_type}   </p>
                              <img src={goldtick} />
                            </div>
                            <div className='flex gap-2 items-center'>
                              <img className="h-5" src={loaction} />
                              <p className='text-sm capitalize'>{locality}</p>

                            </div>

                            <div className='flex items-center gap-2 '>
                              <p className='text-xl font-bold text-[#404040] '>₹ {formattedPrice} </p>
                              <div className='border-[#404040] h-6 border-1 border-l  '></div>
                              <div className='ml-2'>
                                <div className='flex flex-wra items-center text-sm font-medium text-[#404040]  '>
                                  <p>{item.built_up_area || item.plot_area} </p>
                                  {item.built_up_area || item.plot_area ? <p className='text-sm'>sq.ft</p> : ""}
                                </div>
                                <div className='flex items-center text-sm  font-medium text-[#404040]'>

                                  {item.PriceperSqft ? <p>₹ {priceSQTV}</p> : ""}
                                  {item.PriceperSqft ? <p>/sq.ft</p> : ""}
                                </div>

                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    )
                  })
                  }
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Builder;