import React from 'react';
import { FaChevronLeft } from "react-icons/fa";
import propertyImage from "../../assets/Propertimg.png";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { useSelector } from 'react-redux';
interface AdditionalDetails {
  bathroom: string;
  balcony: string;
  preferred_tenant_type: string;
  parking: string;
  carpet_area: string;
  // Add other fields if necessary
}

interface User {
  name: string;
  mobile_no: string;
  category: string;
  company_logo: string;
  company_name: string;
}

interface PropertyImage {
  filename: string;
  id: number;
  isCover: boolean;
  name: string;
  type: string;
  uri: string;
}

interface Property {
  active: boolean;
  additional_details: AdditionalDetails;
  available_from: string;
  bhk: string;
  building_name: string;
  built_up_area: string;
  city: string;
  createdAt: string;
  delete_post_reason: string;
  district: string;
  enter_lease_amount: string;
  flat_furnishing: string;
  furnish_type: string;
  home_screen_post: boolean;
  id: number;
  images: PropertyImage[];
  kbl_property: boolean;
  landmark_street: string;
  latitude: string;
  liked: boolean;
  longitude: string;
  monthly_rent: string;
  negotiable: string;
  post_approval: string;
  post_id: string;
  propertyID: string;
  property_available_for: string;
  property_type: string;
  res_or_com: string;
  security_deposit: string;
  shareUrl: string;
  society_amenities: any; // Adjust type based on actual data or make it optional
  state: string | null;
  sublocality: string;
  tbl_user_reg: User;
  updatedAt: string;
  user_id: string;
  view_post_count: number;
  looking_to:any
  price:any
  cost:any
  lease_amount:any
  rent_or_lease:any
  property_available:any
}

interface DetailsProps {
  passedData?: Property; 
  PassedData?:any
}
const Header: React.FC<DetailsProps> = ({ PassedData }) => {
     const passedData = useSelector((state: any) => state.usersInfo.users);
  

  // Find the cover image from the images array
  const coverImage = passedData[0].images.find((img:any)=> img.isCover) || null;
  console.log(coverImage);

  return (
    <header className="">
      <div className="text-center  font-semibold relative mb-4">
        <span className="hidden md:block absolute left-0 top-1 cursor-pointer">
          <FaChevronLeft />
        </span>
        Review
      </div>
      <div className="rounded-3xl border-black border-opacity-25 border-[1px] md:border-none">
        <div className="w-full">
          <img
            className="w-full rounded-t-3xl md:rounded-none h-[35vh]  object-cover"
            src={coverImage ? coverImage.uri : propertyImage}
            alt=""
          />
        </div>
        <div className="md:flex items-center justify-between p-3 md:px-0 md:py-4">
          <div className="md:w-[60%] relative pr-4">
            <h2 className="text-sm md:text-[18px] text-[#202020] font-semibold relative">
             {passedData[0].bhk} {passedData[0].property_type  } 
              <span className="text-[#FBB337] ml-2 absolute top-[3px]">
                <RiVerifiedBadgeFill />
              </span>
            </h2>
            <p className="text-[#404040] text-[10px] md:text-base font-normal py-2">
             {passedData[0].city}
            </p>
            <div className="hidden md:block absolute top-1/4 right-0 w-[1px] h-1/2 bg-black"></div>
          </div>

          <div className="md:w-[40%] md:text-center">
            <h3 className="font-semibold text-[#202020] text-sm md:text-[18px]">
              {passedData[0].price || passedData[0].cost || passedData[0].enter_lease_amount || passedData[0].lease_amount || (passedData[0].monthly_rent ? `${passedData[0].monthly_rent} / Month` : passedData[0])}
            </h3>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
