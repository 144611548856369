import React from 'react';
import { useStepperContext } from './StepperContext';
import { InputAdornment, TextField, SxProps, Theme } from '@mui/material';
import { keyboard } from '@testing-library/user-event/dist/keyboard';
import { maskCurrency } from '../../PostForm/data';

interface PriceTextFieldProps {
  label?: any;
  formName: string;
  inputId?: number;
  label2?: string;
  keyboard: any;
  required?: boolean;
  userselectedData?: any;
  initial?: any;
  sx?: SxProps<Theme>;
}

const PriceTextField: React.FC<PriceTextFieldProps> = ({
  label,
  formName,
  inputId,
  label2,
  keyboard,
  required,
  userselectedData,
  initial,
}) => {
  const { addUserData } = useStepperContext();
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | { value: unknown }>
  ) => {
    const data = {
      id: inputId,
      [label]: maskCurrency(event.target.value as any),
      formName: formName,
      required: required,
    };
    addUserData(data as any);
  };
  console.log('ll', label, initial);
  const hasError = userselectedData?.includes(label);

  const convertToNumber = (currencyString:any) => {
    // Remove currency symbols and commas, then convert to number
    const numericString = currencyString
      .replace(/[^0-9.]/g, '') // Remove non-numeric characters except for decimal points
      .replace(/^0+(?!\.|$)/, ''); // Remove leading zeros
  
    return parseFloat(numericString); // Convert the cleaned string to a number
  };
  const cleanedData = convertToNumber(initial);
  return (
    <div>
      <TextField
        id={label}
        label={label}
        type={keyboard ? keyboard : 'text'}
        sx={{
          '& .MuiInput-underline:before': {
            borderBottom: '1px dotted',
          },
          '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: '1px dotted',
          },
        }}
        onChange={handleChange}
        defaultValue={cleanedData}
        variant="standard"
        className="w-[100%]"
        InputProps={{
          startAdornment: <InputAdornment position="start">₹</InputAdornment>,
          endAdornment: label2 && (
            <InputAdornment position="end">{label2}</InputAdornment>
          ),
        }}>
        </TextField>

      {hasError && <div>This field is required</div>}
    </div>
  );
};

export default PriceTextField;
