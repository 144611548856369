import React, { useEffect, useState } from "react";
import BG1 from './images/BG1.png';
import { HiBell } from "react-icons/hi";
import { FaSearch } from "react-icons/fa";
import { FaRegHeart } from "react-icons/fa";
import sort from './images/sortby.svg';
import filter from './images/filter.svg';
import { ImInsertTemplate } from "react-icons/im";
import cancel from './images/cancel.svg';
import Filter from "./filter/Filter";
import SortBy from "./sortBy/SortBy";
import search from "./images/Search.svg"
import { Chip, Box } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel'; 

interface SearchItem {
    id: number;
    place: string;
}

interface sort {
    sortOpen: any
    setValue: any
    setSortOpen: any
    setLoading: any
    setfilterLoaction: any
    filterLoaction: any
}

const Search: React.FC<sort> = ({ sortOpen, setValue, setSortOpen, setLoading, setfilterLoaction, filterLoaction }) => {
    const [ename, setEname] = useState<string>("");
    const [sitem, setSitem] = useState<SearchItem[]>([
        { id: 1, place: "Adyar" },
        { id: 2, place: "Tambaram" }
    ]);
    const [filterProperty, setFilterProperty] = useState<string[]>([]);
    // const [filterLoaction, setfilterLoaction] = useState<any[]>([])
    const [filterPrice, setFilterPrice] = useState<any>(true)
    const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false);
    const [kb, setKb] = useState<any>(false)
    const [receivedValue, setReceivedValue] = useState<any>(null);
    console.log(filterLoaction);
    console.log(filterProperty);
    console.log(filterPrice);




    const handgleinput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEname(event.target.value);
    }

    const addEname = () => {
        if (ename.trim() !== "") {
            setSitem([...sitem, { id: sitem.length + 1, place: ename }]);
        }
        setEname("");
    }

    const handgleinputdelete = (id: number) => {
        setSitem(sitem.filter(item => item.id !== id));
    }

    const toggleFilterVisibility = () => {
        setIsFilterVisible(!isFilterVisible);
        setSortOpen(false)

    }
    // const handleChildData = (data: any) => {
    //     setFilterProperty(data)
    //     console.log(data);
    // };

    function handleSort() {
        console.log("hello");
        setIsFilterVisible(false);
        setSortOpen(!sortOpen)
    }

    // function valueLabelFormat() {
    //     if (filterPrice >= 1000 && filterPrice < 100000) {
    //         return (filterPrice / 1000).toFixed(1) + 'k';
    //     }
    //     if (filterPrice >= 10000000) {
    //         return (filterPrice / 10000000).toFixed(2) + ' Cr';
    //     } else if (filterPrice >= 100000) {
    //         return (filterPrice / 100000).toFixed(2) + ' Lac';
    //     }
    //     return filterPrice;
    // }
    return (
        <div className=" md:hidden lg:block md:h-0 lg:h-[99%] h-[99%] w-[50vw]" >
            <div className=" lg:block bg-[#FFFFFF] lg:mt-[7%] msm:hidden sm:hidden">

                    <div className="flex flex-row items-center justify-between xl:gap-10   " style={{width:"100%"}}>
                        {/* <div className="relative  flex items-center rounded-2xl ">
                            <input
                                onClick={toggleFilterVisibility}
                                value={ename}

                                className="text-[#202020] cursor-pointer xl:py-3 lg:py-1  lg:px-10  xl:px-20 bg-[#ECEBEF] flex items-center   rounded-l-3xl  outline-none border 
                                placeholder-[#9D9D9D]  placeholder:absolute placeholder:ml-"
                                placeholder="Search Upto 3 locations"
                            />
                            <button
                                onClick={addEname}
                                className="bg-[#2E368F] cursor-pointer   px-4   xl:py-4 lg:py-2  rounded-r-3xl  flex items-center justify-center"
                            >
                                <img className="  " src={search} />
                            </button>
                        </div> */}
     {/* <div className="flex gap-4 ml-3 mt-3 items-center" style={{width:"70%"}} >
                    <div className="">
                        <p className=" font-semibold">You are currently searching a property at</p>
                        <div className="flex gap-4 mt-2">
                            {Array.isArray(filterLoaction) && filterLoaction.map((item: any, index: number) => (
                                <div key={index} className="flex gap-1 items-center">
                                    <p className="text-[#252B5C] font-semibold">{item}</p>
                                    <img src={cancel} alt="cancel icon" />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="border-solid border-1  border-l border-gray-600 lg:h-5  my-"></div>
              
                </div> */}
                  <Box className="flex gap-4 ml-3  items-center" sx={{ width: '70%', }}>
      <Box>
        <p className="font-semibold">You are currently searching a property at</p>
        <Box className="flex gap-2 mt-1 mb-1">
          {Array.isArray(filterLoaction) && filterLoaction.map((item, index) => (
            <Chip
              key={index}
              label={item}
              onDelete={() => console.log(`Delete ${item}`)} // Add your delete logic here
              deleteIcon={<CancelIcon />}
              sx={{ backgroundColor: '#D1D1D1', color: '#252B5C' }} // Customize the Chip styles
            />
          ))}
        </Box>
      </Box>
    </Box>
            <div className='border-1 border-black h-9  msm:hidden sm:flex opacity-30  sm:mr-1'></div>
    <div style={{width:"30%",}} className="flex justify-between  xl:mr-[1%] lg:mr-[3%] lg:gap-3 xl:gap-3">
                            <div onClick={handleSort} className="cursor-pointer flex  items-center text-[#2E368F] gap-1">
                                <img className="flex justify-center items-center h-5 " src={sort} alt="Sort" />
                                <p className="flex justify-center items-center lg:text-base text-lg font-semibold">Sort by</p>
                            </div>
                            <div className='border-1 border-black h-9  msm:hidden sm:flex opacity-30  sm:mr-1'></div>

                            <div onClick={toggleFilterVisibility} className="cursor-pointer flex items-center gap-1">
                                <img className="h-5" src={filter} alt="Filter" />
                                <p className="font-semibold xl:text-lg lg:text-base text-[#2E368F]">Filter</p>
                            </div>
                        </div>

                </div>
           
            </div>

            <div className={`transition-all duration-1000 ${isFilterVisible ? 'flex' : 'hidden'}`}>
                <Filter kb={kb} setLoading={setLoading} toggleFilterVisibility={toggleFilterVisibility} setFilterProperty={setFilterProperty} setfilterLoaction={setfilterLoaction} setFilterPrice={setFilterPrice} />
            </div>

            <div className={`${sortOpen ? "flex" : "hidden"} `}
            >
                <SortBy setValue={setValue} handleSort={handleSort} />
            </div>
        </div>
    );
}

export default Search;
