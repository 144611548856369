import React from 'react';
import { useLocation } from 'react-router-dom';
import phone from '../compounts/images/Black-phone.png';
import mail from '../compounts/images/mail.png';
import call from '../compounts/images/Call.png';

const ContactUser: React.FC = () => {
  const location = useLocation();
  const data = location.state?.data ?? []; // Ensure data is an array

  console.log(data);
  console.log(data.length);

  return (
    <div className='mt-20 flex flex-col gap-6'>
      {Array.isArray(data) && data.length > 0 ? (
        data.map((item: any, index: number) => (
          <div key={index} className='flex gap-10 mx-[15%] justify-between bg-[#F5F4F8] items-center p-4 rounded-lg'>
            <div className='flex flex-col gap-1 ml-2'>
              <p className='text-lg font-semibold'>{item.name}</p>
              <p className='text-sm text-gray-500'>{new Date(item.createdAt).toLocaleDateString()}</p>
              <div className='flex items-center justify-start gap-2'>
                <img src={phone} alt="Phone icon" className='w-4 h-4' />
                <p>{item.mobile_no}</p>
              </div>
              <div className='flex items-center gap-2'>
                <img src={mail} alt="Mail icon" className='w-4 h-4' />
                <p>{item.email}</p>
              </div>
            </div>
            <div className='flex mr-3 bg-[#FBB337] w-20 h-20 justify-center rounded-full items-center'>
              <img src={call} alt="Call icon" className='w-10 h-10' />
            </div>
          </div>
        ))
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};

export default ContactUser;
