// // // // import React from "react";
// // // // import { useLocation } from 'react-router-dom';

// // // // // Define the type for the image object
// // // // interface Image {
// // // //   id: number;
// // // //   filename: string;
// // // //   name: string;
// // // //   uri: string;
// // // //   isCover: boolean;
// // // // }

// // // // const UpComingProjects: React.FC = () => {
// // // //   const location = useLocation();
// // // //   const passedData = location.state as Image[]; // Typecast location.state to Image array

// // // //   console.log('Passed Data:', passedData);

// // // //   // Filter out non-cover images
// // // //   const nonCoverImages = passedData.filter((img: Image) => !img.isCover);

// // // //   return (
// // // //     <div className="md:py-4 w-full md:w-[90%] xl:w-[70%] mx-auto">
// // // //       <header className="px-2 py-3 text-base md:text-lg lg:text-xl flex items-center gap-2">
// // // //         <div className="font-bold">Upcoming Projects</div>
// // // //       </header>
// // // //       <div className=" w-[80%]">
// // // //         {nonCoverImages.map((image) => (
// // // //           <div key={image.id} className="border rounded shadow">
// // // //             <img
// // // //               src={image.uri}
// // // //               alt={image.name}
// // // //               className="w-full h-auto object-cover rounded"
// // // //             />
// // // //             <p className="text-center mt-2 text-sm">{image.name}</p>
// // // //           </div>
// // // //         ))}
// // // //       </div>
// // // //     </div>
// // // //   );
// // // // };

// // // // export default UpComingProjects;


// // // import React from "react";
// // // import { useLocation, useNavigate } from 'react-router-dom';

// // // // Define the type for the image object
// // // interface Image {
// // //   id: number;
// // //   filename: string;
// // //   name: string;
// // //   uri: string;
// // //   isCover: boolean;
// // // }

// // // const UpComingProjects: React.FC = () => {
// // //   const location = useLocation();
// // //   const navigate = useNavigate();
// // //   const passedData = location.state as Image[]; // Typecast location.state to Image array

// // //   console.log('Passed Data:', passedData);

// // //   // Filter out non-cover images
// // //   const nonCoverImages = passedData.filter((img: Image) => !img.isCover);

// // //   return (
// // //     <div className="md:py-4 w-full md:w-[90%] xl:w-[70%] mx-auto">
// // //       <header className="px-2 py-3 text-base md:text-lg lg:text-xl flex items-center gap-2">
// // //         <button onClick={() => navigate(-1)} className="text-blue-500">
// // //           Back
// // //         </button>
// // //         <div className="font-bold">Upcoming Projects</div>
// // //       </header>
// // //       <div className="flex flex-column flex-wrap justify-center w-full">
// // //         {nonCoverImages.map((image) => (
// // //           <div key={image.id} className="border rounded shadow m-2 w-full sm:w-1/2 md:w-1/3 lg:w-1/4">
// // //             <img
// // //               src={image.uri}
// // //               alt={image.name}
// // //               className="w-full h-auto object-cover rounded"
// // //             />
// // //             {/* <p className="text-center mt-2 text-sm">{image.name}</p> */}
// // //           </div>
// // //         ))}
// // //       </div>
// // //     </div>
// // //   );
// // // };

// // // export default UpComingProjects;


// // import React from "react";
// // import { useLocation, useNavigate } from 'react-router-dom';
// // import ArrowBackIcon from '@mui/icons-material/ArrowBack';


// // // Define the type for the image object
// // interface Image {
// //   id: number;
// //   filename: string;
// //   name: string;
// //   uri: string;
// //   isCover: boolean;
// // }

// // const UpComingProjects: React.FC = () => {
// //   const location = useLocation();
// //   const navigate = useNavigate();
// //   const passedData = location.state as Image[]; // Typecast location.state to Image array

// //   console.log('Passed Data:', passedData);

// //   // Filter out non-cover images
// //   const nonCoverImages = passedData.filter((img: Image) => !img.isCover);

// //   return (
// //     <div className="md:py-4 w-full md:w-[90%] xl:w-[70%] mx-auto">
// //       <header className="px-2 py-3 text-base md:text-lg lg:text-xl flex items-center gap-2">
// //       <button 
// //         onClick={() => navigate(-1)} 
// //         className="flex items-center mb-4 text-blue-500"
// //       >
// //         <ArrowBackIcon className="mr-2" />
// //         Back
// //       </button> 
// //         <div className="font-bold">Upcoming Projects</div>
// //       </header>
// //       <div className="flex flex-col items-center w-full">
// //         {nonCoverImages.map((image) => (
// //           <div key={image.id} className="shadow  w-[70%] h-full">
// //             <img
// //               src={image.uri}
// //               alt={image.name}
// //               className="w-full h-auto "
// //             />
// //             {/* <p className="text-center mt-2 text-sm">{image.name}</p> */}
// //           </div>
// //         ))}
// //       </div>
// //     </div>
// //   );
// // };

// // export default UpComingProjects;

// import React from "react";
// import { useLocation, useNavigate } from 'react-router-dom';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// // Define the type for the image object
// interface Image {
//   id: number;
//   filename: string;
//   name: string;
//   uri: string;
//   isCover: boolean;
// }

// const UpComingProjects: React.FC = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const passedData = location.state as Image[]; // Typecast location.state to Image array

//   console.log('Passed Data:', passedData);

//   // Filter out non-cover images
//   const nonCoverImages = passedData.filter((img: Image) => !img.isCover);

//   return (
//     <div className="md:py-4 w-full md:w-[90%] xl:w-[70%] mx-auto">
// <header className="flex flex-col md:flex-row items-start md:items-center px-2 py-3 text-base md:text-lg lg:text-xl mt-4 md:mt-0 ml-[3%]">
       
//         <button 
//           onClick={() => navigate(-1)} 
//           className="flex items-center mb-2 md:mb-0 text-blue-500"
//         >
//           <ArrowBackIcon className="mr-2" />
//           Upcoming Projects
//         </button>
//       </header>
//       <div className="flex flex-col items-center w-full">
//         {nonCoverImages.map((image) => (
//           <div key={image.id} className="shadow w-[90%] md:w-[70%] h-full">
//             <img
//               src={image.uri}
//               alt={image.name}
//               className="w-full h-auto"
//             />
//             {/* <p className="text-center mt-2 text-sm">{image.name}</p> */}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default UpComingProjects;

import React from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// Define the type for the image object
interface Image {
  id: number;
  filename: string;
  name: string;
  uri: string;
  isCover: boolean;
}

const UpComingProjects: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const passedData = location.state as Image[]; // Typecast location.state to Image array

  console.log('Passed Data:', passedData);

  // Filter out non-cover images
  const nonCoverImages = passedData.filter((img: Image) => !img.isCover);

  return (
    <div className="md:py-4 w-full md:w-[90%] xl:w-[70%] mx-auto">
      <header className="flex flex-col md:flex-row items-start md:items-center px-2 py-3 text-base md:text-lg lg:text-xl mt-4 md:mt-0 ml-[3%]">
        <button 
          onClick={() => navigate(-1)} 
          className="flex items-center mb-2 md:mb-0 text-black-500"
        >
          <ArrowBackIcon className="mr-2" />
        </button>
      </header>
      <div className="flex flex-col items-center w-full">
        {nonCoverImages.map((image) => (
          <div key={image.id} className="shadow w-[90%] md:w-[70%] h-full">
            <img
              src={image.uri}
              alt={image.name}
              className="w-full h-auto"
            />
            {/* <p className="text-center mt-2 text-sm">{image.name}</p> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default UpComingProjects;
