import React from 'react'
import mobile from '../images/mobile.png'
import store from '../images/playstore.png'


const AppPlayStore = () => {
  return (
    <div className='sm:flex msm:flex-col msm:flex sm:flex-row items-center gap-1 mb-2 msm:mx-2 xl:mx-[20%]'>
        <div>
            <img className='h-[60%] w-[100%]' src={mobile}/>
        </div>
        <div className='msm:flex  msm:flex-col sm:block msm:mx-2 md:mx-0'>
            <p className='md:text-2xl msm:text-base font-semibold text-[#1A1A1A]'>Experience Real Estate on the Go with Our Mobile App</p>
            <div className='mt-3 mb-3 text-sm text-[#343434]'>
                <li>Browse properties effortlessly.</li>
                <li>Instant alerts and updates.</li>
                <li>Connect and chat seamlessly.</li>
            </div>
            <p className='text-[#2E368F] mb-2 text-lg font-semibold'>Download Our App</p>
            <img className='w-[30%] ' src={store}/>
        </div>
    </div>
  )
}

export default AppPlayStore