import React, { useState, useEffect } from "react";
import postproperty from "../images/Property post ad.svg";
import post from "../images/Property post Rent.svg";
import postcom from "../images/comadhome.png";
import homemob1 from "../images/homeadmobile/homemobile1.png";
import homemob2 from "../images/homeadmobile/homemobile2.png";
import homemob3 from "../images/homeadmobile/homemobile3.png";

interface Props {
  lookingToValue: string;
}

const PropertyHousing: React.FC<Props> = ({ lookingToValue }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  let imageSrc;
  let imageSrc2;

  if (lookingToValue === "Rent" || lookingToValue === "Lease") {
    imageSrc = post;
    imageSrc2 = homemob2;
  } else if (lookingToValue === "Commercial") {
    imageSrc = postcom;
    imageSrc2 = homemob3;
  } else {
    imageSrc = postproperty;
    imageSrc2 = homemob1;
  }

  return (
    <div className="flex justify-center items-center msm:mx-2 xl:mx-[20%]  md:mx-[20%]mt-3 ">
      <div>
        <img src={isMobile ? imageSrc2 : imageSrc} alt="Post Property" />
      </div>
    </div>
  );
};

export default PropertyHousing;

